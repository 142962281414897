import * as uiActions from './ui.actions';
import {UiActions} from './ui.actions';
import {MobbexErrorCodes} from '../../services/mobbexCodes.service';
import {
	IntPaymentFail,
	IntUIBannerMkt,
	IntUICCInfo,
	IntUISidebarMensajesTab,
	IntUISidebarUserLinks,
	IntUISidebarVisitasTab
} from './ui.model';

export interface UiState {
    currentTitle: string;
    sidebarActiveVisitasTab: IntUISidebarVisitasTab;
    sidebarActiveMsgTab: IntUISidebarMensajesTab;
    bodyScrollLock: boolean;
    bodyGenderClass: string;
    bodyScrollLockCount: number;
    sidebarMsgSend: IntUISidebarUserLinks[];
    sidebarMsgRcv: IntUISidebarUserLinks[];
    sidebarVisitsSend: IntUISidebarUserLinks[];
    sidebarVisitsRcv: IntUISidebarUserLinks[];
    bannerMkt: IntUIBannerMkt;
    isStaticPage: boolean;
    isUploadPhoto: boolean;
    showSuscribiteMenu: boolean;
    isSuscribiteBack: boolean;
	isPaymentLoading: boolean;
	isPaymentSuccess: boolean;
	isPaymentThankYouPage : boolean;
	paymentThankYouPageError: string;
	isPaymentThankYouPageError: boolean;
	paymentCCInfo: IntUICCInfo;
	paymentFails: IntPaymentFail;
	zendeskshow: boolean;
	isLoadingSidebarVisits: boolean;
	isLoadingSidebarMsgs: boolean;
	loginErrorContainerShow: boolean;
}

export const INIT_UI_STATE: UiState = {
    currentTitle: undefined,
    bodyGenderClass: '',
    bodyScrollLock: false,
    bodyScrollLockCount: 0,
    sidebarActiveVisitasTab: IntUISidebarVisitasTab.UI_SIDE_VISITAS_RECIBIDAS,
    sidebarActiveMsgTab: IntUISidebarMensajesTab.UI_SIDE_MSG_RECIBIDOS,
    sidebarMsgSend: [],
    sidebarMsgRcv: [],
    sidebarVisitsSend: [],
    sidebarVisitsRcv: [],
    bannerMkt: null,
	isStaticPage: false,
	isUploadPhoto: false,
	showSuscribiteMenu: false,
	isSuscribiteBack: false,
	isPaymentLoading: false,
	isPaymentSuccess: false,
	isPaymentThankYouPage: false,
	paymentThankYouPageError: '',
	isPaymentThankYouPageError: false,
	paymentCCInfo: {imgUrl:'',issuerBank:''},
	paymentFails: {error_nro: 0, message: ''},
	zendeskshow: true,
	isLoadingSidebarMsgs: false,
	isLoadingSidebarVisits: false,
	loginErrorContainerShow: false
};


export function reducer(
    state: UiState = INIT_UI_STATE,
    action: UiActions,
): UiState {
    switch (action.type) {

		case uiActions.UiActionTypes.LoginErrorContainerShow: {
			return {
				...state,
				loginErrorContainerShow: action.payload
			}
		}

		case uiActions.UiActionTypes.ZendeskShow: {
			return {
				...state,
				zendeskshow: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentFails: {
			return {
				...state,
				paymentFails: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentCCInfo: {
			return {
				...state,
				paymentCCInfo: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentLoading:{
			return {
				...state,
				isPaymentLoading: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentSuccess:{
			return {
				...state,
				isPaymentSuccess: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentThankYouPage:{
			return {
				...state,
				isPaymentThankYouPage: action.payload
			}
		}

		case uiActions.UiActionTypes.PaymentThankYouPageError:{
			const tmp = MobbexErrorCodes(action.payload.code);
			return {
				...state,
				isPaymentThankYouPageError: true,
				paymentThankYouPageError: tmp
			}
		}

		case uiActions.UiActionTypes.UISuscribiteMenuBack:{
			return {
				...state,
				isSuscribiteBack: action.payload
			}
		}

		case uiActions.UiActionTypes.UISuscribiteMenu : {
			return {
				...state,
				showSuscribiteMenu: action.payload
			}
		}

		case uiActions.UiActionTypes.UIUploadPhoto : {
			return {
				...state,
				isUploadPhoto: action.payload
			}
		}

		case uiActions.UiActionTypes.UIStatic : {
			return {
				...state,
				isStaticPage: action.payload
			}
		}

        case uiActions.UiActionTypes.UICleanup : {
            return {
                ...INIT_UI_STATE,
            };
        }

        case uiActions.UiActionTypes.SetCurrentTitle : {
            return {
                ...state,
                currentTitle: action.payload
            };
        }

        case uiActions.UiActionTypes.BodyScrollLock : {
            return {
                ...state,
                bodyScrollLockCount: state.bodyScrollLockCount + 1,
                bodyScrollLock: true
            };
        }

        case uiActions.UiActionTypes.BodyGenderClass : {
            return {
                ...state,
                bodyGenderClass: action.payload.class
            };
        }

        case uiActions.UiActionTypes.BodyScrollUnLock : {

            let tmp = state.bodyScrollLockCount - 1;
            if (tmp < 0) {
                tmp = 0;
            }

            return {
                ...state,
                bodyScrollLockCount: tmp,
                bodyScrollLock: (tmp > 0)   // disable when the count ref has got down to 0
            };
        }

        case uiActions.UiActionTypes.SidebarSetVisitasTab : {
            return {
                ...state,
                sidebarActiveVisitasTab: action.payload.activeTab
            };
        }

        case uiActions.UiActionTypes.SidebarSetMensajesTab : {
            return {
                ...state,
                sidebarActiveMsgTab: action.payload.activeTab
            };
        }

        case uiActions.UiActionTypes.SidebarGetMsgSentSuccess : {
            return {
                ...state,
                sidebarMsgSend: action.payload.users
            };
        }

		case uiActions.UiActionTypes.SidebarGetMsgRcv : {
			return { ...state, isLoadingSidebarMsgs: true }
		}
        case uiActions.UiActionTypes.SidebarGetMsgRcvSuccess : {
            return {
                ...state,
				isLoadingSidebarMsgs: false,
                sidebarMsgRcv: action.payload.users
            };
        }

		case uiActions.UiActionTypes.SidebarGetVisitsRcv : {
			return { ...state, isLoadingSidebarVisits: true }
		}
        case uiActions.UiActionTypes.SidebarGetVisitsRcvSuccess : {
            return {
                ...state,
				isLoadingSidebarVisits: false,
                sidebarVisitsRcv: action.payload.users
            };
        }

        case uiActions.UiActionTypes.SidebarGetVisitsSentSuccess : {
            return {
                ...state,
                sidebarVisitsSend: action.payload.users
            };
        }

        case uiActions.UiActionTypes.UpdateSidebarVisitsSent : {
            // Si el user esta en el array moverlo, caso contrario agregarlo y truncar
            const ix = state.sidebarVisitsSend.findIndex((e) => {
                return e.userId && (e.userId === action.payload.user.userId);
            });

            let newarr = null;
            if (ix >= 0) {
                const tmpArr = [...state.sidebarVisitsSend];
                const item = tmpArr.splice(ix, 1);
                newarr = [item[0], ...tmpArr];
            } else {
                const tmpArr = [...state.sidebarVisitsSend];
                newarr = [action.payload.user, ...tmpArr.slice(0, 7)];
            }

            return {
                ...state,
                sidebarVisitsSend: newarr
            };
        }

        case uiActions.UiActionTypes.UpdateSidebarVisitsRcv: {
            // Si el user esta en el array moverlo, caso contrario agregarlo y truncar
            const ix = state.sidebarVisitsRcv.findIndex((e) => {
                return e.userId && (e.userId === action.payload.user.userId);
            });

            let newarr = null;
            if (ix >= 0) {
                const tmpArr = [...state.sidebarVisitsRcv];
                const item = tmpArr.splice(ix, 1);
                newarr = [item[0], ...tmpArr];
            } else {
                const tmpArr = [...state.sidebarVisitsRcv];
                newarr = [action.payload.user, ...tmpArr.slice(0, 7)];
            }

            return {
                ...state,
                sidebarVisitsRcv: newarr
            };
        }

        case uiActions.UiActionTypes.UpdateSidebarMsgSent: {
            // Si el user esta en el array moverlo, caso contrario agregarlo y truncar
            const ix = state.sidebarMsgSend.findIndex((e) => {
                return e.userId && (e.userId === action.payload.user.userId);
            });

            let newarr = null;
            if (ix >= 0) {
                const tmpArr = [...state.sidebarMsgSend];
                const item = tmpArr.splice(ix, 1);
                newarr = [item[0], ...tmpArr];
            } else {
                const tmpArr = [...state.sidebarMsgSend];
                newarr = [action.payload.user, ...tmpArr.slice(0, 7)];
            }

            return {
                ...state,
                sidebarMsgSend: newarr
            };
        }

        case uiActions.UiActionTypes.UpdateSidebarMsgRcv: {
            // Si el user esta en el array moverlo, caso contrario agregarlo y truncar
            const ix = state.sidebarMsgRcv.findIndex((e) => {
                return e.userId && (e.userId === action.payload.user.userId);
            });

            let newarr = null;
            if (ix >= 0) {
                const tmpArr = [...state.sidebarMsgRcv];
                const item = tmpArr.splice(ix, 1);
                newarr = [item[0], ...tmpArr];

            } else {
                const tmpArr = [...state.sidebarMsgRcv];
                newarr = [action.payload.user, ...tmpArr.slice(0, 7)];
            }

            return {
                ...state,
                sidebarMsgRcv: newarr
            };
        }

        case uiActions.UiActionTypes.BannerMkt: {
            return {
                ...state,
                bannerMkt: action.payload.mkt
            };
        }

        default : {
            return state;
        }
    }
}
