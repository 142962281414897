import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {IntLoginData, IntMatchInfo} from '../../store/user/user.model';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Subscription} from 'rxjs';
import {SeHaFormadoUnaPareja} from '../../store/user/user.actions';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {GaService} from '../../services/ga.service';
import {zcDeepCopy} from '../../services/zc.globals';

@Component({
    selector: 'app-match',
    templateUrl: './match.component.html',
    styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit, OnDestroy {

    subs: Subscription = null;
    subsSess: Subscription = null;
    match: IntMatchInfo = null;
    session: IntLoginData = null;

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private ga: GaService,
        private utils: ZcUtilsService
    ) {
        this.subs = this.store.pipe(select(fromRoot.getMatch)).subscribe(match => {
            // receiver siempre tiene que ser el otro user, para mostrar correctamente los mensajes
            if ( (match !== null) && this.session && this.session.sessionInfo && (match.receiver.iduser ===  this.session.sessionInfo.me.id) ) {
                const matchClone = zcDeepCopy(match)
                const tmp = {...matchClone.sender};
                matchClone.sender = {...matchClone.receiver};
                matchClone.receiver = {...tmp};
                this.match = matchClone;
            }
            else {
                this.match = match;
            }
            this.cd.markForCheck();
        });
        this.subsSess = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
            this.session = session;
        });
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsSess !== null) {
            this.subsSess.unsubscribe();
        }
    }

    close() {
        this.store.dispatch(new SeHaFormadoUnaPareja({match: null}));
    }

    sendMessage() {
        this.store.dispatch(new MessagingOpenChat({
            userId: parseInt(this.match.receiver.iduser, 10),
            retreiveChatHistory: true,
            userName: this.match.receiver.apodo,
            userAvatar: this.match.receiver.imagen,
            gender: this.match.receiver.sexo
        }));

        this.ga.triggerGenericEvent('evento', 'chat', 'alerta_evento');
        this.close();
    }

    getDefault(event: any, gender: string) {
        event.target.src = this.utils.getUserImageURL(gender, null);
    }
}
