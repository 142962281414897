import {ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Subscription} from 'rxjs';
import {IntUIBannerMkt} from '../../store/ui/ui.model';
import {BannerMkt} from '../../store/ui/ui.actions';
import {GaService} from '../../services/ga.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-bannermkt',
    templateUrl: './bannermkt.component.html',
    styleUrls: ['./bannermkt.component.scss']
})
export class BannermktComponent implements OnInit, OnDestroy {

    mkt: IntUIBannerMkt = null;
    mktSubs: Subscription = null;

    @HostListener('document:keydown.escape', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.close();
    }

    constructor(
        private cd: ChangeDetectorRef,
        private ga: GaService,
        private store: Store<fromRoot.State>,
		private router: Router
    ) {
        this.mktSubs = this.store.pipe(select(fromRoot.getBannerMkt)).subscribe((val) => {
            this.mkt = val;
            this.cd.markForCheck();
            if ((this.mkt !== null) && this.mkt.GA && this.mkt.GA.impression) {
                this.ga.gaTrack(this.mkt.GA.impression);
            }
        });

    }

    goToLink(url: string){
    	this.router.navigate([url]);
    	this.close();
	}

    ngOnDestroy(): void {
        if (this.mktSubs !== null) {
            this.mktSubs.unsubscribe();
        }
    }

    ngOnInit() {
    }

    close() {
        if ((this.mkt !== null) && this.mkt.GA && this.mkt.GA.click) {
            this.ga.gaTrack(this.mkt.GA.click);
        }

        this.mkt = null;
        this.store.dispatch(new BannerMkt({mkt: null}));

        // TODO: redirigir a paywall!
    }
}
