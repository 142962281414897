import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IntGustaronGetListing, IntGustaronListingResult, IntGustaronListingResults} from '../../store/gustaron/gustaron.model';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Router} from '@angular/router';
import {GustaronCancel, GustaronInitScroll, GustaronInitSearch} from '../../store/gustaron/gustaron.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {rowsIn} from '../../directives/animations';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {GaService} from '../../services/ga.service';

@Component({
    selector: 'app-gustaron',
    templateUrl: './gustaron.component.html',
    styleUrls: ['./gustaron.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ rowsIn ]
})
export class GustaronComponent implements OnInit, OnDestroy {

    enumListType: any = {
        GUSTARON_MATCHES: IntGustaronGetListing.GUSTARON_MATCHES,
        GUSTARON_LIKES: IntGustaronGetListing.GUSTARON_LIKES,
    };
    typeSubs: Subscription = null;


    results$: Observable<IntGustaronListingResults> = this.store.pipe(select(fromRoot.getGustaronResults));
    activeTab$: Observable<IntGustaronGetListing> = this.store.pipe(select(fromRoot.getGustaronActiveTab));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getGustaronInProcess));
    currentType: IntGustaronGetListing = IntGustaronGetListing.GUSTARON_MATCHES;

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private cd: ChangeDetectorRef,
        private router: Router,
		private ga: GaService
    ) {

        this.typeSubs = this.store.pipe(select(fromRoot.getGustaronActiveTab)).subscribe((tab: IntGustaronGetListing) => {
            this.currentType = tab;
            // this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.search(IntGustaronGetListing.GUSTARON_MATCHES);
    }

    ngOnDestroy(): void {
        if (this.typeSubs !== null) {
            this.typeSubs.unsubscribe();
        }
    }

    search(t: IntGustaronGetListing) {
        this.store.dispatch(new GustaronInitSearch({type: t}));
    }

    triggerScroll() {
        this.store.dispatch(new GustaronInitScroll({type: this.currentType}));
    }

    cancelLike(r: IntGustaronListingResult) {
        this.store.dispatch(new GustaronCancel({user: r}));
    }

    viewProfile(userId: string) {
        this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: 'sent'}));
    }

	sendMessage(userId,userName,userImage,userGender) {
		this.store.dispatch(new MessagingOpenChat({
			userId: parseInt(userId, 10),
			retreiveChatHistory: true,
			userName: userName,
			userAvatar: this.utils.getUserImageURL(userGender, userImage),
			gender: userGender
		}));
		this.ga.triggerGenericEvent('evento', 'chat', 'se_gustan');
	}
}
