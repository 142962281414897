import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UsersService} from '../store/user/users.service';
import {ToastrService} from 'ngx-toastr';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {IntMessagingMessage} from '../store/messaging/messaging.model';
import {MessagingIsOnline, MessagingIsTyping, MessagingMessageReceived} from '../store/messaging/messaging.actions';
import {ZcUtilsService} from './zc-utils.service';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {ProfileOpen} from '../store/profile/profile.actions';
import {BannerMkt, SidebarGetVisitsRcv} from '../store/ui/ui.actions';
import {SeHaFormadoUnaPareja, UserCounters} from '../store/user/user.actions';
import {IntUIBannerMkt} from '../store/ui/ui.model';
import {GaService} from './ga.service';

declare const Pusher: any;


export interface IntNotificationMessage {
    message: string;
    messageType: '1' | '2';
    sender: {
        apodo: string;
        edad: number;
        iduser: string;
        imagen: string;
        nacimiento: string;
        sexo: string;
    }
    timestamp: string;
    title: string;
}

@Injectable({
    providedIn: 'root'
})
export class PusherService {

    pusher: any = null;
    notificationsChannel: any = null;
    chatPresenceChannels: any = {};
    lastUserID: string = null;
	currentSession: any = null;
	isOnline: boolean = false;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private ga: GaService,
        private usersService: UsersService
    ) {
		this.store.pipe(select(fromRoot.getCurrentSession)).subscribe( data => {
			this.currentSession = data;
		});

    }

	sendTyping(channel: string,value: boolean): void {
		this.chatPresenceChannels[channel].trigger('client-typing',{status:value});
	}

	getSubscribePresence(idUsuarioReceptor: number){
		let ids = [this.currentSession.sessionInfo.me.id*1, idUsuarioReceptor*1];
		ids.sort(function(a, b){return a - b});
		const key = environment.pusher.chatChannelPrefix  + ids.join('.');
		//
		return this.pusher.subscribe(key);
	}

    /* Suscribir al chat */
    subscribeChatPresence(idUsuarioReceptor: number) {
		//
		let ids = [this.currentSession.sessionInfo.me.id*1, idUsuarioReceptor*1];
		ids.sort(function(a, b){return a - b});
		const key = environment.pusher.chatChannelPrefix  + ids.join('.');

		// init channel
        this.chatPresenceChannels[key] = this.pusher.subscribe(key);

		// subcribe events
		this.chatPresenceChannels[key].bind('pusher:subscription_succeeded', members =>{
			let isOnline = typeof members.members[idUsuarioReceptor] !== 'undefined';
			this.isOnline = isOnline;
			this.store.dispatch(new MessagingIsOnline(isOnline));
			//
			this.chatPresenceChannels[key].bind('pusher:member_added', () =>{
				this.isOnline = true;
				this.store.dispatch(new MessagingIsOnline(true));
			});
			this.chatPresenceChannels[key].bind('pusher:member_removed', () =>{
				this.isOnline = false;
				this.store.dispatch(new MessagingIsTyping(false));
				this.store.dispatch(new MessagingIsOnline(false));
			});
			this.chatPresenceChannels[key].bind('client-typing', typing =>{
				this.store.dispatch(new MessagingIsTyping(typing.status));
			});
		});

		// get message
		this.chatPresenceChannels[key].bind('mensaje', data => {
			this.store.dispatch(new MessagingIsTyping(false));
			this.store.dispatch(new MessagingMessageReceived({chat: this.returnMsgData(data)}));
		});

		// return channel key
		return key;
    }

    /* Conectar a pusher */
    setupPusher(userID: string) {
        this.lastUserID = userID;

        // disconnect if connected
        this.disconnect();

        this.pusher = new Pusher(environment.pusher.key, {
            authEndpoint: environment.appApi + '/' + environment.endpoints.pusherauth,
            encrypted: true
        });

        this.pusher.config.auth = {
            headers: {
                Authorization: 'Bearer ' + this.usersService.getAccessToken()
            }
        };

        this.pusher.logToConsole = environment.pusher.debug;

        this.pusher.connection.bind('disconnected', () => {
            // Reconectar solo si lastUserID no es null
            if (this.lastUserID !== null) {
                this.setupPusher(this.lastUserID);
            }
        });

        this.notificationsChannel = this.pusher.subscribe(environment.pusher.notifChannelPrefix + userID);

        this.notificationsChannel.bind('mensaje', (data: IntNotificationMessage) => {
            this.store.dispatch(new UserCounters());
			this.store.dispatch(new MessagingMessageReceived({chat: this.returnMsgData(data)}));
        });

        this.notificationsChannel.bind('visita', data => {
            //rosa
            const userAvatar = this.utils.getUserImageName(data.sender.sexo.toString(), data.sender.imagen);
            const toast = this.toastr.success(`
                    <img src="${userAvatar}" class="toast-image" />
                    <img src="assets/images/notification-visit.png" class="toast-type"/>
                    <span class="toast-title">${data.sender.apodo}</span>Está visitando tu perfil
                `, null, {
                progressBar: true,
                enableHtml: true,
                closeButton: true,
                progressAnimation: 'increasing',
                timeOut: 10000
            }).onTap.pipe(take(1))
            .subscribe(() => {
                this.store.dispatch(new ProfileOpen({userId: data.sender.iduser, type: 'rcv'}));
                this.ga.triggerGenericEvent('evento', 'vista_perfil', 'alerta_evento');
            });
            this.store.dispatch(new UserCounters());
            this.store.dispatch(new SidebarGetVisitsRcv());
        });

        this.notificationsChannel.bind('match', data => {
            this.store.dispatch(new SeHaFormadoUnaPareja({match: data}));
            this.ga.triggerGenericEvent('evento', 'se_gustan', 'impresion');
        });

        this.notificationsChannel.bind('system', data => {
            if (data.action && (data.action === 'mkt-pushes')) {
                const mkt: IntUIBannerMkt = {
                    imageDesktop: data.imageDesktop,
                    imageMobile: data.imageMobile,
                    linkDesktop: data.linkDesktop,
                    linkMobile: data.linkMobile,
					linkFrontSpa: data.linkFrontSpa,
                    GA: data.GA
                };
                this.store.dispatch(new BannerMkt({mkt: mkt}));
            }
        });
    }

	/* Disconnect */
	disconnect() {
		this.lastUserID = null;

		if (this.pusher !== null) {
			this.pusher.disconnect();
			this.pusher = null;

			this.chatPresenceChannels = {};
		}
	}

	unBindPrecense(channel: string) {
		this.chatPresenceChannels[channel].unsubscribe(channel);
	}

	private returnMsgData(data){
		const msg: IntMessagingMessage = {
			id: this.utils.generateTemporalID(),
			idemisor: parseInt(data.sender.iduser, 10),
			username: data.sender.apodo,
			date: 'hoy',
			fecha: data.timestamp,
			hour: moment(data.timestamp).format('HH:mm'),
			gcpImage: data.sender.imagen,
			gender: parseInt(data.sender.sexo, 10),
			img: '',
			soyUserAbreChat: false,
			texto: data.message,
			original: null,
			square180: null,
			tipoMensaje: data.messageType,
			_pending: false,
		};
		return msg;
	}

}
