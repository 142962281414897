import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Observable, Subscription} from 'rxjs';
import {IntMessagingChat, IntMessagingMessage, IntMessagingNewMessage} from '../../store/messaging/messaging.model';
import {
	MessagingCloseChat,
	MessagingIsOnline,
	MessagingIsTyping,
	MessagingScrollChat,
	MessagingSendMessage
} from '../../store/messaging/messaging.actions';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {CropperComponent, IntCropperImagesResult} from '../cropper/cropper.component';
import {PusherService} from '../../services/pusher.service';
import {IntGalleryPicture} from '../gallery/gallery.component';
import {
	ProfileClose,
	ProfileDoDislike,
	ProfileOpen,
	ProfileReport,
	ProfileReportSubmit
} from '../../store/profile/profile.actions';
import {IntUISidebarUserLinks} from '../../store/ui/ui.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessagingService} from '../../store/messaging/messaging.service';
import {GaService} from '../../services/ga.service';
import {MatDialogRef} from '@angular/material';
import {fadeIn, rowsIn} from '../../directives/animations';
import {UserCounters} from '../../store/user/user.actions';
import {getMessagingState, isOnline} from '../../store';
import * as fromMessaging from '../../store/messaging/messaging.reducer';

@Component({
    selector: 'app-messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('OpacityInOut', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('150ms ease', style({opacity: '1'}))
            ]),
            // transition(':leave', [
            //     animate('150ms ease', style({ opacity: '0' }))
            // ])
        ]), rowsIn, fadeIn
    ]
})
export class MessagingComponent implements OnInit, OnDestroy {

    @ViewChild('chatInfinityScroll') chatInfinityScroll: ElementRef;
    @ViewChild('chatMessages') chatMessages: ElementRef;
    @ViewChild('messageToSendInput') messageToSendInput: ElementRef;
    @ViewChild('appcropper', { static: true }) cropper: CropperComponent;

    chat: IntMessagingChat = null;
    inProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getMessagingProcess));
	isOnline$: Observable<boolean> = this.store.pipe(select(fromRoot.isOnline));
	isTyping$: Observable<boolean> = this.store.pipe(select(fromRoot.isTyping));
    chatUserId: number = 0;
	isOnline: boolean = false;
	channelKey: string = '';
	objPusher: any;

    showReportPop = false;
    motivoDenuncia = null;
    inScrollProgress = false;
    masMensajes = false;
    messageToSend = '';

    isGalleryOpen = false;

    galleryPictures: IntGalleryPicture[] = [];
    galleryStartIndex = 0;

    showEmojis = false;
    keepEmojisOpenFlag = false;
    emojiESP = {
        search: 'Buscar',
        notfound: 'No se encontraron Emojis',
        skintext: 'Seleccioná el tono de piel por defecto',
        categories: {
            search: 'Resultados',
            recent: 'Usados frecuentemente',
            people: 'Smileys y Personas',
            nature: 'Animales y Naturaleza',
            foods: 'Comida y Bebidas',
            activity: 'Actividades',
            places: 'Viajes y Lugares',
            objects: 'Objetos',
            symbols: 'Símbolos',
            flags: 'Banderas',
            custom: 'Personalizados',
        }
    };

    throttleTime = Date.now();
    useridSubs: Subscription = null;
    scrollSubs: Subscription = null;
    chatSubs: Subscription = null;
    moreSubs: Subscription = null;
    closeChatSubs: Subscription = null;
	isonlineSubs: Subscription = null;

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private cd: ChangeDetectorRef,
        private ga: GaService,
        private modalService: NgbModal,
        private messagingService: MessagingService,
        private pusherService: PusherService,
		private dialogRef: MatDialogRef<MessagingComponent>
    ) {

    }

    ngOnInit() {
		this.closeChatSubs = this.store.pipe(select(fromRoot.getCloseChat)).subscribe((val) => {
			if(val) { this.dialogRef.close(); }
		});
		this.chatSubs = this.store.pipe(select(fromRoot.getMessagingResults)).subscribe((val) => {
			this.chat = val;
			this.cd.markForCheck();
		});
		this.useridSubs = this.store.pipe(select(fromRoot.getMessagingUserID)).subscribe((val) => {

			// Setup initial state
			this.showEmojis = false;    // close the emoji picker in case it was open
			this.keepEmojisOpenFlag = false;
			this.masMensajes = false;
			this.messageToSend = '';
			this.motivoDenuncia = null;
			this.showReportPop = false;
			this.chatUserId = val;

			this.channelKey = this.pusherService.subscribeChatPresence(this.chatUserId);

			setTimeout(() => {
				if (!this.utils.isUndefinedOrNull(this.messageToSendInput)) {
					this.messageToSendInput.nativeElement.focus();
				}
			}, 100);
		});
		this.scrollSubs = this.store.pipe(select(fromRoot.getMessagingScrollProcess)).subscribe((val) => {
			this.inScrollProgress = val;
		});
		this.moreSubs = this.store.pipe(select(fromRoot.getMessagingMasMensajes)).subscribe((val) => {
			this.masMensajes = val;
		});
		this.isonlineSubs = this.isOnline$.subscribe( value => {
			this.isOnline = value;
		});

		this.dialogRef.backdropClick().subscribe( () =>{
			this.close();
		});

    }

    ngOnDestroy(): void {
		this.pusherService.unBindPrecense(this.channelKey);
		this.store.dispatch(new MessagingIsOnline(false));
		this.store.dispatch(new UserCounters());
        if (this.chatSubs !== null) {
            this.chatSubs.unsubscribe();
        }
        if (this.scrollSubs !== null) {
            this.scrollSubs.unsubscribe();
        }
        if (this.useridSubs !== null) {
            this.useridSubs.unsubscribe();
        }
        if (this.moreSubs !== null) {
            this.moreSubs.unsubscribe();
        }
        if (this.closeChatSubs !== null){
        	this.closeChatSubs.unsubscribe();
		}
		if	(this.isonlineSubs !== null){
			this.isonlineSubs.unsubscribe();
		}

    }

	bloquearUser(idUser: string){
		this.store.dispatch(new ProfileDoDislike({userId: idUser}));
		this.close();
	}

    reportar() {
        if (this.utils.isUndefinedOrNull(this.motivoDenuncia) || (this.motivoDenuncia === '')) {
            return;
        }

        if (this.motivoDenuncia === 99) {
            // otro motivo, abrir form de denuncia
            if (this.chat && this.chat.user && this.chat.user.name) {
                const userId = '' + this.chatUserId;
                const userNick = this.chat.user.name;
                this.store.dispatch(new MessagingCloseChat());
                this.store.dispatch(new ProfileReport({userId: userId, userNick: userNick}));
            }
        } else {
            // enviar reporte y cerrar
            this.store.dispatch(new ProfileReportSubmit({userId: '' + this.chatUserId, reportId: this.motivoDenuncia, reportText: '(sin detalle)'}));
            this.store.dispatch(new MessagingCloseChat());
        }
    }

    close() {
		this.store.dispatch(new MessagingCloseChat());
    }

    triggerInfinityScroll() {
        if (!this.masMensajes || this.inScrollProgress || (((this.throttleTime + 50) - Date.now()) > 0)) {
            return;
        }

        this.throttleTime = Date.now();

        if (!this.utils.isUndefinedOrNull(this.chatInfinityScroll)) {
            const chatInfinityScroll = this.chatInfinityScroll.nativeElement.getBoundingClientRect();
            if (chatInfinityScroll.top >= 0) {
                this.scroll();
            }
        }
    }

    scroll() {
        this.store.dispatch(new MessagingScrollChat());
    }

    sendMessage() {
		this.closeEmoji();

        setTimeout(() => {
            if (!this.utils.isUndefinedOrNull(this.messageToSendInput)) {
                // focus input
                this.messageToSendInput.nativeElement.focus();
            }
            if (!this.utils.isUndefinedOrNull(this.chatMessages)) {
                // scroll to bottom
                this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
            }
        }, 100);

        this.messageToSend = this.messageToSend.trim();
        if (this.messageToSend === '') {
            return;
        }

		const msg: IntMessagingNewMessage = {
			isChat: (this.isOnline) ? 'true' : 'false',
			mensaje: this.messageToSend,
			type: '1',
			userId: this.chatUserId,
			temporal_id: this.utils.generateTemporalID()
		};

		this.messageToSend = '';

		const userLink: IntUISidebarUserLinks = {
			userId: this.chat.user.id,
			gcpImage: this.chat.user.gcpImage,
			image: this.chat.user.gcpImage,
			gender: this.chat.user.genderId,
			username: this.chat.user.name,
			age: 0
		};
		this.pusherService.sendTyping(this.channelKey,false);
		this.store.dispatch(new MessagingSendMessage({message: msg, userLink: userLink}));
    }

    messagesKeyDown(event: KeyboardEvent) {
        var code = 0;

        if (this.showEmojis) {
            this.keepEmojisOpenFlag = false;
            this.showEmojis = false;
            return;
        }

        if (event.which !== undefined) {
            code = event.which;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        } else {
            return;
        }

		// typing
		if(code === 8 && this.messageToSend === ''){
			this.pusherService.sendTyping(this.channelKey,false);
		}else{ this.pusherService.sendTyping(this.channelKey,true); }

        if (code === 27) {
            event.preventDefault();
            this.close();
        } else if (code === 13) {
            event.preventDefault();
            this.sendMessage();
        }
    }

    sendImage() {
    	this.closeEmoji();
        this.cropper.initializeCropper();
    }

    croppedImage(e: IntCropperImagesResult) {
        if (e !== null) {
            this.doUploadImage(e);
        }
    }

    doUploadImage(f: IntCropperImagesResult) {
		const msg: IntMessagingNewMessage = {
			isChat: (this.isOnline) ? 'true' : 'false',
			mensaje: f.filename,
			file: f.cropped,
			type: '2',
			userId: this.chatUserId,
			temporal_id: this.utils.generateTemporalID()
		};

		const userLink: IntUISidebarUserLinks = {
			userId: this.chat.user.id,
			gcpImage: this.chat.user.gcpImage,
			image: this.chat.user.gcpImage,
			gender: this.chat.user.genderId,
			username: this.chat.user.name,
			age: 0
		};

		this.store.dispatch(new MessagingSendMessage({message: msg, userLink: userLink}));

		setTimeout(() => {
			if (!this.utils.isUndefinedOrNull(this.messageToSendInput)) {
				// focus input
				this.messageToSendInput.nativeElement.focus();
			}
			if (!this.utils.isUndefinedOrNull(this.chatMessages)) {
				// scroll to bottom
				this.chatMessages.nativeElement.scrollTop = this.chatMessages.nativeElement.scrollHeight;
			}
		}, 100);
    }

    doShowEmojis() {
        if (this.showEmojis) {
            this.keepEmojisOpenFlag = false;
            this.showEmojis = false;
            return;
        }

        this.showEmojis = true;
    }

    doEmojiSelect(e) {
        // this.showEmojis = false;
        this.keepEmojisOpenFlag = true; // prevent from closing on focus

        if (!this.messageToSend) {
            this.messageToSend = '';
        }

        if (e.emoji && e.emoji.shortName) {
            if (this.messageToSendInput.nativeElement.selectionStart || this.messageToSendInput.nativeElement.selectionStart == '0') {
                let pos = parseInt(this.messageToSendInput.nativeElement.selectionStart, 10);
                const part1 = this.messageToSend.substring(0, pos);
                const part2 = this.messageToSend.substring(pos);
                this.messageToSend = part1 + e.emoji.native + part2;
                pos = this.messageToSend.length;    // unicode aware length
                this.messageToSend += part2;

                setTimeout(() => {
                    this.messageToSendInput.nativeElement.setSelectionRange(pos, pos);
                }, 10);

            } else {
                this.messageToSend += e.emoji.native + ' ';
                setTimeout(() => {
                    this.messageToSendInput.nativeElement.setSelectionRange(this.messageToSend.length, this.messageToSend.length);
                }, 10);
            }
        }

        this.messageToSendInput.nativeElement.focus();
    }

    onInputFocus() {
        this.closeEmoji();
    }

    openGallery(m: IntMessagingMessage) {
        if (this.chat === null) {
            return;
        }

        this.galleryPictures = this.chat.conversacion
        .filter(m => m.tipoMensaje === '2')
        .map(m => {
            return {image: m.texto['original'], thumb: m.texto['square180']};
        });

        this.galleryStartIndex = this.galleryPictures.findIndex(i => {
            return i.image === m.texto['original'];
        });

        // console.debug(images);
        this.isGalleryOpen = true;
    }

    closeGallery() {
        this.isGalleryOpen = false;
    }

    askForPermission(modalPermission) {
        const modalRef = this.modalService.open(modalPermission, {centered: true});
        modalRef.result.then((ret) => {
                if (ret) {
                    this.messagingService.setImagePermission( this.chatUserId.toString(), true ).subscribe( (apiret) => {
                        if (apiret.status === 200) {
                            const tmp = {...this.chat};
                            tmp.imagenes = true;
                            this.chat = {...tmp};
                            this.cd.markForCheck();
                        }
                    });
                }
            },
            () => {
                return false;
            });
    }

    verperfil() {
        if (this.chat && this.chat.user && this.chat.user.name) {
            const userId = '' + this.chatUserId;
            this.store.dispatch(new MessagingCloseChat());
            this.store.dispatch(new ProfileClose());    // just in case it's open so bodylock updates
            this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: 'sent'}));
            this.ga.triggerGenericEvent('evento', 'vista_perfil', 'chat');
        }

    }

    private closeEmoji() {
		if (this.keepEmojisOpenFlag) {
			this.keepEmojisOpenFlag = false;
		} else if (this.showEmojis) {
			this.showEmojis = false;
		}
	}

    getEmojiImage(set: string, sheetSize: number) {
        // console.debug(set, sheetSize);
        return '/assets/emojis/emojis32.png';
    }

}
