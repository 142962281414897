import {Action} from '@ngrx/store';
import {IntVisitasGetListing, IntVisitasListingResults} from './visitas.model';


export enum VisitasActionTypes {
    VisitasInitSearch     = '[Visitas API] Init search',
    VisitasDoSearch       = '[Visitas API] Do search',
    VisitasInitScroll     = '[Visitas API] Init Scroll',
    VisitasDoScroll       = '[Visitas API] Do scroll',
    VisitasFail           = '[Visitas API] Fail',
    VisitasCleanup        = '[Visitas API] Cleanup',
}

export class VisitasInitSearch implements Action {
    readonly type = VisitasActionTypes.VisitasInitSearch;

    constructor(public payload: { type: IntVisitasGetListing }) {
    }
}
export class VisitasDoSearch implements Action {
    readonly type = VisitasActionTypes.VisitasDoSearch;

    constructor(public payload: { type: IntVisitasGetListing, results: IntVisitasListingResults }) {
    }
}
export class VisitasInitScroll implements Action {
    readonly type = VisitasActionTypes.VisitasInitScroll;

    constructor(public payload: { type: IntVisitasGetListing }) {
    }
}
export class VisitasDoScroll implements Action {
    readonly type = VisitasActionTypes.VisitasDoScroll;

    constructor(public payload: { type: IntVisitasGetListing, results: IntVisitasListingResults }) {
    }
}
export class VisitasFail implements Action {
    readonly type = VisitasActionTypes.VisitasFail;
    constructor(public payload: { results: IntVisitasListingResults }) {
    }
}
export class VisitasCleanup implements Action {
    readonly type = VisitasActionTypes.VisitasCleanup;
}


export type VisitasActions =
    VisitasInitSearch
    | VisitasDoSearch
    | VisitasInitScroll
    | VisitasDoScroll
    | VisitasFail
    | VisitasCleanup;
