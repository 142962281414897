import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss']
})
export class LoadingCircleComponent implements OnInit {

  @Input() tipo: string = 'medium'

  constructor() { }

  ngOnInit() {
  }

}
