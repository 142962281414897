import {Injectable, OnInit} from '@angular/core';
import {EMPTY, of} from 'rxjs';

import {catchError, map, mergeMap, take, tap, withLatestFrom,} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';
import * as MessagingActions from './messaging.actions';
import {MessagingCloseChat, MessagingOpenChat} from './messaging.actions';
import {MessagingService} from './messaging.service';
import {BodyScrollLock, BodyScrollUnLock, UpdateSidebarMsgRcv, UpdateSidebarMsgSent} from '../ui/ui.actions';
import {IntMessagingNewMessageReply} from './messaging.model';
import {ToastrService} from 'ngx-toastr';
import {MensajesMarkAsReadByUserId} from '../mensajes/mensajes.actions';
import * as UsersActions from '../user/user.actions';
import {IntUISidebarUserLinks} from '../ui/ui.model';
import {ProfileClose} from '../profile/profile.actions';
import {Router} from '@angular/router';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {GaService} from '../../services/ga.service';
import {MatDialog} from '@angular/material/dialog';
import {MessagingComponent} from '../../components/messaging/messaging.component';

@Injectable()
export class MessagingEffects{
    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private router: Router,
        private utils: ZcUtilsService,
        private ga: GaService,
        private messagingService: MessagingService,
        private toastr: ToastrService,
		public dialog: MatDialog
    ) {
    }

    dialogRef: any;

    @Effect()
    openChat$ = this.actions$.pipe(
        ofType<MessagingActions.MessagingOpenChat>(MessagingActions.MessagingActionTypes.MessagingOpenChat),
        mergeMap((action) => {
				return this.messagingService.getChat(action.payload.userId, null).pipe(
					map((results: any) => {
						if ((results.status === 200) && results.data) {
							// open modal
							this.dialog.open(MessagingComponent, {
								height: '600px',
								width: '900px',
							});
							this.store.dispatch(new MensajesMarkAsReadByUserId({userId: action.payload.userId}));
							//this.store.dispatch(new UsersActions.UserCounters()); ?????

							results.data.user.gender = action.payload.gender;
							return new MessagingActions.MessagingOpenChatSuccess({chat: results.data});
						} else {
							return new MessagingActions.MessagingFail();
						}
					}),
					catchError(err => {
						if (err.status && err.statusText && (err.status === 402)) {
							// HACK: for user whitout image
							let url;
							if(action.payload.userAvatar.indexOf('error') === -1){
								url = '/suscribite/RM/' + action.payload.userName + '/' + action.payload.userAvatar;
							}else{
								url = '/suscribite/RM/' + action.payload.userName + '/null';
							}
							this.router.navigateByUrl(url);
							return of(new MessagingActions.MessagingFail());
						} else {
							return of(new MessagingActions.MessagingFail());
						}
					})
				)
			}
        )
    );

    @Effect()
    scrollChat$ = this.actions$.pipe(
        ofType<MessagingActions.MessagingScrollChat>(MessagingActions.MessagingActionTypes.MessagingScrollChat),
        withLatestFrom(this.store),
        mergeMap((params) => {

            if (params[1].messaging.chat.conversacion.length === 0) {
                return of(new MessagingActions.MessagingFail());
            }

            const last = params[1].messaging.chat.conversacion[params[1].messaging.chat.conversacion.length - 1];

            return this.messagingService.getChat(params[1].messaging.chat.user.id, '' + last.id).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new MessagingActions.MessagingScrollChatSuccess({chat: results.data});
                    } else {
                        return new MessagingActions.MessagingFail();
                    }
                }),
                catchError(err => of(new MessagingActions.MessagingFail()))
            );
        })
    );


    @Effect()
    sendMessage$ = this.actions$.pipe(
        ofType<MessagingActions.MessagingSendMessage>(MessagingActions.MessagingActionTypes.MessagingSendMessage),
        mergeMap((action) =>
            this.messagingService.sendMessage(action.payload.message).pipe(
                map((results: IntMessagingNewMessageReply) => {
					if (results.status === 200) {
                        // Actualizar sidebar
                        this.store.dispatch(new UpdateSidebarMsgSent({user: action.payload.userLink}));
                        return new MessagingActions.MessagingSendMessageSuccess({message: action.payload.message, imagen: results.imgPath});
                    } else {
                        return new MessagingActions.MessagingFail();
                    }
                }),
                catchError(err => {
                    if (err['status'] && (err['status'] === 402)) {
                        // payment required!
                        this.store.dispatch(new ProfileClose());
                        this.store.dispatch(new MessagingActions.MessagingCloseChat());
                        // userLink.gcpImage is not gender fallback safe
                        this.router.navigateByUrl('/suscribite/SM/' + action.payload.userLink.username + '/' + this.utils.getUserImageForPaywall(action.payload.userLink.gender, action.payload.userLink.gcpImage));
                        return of(new MessagingActions.MessagingFail());
                    } else {
                        return of(new MessagingActions.MessagingFail());
                    }
                })
            )
        )
    );


    @Effect()
    receiveMessage$ = this.actions$.pipe(
        ofType<MessagingActions.MessagingMessageReceived>(MessagingActions.MessagingActionTypes.MessagingMessageReceived),
        withLatestFrom(this.store),
        map((params) => {
            const action = params[0].payload.chat;
            const state = params[1].messaging;

            // Actualizar sidebar
            const userLink: IntUISidebarUserLinks = {
                userId: action.idemisor,
                gcpImage: action.gcpImage,
                image: action.gcpImage,
                gender: '' + action.gender,
                username: action.username,
                age: 0
            };
            this.store.dispatch(new UpdateSidebarMsgRcv({user: userLink}));

            if ((state.chat === null) || (state.chat.user.id !== action.idemisor && !state.isOnline)) {
                const userAvatar = this.utils.getUserImageName(action.gender.toString(), action.gcpImage);
                this.toastr.info(`
                        <img src="${userAvatar}" class="toast-image" />
                        <img src="assets/images/notification-message.png" class="toast-type"/>
                        <span class="toast-title">${action.username}</span>Te envió un mensaje
                    `, null, {
                    progressBar: true,
                    enableHtml: true,
                    closeButton: true,
                    progressAnimation: 'increasing',
                    timeOut: 5000
                }).onTap.pipe(take(1)).subscribe(() => {
                    this.store.dispatch(new MessagingOpenChat({
                        userId: action.idemisor,
                        retreiveChatHistory: true,
                        userName: action.username,
                        userAvatar: userAvatar,
                        gender: action.gender.toString()
                    }));
                    this.ga.triggerGenericEvent('evento', 'chat', 'alerta_evento');
                });

                return new MessagingActions.MessagingMessageReceivedAdd({chat: null});
            } else {
                return new MessagingActions.MessagingMessageReceivedAdd({chat: action});
            }
        })
    );


    @Effect({dispatch: false})
    closeChat$ = this.actions$.pipe(
        ofType<MessagingActions.MessagingCloseChat>(MessagingActions.MessagingActionTypes.MessagingCloseChat),
        tap(() => this.store.dispatch(new BodyScrollUnLock()))
    );

}
