import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError,
    map, mergeMap, tap, withLatestFrom,
} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {RecomendadosService} from './recomendados.service';
import * as RecomendadosActions from './recomendados.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';

@Injectable()
export class RecomendadosEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private recomendadosService: RecomendadosService,
    ) {
    }

    @Effect()
    initListingRecomendados$ = this.actions$.pipe(
        ofType<RecomendadosActions.RecomendadosListing>(RecomendadosActions.RecomendadosActionTypes.RecomendadosListing),
        mergeMap((action) =>
            this.recomendadosService.listing().pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new RecomendadosActions.RecomendadosListingDone({results: results});
                    } else {
                        return new RecomendadosActions.RecomendadosListingFail({results: results});
                    }
                }),
                catchError(err => of(new RecomendadosActions.RecomendadosListingFail({results: err})))
            )
        )
    );
}
