import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

    @Input() label: string;
    @Input() value: string | number;
    @Input() checked: boolean;

    @Output() checkChange = new EventEmitter<MatCheckboxChange>();

    constructor() {
    }

    ngOnInit() {
    }

    onChange(event: MatCheckboxChange) {
        this.checkChange.emit(event);
    }
}
