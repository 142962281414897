import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Expo} from 'gsap/all';
import {FullscreenService} from '../../services/fullscreen.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ZcUtilsService} from '../../services/zc-utils.service';


declare var TweenLite: any;

/**
 npm install --save gsap
 npm install --save-dev @types/greensock

 add scripts in angular.json
 "node_modules/gsap/src/uncompressed/TweenLite.js"


 */

const ZOOM_LIST = [1, 2, 4, 8];

export interface IntGalleryPicture {
    image: string;
    thumb: string;
}

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, OnDestroy {

    @ViewChild('thumbs', { static: true }) thumbs: ElementRef;
    @ViewChild('thumbscont', { static: true }) thumbscont: ElementRef;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Input() pictures: IntGalleryPicture[];
    @Input() gender: string;
    @Input() currentIndex = 0;

    swipeRatio = 50;
    currentZoom = 0;

    currentXPosition = 0;

    _isFullScreen = false;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    @HostListener('document:keydown.escape', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.doClose();
    }

    constructor(
        private fss: FullscreenService,
        private utils: ZcUtilsService,
    ) {
        fss.fullscreen$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((isFullscreen) => {
            this._isFullScreen = isFullscreen;
        });
    }

    ngOnInit() {
        this.setInitialIndexPositionNoAnimation();
        // this.setXThumbPosition();
    }

    doClose() {
        this.close.emit();
    }

    getIndexText() {
        const index = this.currentIndex;
        const length = (this.pictures) ? this.pictures.length : 0;
        const current = (index < 9) ? '0' + (index + 1) : (index + 1);
        const total = (length < 9) ? '0' + length : length;
        return current + ' de ' + total;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    goprev() {
        if (this.currentZoom > 0) {
            this.currentZoom = 0;
        }
        this.currentIndex--;
        if (this.currentIndex < 0) {
            this.currentIndex = this.pictures.length - 1;
        }
        // update positions
        this.setXThumbPosition();
    }

    gonext() {
        if (this.currentZoom > 0) {
            this.currentZoom = 0;
        }
        this.currentIndex++;
        if (this.currentIndex > this.pictures.length - 1) {
            this.currentIndex = 0;
        }
        // update positions
        this.setXThumbPosition();
    }

    zoomOut() {
        if (this.currentZoom > 0) {
            this.currentZoom--;
        }
    }

    zoomIn() {
        if (this.currentZoom < ZOOM_LIST.length - 1) {
            this.currentZoom++;
        }
    }

    /**
     * Pan End
     * @param e
     */
    panEnd(e) {
        if ((this.currentZoom === 0) && (Math.abs(e.deltaX) > this.swipeRatio)) {
            if (e.deltaX > 0) {
                if (this.currentIndex > 0) {
                    this.currentIndex--;
                }
            } else if (this.currentIndex < this.pictures.length - 1) {
                this.currentIndex++;
            }
        }

        // update positions
        this.setXThumbPosition();
    }

    /**
     * Pan
     * @param e
     */
    pan(e) {
        if (this.currentZoom === 0) {
            const deltaVw = (e.deltaX * 100 / window.innerWidth) * 1.5;

            this.currentXPosition = this.currentIndex * -100 + deltaVw;
        }
    }


    tap(e) {
        // this.imageZoom.tap(e)
    }

    pinch(e) {
        // this.imageZoom.pich(e)
    }

    hasZoomOut() {
        return (this.currentZoom > 0);
    }

    hasZoomIn() {
        return (this.currentZoom < ZOOM_LIST.length - 1);
    }

    setXThumbPosition() {
        this.currentXPosition = this.currentIndex * -100;

        const h = this.thumbs.nativeElement.clientHeight;
        const w = (window.innerWidth - h) * 0.5;
        let x = (h * this.currentIndex) - w;
        x = (x > this.thumbs.nativeElement.scrollWidth) ? this.thumbs.nativeElement.scrollWidth : x;

        TweenLite.to(this.thumbscont.nativeElement, 0.5, {scrollLeft: x, ease: Expo.easeOut});
    }

    setInitialIndexPositionNoAnimation() {
        this.currentXPosition = this.currentIndex * -100;

        const h = this.thumbs.nativeElement.clientHeight;
        const w = (window.innerWidth - h) * 0.5;
        let x = (h * this.currentIndex) - w;
        x = (x > this.thumbs.nativeElement.scrollWidth) ? this.thumbs.nativeElement.scrollWidth : x;

        this.thumbscont.nativeElement.scrollLeft = x;
    }

    updateZoom($event: number) {
        this.currentZoom = $event;
    }

    swipe(index: number) {
        this.currentIndex = index;
        this.currentZoom = 0;
        this.setXThumbPosition();
    }


    toggleFullScreen() {
        if (this.fss.fullscreenLib.enabled) {
            this.fss.fullscreenLib.toggle();
        }
        // const elem:any =  document.body;
        // const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
        // if (methodToBeInvoked) methodToBeInvoked.call(elem);

    }

    getDefault(event: any) {
        event.target.src = this.utils.getUserImageURL(this.gender, null);
    }
}
