import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import {IntDashboardListingResult} from '../../store/dashboard/dashboard.model';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {IntUIBannerMkt} from '../../store/ui/ui.model';
import {BannerMkt} from '../../store/ui/ui.actions';
import {GaService} from '../../services/ga.service';
import {Observable} from 'rxjs';
import {disLikedProfile, fadeIn, fadeOut, likedProfile} from '../../directives/animations';
import {SetJuegoLikesRemove} from '../../store/user/user.actions';

export type fadeCardState = 'visible' | 'hidden';

@Component({
    selector: 'app-usercard',
    templateUrl: './usercard.component.html',
    styleUrls: ['./usercard.component.scss'],
	animations: [ fadeIn, likedProfile, disLikedProfile ]
})
export class UsercardComponent {

    @Input() user: IntDashboardListingResult;
    @Input() gaSource: string;
    @Output() sendMessage: EventEmitter<IntDashboardListingResult> = new EventEmitter();
    @Output() dislike: EventEmitter<IntDashboardListingResult> = new EventEmitter();
    @Output() like: EventEmitter<IntDashboardListingResult> = new EventEmitter();

	inProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getMessagingProcess));

    loading = true;

	state: string;
	stateDisLike: string;

	notShow: boolean = false;
	showDislike: boolean = false;

    constructor(
        private store: Store<fromRoot.State>,
        private ga: GaService,
        private utils: ZcUtilsService
    ) { this.state = 'hidden'; this.stateDisLike = 'hidden' }


    getDefault(event: any, gender: string) {
        event.target.src = this.utils.getUserImageURL(gender, null);
    }

    doSendMessage() {
        if (this.user._loading) {
            return;
        }
        this.sendMessage.emit(this.user);
        this.ga.triggerGenericEvent('evento', 'chat', this.gaSource);
    }

    doDislike() {
        if (this.user._loading) {
            return;
        }
        this.dislike.emit(this.user);
		this.stateDisLike = 'visible';
    }

    doLike() {
        if (this.user._loading) {
            return;
        }
        this.like.emit(this.user);
		this.state = 'visible';
    }

	animationDone(event: any) { this.state = 'hidden'; }

    viewProfile(id_usuario: string) {
        if (this.user._loading) {
            return;
        }
        this.store.dispatch(new ProfileOpen({userId: parseInt(id_usuario, 10), type: 'sent'}));
        this.ga.triggerGenericEvent('evento', 'vista_perfil', this.gaSource);
    }
}
