import {Injectable} from '@angular/core';


export interface IntCiudadData {
    l: string;
    v: number;
}

export interface IntLocalidadesData {
    l: string;
    v: number;
    cities: IntCiudadData[];
}

@Injectable({
    providedIn: 'root'
})
export class LocalidadesService {

    const;
    locasData: IntLocalidadesData[] = [{
        'cities': [{l: 'Agronomía', v: 3353}, {l: 'Almagro', v: 3354}, {l: 'Balvanera', v: 3355}, {l: 'Barracas', v: 3356}, {
            l: 'Belgrano',
            v: 3357
        }, {l: 'Boca', v: 3358}, {l: 'Boedo', v: 3359}, {l: 'Caballito', v: 3360}, {l: 'Chacarita', v: 3361}, {
            l: 'Coghlan',
            v: 3362
        }, {l: 'Colegiales', v: 3363}, {l: 'Constitución', v: 3364}, {l: 'Flores', v: 3365}, {l: 'Floresta', v: 3366}, {
            l: 'La Paternal',
            v: 3367
        }, {l: 'Liniers', v: 3368}, {l: 'Mataderos', v: 3369}, {l: 'Monte Castro', v: 3370}, {l: 'Monserrat', v: 3371}, {
            l: 'Nueva Pompeya',
            v: 3372
        }, {l: 'Núñez', v: 3373}, {l: 'Palermo', v: 3374}, {l: 'Parque Avellaneda', v: 3375}, {
            l: 'Parque Chacabuco',
            v: 3376
        }, {l: 'Parque Chas', v: 3377}, {l: 'Parque Patricios', v: 3378}, {l: 'Puerto Madero', v: 3379}, {
            l: 'Recoleta',
            v: 3380
        }, {l: 'Retiro', v: 3381}, {l: 'Saavedra', v: 3382}, {l: 'San Cristóbal', v: 3383}, {l: 'San Nicolás', v: 3384}, {
            l: 'San Telmo',
            v: 3385
        }, {l: 'Vélez Sársfield', v: 3386}, {l: 'Versalles', v: 3387}, {l: 'Villa Crespo', v: 3388}, {
            l: 'Villa del Parque',
            v: 3389
        }, {l: 'Villa Devoto', v: 3390}, {l: 'Villa Gral. Mitre', v: 3391}, {l: 'Villa Lugano', v: 3392}, {
            l: 'Villa Luro',
            v: 3393
        }, {l: 'Villa Ortúzar', v: 3394}, {l: 'Villa Pueyrredón', v: 3395}, {l: 'Villa Real', v: 3396}, {
            l: 'Villa Riachuelo',
            v: 3397
        }, {l: 'Villa Santa Rita', v: 3398}, {l: 'Villa Soldati', v: 3399}, {l: 'Villa Urquiza', v: 3400}], l: 'Capital Federal', v: 85
    }, {
        'cities': [{l: '11 de Septiembre', v: 3402}, {l: '20 de Junio', v: 3403}, {l: '25 de Mayo', v: 3404}, {
            l: 'Acassuso',
            v: 3405
        }, {l: 'Adrogué', v: 3406}, {l: 'Aldo Bonzi', v: 3407}, {l: 'Área Reserva Cinturón Ecológico', v: 3408}, {
            l: 'Avellaneda',
            v: 3409
        }, {l: 'Banfield', v: 3410}, {l: 'Barrio Parque', v: 3411}, {l: 'Barrio Santa Teresita', v: 3412}, {
            l: 'Beccar',
            v: 3413
        }, {l: 'Bella Vista', v: 3414}, {l: 'Berazategui', v: 3415}, {l: 'Bernal Este', v: 3416}, {
            l: 'Bernal Oeste',
            v: 3417
        }, {l: 'Billinghurst', v: 3418}, {l: 'Boulogne', v: 3419}, {l: 'Burzaco', v: 3420}, {l: 'Carapachay', v: 3421}, {
            l: 'Caseros',
            v: 3422
        }, {l: 'Castelar', v: 3423}, {l: 'Churruca', v: 3424}, {l: 'Ciudad Evita', v: 3425}, {l: 'Ciudad Madero', v: 3426}, {
            l: 'Ciudadela',
            v: 3427
        }, {l: 'Claypole', v: 3428}, {l: 'Crucecita', v: 3429}, {l: 'Dock Sud', v: 3430}, {l: 'Don Bosco', v: 3431}, {
            l: 'Don Orione',
            v: 3432
        }, {l: 'El Jagüel', v: 3433}, {l: 'El Libertador', v: 3434}, {l: 'El Palomar', v: 3435}, {l: 'El Tala', v: 3436}, {
            l: 'El Trébol',
            v: 3437
        }, {l: 'Ezeiza', v: 3438}, {l: 'Ezpeleta', v: 3439}, {l: 'Florencio Varela', v: 3440}, {
            l: 'Florida',
            v: 3441
        }, {l: 'Francisco Álvarez', v: 3442}, {l: 'Gerli', v: 3443}, {l: 'Glew', v: 3444}, {
            l: 'González Catán',
            v: 3445
        }, {l: 'Gral. Lamadrid', v: 3446}, {l: 'Grand Bourg', v: 3447}, {
            l: 'Gregorio de Laferrere',
            v: 3448
        }, {l: 'Guillermo Enrique Hudson', v: 3449}, {l: 'Haedo', v: 3450}, {l: 'Hurlingham', v: 3451}, {
            l: 'Ing. Sourdeaux',
            v: 3452
        }, {l: 'Isidro Casanova', v: 3453}, {l: 'Ituzaingó', v: 3454}, {l: 'José C. Paz', v: 3455}, {
            l: 'José Ingenieros',
            v: 3456
        }, {l: 'José Marmol', v: 3457}, {l: 'La Lucila', v: 3458}, {l: 'La Reja', v: 3459}, {l: 'La Tablada', v: 3460}, {
            l: 'Lanús',
            v: 3461
        }, {l: 'Llavallol', v: 3462}, {l: 'Loma Hermosa', v: 3463}, {l: 'Lomas de Zamora', v: 3464}, {
            l: 'Lomas del Millón',
            v: 3465
        }, {l: 'Lomas del Mirador', v: 3466}, {l: 'Longchamps', v: 3467}, {l: 'Los Polvorines', v: 3468}, {
            l: 'Luis Guillón',
            v: 3469
        }, {l: 'Malvinas Argentinas', v: 3470}, {l: 'Martín Coronado', v: 3471}, {l: 'Martínez', v: 3472}, {
            l: 'Merlo',
            v: 3473
        }, {l: 'Ministro Rivadavia', v: 3474}, {l: 'Monte Chingolo', v: 3475}, {l: 'Monte Grande', v: 3476}, {
            l: 'Moreno',
            v: 3477
        }, {l: 'Morón', v: 3478}, {l: 'Muñiz', v: 3479}, {l: 'Olivos', v: 3480}, {l: 'Pablo Nogués', v: 3481}, {
            l: 'Pablo Podestá',
            v: 3482
        }, {l: 'Paso del Rey', v: 3483}, {l: 'Pereyra', v: 3484}, {l: 'Piñeiro', v: 3485}, {l: 'Plátanos', v: 3486}, {
            l: 'Pontevedra',
            v: 3487
        }, {l: 'Quilmes', v: 3488}, {l: 'Rafael Calzada', v: 3489}, {l: 'Rafael Castillo', v: 3490}, {
            l: 'Ramos Mejía',
            v: 3491
        }, {l: 'Ranelagh', v: 3492}, {l: 'Remedios de Escalada', v: 3493}, {l: 'Sáenz Peña', v: 3494}, {
            l: 'San Antonio de Padua',
            v: 3495
        }, {l: 'San Fernando', v: 3496}, {l: 'San Francisco Solano', v: 3497}, {l: 'San Isidro', v: 3498}, {
            l: 'San José',
            v: 3499
        }, {l: 'San Justo', v: 3500}, {l: 'San Martín', v: 3501}, {l: 'San Miguel', v: 3502}, {l: 'Santos Lugares', v: 3503}, {
            l: 'Sarandí',
            v: 3504
        }, {l: 'Sourigues', v: 3505}, {l: 'Tapiales', v: 3506}, {l: 'Temperley', v: 3507}, {l: 'Tigre', v: 3508}, {
            l: 'Tortuguitas',
            v: 3509
        }, {l: 'Tristán Suárez', v: 3510}, {l: 'Trujui', v: 3511}, {l: 'Turdera', v: 3512}, {
            l: 'Valentín Alsina',
            v: 3513
        }, {l: 'Vicente López', v: 3514}, {l: 'Villa Adelina', v: 3515}, {l: 'Villa Ballester', v: 3516}, {
            l: 'Villa Bosch',
            v: 3517
        }, {l: 'Villa Caraza', v: 3518}, {l: 'Villa Celina', v: 3519}, {l: 'Villa Centenario', v: 3520}, {
            l: 'Villa de Mayo',
            v: 3521
        }, {l: 'Villa Diamante', v: 3522}, {l: 'Villa Domínico', v: 3523}, {l: 'Villa España', v: 3524}, {
            l: 'Villa Fiorito',
            v: 3525
        }, {l: 'Villa Guillermina', v: 3526}, {l: 'Villa Insuperable', v: 3527}, {
            l: 'Villa José León Suárez',
            v: 3528
        }, {l: 'Villa La Florida', v: 3529}, {l: 'Villa Luzuriaga', v: 3530}, {l: 'Villa Martelli', v: 3531}, {
            l: 'Villa Obrera',
            v: 3532
        }, {l: 'Villa Progreso', v: 3533}, {l: 'Villa Raffo', v: 3534}, {l: 'Villa Sarmiento', v: 3535}, {
            l: 'Villa Tesei',
            v: 3536
        }, {l: 'Villa Udaondo', v: 3537}, {l: 'Virrey del Pino', v: 3538}, {l: 'Wilde', v: 3539}, {l: 'William Morris', v: 3540}],
        l: 'Buenos Aires - GBA',
        v: 86
    }, {
        'cities': [{l: '25 de Mayo', v: 3542}, {l: '3 de febrero', v: 3543}, {l: 'A. Alsina', v: 3544}, {
            l: 'A. Gonzáles Cháves',
            v: 3545
        }, {l: 'Aguas Verdes', v: 3546}, {l: 'Alberti', v: 3547}, {l: 'Arrecifes', v: 3548}, {l: 'Ayacucho', v: 3549}, {
            l: 'Azul',
            v: 3550
        }, {l: 'Bahía Blanca', v: 3551}, {l: 'Balcarce', v: 3552}, {l: 'Baradero', v: 3553}, {l: 'Benito Juárez', v: 3554}, {
            l: 'Berisso',
            v: 3555
        }, {l: 'Bolívar', v: 3556}, {l: 'Bragado', v: 3557}, {l: 'Brandsen', v: 3558}, {l: 'Campana', v: 3559}, {
            l: 'Cañuelas',
            v: 3560
        }, {l: 'Capilla del Señor', v: 3561}, {l: 'Capitán Sarmiento', v: 3562}, {l: 'Carapachay', v: 3563}, {
            l: 'Carhue',
            v: 3564
        }, {l: 'Cariló', v: 3565}, {l: 'Carlos Casares', v: 3566}, {l: 'Carlos Tejedor', v: 3567}, {
            l: 'Carmen de Areco',
            v: 3568
        }, {l: 'Carmen de Patagones', v: 3569}, {l: 'Castelli', v: 3570}, {l: 'Chacabuco', v: 3571}, {
            l: 'Chascomús',
            v: 3572
        }, {l: 'Chivilcoy', v: 3573}, {l: 'Colón', v: 3574}, {l: 'Coronel Dorrego', v: 3575}, {
            l: 'Coronel Pringles',
            v: 3576
        }, {l: 'Coronel Rosales', v: 3577}, {l: 'Coronel Suarez', v: 3578}, {l: 'Costa Azul', v: 3579}, {
            l: 'Costa Chica',
            v: 3580
        }, {l: 'Costa del Este', v: 3581}, {l: 'Costa Esmeralda', v: 3582}, {l: 'Daireaux', v: 3583}, {
            l: 'Darregueira',
            v: 3584
        }, {l: 'Del Viso', v: 3585}, {l: 'Dolores', v: 3586}, {l: 'Don Torcuato', v: 3587}, {l: 'Ensenada', v: 3588}, {
            l: 'Escobar',
            v: 3589
        }, {l: 'Exaltación de la Cruz', v: 3590}, {l: 'Florentino Ameghino', v: 3591}, {l: 'Garín', v: 3592}, {
            l: 'Gral. Alvarado',
            v: 3593
        }, {l: 'Gral. Alvear', v: 3594}, {l: 'Gral. Arenales', v: 3595}, {l: 'Gral. Belgrano', v: 3596}, {
            l: 'Gral. Guido',
            v: 3597
        }, {l: 'Gral. Lamadrid', v: 3598}, {l: 'Gral. Las Heras', v: 3599}, {l: 'Gral. Lavalle', v: 3600}, {
            l: 'Gral. Madariaga',
            v: 3601
        }, {l: 'Gral. Pacheco', v: 3602}, {l: 'Gral. Paz', v: 3603}, {l: 'Gral. Pinto', v: 3604}, {
            l: 'Gral. Pueyrredón',
            v: 3605
        }, {l: 'Gral. Rodríguez', v: 3606}, {l: 'Gral. Viamonte', v: 3607}, {l: 'Gral. Villegas', v: 3608}, {
            l: 'Guaminí',
            v: 3609
        }, {l: 'Guernica', v: 3610}, {l: 'Hipólito Yrigoyen', v: 3611}, {l: 'Ing. Maschwitz', v: 3612}, {
            l: 'Junín',
            v: 3613
        }, {l: 'La Plata', v: 3614}, {l: 'Laprida', v: 3615}, {l: 'Las Flores', v: 3616}, {
            l: 'Las Toninas',
            v: 3617
        }, {l: 'Leandro N. Alem', v: 3618}, {l: 'Lincoln', v: 3619}, {l: 'Loberia', v: 3620}, {l: 'Lobos', v: 3621}, {
            l: 'Los Cardales',
            v: 3622
        }, {l: 'Los Toldos', v: 3623}, {l: 'Lucila del Mar', v: 3624}, {l: 'Luján', v: 3625}, {l: 'Magdalena', v: 3626}, {
            l: 'Maipú',
            v: 3627
        }, {l: 'Mar Chiquita', v: 3628}, {l: 'Mar de Ajó', v: 3629}, {l: 'Mar de las Pampas', v: 3630}, {
            l: 'Mar del Plata',
            v: 3631
        }, {l: 'Mar del Tuyú', v: 3632}, {l: 'Marcos Paz', v: 3633}, {l: 'Mercedes', v: 3634}, {l: 'Miramar', v: 3635}, {
            l: 'Monte',
            v: 3636
        }, {l: 'Monte Hermoso', v: 3637}, {l: 'Munro', v: 3638}, {l: 'Navarro', v: 3639}, {l: 'Necochea', v: 3640}, {
            l: 'Olavarría',
            v: 3641
        }, {l: 'Partido de la Costa', v: 3642}, {l: 'Pehuajó', v: 3643}, {l: 'Pellegrini', v: 3644}, {l: 'Pergamino', v: 3645}, {
            l: 'Pigüé',
            v: 3646
        }, {l: 'Pila', v: 3647}, {l: 'Pilar', v: 3648}, {l: 'Pinamar', v: 3649}, {l: 'Pinar del Sol', v: 3650}, {
            l: 'Polvorines',
            v: 3651
        }, {l: 'Pte. Perón', v: 3652}, {l: 'Puán', v: 3653}, {l: 'Punta Indio', v: 3654}, {l: 'Ramallo', v: 3655}, {
            l: 'Rauch',
            v: 3656
        }, {l: 'Rivadavia', v: 3657}, {l: 'Rojas', v: 3658}, {l: 'Roque Pérez', v: 3659}, {l: 'Saavedra', v: 3660}, {
            l: 'Saladillo',
            v: 3661
        }, {l: 'Salliqueló', v: 3662}, {l: 'Salto', v: 3663}, {l: 'San Andrés de Giles', v: 3664}, {
            l: 'San Antonio de Areco',
            v: 3665
        }, {l: 'San Antonio de Padua', v: 3666}, {l: 'San Bernardo', v: 3667}, {l: 'San Cayetano', v: 3668}, {
            l: 'San Clemente del Tuyú',
            v: 3669
        }, {l: 'San Nicolás', v: 3670}, {l: 'San Pedro', v: 3671}, {l: 'San Vicente', v: 3672}, {
            l: 'Santa Teresita',
            v: 3673
        }, {l: 'Suipacha', v: 3674}, {l: 'Tandil', v: 3675}, {l: 'Tapalqué', v: 3676}, {l: 'Tordillo', v: 3677}, {
            l: 'Tornquist',
            v: 3678
        }, {l: 'Trenque Lauquen', v: 3679}, {l: 'Tres Lomas', v: 3680}, {l: 'Villa Gesell', v: 3681}, {
            l: 'Villarino',
            v: 3682
        }, {l: 'Zárate', v: 3683}], l: 'Buenos Aires', v: 87
    }, {
        'cities': [{l: 'Aconquija', v: 3685}, {l: 'Ancasti', v: 3686}, {l: 'Andalgalá', v: 3687}, {l: 'Antofagasta', v: 3688}, {
            l: 'Belén',
            v: 3689
        }, {l: 'Capayán', v: 3690}, {l: 'Capital', v: 3691}, {l: 'Catamarca', v: 3692}, {l: 'Corral Quemado', v: 3693}, {
            l: 'El Alto',
            v: 3694
        }, {l: 'El Rodeo', v: 3695}, {l: 'F.Mamerto Esquiú', v: 3696}, {l: 'Fiambalá', v: 3697}, {l: 'Hualfín', v: 3698}, {
            l: 'Huillapima',
            v: 3699
        }, {l: 'Icaño', v: 3700}, {l: 'La Puerta', v: 3701}, {l: 'Las Juntas', v: 3702}, {l: 'Londres', v: 3703}, {
            l: 'Los Altos',
            v: 3704
        }, {l: 'Los Varela', v: 3705}, {l: 'Mutquín', v: 3706}, {l: 'Paclín', v: 3707}, {l: 'Poman', v: 3708}, {
            l: 'Pozo de La Piedra',
            v: 3709
        }, {l: 'Puerta de Corral', v: 3710}, {l: 'Puerta San José', v: 3711}, {l: 'Recreo', v: 3712}, {
            l: 'S.F.V de Catamarca',
            v: 3713
        }, {l: 'San Fernando', v: 3714}, {l: 'San Fernando del Valle', v: 3715}, {l: 'San José', v: 3716}, {
            l: 'Santa María',
            v: 3717
        }, {l: 'Santa Rosa', v: 3718}, {l: 'Saujil', v: 3719}, {l: 'Tapso', v: 3720}, {l: 'Tinogasta', v: 3721}, {
            l: 'Valle Viejo',
            v: 3722
        }, {l: 'Villa Vil', v: 3723}], l: 'Catamarca', v: 88
    }, {
        'cities': [{l: 'Aviá Teraí', v: 3725}, {l: 'Barranqueras', v: 3726}, {l: 'Basail', v: 3727}, {
            l: 'Campo Largo',
            v: 3728
        }, {l: 'Capital', v: 3729}, {l: 'Capitán Solari', v: 3730}, {l: 'Charadai', v: 3731}, {l: 'Charata', v: 3732}, {
            l: 'Chorotis',
            v: 3733
        }, {l: 'Ciervo Petiso', v: 3734}, {l: 'Cnel. Du Graty', v: 3735}, {l: 'Col. Benítez', v: 3736}, {
            l: 'Col. Elisa',
            v: 3737
        }, {l: 'Col. Popular', v: 3738}, {l: 'Colonias Unidas', v: 3739}, {l: 'Concepción', v: 3740}, {
            l: 'Corzuela',
            v: 3741
        }, {l: 'Cote Lai', v: 3742}, {l: 'El Sauzalito', v: 3743}, {l: 'Enrique Urien', v: 3744}, {
            l: 'Fontana',
            v: 3745
        }, {l: 'Fte. Esperanza', v: 3746}, {l: 'Gancedo', v: 3747}, {l: 'Gral. Capdevila', v: 3748}, {
            l: 'Gral. Pinero',
            v: 3749
        }, {l: 'Gral. San Martín', v: 3750}, {l: 'Gral. Vedia', v: 3751}, {l: 'Hermoso Campo', v: 3752}, {
            l: 'I. del Cerrito',
            v: 3753
        }, {l: 'J.J. Castelli', v: 3754}, {l: 'La Clotilde', v: 3755}, {l: 'La Eduvigis', v: 3756}, {
            l: 'La Escondida',
            v: 3757
        }, {l: 'La Leonesa', v: 3758}, {l: 'La Tigra', v: 3759}, {l: 'La Verde', v: 3760}, {
            l: 'Laguna Blanca',
            v: 3761
        }, {l: 'Laguna Limpia', v: 3762}, {l: 'Lapachito', v: 3763}, {l: 'Las Breñas', v: 3764}, {
            l: 'Las Garcitas',
            v: 3765
        }, {l: 'Las Palmas', v: 3766}, {l: 'Los Frentones', v: 3767}, {l: 'Machagai', v: 3768}, {
            l: 'Makallé',
            v: 3769
        }, {l: 'Margarita Belén', v: 3770}, {l: 'Miraflores', v: 3771}, {l: 'Misión N. Pompeya', v: 3772}, {
            l: 'Napenay',
            v: 3773
        }, {l: 'Pampa Almirón', v: 3774}, {l: 'Pampa del Indio', v: 3775}, {l: 'Pampa del Infierno', v: 3776}, {
            l: 'Pdcia. de La Plaza',
            v: 3777
        }, {l: 'Pdcia. Roca', v: 3778}, {l: 'Pdcia. Roque Sáenz Peña', v: 3779}, {l: 'Pto. Bermejo', v: 3780}, {
            l: 'Pto. Eva Perón',
            v: 3781
        }, {l: 'Puero Tirol', v: 3782}, {l: 'Puerto Vilelas', v: 3783}, {l: 'Quitilipi', v: 3784}, {
            l: 'Resistencia',
            v: 3785
        }, {l: 'Sáenz Peña', v: 3786}, {l: 'Samuhú', v: 3787}, {l: 'San Bernardo', v: 3788}, {l: 'Santa Sylvina', v: 3789}, {
            l: 'Taco Pozo',
            v: 3790
        }, {l: 'Tres Isletas', v: 3791}, {l: 'Villa Ángela', v: 3792}, {l: 'Villa Berthet', v: 3793}, {l: 'Villa R. Bermejito', v: 3794}],
        l: 'Chaco',
        v: 89
    }, {
        'cities': [{l: 'Aldea Apeleg', v: 3796}, {l: 'Aldea Beleiro', v: 3797}, {l: 'Aldea Epulef', v: 3798}, {
            l: 'Alto Río Sengerr',
            v: 3799
        }, {l: 'Buen Pasto', v: 3800}, {l: 'Camarones', v: 3801}, {l: 'Carrenleufú', v: 3802}, {l: 'Cholila', v: 3803}, {
            l: 'Co. Centinela',
            v: 3804
        }, {l: 'Colan Conhué', v: 3805}, {l: 'Comodoro Rivadavia', v: 3806}, {l: 'Corcovado', v: 3807}, {
            l: 'Cushamen',
            v: 3808
        }, {l: 'Dique F. Ameghino', v: 3809}, {l: 'Dolavón', v: 3810}, {l: 'Dr. R. Rojas', v: 3811}, {
            l: 'El Hoyo',
            v: 3812
        }, {l: 'El Maitén', v: 3813}, {l: 'Epuyén', v: 3814}, {l: 'Esquel', v: 3815}, {l: 'Facundo', v: 3816}, {
            l: 'Gaimán',
            v: 3817
        }, {l: 'Gan Gan', v: 3818}, {l: 'Gastre', v: 3819}, {l: 'Gdor. Costa', v: 3820}, {l: 'Gualjaina', v: 3821}, {
            l: 'J. de San Martín',
            v: 3822
        }, {l: 'Lago Blanco', v: 3823}, {l: 'Lago Puelo', v: 3824}, {l: 'Lagunita Salada', v: 3825}, {
            l: 'Las Plumas',
            v: 3826
        }, {l: 'Los Altares', v: 3827}, {l: 'Paso de los Indios', v: 3828}, {l: 'Paso del Sapo', v: 3829}, {
            l: 'Pto. Madryn',
            v: 3830
        }, {l: 'Pto. Pirámides', v: 3831}, {l: 'Rada Tilly', v: 3832}, {l: 'Rawson', v: 3833}, {l: 'Río Mayo', v: 3834}, {
            l: 'Río Pico',
            v: 3835
        }, {l: 'Sarmiento', v: 3836}, {l: 'Tecka', v: 3837}, {l: 'Telsen', v: 3838}, {l: 'Trelew', v: 3839}, {
            l: 'Trevelin',
            v: 3840
        }, {l: 'Veintiocho de Julio', v: 3841}], l: 'Chubut', v: 90
    }, {
        'cities': [{l: 'Achiras', v: 3843}, {l: 'Adelia Maria', v: 3844}, {l: 'Agua de Oro', v: 3845}, {
            l: 'Alcira Gigena',
            v: 3846
        }, {l: 'Aldea Santa Maria', v: 3847}, {l: 'Alejandro Roca', v: 3848}, {l: 'Alejo Ledesma', v: 3849}, {
            l: 'Alicia',
            v: 3850
        }, {l: 'Almafuerte', v: 3851}, {l: 'Alpa Corral', v: 3852}, {l: 'Alta Gracia', v: 3853}, {
            l: 'Alto Alegre',
            v: 3854
        }, {l: 'Alto de Los Quebrachos', v: 3855}, {l: 'Altos de Chipion', v: 3856}, {l: 'Amboy', v: 3857}, {
            l: 'Ambul',
            v: 3858
        }, {l: 'Ana Zumaran', v: 3859}, {l: 'Anisacate', v: 3860}, {l: 'Arguello', v: 3861}, {l: 'Arias', v: 3862}, {
            l: 'Arroyito',
            v: 3863
        }, {l: 'Arroyo Algodon', v: 3864}, {l: 'Arroyo Cabral', v: 3865}, {l: 'Arroyo Los Patos', v: 3866}, {
            l: 'Assunta',
            v: 3867
        }, {l: 'Atahona', v: 3868}, {l: 'Ausonia', v: 3869}, {l: 'Avellaneda', v: 3870}, {l: 'Ballesteros', v: 3871}, {
            l: 'Ballesteros Sud',
            v: 3872
        }, {l: 'Balnearia', v: 3873}, {l: 'Bañado de Soto', v: 3874}, {l: 'Bell Ville', v: 3875}, {
            l: 'Bengolea',
            v: 3876
        }, {l: 'Benjamin Gould', v: 3877}, {l: 'Berrotaran', v: 3878}, {l: 'Bialet Masse', v: 3879}, {
            l: 'Bouwer',
            v: 3880
        }, {l: 'Brinkmann', v: 3881}, {l: 'Buchardo', v: 3882}, {l: 'Bulnes', v: 3883}, {l: 'Cabalango', v: 3884}, {
            l: 'Calamuchita',
            v: 3885
        }, {l: 'Calchin', v: 3886}, {l: 'Calchin Oeste', v: 3887}, {l: 'Calmayo', v: 3888}, {l: 'Camilo Aldao', v: 3889}, {
            l: 'Caminiaga',
            v: 3890
        }, {l: 'Cañada de Luque', v: 3891}, {l: 'Cañada de Machado', v: 3892}, {l: 'Cañada de Rio Pinto', v: 3893}, {
            l: 'Cañada del Sauce',
            v: 3894
        }, {l: 'Canals', v: 3895}, {l: 'Candelaria Sud', v: 3896}, {l: 'Capilla de Remedios', v: 3897}, {
            l: 'Capilla de Siton',
            v: 3898
        }, {l: 'Capilla del Carmen', v: 3899}, {l: 'Capilla del Monte', v: 3900}, {l: 'Capital', v: 3901}, {
            l: 'Capitan Gral B. O´Higgins',
            v: 3902
        }, {l: 'Carnerillo', v: 3903}, {l: 'Carrilobo', v: 3904}, {l: 'Casa Grande', v: 3905}, {
            l: 'Cavanagh',
            v: 3906
        }, {l: 'Cerro Colorado', v: 3907}, {l: 'Chaján', v: 3908}, {l: 'Chalacea', v: 3909}, {l: 'Chañar Viejo', v: 3910}, {
            l: 'Chancaní',
            v: 3911
        }, {l: 'Charbonier', v: 3912}, {l: 'Charras', v: 3913}, {l: 'Chazón', v: 3914}, {l: 'Chilibroste', v: 3915}, {
            l: 'Chucul',
            v: 3916
        }, {l: 'Chuña', v: 3917}, {l: 'Chuña Huasi', v: 3918}, {l: 'Churqui Cañada', v: 3919}, {
            l: 'Cienaga Del Coro',
            v: 3920
        }, {l: 'Cintra', v: 3921}, {l: 'Col. Almada', v: 3922}, {l: 'Col. Anita', v: 3923}, {l: 'Col. Barge', v: 3924}, {
            l: 'Col. Bismark',
            v: 3925
        }, {l: 'Col. Bremen', v: 3926}, {l: 'Col. Caroya', v: 3927}, {l: 'Col. Italiana', v: 3928}, {
            l: 'Col. Iturraspe',
            v: 3929
        }, {l: 'Col. Las Cuatro Esquinas', v: 3930}, {l: 'Col. Las Pichanas', v: 3931}, {l: 'Col. Marina', v: 3932}, {
            l: 'Col. Prosperidad',
            v: 3933
        }, {l: 'Col. San Bartolome', v: 3934}, {l: 'Col. San Pedro', v: 3935}, {l: 'Col. Tirolesa', v: 3936}, {
            l: 'Col. Vicente Aguero',
            v: 3937
        }, {l: 'Col. Videla', v: 3938}, {l: 'Col. Vignaud', v: 3939}, {l: 'Col. Waltelina', v: 3940}, {
            l: 'Colazo',
            v: 3941
        }, {l: 'Comechingones', v: 3942}, {l: 'Conlara', v: 3943}, {l: 'Copacabana', v: 3944}, {
            l: 'Córdoba',
            v: 3945
        }, {l: 'Coronel Baigorria', v: 3946}, {l: 'Coronel Moldes', v: 3947}, {l: 'Corral de Bustos', v: 3948}, {
            l: 'Corralito',
            v: 3949
        }, {l: 'Cosquín', v: 3950}, {l: 'Costa Sacate', v: 3951}, {l: 'Cruz Alta', v: 3952}, {
            l: 'Cruz de Caña',
            v: 3953
        }, {l: 'Cruz del Eje', v: 3954}, {l: 'Cuesta Blanca', v: 3955}, {l: 'Dean Funes', v: 3956}, {
            l: 'Del Campillo',
            v: 3957
        }, {l: 'Despeñaderos', v: 3958}, {l: 'Devoto', v: 3959}, {l: 'Diego de Rojas', v: 3960}, {
            l: 'Dique Chico',
            v: 3961
        }, {l: 'El Arañado', v: 3962}, {l: 'El Brete', v: 3963}, {l: 'El Chacho', v: 3964}, {l: 'El Crispín', v: 3965}, {
            l: 'El Fortín',
            v: 3966
        }, {l: 'El Manzano', v: 3967}, {l: 'El Rastreador', v: 3968}, {l: 'El Rodeo', v: 3969}, {l: 'El Tío', v: 3970}, {
            l: 'Elena',
            v: 3971
        }, {l: 'Embalse', v: 3972}, {l: 'Esquina', v: 3973}, {l: 'Estación Gral. Paz', v: 3974}, {
            l: 'Estación Juárez Celman',
            v: 3975
        }, {l: 'Estancia de Guadalupe', v: 3976}, {l: 'Estancia Vieja', v: 3977}, {l: 'Etruria', v: 3978}, {
            l: 'Eufrasio Loza',
            v: 3979
        }, {l: 'Falda del Carmen', v: 3980}, {l: 'Freyre', v: 3981}, {l: 'Gral. Baldissera', v: 3982}, {
            l: 'Gral. Cabrera',
            v: 3983
        }, {l: 'Gral. Deheza', v: 3984}, {l: 'Gral. Fotheringham', v: 3985}, {l: 'Gral. Levalle', v: 3986}, {
            l: 'Gral. Roca',
            v: 3987
        }, {l: 'Guanaco Muerto', v: 3988}, {l: 'Guasapampa', v: 3989}, {l: 'Guatimozin', v: 3990}, {
            l: 'Gutenberg',
            v: 3991
        }, {l: 'Hernando', v: 3992}, {l: 'Huanchillas', v: 3993}, {l: 'Huerta Grande', v: 3994}, {
            l: 'Huinca Renanco',
            v: 3995
        }, {l: 'Idiazabal', v: 3996}, {l: 'Impira', v: 3997}, {l: 'Inriville', v: 3998}, {l: 'Isla Verde', v: 3999}, {
            l: 'Italó',
            v: 4000
        }, {l: 'James Craik', v: 4001}, {l: 'Jesús María', v: 4002}, {l: 'Jovita', v: 4003}, {l: 'Justiniano Posse', v: 4004}, {
            l: 'Km 658',
            v: 4005
        }, {l: 'L. V. Mansilla', v: 4006}, {l: 'La Batea', v: 4007}, {l: 'La Calera', v: 4008}, {
            l: 'La Carlota',
            v: 4009
        }, {l: 'La Carolina', v: 4010}, {l: 'La Cautiva', v: 4011}, {l: 'La Cesira', v: 4012}, {l: 'La Cruz', v: 4013}, {
            l: 'La Cumbre',
            v: 4014
        }, {l: 'La Cumbrecita', v: 4015}, {l: 'La Falda', v: 4016}, {l: 'La Francia', v: 4017}, {l: 'La Granja', v: 4018}, {
            l: 'La Higuera',
            v: 4019
        }, {l: 'La Laguna', v: 4020}, {l: 'La Paisanita', v: 4021}, {l: 'La Palestina', v: 4022}, {
            l: 'La Pampa',
            v: 4023
        }, {l: 'La Paquita', v: 4024}, {l: 'La Para', v: 4025}, {l: 'La Paz', v: 4026}, {l: 'La Playa', v: 4027}, {
            l: 'La Playosa',
            v: 4028
        }, {l: 'La Población', v: 4029}, {l: 'La Posta', v: 4030}, {l: 'La Puerta', v: 4031}, {
            l: 'La Quinta',
            v: 4032
        }, {l: 'La Rancherita', v: 4033}, {l: 'La Rinconada', v: 4034}, {l: 'La Serranita', v: 4035}, {
            l: 'La Tordilla',
            v: 4036
        }, {l: 'Laborde', v: 4037}, {l: 'Laboulaye', v: 4038}, {l: 'Laguna Larga', v: 4039}, {
            l: 'Las Acequias',
            v: 4040
        }, {l: 'Las Albahacas', v: 4041}, {l: 'Las Arrias', v: 4042}, {l: 'Las Bajadas', v: 4043}, {
            l: 'Las Caleras',
            v: 4044
        }, {l: 'Las Calles', v: 4045}, {l: 'Las Cañadas', v: 4046}, {l: 'Las Gramillas', v: 4047}, {
            l: 'Las Higueras',
            v: 4048
        }, {l: 'Las Isletillas', v: 4049}, {l: 'Las Junturas', v: 4050}, {l: 'Las Palmas', v: 4051}, {
            l: 'Las Peñas',
            v: 4052
        }, {l: 'Las Peñas Sud', v: 4053}, {l: 'Las Perdices', v: 4054}, {l: 'Las Playas', v: 4055}, {
            l: 'Las Rabonas',
            v: 4056
        }, {l: 'Las Saladas', v: 4057}, {l: 'Las Tapias', v: 4058}, {l: 'Las Varas', v: 4059}, {
            l: 'Las Varillas',
            v: 4060
        }, {l: 'Las Vertientes', v: 4061}, {l: 'Leguizamón', v: 4062}, {l: 'Leones', v: 4063}, {
            l: 'Los Cedros',
            v: 4064
        }, {l: 'Los Cerrillos', v: 4065}, {l: 'Los Chañaritos (C.E)', v: 4066}, {l: 'Los Chanaritos (R.S)', v: 4067}, {
            l: 'Los Cisnes',
            v: 4068
        }, {l: 'Los Cocos', v: 4069}, {l: 'Los Cóndores', v: 4070}, {l: 'Los Hornillos', v: 4071}, {
            l: 'Los Hoyos',
            v: 4072
        }, {l: 'Los Mistoles', v: 4073}, {l: 'Los Molinos', v: 4074}, {l: 'Los Pozos', v: 4075}, {
            l: 'Los Reartes',
            v: 4076
        }, {l: 'Los Surgentes', v: 4077}, {l: 'Los Talares', v: 4078}, {l: 'Los Zorros', v: 4079}, {l: 'Lozada', v: 4080}, {
            l: 'Luca',
            v: 4081
        }, {l: 'Luque', v: 4082}, {l: 'Luyaba', v: 4083}, {l: 'Malagueño', v: 4084}, {l: 'Malena', v: 4085}, {
            l: 'Malvinas Argentinas',
            v: 4086
        }, {l: 'Manfredi', v: 4087}, {l: 'Maquinista Gallini', v: 4088}, {l: 'Marcos Juárez', v: 4089}, {
            l: 'Marull',
            v: 4090
        }, {l: 'Matorrales', v: 4091}, {l: 'Mattaldi', v: 4092}, {l: 'Mayu Sumaj', v: 4093}, {l: 'Media Naranja', v: 4094}, {
            l: 'Melo',
            v: 4095
        }, {l: 'Mendiolaza', v: 4096}, {l: 'Mi Granja', v: 4097}, {l: 'Mina Clavero', v: 4098}, {l: 'Miramar', v: 4099}, {
            l: 'Morrison',
            v: 4100
        }, {l: 'Morteros', v: 4101}, {l: 'Mte. Buey', v: 4102}, {l: 'Mte. Cristo', v: 4103}, {
            l: 'Mte. De Los Gauchos',
            v: 4104
        }, {l: 'Mte. Leña', v: 4105}, {l: 'Mte. Maíz', v: 4106}, {l: 'Mte. Ralo', v: 4107}, {
            l: 'Nicolás Bruzone',
            v: 4108
        }, {l: 'Noetinger', v: 4109}, {l: 'Nono', v: 4110}, {l: 'Nueva Córdoba', v: 4111}, {l: 'Obispo Trejo', v: 4112}, {
            l: 'Olaeta',
            v: 4113
        }, {l: 'Oliva', v: 4114}, {l: 'Olivares San Nicolás', v: 4115}, {l: 'Onagolty', v: 4116}, {l: 'Oncativo', v: 4117}, {
            l: 'Ordoñez',
            v: 4118
        }, {l: 'Pacheco De Melo', v: 4119}, {l: 'Pampayasta N.', v: 4120}, {l: 'Pampayasta S.', v: 4121}, {
            l: 'Panaholma',
            v: 4122
        }, {l: 'Pascanas', v: 4123}, {l: 'Pasco', v: 4124}, {l: 'Paso del Durazno', v: 4125}, {l: 'Paso Viejo', v: 4126}, {
            l: 'Pilar',
            v: 4127
        }, {l: 'Pincén', v: 4128}, {l: 'Piquillín', v: 4129}, {l: 'Plaza de Mercedes', v: 4130}, {
            l: 'Plaza Luxardo',
            v: 4131
        }, {l: 'Porteña', v: 4132}, {l: 'Potrero de Garay', v: 4133}, {l: 'Pozo del Molle', v: 4134}, {
            l: 'Pozo Nuevo',
            v: 4135
        }, {l: 'Pueblo Italiano', v: 4136}, {l: 'Puesto de Castro', v: 4137}, {l: 'Punta del Agua', v: 4138}, {
            l: 'Quebracho Herrado',
            v: 4139
        }, {l: 'Quilino', v: 4140}, {l: 'Rafael García', v: 4141}, {l: 'Ranqueles', v: 4142}, {l: 'Rayo Cortado', v: 4143}, {
            l: 'Reducción',
            v: 4144
        }, {l: 'Rincón', v: 4145}, {l: 'Río Bamba', v: 4146}, {l: 'Río Ceballos', v: 4147}, {
            l: 'Río Cuarto',
            v: 4148
        }, {l: 'Río de Los Sauces', v: 4149}, {l: 'Río Primero', v: 4150}, {l: 'Río Segundo', v: 4151}, {
            l: 'Río Tercero',
            v: 4152
        }, {l: 'Rosales', v: 4153}, {l: 'Rosario del Saladillo', v: 4154}, {l: 'Sacanta', v: 4155}, {
            l: 'Sagrada Familia',
            v: 4156
        }, {l: 'Saira', v: 4157}, {l: 'Saladillo', v: 4158}, {l: 'Saldán', v: 4159}, {l: 'Salsacate', v: 4160}, {
            l: 'Salsipuedes',
            v: 4161
        }, {l: 'Sampacho', v: 4162}, {l: 'San Agustín', v: 4163}, {l: 'San Antonio de Arredondo', v: 4164}, {
            l: 'San Antonio de Litín',
            v: 4165
        }, {l: 'San Basilio', v: 4166}, {l: 'San Carlos Minas', v: 4167}, {l: 'San Clemente', v: 4168}, {
            l: 'San Esteban',
            v: 4169
        }, {l: 'San Francisco', v: 4170}, {l: 'San Ignacio', v: 4171}, {l: 'San Javier', v: 4172}, {
            l: 'San Jerónimo',
            v: 4173
        }, {l: 'San Joaquín', v: 4174}, {l: 'San José de La Dormida', v: 4175}, {l: 'San José de Las Salinas', v: 4176}, {
            l: 'San Lorenzo',
            v: 4177
        }, {l: 'San Marcos Sierras', v: 4178}, {l: 'San Marcos Sud', v: 4179}, {l: 'San Pedro', v: 4180}, {
            l: 'San Pedro N.',
            v: 4181
        }, {l: 'San Roque', v: 4182}, {l: 'San Vicente', v: 4183}, {l: 'Santa Catalina', v: 4184}, {
            l: 'Santa Elena',
            v: 4185
        }, {l: 'Santa Eufemia', v: 4186}, {l: 'Santa Maria', v: 4187}, {l: 'Sarmiento', v: 4188}, {
            l: 'Saturnino M.Laspiur',
            v: 4189
        }, {l: 'Sauce Arriba', v: 4190}, {l: 'Sebastián Elcano', v: 4191}, {l: 'Seeber', v: 4192}, {
            l: 'Segunda Usina',
            v: 4193
        }, {l: 'Serrano', v: 4194}, {l: 'Serrezuela', v: 4195}, {l: 'Sgo. Temple', v: 4196}, {l: 'Silvio Pellico', v: 4197}, {
            l: 'Simbolar',
            v: 4198
        }, {l: 'Sinsacate', v: 4199}, {l: 'Sta. Rosa de Calamuchita', v: 4200}, {l: 'Sta. Rosa de Río Primero', v: 4201}, {
            l: 'Suco',
            v: 4202
        }, {l: 'Tala Cañada', v: 4203}, {l: 'Tala Huasi', v: 4204}, {l: 'Talaini', v: 4205}, {l: 'Tancacha', v: 4206}, {
            l: 'Tanti',
            v: 4207
        }, {l: 'Ticino', v: 4208}, {l: 'Tinoco', v: 4209}, {l: 'Tío Pujio', v: 4210}, {l: 'Toledo', v: 4211}, {
            l: 'Toro Pujio',
            v: 4212
        }, {l: 'Tosno', v: 4213}, {l: 'Tosquita', v: 4214}, {l: 'Tránsito', v: 4215}, {l: 'Tuclame', v: 4216}, {
            l: 'Tutti',
            v: 4217
        }, {l: 'Ucacha', v: 4218}, {l: 'Unquillo', v: 4219}, {l: 'Valle de Anisacate', v: 4220}, {
            l: 'Valle Hermoso',
            v: 4221
        }, {l: 'Vélez Sarfield', v: 4222}, {l: 'Viamonte', v: 4223}, {l: 'Vicuña Mackenna', v: 4224}, {
            l: 'Villa Allende',
            v: 4225
        }, {l: 'Villa Amancay', v: 4226}, {l: 'Villa Ascasubi', v: 4227}, {l: 'Villa Candelaria N.', v: 4228}, {
            l: 'Villa Carlos Paz',
            v: 4229
        }, {l: 'Villa Cerro Azul', v: 4230}, {l: 'Villa Ciudad de América', v: 4231}, {
            l: 'Villa Ciudad Pque Los Reartes',
            v: 4232
        }, {l: 'Villa Concepción del Tío', v: 4233}, {l: 'Villa Cura Brochero', v: 4234}, {
            l: 'Villa de Las Rosas',
            v: 4235
        }, {l: 'Villa de María', v: 4236}, {l: 'Villa de Pocho', v: 4237}, {l: 'Villa de Soto', v: 4238}, {
            l: 'Villa del Dique',
            v: 4239
        }, {l: 'Villa del Prado', v: 4240}, {l: 'Villa del Rosario', v: 4241}, {l: 'Villa del Totoral', v: 4242}, {
            l: 'Villa Dolores',
            v: 4243
        }, {l: 'Villa El Chancay', v: 4244}, {l: 'Villa Elisa', v: 4245}, {l: 'Villa Flor Serrana', v: 4246}, {
            l: 'Villa Fontana',
            v: 4247
        }, {l: 'Villa Giardino', v: 4248}, {l: 'Villa Gral. Belgrano', v: 4249}, {l: 'Villa Gutierrez', v: 4250}, {
            l: 'Villa Huidobro',
            v: 4251
        }, {l: 'Villa La Bolsa', v: 4252}, {l: 'Villa Los Aromos', v: 4253}, {l: 'Villa Los Patos', v: 4254}, {
            l: 'Villa María',
            v: 4255
        }, {l: 'Villa Nueva', v: 4256}, {l: 'Villa Pque. Santa Ana', v: 4257}, {l: 'Villa Pque. Siquiman', v: 4258}, {
            l: 'Villa Quillinzo',
            v: 4259
        }, {l: 'Villa Rossi', v: 4260}, {l: 'Villa Rumipal', v: 4261}, {l: 'Villa San Esteban', v: 4262}, {
            l: 'Villa San Isidro',
            v: 4263
        }, {l: 'Villa Santa Cruz', v: 4264}, {l: 'Villa Sarmiento (G.R)', v: 4265}, {
            l: 'Villa Sarmiento (S.A)',
            v: 4266
        }, {l: 'Villa Tulumba', v: 4267}, {l: 'Villa Valeria', v: 4268}, {l: 'Villa Yacanto', v: 4269}, {
            l: 'Washington',
            v: 4270
        }, {l: 'Wenceslao Escalante', v: 4271}, {l: 'Ycho Cruz Sierras', v: 4272}], l: 'Córdoba', v: 91
    }, {
        'cities': [{l: 'Alvear', v: 4274}, {l: 'Bella Vista', v: 4275}, {l: 'Berón de Astrada', v: 4276}, {
            l: 'Bonpland',
            v: 4277
        }, {l: 'Caá Cati', v: 4278}, {l: 'Capital', v: 4279}, {l: 'Chavarría', v: 4280}, {
            l: 'Col. C. Pellegrini',
            v: 4281
        }, {l: 'Col. Libertad', v: 4282}, {l: 'Col. Liebig', v: 4283}, {l: 'Col. Sta Rosa', v: 4284}, {
            l: 'Concepción',
            v: 4285
        }, {l: 'Cruz de Los Milagros', v: 4286}, {l: 'Curuzú-Cuatiá', v: 4287}, {l: 'Empedrado', v: 4288}, {
            l: 'Esquina',
            v: 4289
        }, {l: 'Estación Torrent', v: 4290}, {l: 'Felipe Yofré', v: 4291}, {l: 'Garruchos', v: 4292}, {
            l: 'Gdor. Agrónomo',
            v: 4293
        }, {l: 'Gdor. Martínez', v: 4294}, {l: 'Goya', v: 4295}, {l: 'Guaviravi', v: 4296}, {l: 'Herlitzka', v: 4297}, {
            l: 'Ita-Ibate',
            v: 4298
        }, {l: 'Itatí', v: 4299}, {l: 'Ituzaingó', v: 4300}, {l: 'José Rafael Gómez', v: 4301}, {l: 'Juan Pujol', v: 4302}, {
            l: 'La Cruz',
            v: 4303
        }, {l: 'Lavalle', v: 4304}, {l: 'Lomas de Vallejos', v: 4305}, {l: 'Loreto', v: 4306}, {
            l: 'Mariano I. Loza',
            v: 4307
        }, {l: 'Mburucuyá', v: 4308}, {l: 'Mercedes', v: 4309}, {l: 'Mocoretá', v: 4310}, {
            l: 'Mte. Caseros',
            v: 4311
        }, {l: 'Nueve de Julio', v: 4312}, {l: 'Palmar Grande', v: 4313}, {l: 'Parada Pucheta', v: 4314}, {
            l: 'Paso de La Patria',
            v: 4315
        }, {l: 'Paso de Los Libres', v: 4316}, {l: 'Pedro R. Fernandez', v: 4317}, {l: 'Perugorría', v: 4318}, {
            l: 'Pueblo Libertador',
            v: 4319
        }, {l: 'Ramada Paso', v: 4320}, {l: 'Riachuelo', v: 4321}, {l: 'Saladas', v: 4322}, {l: 'San Antonio', v: 4323}, {
            l: 'San Carlos',
            v: 4324
        }, {l: 'San Cosme', v: 4325}, {l: 'San Lorenzo', v: 4326}, {l: 'San Luis del Palmar', v: 4327}, {
            l: 'San Miguel',
            v: 4328
        }, {l: 'San Roque', v: 4329}, {l: 'Santa Ana', v: 4330}, {l: 'Santa Lucía', v: 4331}, {l: 'Santo Tomé', v: 4332}, {
            l: 'Sauce',
            v: 4333
        }, {l: 'Tabay', v: 4334}, {l: 'Tapebicuá', v: 4335}, {l: 'Tatacua', v: 4336}, {l: 'Virasoro', v: 4337}, {
            l: 'Yapeyú',
            v: 4338
        }, {l: 'Yataití Calle', v: 4339}], l: 'Corrientes', v: 92
    }, {
        'cities': [{l: 'Alarcón', v: 4341}, {l: 'Alcaraz', v: 4342}, {l: 'Alcaraz N.', v: 4343}, {
            l: 'Alcaraz S.',
            v: 4344
        }, {l: 'Aldea Asunción', v: 4345}, {l: 'Aldea Brasilera', v: 4346}, {l: 'Aldea Elgenfeld', v: 4347}, {
            l: 'Aldea Grapschental',
            v: 4348
        }, {l: 'Aldea Ma. Luisa', v: 4349}, {l: 'Aldea Protestante', v: 4350}, {l: 'Aldea Salto', v: 4351}, {
            l: 'Aldea San Antonio (G)',
            v: 4352
        }, {l: 'Aldea San Antonio (P)', v: 4353}, {l: 'Aldea San Juan', v: 4354}, {l: 'Aldea San Miguel', v: 4355}, {
            l: 'Aldea San Rafael',
            v: 4356
        }, {l: 'Aldea Spatzenkutter', v: 4357}, {l: 'Aldea Sta. María', v: 4358}, {l: 'Aldea Sta. Rosa', v: 4359}, {
            l: 'Aldea Valle María',
            v: 4360
        }, {l: 'Altamirano Sur', v: 4361}, {l: 'Antelo', v: 4362}, {l: 'Antonio Tomás', v: 4363}, {
            l: 'Aranguren',
            v: 4364
        }, {l: 'Arroyo Barú', v: 4365}, {l: 'Arroyo Burgos', v: 4366}, {l: 'Arroyo Clé', v: 4367}, {
            l: 'Arroyo Corralito',
            v: 4368
        }, {l: 'Arroyo del Medio', v: 4369}, {l: 'Arroyo Maturrango', v: 4370}, {l: 'Arroyo Palo Seco', v: 4371}, {
            l: 'Banderas',
            v: 4372
        }, {l: 'Basavilbaso', v: 4373}, {l: 'Betbeder', v: 4374}, {l: 'Bovril', v: 4375}, {l: 'Caseros', v: 4376}, {
            l: 'Ceibas',
            v: 4377
        }, {l: 'Cerrito', v: 4378}, {l: 'Chajarí', v: 4379}, {l: 'Chilcas', v: 4380}, {l: 'Clodomiro Ledesma', v: 4381}, {
            l: 'Col. Alemana',
            v: 4382
        }, {l: 'Col. Avellaneda', v: 4383}, {l: 'Col. Avigdor', v: 4384}, {l: 'Col. Ayuí', v: 4385}, {
            l: 'Col. Baylina',
            v: 4386
        }, {l: 'Col. Carrasco', v: 4387}, {l: 'Col. Celina', v: 4388}, {l: 'Col. Cerrito', v: 4389}, {
            l: 'Col. Crespo',
            v: 4390
        }, {l: 'Col. Elia', v: 4391}, {l: 'Col. Ensayo', v: 4392}, {l: 'Col. La Argentina', v: 4393}, {
            l: 'Col. Merou',
            v: 4394
        }, {l: 'Col. Oficial Nº13', v: 4395}, {l: 'Col. Oficial Nº14', v: 4396}, {l: 'Col. Oficial Nª3', v: 4397}, {
            l: 'Col. Oficial Nº5',
            v: 4398
        }, {l: 'Col. Reffino', v: 4399}, {l: 'Col. Viraró', v: 4400}, {l: 'Col. Gral. Roca', v: 4401}, {
            l: 'Col. Tunas',
            v: 4402
        }, {l: 'Colón', v: 4403}, {l: 'Concepción del Uruguay', v: 4404}, {l: 'Concordia', v: 4405}, {
            l: 'Conscripto Bernardi',
            v: 4406
        }, {l: 'Costa Grande', v: 4407}, {l: 'Costa San Antonio', v: 4408}, {l: 'Costa Uruguay N.', v: 4409}, {
            l: 'Costa Uruguay S.',
            v: 4410
        }, {l: 'Crespo', v: 4411}, {l: 'Crucecitas 3ª', v: 4412}, {l: 'Crucecitas 7ª', v: 4413}, {
            l: 'Crucecitas 8ª',
            v: 4414
        }, {l: 'Cuchilla Redonda', v: 4415}, {l: 'Curtiembre', v: 4416}, {l: 'Diamante', v: 4417}, {
            l: 'Distrito 6º',
            v: 4418
        }, {l: 'Distrito Chañar', v: 4419}, {l: 'Distrito Chiqueros', v: 4420}, {l: 'Distrito Cuarto', v: 4421}, {
            l: 'Distrito Diego López',
            v: 4422
        }, {l: 'Distrito Pajonal', v: 4423}, {l: 'Distrito Sauce', v: 4424}, {l: 'Distrito Tala', v: 4425}, {
            l: 'Distrito Talitas',
            v: 4426
        }, {l: 'Don Cristóbal 1ª Sección', v: 4427}, {l: 'Don Cristóbal 2ª Sección', v: 4428}, {l: 'Durazno', v: 4429}, {
            l: 'El Cimarrón',
            v: 4430
        }, {l: 'El Gramillal', v: 4431}, {l: 'El Palenque', v: 4432}, {l: 'El Pingo', v: 4433}, {
            l: 'El Quebracho',
            v: 4434
        }, {l: 'El Redomón', v: 4435}, {l: 'El Solar', v: 4436}, {l: 'Enrique Carbo', v: 4437}, {
            l: 'Entre Ríos',
            v: 4438
        }, {l: 'Espinillo N.', v: 4439}, {l: 'Estación Campos', v: 4440}, {l: 'Estación Escriña', v: 4441}, {
            l: 'Estación Lazo',
            v: 4442
        }, {l: 'Estación Raíces', v: 4443}, {l: 'Estación Yerúa', v: 4444}, {l: 'Estancia Grande', v: 4445}, {
            l: 'Estancia Líbaros',
            v: 4446
        }, {l: 'Estancia Racedo', v: 4447}, {l: 'Estancia Solá', v: 4448}, {l: 'Estancia Yuquerí', v: 4449}, {
            l: 'Estaquitas',
            v: 4450
        }, {l: 'Faustino M. Parera', v: 4451}, {l: 'Febre', v: 4452}, {l: 'Federación', v: 4453}, {
            l: 'Federal',
            v: 4454
        }, {l: 'Gdor. Echagüe', v: 4455}, {l: 'Gdor. Mansilla', v: 4456}, {l: 'Gilbert', v: 4457}, {
            l: 'González Calderón',
            v: 4458
        }, {l: 'Gral. Almada', v: 4459}, {l: 'Gral. Alvear', v: 4460}, {l: 'Gral. Campos', v: 4461}, {
            l: 'Gral. Galarza',
            v: 4462
        }, {l: 'Gral. Ramírez', v: 4463}, {l: 'Gualeguay', v: 4464}, {l: 'Gualeguaychú', v: 4465}, {
            l: 'Gualeguaycito',
            v: 4466
        }, {l: 'Guardamonte', v: 4467}, {l: 'Hambis', v: 4468}, {l: 'Hasenkamp', v: 4469}, {l: 'Hernandarias', v: 4470}, {
            l: 'Hernández',
            v: 4471
        }, {l: 'Herrera', v: 4472}, {l: 'Hinojal', v: 4473}, {l: 'Hocker', v: 4474}, {l: 'Ing. Sajaroff', v: 4475}, {
            l: 'Irazusta',
            v: 4476
        }, {l: 'Isletas', v: 4477}, {l: 'J.J De Urquiza', v: 4478}, {l: 'Jubileo', v: 4479}, {l: 'La Clarita', v: 4480}, {
            l: 'La Criolla',
            v: 4481
        }, {l: 'La Esmeralda', v: 4482}, {l: 'La Florida', v: 4483}, {l: 'La Fraternidad', v: 4484}, {
            l: 'La Hierra',
            v: 4485
        }, {l: 'La Ollita', v: 4486}, {l: 'La Paz', v: 4487}, {l: 'La Picada', v: 4488}, {l: 'La Providencia', v: 4489}, {
            l: 'La Verbena',
            v: 4490
        }, {l: 'Laguna Benítez', v: 4491}, {l: 'Larroque', v: 4492}, {l: 'Las Cuevas', v: 4493}, {
            l: 'Las Garzas',
            v: 4494
        }, {l: 'Las Guachas', v: 4495}, {l: 'Las Mercedes', v: 4496}, {l: 'Las Moscas', v: 4497}, {
            l: 'Las Mulitas',
            v: 4498
        }, {l: 'Las Toscas', v: 4499}, {l: 'Laurencena', v: 4500}, {l: 'Libertador San Martín', v: 4501}, {
            l: 'Loma Limpia',
            v: 4502
        }, {l: 'Los Ceibos', v: 4503}, {l: 'Los Charruas', v: 4504}, {l: 'Los Conquistadores', v: 4505}, {
            l: 'Lucas González',
            v: 4506
        }, {l: 'Lucas N.', v: 4507}, {l: 'Lucas S. 1ª', v: 4508}, {l: 'Lucas S. 2ª', v: 4509}, {l: 'Maciá', v: 4510}, {
            l: 'María Grande',
            v: 4511
        }, {l: 'María Grande 2ª', v: 4512}, {l: 'Médanos', v: 4513}, {l: 'Mojones N.', v: 4514}, {
            l: 'Mojones S.',
            v: 4515
        }, {l: 'Molino Doll', v: 4516}, {l: 'Monte Redondo', v: 4517}, {l: 'Montoya', v: 4518}, {l: 'Mulas Grandes', v: 4519}, {
            l: 'Ñancay',
            v: 4520
        }, {l: 'Nogoyá', v: 4521}, {l: 'Nueva Escocia', v: 4522}, {l: 'Nueva Vizcaya', v: 4523}, {l: 'Ombú', v: 4524}, {
            l: 'Oro Verde',
            v: 4525
        }, {l: 'Paraná', v: 4526}, {l: 'Pasaje Guayaquil', v: 4527}, {l: 'Pasaje Las Tunas', v: 4528}, {
            l: 'Paso de La Arena',
            v: 4529
        }, {l: 'Paso de La Laguna', v: 4530}, {l: 'Paso de Las Piedras', v: 4531}, {l: 'Paso Duarte', v: 4532}, {
            l: 'Pastor Britos',
            v: 4533
        }, {l: 'Pedernal', v: 4534}, {l: 'Perdices', v: 4535}, {l: 'Picada Berón', v: 4536}, {
            l: 'Piedras Blancas',
            v: 4537
        }, {l: 'Primer Distrito Cuchilla', v: 4538}, {l: 'Primero de Mayo', v: 4539}, {l: 'Pronunciamiento', v: 4540}, {
            l: 'Pto. Algarrobo',
            v: 4541
        }, {l: 'Pto. Ibicuy', v: 4542}, {l: 'Pueblo Brugo', v: 4543}, {l: 'Pueblo Cazes', v: 4544}, {
            l: 'Pueblo Gral. Belgrano',
            v: 4545
        }, {l: 'Pueblo Liebig', v: 4546}, {l: 'Puerto Yeruá', v: 4547}, {l: 'Punta del Monte', v: 4548}, {
            l: 'Quebracho',
            v: 4549
        }, {l: 'Quinto Distrito', v: 4550}, {l: 'Raices Oeste', v: 4551}, {l: 'Rincón de Nogoyá', v: 4552}, {
            l: 'Rincón del Cinto',
            v: 4553
        }, {l: 'Rincón del Doll', v: 4554}, {l: 'Rincón del Gato', v: 4555}, {l: 'Rocamora', v: 4556}, {
            l: 'Rosario del Tala',
            v: 4557
        }, {l: 'San Benito', v: 4558}, {l: 'San Cipriano', v: 4559}, {l: 'San Ernesto', v: 4560}, {
            l: 'San Gustavo',
            v: 4561
        }, {l: 'San Jaime', v: 4562}, {l: 'San José', v: 4563}, {l: 'San José de Feliciano', v: 4564}, {
            l: 'San Justo',
            v: 4565
        }, {l: 'San Marcial', v: 4566}, {l: 'San Pedro', v: 4567}, {l: 'San Ramírez', v: 4568}, {l: 'San Ramón', v: 4569}, {
            l: 'San Roque',
            v: 4570
        }, {l: 'San Salvador', v: 4571}, {l: 'San Víctor', v: 4572}, {l: 'Santa Ana', v: 4573}, {
            l: 'Santa Anita',
            v: 4574
        }, {l: 'Santa Elena', v: 4575}, {l: 'Santa Lucía', v: 4576}, {l: 'Santa Luisa', v: 4577}, {
            l: 'Sauce de Luna',
            v: 4578
        }, {l: 'Sauce Montrull', v: 4579}, {l: 'Sauce Pinto', v: 4580}, {l: 'Sauce Sur', v: 4581}, {l: 'Seguí', v: 4582}, {
            l: 'Sir Leonard',
            v: 4583
        }, {l: 'Sosa', v: 4584}, {l: 'Tabossi', v: 4585}, {l: 'Tezanos Pinto', v: 4586}, {l: 'Ubajay', v: 4587}, {
            l: 'Urdinarrain',
            v: 4588
        }, {l: 'Veinte de Septiembre', v: 4589}, {l: 'Viale', v: 4590}, {l: 'Victoria', v: 4591}, {
            l: 'Villa Clara',
            v: 4592
        }, {l: 'Villa del Rosario', v: 4593}, {l: 'Villa Domínguez', v: 4594}, {l: 'Villa Elisa', v: 4595}, {
            l: 'Villa Fontana',
            v: 4596
        }, {l: 'Villa Gdor. Etchevehere', v: 4597}, {l: 'Villa Mantero', v: 4598}, {l: 'Villa Paranacito', v: 4599}, {
            l: 'Villa Urquiza',
            v: 4600
        }, {l: 'Villaguay', v: 4601}, {l: 'Walter Moss', v: 4602}, {l: 'Yacaré', v: 4603}, {l: 'Yeso Oeste', v: 4604}],
        l: 'Entre Ríos',
        v: 93
    }, {
        'cities': [{l: 'Buena Vista', v: 4606}, {l: 'Clorinda', v: 4607}, {l: 'Col. Pastoril', v: 4608}, {
            l: 'Cte. Fontana',
            v: 4609
        }, {l: 'El Colorado', v: 4610}, {l: 'El Espinillo', v: 4611}, {l: 'Estanislao Del Campo', v: 4612}, {
            l: 'Formosa',
            v: 4613
        }, {l: 'Fortín Lugones', v: 4614}, {l: 'Gral. Lucio V. Mansilla', v: 4615}, {
            l: 'Gral. Manuel Belgrano',
            v: 4616
        }, {l: 'Gral. Mosconi', v: 4617}, {l: 'Gran Guardia', v: 4618}, {l: 'Herradura', v: 4619}, {
            l: 'Ibarreta',
            v: 4620
        }, {l: 'Ing. Juárez', v: 4621}, {l: 'Laguna Blanca', v: 4622}, {l: 'Laguna Naick Neck', v: 4623}, {
            l: 'Laguna Yema',
            v: 4624
        }, {l: 'Las Lomitas', v: 4625}, {l: 'Los Chiriguanos', v: 4626}, {l: 'Mayor V. Villafañe', v: 4627}, {
            l: 'Misión San Fco.',
            v: 4628
        }, {l: 'Palo Santo', v: 4629}, {l: 'Pirané', v: 4630}, {l: 'Pozo del Maza', v: 4631}, {
            l: 'Riacho He-He',
            v: 4632
        }, {l: 'San Hilario', v: 4633}, {l: 'San Martín II', v: 4634}, {l: 'Siete Palmas', v: 4635}, {
            l: 'Subteniente Perín',
            v: 4636
        }, {l: 'Tres Lagunas', v: 4637}, {l: 'Villa Dos Trece', v: 4638}, {l: 'Villa Escolar', v: 4639}, {
            l: 'Villa Gral. Güemes',
            v: 4640
        }], l: 'Formosa', v: 94
    }, {
        'cities': [{l: 'Abdon Castro Tolay', v: 4642}, {l: 'Abra Pampa', v: 4643}, {l: 'Abralaite', v: 4644}, {
            l: 'Aguas Calientes',
            v: 4645
        }, {l: 'Arrayanal', v: 4646}, {l: 'Barrios', v: 4647}, {l: 'Caimancito', v: 4648}, {l: 'Calilegua', v: 4649}, {
            l: 'Cangrejillos',
            v: 4650
        }, {l: 'Caspala', v: 4651}, {l: 'Catuá', v: 4652}, {l: 'Cieneguillas', v: 4653}, {l: 'Coranzulli', v: 4654}, {
            l: 'Cusi-Cusi',
            v: 4655
        }, {l: 'El Aguilar', v: 4656}, {l: 'El Carmen', v: 4657}, {l: 'El Cóndor', v: 4658}, {l: 'El Fuerte', v: 4659}, {
            l: 'El Piquete',
            v: 4660
        }, {l: 'El Talar', v: 4661}, {l: 'Fraile Pintado', v: 4662}, {l: 'Hipólito Yrigoyen', v: 4663}, {
            l: 'Huacalera',
            v: 4664
        }, {l: 'Humahuaca', v: 4665}, {l: 'La Esperanza', v: 4666}, {l: 'La Mendieta', v: 4667}, {l: 'La Quiaca', v: 4668}, {
            l: 'Ledesma',
            v: 4669
        }, {l: 'Libertador Gral. San Martin', v: 4670}, {l: 'Maimara', v: 4671}, {l: 'Mina Pirquitas', v: 4672}, {
            l: 'Monterrico',
            v: 4673
        }, {l: 'Palma Sola', v: 4674}, {l: 'Palpalá', v: 4675}, {l: 'Pampa Blanca', v: 4676}, {l: 'Pampichuela', v: 4677}, {
            l: 'Perico',
            v: 4678
        }, {l: 'Puesto del Marqués', v: 4679}, {l: 'Puesto Viejo', v: 4680}, {l: 'Pumahuasi', v: 4681}, {
            l: 'Purmamarca',
            v: 4682
        }, {l: 'Rinconada', v: 4683}, {l: 'Rodeitos', v: 4684}, {l: 'Rosario de Río Grande', v: 4685}, {
            l: 'San Antonio',
            v: 4686
        }, {l: 'San Francisco', v: 4687}, {l: 'San Pedro', v: 4688}, {l: 'San Rafael', v: 4689}, {
            l: 'San Salvador',
            v: 4690
        }, {l: 'Santa Ana', v: 4691}, {l: 'Santa Catalina', v: 4692}, {l: 'Santa Clara', v: 4693}, {l: 'Susques', v: 4694}, {
            l: 'Tilcara',
            v: 4695
        }, {l: 'Tres Cruces', v: 4696}, {l: 'Tumbaya', v: 4697}, {l: 'Valle Grande', v: 4698}, {l: 'Vinalito', v: 4699}, {
            l: 'Volcán',
            v: 4700
        }, {l: 'Yala', v: 4701}, {l: 'Yaví', v: 4702}, {l: 'Yuto', v: 4703}], l: 'Jujuy', v: 95
    }, {
        'cities': [{l: 'Abramo', v: 4705}, {l: 'Adolfo Van Praet', v: 4706}, {l: 'Agustoni', v: 4707}, {
            l: 'Algarrobo del Aguila',
            v: 4708
        }, {l: 'Alpachiri', v: 4709}, {l: 'Alta Italia', v: 4710}, {l: 'Anguil', v: 4711}, {l: 'Arata', v: 4712}, {
            l: 'Ataliva Roca',
            v: 4713
        }, {l: 'Bernardo Larroude', v: 4714}, {l: 'Bernasconi', v: 4715}, {l: 'Caleufú', v: 4716}, {
            l: 'Carro Quemado',
            v: 4717
        }, {l: 'Catriló', v: 4718}, {l: 'Ceballos', v: 4719}, {l: 'Chacharramendi', v: 4720}, {
            l: 'Col. Barón',
            v: 4721
        }, {l: 'Col. Santa María', v: 4722}, {l: 'Conhelo', v: 4723}, {l: 'Coronel Hilario Lagos', v: 4724}, {
            l: 'Cuchillo-Có',
            v: 4725
        }, {l: 'Doblas', v: 4726}, {l: 'Dorila', v: 4727}, {l: 'Eduardo Castex', v: 4728}, {l: 'Embajador Martini', v: 4729}, {
            l: 'Falucho',
            v: 4730
        }, {l: 'Gral. Acha', v: 4731}, {l: 'Gral. Manuel Campos', v: 4732}, {l: 'Gral. Pico', v: 4733}, {
            l: 'Guatraché',
            v: 4734
        }, {l: 'Ing. Luiggi', v: 4735}, {l: 'Intendente Alvear', v: 4736}, {l: 'Jacinto Arauz', v: 4737}, {
            l: 'La Adela',
            v: 4738
        }, {l: 'La Humada', v: 4739}, {l: 'La Maruja', v: 4740}, {l: 'La Pampa', v: 4741}, {l: 'La Reforma', v: 4742}, {
            l: 'Limay Mahuida',
            v: 4743
        }, {l: 'Lonquimay', v: 4744}, {l: 'Loventuel', v: 4745}, {l: 'Luan Toro', v: 4746}, {l: 'Macachín', v: 4747}, {
            l: 'Maisonnave',
            v: 4748
        }, {l: 'Mauricio Mayer', v: 4749}, {l: 'Metileo', v: 4750}, {l: 'Miguel Cané', v: 4751}, {
            l: 'Miguel Riglos',
            v: 4752
        }, {l: 'Monte Nievas', v: 4753}, {l: 'Parera', v: 4754}, {l: 'Perú', v: 4755}, {l: 'Pichi-Huinca', v: 4756}, {
            l: 'Puelches',
            v: 4757
        }, {l: 'Puelén', v: 4758}, {l: 'Quehue', v: 4759}, {l: 'Quemú Quemú', v: 4760}, {l: 'Quetrequén', v: 4761}, {
            l: 'Rancul',
            v: 4762
        }, {l: 'Realicó', v: 4763}, {l: 'Relmo', v: 4764}, {l: 'Rolón', v: 4765}, {l: 'Rucanelo', v: 4766}, {
            l: 'Sarah',
            v: 4767
        }, {l: 'Speluzzi', v: 4768}, {l: 'Sta. Isabel', v: 4769}, {l: 'Sta. Rosa', v: 4770}, {l: 'Sta. Teresa', v: 4771}, {
            l: 'Telén',
            v: 4772
        }, {l: 'Toay', v: 4773}, {l: 'Tomas M. de Anchorena', v: 4774}, {l: 'Trenel', v: 4775}, {l: 'Unanue', v: 4776}, {
            l: 'Uriburu',
            v: 4777
        }, {l: 'Veinticinco de Mayo', v: 4778}, {l: 'Vertiz', v: 4779}, {l: 'Victorica', v: 4780}, {
            l: 'Villa Mirasol',
            v: 4781
        }, {l: 'Winifreda', v: 4782}], l: 'La Pampa', v: 96
    }, {
        'cities': [{l: 'Arauco', v: 4784}, {l: 'Capital', v: 4785}, {l: 'Castro Barros', v: 4786}, {
            l: 'Chamical',
            v: 4787
        }, {l: 'Chilecito', v: 4788}, {l: 'Coronel F. Varela', v: 4789}, {l: 'Famatina', v: 4790}, {
            l: 'Gral. A.V.Peñaloza',
            v: 4791
        }, {l: 'Gral. Belgrano', v: 4792}, {l: 'Gral. J.F. Quiroga', v: 4793}, {l: 'Gral. Lamadrid', v: 4794}, {
            l: 'Gral. Ocampo',
            v: 4795
        }, {l: 'Gral. San Martín', v: 4796}, {l: 'Independencia', v: 4797}, {l: 'Rosario Penaloza', v: 4798}, {
            l: 'San Blas de Los Sauces',
            v: 4799
        }, {l: 'Sanagasta', v: 4800}, {l: 'Vinchina', v: 4801}], l: 'La Rioja', v: 97
    }, {
        'cities': [{l: 'Capital', v: 4803}, {l: 'Chacras de Coria', v: 4804}, {l: 'Dorrego', v: 4805}, {
            l: 'Gllen',
            v: 4806
        }, {l: 'Godoy Cruz', v: 4807}, {l: 'Gral. Alvear', v: 4808}, {l: 'Guaymallén', v: 4809}, {l: 'Junín', v: 4810}, {
            l: 'La Paz',
            v: 4811
        }, {l: 'Las Heras', v: 4812}, {l: 'Lavalle', v: 4813}, {l: 'Luján', v: 4814}, {l: 'Luján De Cuyo', v: 4815}, {
            l: 'Maipú',
            v: 4816
        }, {l: 'Malargüe', v: 4817}, {l: 'Rivadavia', v: 4818}, {l: 'San Carlos', v: 4819}, {l: 'San Martín', v: 4820}, {
            l: 'San Rafael',
            v: 4821
        }, {l: 'Sta. Rosa', v: 4822}, {l: 'Tunuyán', v: 4823}, {l: 'Tupungato', v: 4824}, {l: 'Villa Nueva', v: 4825}], l: 'Mendoza', v: 98
    }, {
        'cities': [{l: 'Alba Posse', v: 4827}, {l: 'Almafuerte', v: 4828}, {l: 'Apóstoles', v: 4829}, {
            l: 'Aristóbulo Del Valle',
            v: 4830
        }, {l: 'Arroyo Del Medio', v: 4831}, {l: 'Azara', v: 4832}, {l: 'Bdo. De Irigoyen', v: 4833}, {
            l: 'Bonpland',
            v: 4834
        }, {l: 'Caá Yari', v: 4835}, {l: 'Campo Grande', v: 4836}, {l: 'Campo Ramón', v: 4837}, {
            l: 'Campo Viera',
            v: 4838
        }, {l: 'Candelaria', v: 4839}, {l: 'Capioví', v: 4840}, {l: 'Caraguatay', v: 4841}, {
            l: 'Cdte. Guacurarí',
            v: 4842
        }, {l: 'Cerro Azul', v: 4843}, {l: 'Cerro Corá', v: 4844}, {l: 'Col. Alberdi', v: 4845}, {
            l: 'Col. Aurora',
            v: 4846
        }, {l: 'Col. Delicia', v: 4847}, {l: 'Col. Polana', v: 4848}, {l: 'Col. Victoria', v: 4849}, {
            l: 'Col. Wanda',
            v: 4850
        }, {l: 'Concepción De La Sierra', v: 4851}, {l: 'Corpus', v: 4852}, {l: 'Dos Arroyos', v: 4853}, {
            l: 'Dos de Mayo',
            v: 4854
        }, {l: 'El Alcázar', v: 4855}, {l: 'El Dorado', v: 4856}, {l: 'El Soberbio', v: 4857}, {l: 'Esperanza', v: 4858}, {
            l: 'F. Ameghino',
            v: 4859
        }, {l: 'Fachinal', v: 4860}, {l: 'Garuhapé', v: 4861}, {l: 'Garupá', v: 4862}, {l: 'Gdor. López', v: 4863}, {
            l: 'Gdor. Roca',
            v: 4864
        }, {l: 'Gral. Alvear', v: 4865}, {l: 'Gral. Urquiza', v: 4866}, {l: 'Guaraní', v: 4867}, {l: 'H. Yrigoyen', v: 4868}, {
            l: 'Iguazú',
            v: 4869
        }, {l: 'Itacaruaré', v: 4870}, {l: 'Jardín América', v: 4871}, {l: 'Leandro N. Alem', v: 4872}, {
            l: 'Libertad',
            v: 4873
        }, {l: 'Loreto', v: 4874}, {l: 'Los Helechos', v: 4875}, {l: 'Mártires', v: 4876}, {l: 'Misiones', v: 4877}, {
            l: 'Mojón Grande',
            v: 4878
        }, {l: 'Montecarlo', v: 4879}, {l: 'Nueve de Julio', v: 4880}, {l: 'Oberá', v: 4881}, {
            l: 'Olegario V. Andrade',
            v: 4882
        }, {l: 'Panambí', v: 4883}, {l: 'Posadas', v: 4884}, {l: 'Profundidad', v: 4885}, {l: 'Pto. Iguazú', v: 4886}, {
            l: 'Pto. Leoni',
            v: 4887
        }, {l: 'Pto. Piray', v: 4888}, {l: 'Pto. Rico', v: 4889}, {l: 'Ruiz de Montoya', v: 4890}, {
            l: 'San Antonio',
            v: 4891
        }, {l: 'San Ignacio', v: 4892}, {l: 'San Javier', v: 4893}, {l: 'San José', v: 4894}, {l: 'San Martín', v: 4895}, {
            l: 'San Pedro',
            v: 4896
        }, {l: 'San Vicente', v: 4897}, {l: 'Santiago De Liniers', v: 4898}, {l: 'Sta. Ana', v: 4899}, {
            l: 'Sta. María',
            v: 4900
        }, {l: 'Santo Pipo', v: 4901}, {l: 'Tres Capones', v: 4902}, {l: 'Veinticinco de Mayo', v: 4903}, {l: 'Wanda', v: 4904}],
        l: 'Misiones',
        v: 99
    }, {
        'cities': [{l: 'Aguada San Roque', v: 4906}, {l: 'Aluminé', v: 4907}, {l: 'Andacollo', v: 4908}, {
            l: 'Añelo',
            v: 4909
        }, {l: 'Bajada del Agrio', v: 4910}, {l: 'Barrancas', v: 4911}, {l: 'Buta Ranquil', v: 4912}, {
            l: 'Capital',
            v: 4913
        }, {l: 'Caviahué', v: 4914}, {l: 'Centenario', v: 4915}, {l: 'Chorriaca', v: 4916}, {l: 'Chos Malal', v: 4917}, {
            l: 'Cipolletti',
            v: 4918
        }, {l: 'Covunco Abajo', v: 4919}, {l: 'Coyuco Cochico', v: 4920}, {l: 'Cutral Có', v: 4921}, {
            l: 'El Cholar',
            v: 4922
        }, {l: 'El Huecú', v: 4923}, {l: 'El Sauce', v: 4924}, {l: 'Guañacos', v: 4925}, {l: 'Huinganco', v: 4926}, {
            l: 'Las Coloradas',
            v: 4927
        }, {l: 'Las Lajas', v: 4928}, {l: 'Las Ovejas', v: 4929}, {l: 'Loncopué', v: 4930}, {
            l: 'Los Catutos',
            v: 4931
        }, {l: 'Los Chihuidos', v: 4932}, {l: 'Los Miches', v: 4933}, {l: 'Manzano Amargo', v: 4934}, {
            l: 'Neuquén',
            v: 4935
        }, {l: 'Octavio Pico', v: 4936}, {l: 'Paso Aguerre', v: 4937}, {l: 'Picún Leufú', v: 4938}, {
            l: 'Piedra del Aguila',
            v: 4939
        }, {l: 'Pilo Lil', v: 4940}, {l: 'Plaza Huincul', v: 4941}, {l: 'Plottier', v: 4942}, {
            l: 'Quili Malal',
            v: 4943
        }, {l: 'Ramón Castro', v: 4944}, {l: 'Rincón de Los Sauces', v: 4945}, {
            l: 'San Martín de Los Andes',
            v: 4946
        }, {l: 'San Patricio del Chañar', v: 4947}, {l: 'Santo Tomás', v: 4948}, {l: 'Sauzal Bonito', v: 4949}, {
            l: 'Senillosa',
            v: 4950
        }, {l: 'Taquimilán', v: 4951}, {l: 'Tricao Malal', v: 4952}, {l: 'Varvarco', v: 4953}, {
            l: 'Villa Curí Leuvu',
            v: 4954
        }, {l: 'Villa del Nahueve', v: 4955}, {l: 'Villa del Puente Picún Leuvú', v: 4956}, {
            l: 'Villa El Chocón',
            v: 4957
        }, {l: 'Villa La Angostura', v: 4958}, {l: 'Villa Pehuenia', v: 4959}, {l: 'Villa Traful', v: 4960}, {
            l: 'Vista Alegre',
            v: 4961
        }, {l: 'Zapala', v: 4962}], l: 'Neuquén', v: 100
    }, {
        'cities': [{l: 'Aguada Cecilio', v: 4964}, {l: 'Aguada de Guerra', v: 4965}, {l: 'Allén', v: 4966}, {
            l: 'Arroyo de La Ventana',
            v: 4967
        }, {l: 'Arroyo Los Berros', v: 4968}, {l: 'Bariloche', v: 4969}, {l: 'Campo Grande', v: 4970}, {
            l: 'Catriel',
            v: 4971
        }, {l: 'Cerro Policía', v: 4972}, {l: 'Cervantes', v: 4973}, {l: 'Chelforo', v: 4974}, {l: 'Chinchinales', v: 4975}, {
            l: 'Chimpay',
            v: 4976
        }, {l: 'Chipauquil', v: 4977}, {l: 'Choele Choel', v: 4978}, {l: 'Cinco Saltos', v: 4979}, {
            l: 'Cipolletti',
            v: 4980
        }, {l: 'Clemente Onelli', v: 4981}, {l: 'Colán Conhue', v: 4982}, {l: 'Comallo', v: 4983}, {l: 'Comicó', v: 4984}, {
            l: 'Cona Niyeu',
            v: 4985
        }, {l: 'Calte. Cordero', v: 4986}, {l: 'Coronel Belisle', v: 4987}, {l: 'Cubanea', v: 4988}, {
            l: 'Darwin',
            v: 4989
        }, {l: 'Dina Huapi', v: 4990}, {l: 'El Bolsón', v: 4991}, {l: 'El Caín', v: 4992}, {l: 'El Manso', v: 4993}, {
            l: 'Gral. Conesa',
            v: 4994
        }, {l: 'Gral. Enrique Godoy', v: 4995}, {l: 'Gral. Fernandez Oro', v: 4996}, {l: 'Gral. Roca', v: 4997}, {
            l: 'Guardia Mitre',
            v: 4998
        }, {l: 'Ing. Jacobacci', v: 4999}, {l: 'Ing. Huergo', v: 5000}, {l: 'Laguna Blanca', v: 5001}, {
            l: 'Lamarque',
            v: 5002
        }, {l: 'Las Grutas', v: 5003}, {l: 'Los Menucos', v: 5004}, {l: 'Luis Beltrán', v: 5005}, {
            l: 'Mainqué',
            v: 5006
        }, {l: 'Mamuel Choique', v: 5007}, {l: 'Maquinchao', v: 5008}, {l: 'Mencué', v: 5009}, {
            l: 'Mtro. Ramos Mexia',
            v: 5010
        }, {l: 'Nahuel Niyeu', v: 5011}, {l: 'Naupa Huen', v: 5012}, {l: 'Ñorquinco', v: 5013}, {
            l: 'Ojos de Agua',
            v: 5014
        }, {l: 'Paso de Agua', v: 5015}, {l: 'Paso Flores', v: 5016}, {l: 'Peñas Blancas', v: 5017}, {
            l: 'Pichi Mahuida',
            v: 5018
        }, {l: 'Pilcaniyeu', v: 5019}, {l: 'Pomona', v: 5020}, {l: 'Prahuaniyeu', v: 5021}, {l: 'Rincón Treneta', v: 5022}, {
            l: 'Río Chico',
            v: 5023
        }, {l: 'Río Colorado', v: 5024}, {l: 'Roca', v: 5025}, {l: 'San Antonio Oeste', v: 5026}, {
            l: 'San Javier',
            v: 5027
        }, {l: 'Sierra Colorada', v: 5028}, {l: 'Sierra Grande', v: 5029}, {l: 'Sierra Pailemán', v: 5030}, {
            l: 'Valcheta',
            v: 5031
        }, {l: 'Valle Azul', v: 5032}, {l: 'Viedma', v: 5033}, {l: 'Villa Llanquín', v: 5034}, {
            l: 'Villa Mascardi',
            v: 5035
        }, {l: 'Villa Regina', v: 5036}, {l: 'Yaminué', v: 5037}], l: 'Río Negro', v: 101
    }, {
        'cities': [{l: 'Aguaray', v: 5039}, {l: 'Angastaco', v: 5040}, {l: 'Animaná', v: 5041}, {l: 'A. Saravia', v: 5042}, {
            l: 'Cachi',
            v: 5043
        }, {l: 'Cafayate', v: 5044}, {l: 'Campo Quijano', v: 5045}, {l: 'Campo Santo', v: 5046}, {l: 'Capital', v: 5047}, {
            l: 'Cerrillos',
            v: 5048
        }, {l: 'Chicoana', v: 5049}, {l: 'Col. Sta. Rosa', v: 5050}, {l: 'Coronel Moldes', v: 5051}, {
            l: 'El Bordo',
            v: 5052
        }, {l: 'El Carril', v: 5053}, {l: 'El Galpón', v: 5054}, {l: 'El Jardín', v: 5055}, {l: 'El Potrero', v: 5056}, {
            l: 'El Quebrachal',
            v: 5057
        }, {l: 'El Tala', v: 5058}, {l: 'Embarcación', v: 5059}, {l: 'Gral. Ballivian', v: 5060}, {
            l: 'Gral. Güemes',
            v: 5061
        }, {l: 'Gral. Mosconi', v: 5062}, {l: 'Gral. Pizarro', v: 5063}, {l: 'Guachipas', v: 5064}, {
            l: 'Hipólito Yrigoyen',
            v: 5065
        }, {l: 'Iruyá', v: 5066}, {l: 'Isla De Cañas', v: 5067}, {l: 'J. V. Gonzalez', v: 5068}, {
            l: 'La Caldera',
            v: 5069
        }, {l: 'La Candelaria', v: 5070}, {l: 'La Merced', v: 5071}, {l: 'La Poma', v: 5072}, {l: 'La Viña', v: 5073}, {
            l: 'Las Lajitas',
            v: 5074
        }, {l: 'Los Toldos', v: 5075}, {l: 'Metán', v: 5076}, {l: 'Molinos', v: 5077}, {l: 'Nazareno', v: 5078}, {
            l: 'Orán',
            v: 5079
        }, {l: 'Payogasta', v: 5080}, {l: 'Pichanal', v: 5081}, {l: 'Prof. S. Mazza', v: 5082}, {
            l: 'Río Piedras',
            v: 5083
        }, {l: 'Rivadavia Banda Norte', v: 5084}, {l: 'Rivadavia Banda Sur', v: 5085}, {
            l: 'Rosario de La Frontera',
            v: 5086
        }, {l: 'Rosario de Lerma', v: 5087}, {l: 'Salta', v: 5088}, {l: 'San Antonio', v: 5089}, {
            l: 'San Carlos',
            v: 5090
        }, {l: 'San José De Metán', v: 5091}, {l: 'San Ramón', v: 5092}, {l: 'Santa Victoria E.', v: 5093}, {
            l: 'Santa Victoria O.',
            v: 5094
        }, {l: 'Saclantás', v: 5095}, {l: 'Tartagal', v: 5096}, {l: 'Tolar Grande', v: 5097}, {l: 'Urundel', v: 5098}, {
            l: 'Vaqueros',
            v: 5099
        }, {l: 'Villa San Lorenzo', v: 5100}], l: 'Salta', v: 102
    }, {
        'cities': [{l: 'Albardón', v: 5102}, {l: 'Angaco', v: 5103}, {l: 'Calingasta', v: 5104}, {l: 'Capital', v: 5105}, {
            l: 'Caucete',
            v: 5106
        }, {l: 'Chimbas', v: 5107}, {l: 'Iglesia', v: 5108}, {l: 'Jachal', v: 5109}, {l: 'Nueve de Julio', v: 5110}, {
            l: 'Pocito',
            v: 5111
        }, {l: 'Rawson', v: 5112}, {l: 'Rivadavia', v: 5113}, {l: 'San Juan', v: 5114}, {l: 'San Martín', v: 5115}, {
            l: 'Santa Lucía',
            v: 5116
        }, {l: 'Sarmiento', v: 5117}, {l: 'Ullum', v: 5118}, {l: 'Valle Fértil', v: 5119}, {l: 'Veinticinco de Mayo', v: 5120}, {
            l: 'Zonda',
            v: 5121
        }], l: 'San Juan', v: 103
    }, {
        'cities': [{l: 'Alto Pelado', v: 5123}, {l: 'Alto Pencoso', v: 5124}, {l: 'Anchorena', v: 5125}, {
            l: 'Arizona',
            v: 5126
        }, {l: 'Bagual', v: 5127}, {l: 'Balde', v: 5128}, {l: 'Batavia', v: 5129}, {l: 'Beazley', v: 5130}, {
            l: 'Buena Esperanza',
            v: 5131
        }, {l: 'Candelaria', v: 5132}, {l: 'Capital', v: 5133}, {l: 'Carolina', v: 5134}, {l: 'Carpintería', v: 5135}, {
            l: 'Concarán',
            v: 5136
        }, {l: 'Cortaderas', v: 5137}, {l: 'El Morro', v: 5138}, {l: 'El Trapiche', v: 5139}, {
            l: 'El Volcán',
            v: 5140
        }, {l: 'Fortín El Patria', v: 5141}, {l: 'Fortuna', v: 5142}, {l: 'Fraga', v: 5143}, {l: 'Juan Jorba', v: 5144}, {
            l: 'Juan Llerena',
            v: 5145
        }, {l: 'Juana Koslay', v: 5146}, {l: 'Justo Daract', v: 5147}, {l: 'La Calera', v: 5148}, {
            l: 'La Florida',
            v: 5149
        }, {l: 'La Punilla', v: 5150}, {l: 'La Toma', v: 5151}, {l: 'Lafinur', v: 5152}, {l: 'Las Aguadas', v: 5153}, {
            l: 'Las Chacras',
            v: 5154
        }, {l: 'Las Lagunas', v: 5155}, {l: 'Las Vertientes', v: 5156}, {l: 'Lavaisse', v: 5157}, {
            l: 'Leandro N. Alem',
            v: 5158
        }, {l: 'Los Molles', v: 5159}, {l: 'Luján', v: 5160}, {l: 'Mercedes', v: 5161}, {l: 'Merlo', v: 5162}, {
            l: 'Naschel',
            v: 5163
        }, {l: 'Navia', v: 5164}, {l: 'Nogolí', v: 5165}, {l: 'Nueva Galia', v: 5166}, {l: 'Papagayos', v: 5167}, {
            l: 'Paso Grande',
            v: 5168
        }, {l: 'Potrero de Los Funes', v: 5169}, {l: 'Quines', v: 5170}, {l: 'Renca', v: 5171}, {
            l: 'Saladillo',
            v: 5172
        }, {l: 'San Francisco', v: 5173}, {l: 'San Gerónimo', v: 5174}, {l: 'San Martín', v: 5175}, {
            l: 'San Pablo',
            v: 5176
        }, {l: 'Santa Rosa de Conlara', v: 5177}, {l: 'Talita', v: 5178}, {l: 'Tilisarao', v: 5179}, {
            l: 'Unión',
            v: 5180
        }, {l: 'Villa de La Quebrada', v: 5181}, {l: 'Villa de Praga', v: 5182}, {l: 'Villa del Carmen', v: 5183}, {
            l: 'Villa Gral. Roca',
            v: 5184
        }, {l: 'Villa Larca', v: 5185}, {l: 'Villa Mercedes', v: 5186}, {l: 'Zanjitas', v: 5187}], l: 'San Luis', v: 104
    }, {
        'cities': [{l: 'Calafate', v: 5189}, {l: 'Caleta Olivia', v: 5190}, {l: 'Cañadón Seco', v: 5191}, {
            l: 'Comandante Piedrabuena',
            v: 5192
        }, {l: 'El Calafate', v: 5193}, {l: 'El Chaltén', v: 5194}, {l: 'Gdor. Gregores', v: 5195}, {
            l: 'Hipólito Yrigoyen',
            v: 5196
        }, {l: 'Jaramillo', v: 5197}, {l: 'Koluel Kaike', v: 5198}, {l: 'Las Heras', v: 5199}, {
            l: 'Los Antiguos',
            v: 5200
        }, {l: 'Perito Moreno', v: 5201}, {l: 'Pico Truncado', v: 5202}, {l: 'Pto. Deseado', v: 5203}, {
            l: 'Pto. San Julián',
            v: 5204
        }, {l: 'Pto. Santa Cruz', v: 5205}, {l: 'Río Cuarto', v: 5206}, {l: 'Río Gallegos', v: 5207}, {
            l: 'Río Turbio',
            v: 5208
        }, {l: 'Tres Lagos', v: 5209}, {l: 'Veintiocho De Noviembre', v: 5210}], l: 'Santa Cruz', v: 105
    }, {
        'cities': [{l: 'Aarón Castellanos', v: 5212}, {l: 'Acebal', v: 5213}, {l: 'Aguará Grande', v: 5214}, {
            l: 'Albarellos',
            v: 5215
        }, {l: 'Alcorta', v: 5216}, {l: 'Aldao', v: 5217}, {l: 'Alejandra', v: 5218}, {l: 'Álvarez', v: 5219}, {
            l: 'Ambrosetti',
            v: 5220
        }, {l: 'Amenábar', v: 5221}, {l: 'Angélica', v: 5222}, {l: 'Angeloni', v: 5223}, {l: 'Arequito', v: 5224}, {
            l: 'Arminda',
            v: 5225
        }, {l: 'Armstrong', v: 5226}, {l: 'Arocena', v: 5227}, {l: 'Arroyo Aguiar', v: 5228}, {
            l: 'Arroyo Ceibal',
            v: 5229
        }, {l: 'Arroyo Leyes', v: 5230}, {l: 'Arroyo Seco', v: 5231}, {l: 'Arrufó', v: 5232}, {l: 'Arteaga', v: 5233}, {
            l: 'Ataliva',
            v: 5234
        }, {l: 'Aurelia', v: 5235}, {l: 'Avellaneda', v: 5236}, {l: 'Barrancas', v: 5237}, {
            l: 'Bauer Y Sigel',
            v: 5238
        }, {l: 'Bella Italia', v: 5239}, {l: 'Berabevú', v: 5240}, {l: 'Berna', v: 5241}, {
            l: 'Bernardo de Irigoyen',
            v: 5242
        }, {l: 'Bigand', v: 5243}, {l: 'Bombal', v: 5244}, {l: 'Bouquet', v: 5245}, {l: 'Bustinza', v: 5246}, {
            l: 'Cabal',
            v: 5247
        }, {l: 'Cacique Ariacaiquin', v: 5248}, {l: 'Cafferata', v: 5249}, {l: 'Calchaquí', v: 5250}, {
            l: 'Campo Andino',
            v: 5251
        }, {l: 'Campo Piaggio', v: 5252}, {l: 'Candioti', v: 5253}, {l: 'Cañada de Gómez', v: 5254}, {
            l: 'Cañada del Ucle',
            v: 5255
        }, {l: 'Cañada Rica', v: 5256}, {l: 'Cañada Rosquín', v: 5257}, {l: 'Capital', v: 5258}, {
            l: 'Capitán Bermúdez',
            v: 5259
        }, {l: 'Capivara', v: 5260}, {l: 'Carcarañá', v: 5261}, {l: 'Carlos Pellegrini', v: 5262}, {
            l: 'Carmen',
            v: 5263
        }, {l: 'Carmen Del Sauce', v: 5264}, {l: 'Carreras', v: 5265}, {l: 'Carrizales', v: 5266}, {l: 'Casalegno', v: 5267}, {
            l: 'Casas',
            v: 5268
        }, {l: 'Casilda', v: 5269}, {l: 'Castelar', v: 5270}, {l: 'Castellanos', v: 5271}, {l: 'Cayastá', v: 5272}, {
            l: 'Cayastacito',
            v: 5273
        }, {l: 'Centeno', v: 5274}, {l: 'Cepeda', v: 5275}, {l: 'Ceres', v: 5276}, {l: 'Chabás', v: 5277}, {
            l: 'Chañar Ladeado',
            v: 5278
        }, {l: 'Chapuy', v: 5279}, {l: 'Chovet', v: 5280}, {l: 'Christophersen', v: 5281}, {l: 'Classon', v: 5282}, {
            l: 'Col. Aldao',
            v: 5283
        }, {l: 'Col. Ana', v: 5284}, {l: 'Col. Belgrano', v: 5285}, {l: 'Col. Bicha', v: 5286}, {
            l: 'Col. Bigand',
            v: 5287
        }, {l: 'Col. Bossi', v: 5288}, {l: 'Col. Cavour', v: 5289}, {l: 'Col. Cello', v: 5290}, {
            l: 'Col. Dolores',
            v: 5291
        }, {l: 'Col. Dos Rosas', v: 5292}, {l: 'Col. Durán', v: 5293}, {l: 'Col. Iturraspe', v: 5294}, {
            l: 'Col. Margarita',
            v: 5295
        }, {l: 'Col. Mascias', v: 5296}, {l: 'Col. Raquel', v: 5297}, {l: 'Col. Rosa', v: 5298}, {
            l: 'Col. San José',
            v: 5299
        }, {l: 'Constanza', v: 5300}, {l: 'Coronda', v: 5301}, {l: 'Cnel. Arnold', v: 5302}, {
            l: 'Cnel. Bogado',
            v: 5303
        }, {l: 'Cnel. Fraga', v: 5304}, {l: 'Cnel. Dominguez', v: 5305}, {l: 'Correa', v: 5306}, {l: 'Crispi', v: 5307}, {
            l: 'Cululú',
            v: 5308
        }, {l: 'Curupayti', v: 5309}, {l: 'Desvio Arijón', v: 5310}, {l: 'Diaz', v: 5311}, {l: 'Diego de Alvear', v: 5312}, {
            l: 'Egusquiza',
            v: 5313
        }, {l: 'El Arazá', v: 5314}, {l: 'El Rabón', v: 5315}, {l: 'El Sombrerito', v: 5316}, {l: 'El Trébol', v: 5317}, {
            l: 'Elisa',
            v: 5318
        }, {l: 'Elortondo', v: 5319}, {l: 'Emilia', v: 5320}, {l: 'Empalme San Carlos', v: 5321}, {
            l: 'Empalme Villa Constitucion',
            v: 5322
        }, {l: 'Esmeralda', v: 5323}, {l: 'Esperanza', v: 5324}, {l: 'Estación Alvear', v: 5325}, {
            l: 'Estacion Clucellas',
            v: 5326
        }, {l: 'Esteban Rams', v: 5327}, {l: 'Esther', v: 5328}, {l: 'Eusebia', v: 5329}, {l: 'Esustolia', v: 5330}, {
            l: 'Felicia',
            v: 5331
        }, {l: 'Fidela', v: 5332}, {l: 'Fighiera', v: 5333}, {l: 'Firmat', v: 5334}, {l: 'Florencia', v: 5335}, {
            l: 'Fortín Olmos',
            v: 5336
        }, {l: 'Franck', v: 5337}, {l: 'Fray Luis Beltrán', v: 5338}, {l: 'Frontera', v: 5339}, {l: 'Fuentes', v: 5340}, {
            l: 'Funes',
            v: 5341
        }, {l: 'Gaboto', v: 5342}, {l: 'Galisteo', v: 5343}, {l: 'Gálvez', v: 5344}, {l: 'Garabalto', v: 5345}, {
            l: 'Garibaldi',
            v: 5346
        }, {l: 'Gato Colorado', v: 5347}, {l: 'Gral. Gelly', v: 5348}, {l: 'Gral. Lagos', v: 5349}, {
            l: 'Gessler',
            v: 5350
        }, {l: 'Gdor. Crespo', v: 5351}, {l: 'Gödeken', v: 5352}, {l: 'Godoy', v: 5353}, {
            l: 'Golondrina',
            v: 5354
        }, {l: 'Granadero Baigorria', v: 5355}, {l: 'Gregoria Perez De Denis', v: 5356}, {l: 'Grutly', v: 5357}, {
            l: 'Guadalupe N.',
            v: 5358
        }, {l: 'Helvecia', v: 5359}, {l: 'Hersilia', v: 5360}, {l: 'Hipatía', v: 5361}, {l: 'Huanqueros', v: 5362}, {
            l: 'Hugentobler',
            v: 5363
        }, {l: 'Hughes', v: 5364}, {l: 'Humberto 1º', v: 5365}, {l: 'Humboldt', v: 5366}, {l: 'Ibarlucea', v: 5367}, {
            l: 'Ing. Chanourdie',
            v: 5368
        }, {l: 'Intiyaco', v: 5369}, {l: 'Ituzaingó', v: 5370}, {l: 'Jacinto L. Aráuz', v: 5371}, {
            l: 'Josefina',
            v: 5372
        }, {l: 'Juan B. Molina', v: 5373}, {l: 'Juan de Garay', v: 5374}, {l: 'Juncal', v: 5375}, {l: 'La Brava', v: 5376}, {
            l: 'La Cabral',
            v: 5377
        }, {l: 'La Camila', v: 5378}, {l: 'La Chispa', v: 5379}, {l: 'La Clara', v: 5380}, {l: 'La Criolla', v: 5381}, {
            l: 'La Gallareta',
            v: 5382
        }, {l: 'La Lucila', v: 5383}, {l: 'La Pelada', v: 5384}, {l: 'La Penca', v: 5385}, {l: 'La Rubia', v: 5386}, {
            l: 'La Sarita',
            v: 5387
        }, {l: 'La Vanguardia', v: 5388}, {l: 'Labordeboy', v: 5389}, {l: 'Laguna Paiva', v: 5390}, {l: 'Landeta', v: 5391}, {
            l: 'Lanteri',
            v: 5392
        }, {l: 'Larrechea', v: 5393}, {l: 'Las Avispas', v: 5394}, {l: 'Las Bandurrias', v: 5395}, {
            l: 'Las Garzas',
            v: 5396
        }, {l: 'Las Palmeras', v: 5397}, {l: 'Las Parejas', v: 5398}, {l: 'Las Petacas', v: 5399}, {
            l: 'Las Rosas',
            v: 5400
        }, {l: 'Las Toscas', v: 5401}, {l: 'Las Tunas', v: 5402}, {l: 'Lazzarino', v: 5403}, {l: 'Lehmann', v: 5404}, {
            l: 'Llambi Campbell',
            v: 5405
        }, {l: 'Logroño', v: 5406}, {l: 'Loma Alta', v: 5407}, {l: 'López', v: 5408}, {l: 'Los Amores', v: 5409}, {
            l: 'Los Cardos',
            v: 5410
        }, {l: 'Los Laureles', v: 5411}, {l: 'Los Molinos', v: 5412}, {l: 'Los Quirquinchos', v: 5413}, {
            l: 'Lucio V. Lopez',
            v: 5414
        }, {l: 'Luis Palacios', v: 5415}, {l: 'Maciel', v: 5416}, {l: 'Maggiolo', v: 5417}, {
            l: 'Malabrigo',
            v: 5418
        }, {l: 'Marcelino Escalada', v: 5419}, {l: 'Margarita', v: 5420}, {l: 'Ma. Juana', v: 5421}, {
            l: 'Ma. Luisa',
            v: 5422
        }, {l: 'Ma. Susana', v: 5423}, {l: 'Ma. Teresa', v: 5424}, {l: 'Matilde', v: 5425}, {l: 'Mauá', v: 5426}, {
            l: 'Máximo Paz',
            v: 5427
        }, {l: 'Melincué', v: 5428}, {l: 'Miguel Torres', v: 5429}, {l: 'Moisés Ville', v: 5430}, {l: 'Monigotes', v: 5431}, {
            l: 'Monje',
            v: 5432
        }, {l: 'Monte Obscuridad', v: 5433}, {l: 'Monte Vera', v: 5434}, {l: 'Montefiore', v: 5435}, {
            l: 'Montes de Oca',
            v: 5436
        }, {l: 'Murphy', v: 5437}, {l: 'Naré', v: 5438}, {l: 'Nelson', v: 5439}, {l: 'Nicanor E. Molinas', v: 5440}, {
            l: 'Nuevo Torino',
            v: 5441
        }, {l: 'Ñanducita', v: 5442}, {l: 'Oliveros', v: 5443}, {l: 'Palacios', v: 5444}, {l: 'Pavón', v: 5445}, {
            l: 'Pavón Arriba',
            v: 5446
        }, {l: 'Pedro Gómez Cello', v: 5447}, {l: 'Pérez', v: 5448}, {l: 'Peyrano', v: 5449}, {l: 'Piamonte', v: 5450}, {
            l: 'Pilar',
            v: 5451
        }, {l: 'Piñero', v: 5452}, {l: 'Plaza Clucellas', v: 5453}, {l: 'Portugalete', v: 5454}, {
            l: 'Pozo Borrado',
            v: 5455
        }, {l: 'Pte. Roca', v: 5456}, {l: 'Progreso', v: 5457}, {l: 'Providencia', v: 5458}, {
            l: 'Pueblo Andino',
            v: 5459
        }, {l: 'Pueblo Esther', v: 5460}, {l: 'Pueblo Irigoyen', v: 5461}, {l: 'Pueblo Marini', v: 5462}, {
            l: 'Pueblo Muñoz',
            v: 5463
        }, {l: 'Pueblo Uranga', v: 5464}, {l: 'Pueblo Gral. San Martín', v: 5465}, {l: 'Pujato', v: 5466}, {
            l: 'Pujato N.',
            v: 5467
        }, {l: 'Rafaela', v: 5468}, {l: 'Ramayón', v: 5469}, {l: 'Ramona', v: 5470}, {l: 'Reconquista', v: 5471}, {
            l: 'Recreo',
            v: 5472
        }, {l: 'Ricardone', v: 5473}, {l: 'Rivadavia', v: 5474}, {l: 'Roldán', v: 5475}, {l: 'Romang', v: 5476}, {
            l: 'Rosario',
            v: 5477
        }, {l: 'Rueda', v: 5478}, {l: 'Rufino', v: 5479}, {l: 'Sa Pereira', v: 5480}, {l: 'Saguier', v: 5481}, {
            l: 'Saladero M. Cabal',
            v: 5482
        }, {l: 'Salto Grande', v: 5483}, {l: 'San Agustín', v: 5484}, {l: 'San Antonio de Obligado', v: 5485}, {
            l: 'San Bernardo (N.J.)',
            v: 5486
        }, {l: 'San Bernardo (S.J.)', v: 5487}, {l: 'San Carlos Centro', v: 5488}, {l: 'San Carlos N.', v: 5489}, {
            l: 'San Carlos S.',
            v: 5490
        }, {l: 'San Cristóbal', v: 5491}, {l: 'San Eduardo', v: 5492}, {l: 'San Eugenio', v: 5493}, {
            l: 'San Fabián',
            v: 5494
        }, {l: 'San Fco. de Santa Fé', v: 5495}, {l: 'San Genaro', v: 5496}, {l: 'San Genaro N.', v: 5497}, {
            l: 'San Gregorio',
            v: 5498
        }, {l: 'San Guillermo', v: 5499}, {l: 'San Javier', v: 5500}, {l: 'San Jerónimo del Sauce', v: 5501}, {
            l: 'San Jerónimo N.',
            v: 5502
        }, {l: 'San Jerónimo S.', v: 5503}, {l: 'San Jorge', v: 5504}, {l: 'San José de La Esquina', v: 5505}, {
            l: 'San José del Rincón',
            v: 5506
        }, {l: 'San Justo', v: 5507}, {l: 'San Lorenzo', v: 5508}, {l: 'San Mariano', v: 5509}, {
            l: 'San Martín de Las Escobas',
            v: 5510
        }, {l: 'San Martín N.', v: 5511}, {l: 'San Vicente', v: 5512}, {l: 'Sancti Spititu', v: 5513}, {
            l: 'Sanford',
            v: 5514
        }, {l: 'Sta. Clara de Buena Vista', v: 5515}, {l: 'Sta. Clara de Saguier', v: 5516}, {
            l: 'Sta. Isabel',
            v: 5517
        }, {l: 'Sta. Margarita', v: 5518}, {l: 'Sta. Maria Centro', v: 5519}, {l: 'Sta. María N.', v: 5520}, {
            l: 'Sta. Rosa',
            v: 5521
        }, {l: 'Sta. Teresa', v: 5522}, {l: 'Santo Domingo', v: 5523}, {l: 'Santo Tomé', v: 5524}, {
            l: 'Santurce',
            v: 5525
        }, {l: 'Sargento Cabral', v: 5526}, {l: 'Sarmiento', v: 5527}, {l: 'Sastre', v: 5528}, {l: 'Sauce Viejo', v: 5529}, {
            l: 'Serodino',
            v: 5530
        }, {l: 'Silva', v: 5531}, {l: 'Soldini', v: 5532}, {l: 'Soledad', v: 5533}, {l: 'Soutomayor', v: 5534}, {
            l: 'Suardi',
            v: 5535
        }, {l: 'Sunchales', v: 5536}, {l: 'Susana', v: 5537}, {l: 'Tacuarendí', v: 5538}, {l: 'Tacural', v: 5539}, {
            l: 'Tartagal',
            v: 5540
        }, {l: 'Teodelina', v: 5541}, {l: 'Theobald', v: 5542}, {l: 'Timbúes', v: 5543}, {l: 'Toba', v: 5544}, {
            l: 'Tortugas',
            v: 5545
        }, {l: 'Tostado', v: 5546}, {l: 'Totoras', v: 5547}, {l: 'Traill', v: 5548}, {l: 'Venado Tuerto', v: 5549}, {
            l: 'Vera',
            v: 5550
        }, {l: 'Vera y Pintado', v: 5551}, {l: 'Videla', v: 5552}, {l: 'Vila', v: 5553}, {l: 'Villa Amelia', v: 5554}, {
            l: 'Villa Ana',
            v: 5555
        }, {l: 'Villa Cañas', v: 5556}, {l: 'Villa Constitución', v: 5557}, {l: 'Villa Eloísa', v: 5558}, {
            l: 'Villa Gdor. Gálvez',
            v: 5559
        }, {l: 'Villa Guillermina', v: 5560}, {l: 'Villa Minetti', v: 5561}, {l: 'Villa Mugueta', v: 5562}, {
            l: 'Villa Ocampo',
            v: 5563
        }, {l: 'Villa San José', v: 5564}, {l: 'Villa Saralegui', v: 5565}, {l: 'Villa Trinidad', v: 5566}, {
            l: 'Villada',
            v: 5567
        }, {l: 'Virginia', v: 5568}, {l: 'Wheelwright', v: 5569}, {l: 'Zavalla', v: 5570}, {l: 'Zenón Pereira', v: 5571}],
        l: 'Santa Fe',
        v: 106
    }, {
        'cities': [{l: 'Añatuya', v: 5573}, {l: 'Árraga', v: 5574}, {l: 'Bandera', v: 5575}, {l: 'Bandera Bajada', v: 5576}, {
            l: 'Beltrán',
            v: 5577
        }, {l: 'Brea Pozo', v: 5578}, {l: 'Campo Gallo', v: 5579}, {l: 'Capital', v: 5580}, {l: 'Chilca Juliana', v: 5581}, {
            l: 'Choya',
            v: 5582
        }, {l: 'Clodomira', v: 5583}, {l: 'Col. Alpina', v: 5584}, {l: 'Col. Dora', v: 5585}, {
            l: 'Col. El Simbolar Robles',
            v: 5586
        }, {l: 'El Bobadal', v: 5587}, {l: 'El Charco', v: 5588}, {l: 'El Mojón', v: 5589}, {
            l: 'Estación Atamisqui',
            v: 5590
        }, {l: 'Estación Simbolar', v: 5591}, {l: 'Fernández', v: 5592}, {l: 'Fortín Inca', v: 5593}, {l: 'Frías', v: 5594}, {
            l: 'Garza',
            v: 5595
        }, {l: 'Gramilla', v: 5596}, {l: 'Guardia Escolta', v: 5597}, {l: 'Herrera', v: 5598}, {l: 'Icaño', v: 5599}, {
            l: 'Ing. Forres',
            v: 5600
        }, {l: 'La Banda', v: 5601}, {l: 'La Cañada', v: 5602}, {l: 'Laprida', v: 5603}, {l: 'Lavalle', v: 5604}, {
            l: 'Loreto',
            v: 5605
        }, {l: 'Los Juríes', v: 5606}, {l: 'Los Núñez', v: 5607}, {l: 'Los Pirpintos', v: 5608}, {
            l: 'Los Quiroga',
            v: 5609
        }, {l: 'Los Telares', v: 5610}, {l: 'Lugones', v: 5611}, {l: 'Malbrán', v: 5612}, {l: 'Matara', v: 5613}, {
            l: 'Medellín',
            v: 5614
        }, {l: 'Monte Quemado', v: 5615}, {l: 'Nueva Esperanza', v: 5616}, {l: 'Nueva Francia', v: 5617}, {
            l: 'Palo Negro',
            v: 5618
        }, {l: 'Pampa de Los Guanacos', v: 5619}, {l: 'Pinto', v: 5620}, {l: 'Pozo Hondo', v: 5621}, {
            l: 'Quimilí',
            v: 5622
        }, {l: 'Real Sayana', v: 5623}, {l: 'Sachayoj', v: 5624}, {l: 'San Pedro de Guasayán', v: 5625}, {
            l: 'Selva',
            v: 5626
        }, {l: 'Sol de Julio', v: 5627}, {l: 'Sumampa', v: 5628}, {l: 'Suncho Corral', v: 5629}, {l: 'Taboada', v: 5630}, {
            l: 'Tapso',
            v: 5631
        }, {l: 'Termas de Rio Hondo', v: 5632}, {l: 'Tintina', v: 5633}, {l: 'Tomas Young', v: 5634}, {
            l: 'Vilelas',
            v: 5635
        }, {l: 'Villa Atamisqui', v: 5636}, {l: 'Villa La Punta', v: 5637}, {l: 'Villa Ojo de Agua', v: 5638}, {
            l: 'Villa Río Hondo',
            v: 5639
        }, {l: 'Villa Salavina', v: 5640}, {l: 'Villa Unión', v: 5641}, {l: 'Vilmer', v: 5642}, {l: 'Weisburd', v: 5643}],
        l: 'Santiago del Estero',
        v: 107
    }, {'cities': [{l: 'Río Grande', v: 5645}, {l: 'Tolhuin', v: 5646}, {l: 'Ushuaia', v: 5647}], l: 'Tierra del Fuego', v: 108}, {
        'cities': [{l: 'Acheral', v: 5649}, {l: 'Agua Dulce', v: 5650}, {l: 'Aguilares', v: 5651}, {
            l: 'Alderetes',
            v: 5652
        }, {l: 'Alpachiri', v: 5653}, {l: 'Alto Verde', v: 5654}, {l: 'Amaicha del Valle', v: 5655}, {
            l: 'Amberes',
            v: 5656
        }, {l: 'Ancajuli', v: 5657}, {l: 'Arcadia', v: 5658}, {l: 'Atahona', v: 5659}, {
            l: 'Banda del Río Sali',
            v: 5660
        }, {l: 'Bella Vista', v: 5661}, {l: 'Buena Vista', v: 5662}, {l: 'Burruyacú', v: 5663}, {
            l: 'Capitán Cáceres',
            v: 5664
        }, {l: 'Cevil Redondo', v: 5665}, {l: 'Choromoro', v: 5666}, {l: 'Ciudacita', v: 5667}, {
            l: 'Colalao del Valle',
            v: 5668
        }, {l: 'Colombres', v: 5669}, {l: 'Concepción', v: 5670}, {l: 'Delfín Gallo', v: 5671}, {
            l: 'El Bracho',
            v: 5672
        }, {l: 'El Cadillal', v: 5673}, {l: 'El Cercado', v: 5674}, {l: 'El Chañar', v: 5675}, {l: 'El Manantial', v: 5676}, {
            l: 'El Mojón',
            v: 5677
        }, {l: 'El Mollar', v: 5678}, {l: 'El Naranjito', v: 5679}, {l: 'El Naranjo', v: 5680}, {
            l: 'El Polear',
            v: 5681
        }, {l: 'El Puestito', v: 5682}, {l: 'El Sacrificio', v: 5683}, {l: 'El Timbó', v: 5684}, {l: 'Escaba', v: 5685}, {
            l: 'Esquina',
            v: 5686
        }, {l: 'Estación Aráoz', v: 5687}, {l: 'Famaillá', v: 5688}, {l: 'Gastone', v: 5689}, {
            l: 'Gdor. Garmendia',
            v: 5690
        }, {l: 'Gdor. Piedrabuena', v: 5691}, {l: 'Graneros', v: 5692}, {l: 'Huasa Pampa', v: 5693}, {
            l: 'J. B. Alberdi',
            v: 5694
        }, {l: 'La Cocha', v: 5695}, {l: 'La Esperanza', v: 5696}, {l: 'La Florida', v: 5697}, {l: 'La Ramada', v: 5698}, {
            l: 'La Trinidad',
            v: 5699
        }, {l: 'Lamadrid', v: 5700}, {l: 'Las Cejas', v: 5701}, {l: 'Las Talas', v: 5702}, {l: 'Las Talitas', v: 5703}, {
            l: 'Los Bulacio',
            v: 5704
        }, {l: 'Los Gómez', v: 5705}, {l: 'Los Nogales', v: 5706}, {l: 'Los Pereyra', v: 5707}, {
            l: 'Los Pérez',
            v: 5708
        }, {l: 'Los Puestos', v: 5709}, {l: 'Los Ralos', v: 5710}, {l: 'Los Sarmientos', v: 5711}, {l: 'Los Sosa', v: 5712}, {
            l: 'Lules',
            v: 5713
        }, {l: 'M. García Fernández', v: 5714}, {l: 'Manuela Pedraza', v: 5715}, {l: 'Medinas', v: 5716}, {
            l: 'Monte Bello',
            v: 5717
        }, {l: 'Monteagudo', v: 5718}, {l: 'Monteros', v: 5719}, {l: 'Padre Monti', v: 5720}, {l: 'Pampa Mayo', v: 5721}, {
            l: 'Quilmes',
            v: 5722
        }, {l: 'Raco', v: 5723}, {l: 'Ranchillos', v: 5724}, {l: 'Río Chico', v: 5725}, {l: 'Río Colorado', v: 5726}, {
            l: 'Río Seco',
            v: 5727
        }, {l: 'Rumi Punco', v: 5728}, {l: 'San Andrés', v: 5729}, {l: 'San Felipe', v: 5730}, {
            l: 'San Ignacio',
            v: 5731
        }, {l: 'San Javier', v: 5732}, {l: 'San José', v: 5733}, {l: 'San Miguel de Tucumán', v: 5734}, {
            l: 'San Pedro',
            v: 5735
        }, {l: 'San Pedro de Colalao', v: 5736}, {l: 'Santa Rosa de Leales', v: 5737}, {l: 'Sgto. Moya', v: 5738}, {
            l: 'Siete de Abril',
            v: 5739
        }, {l: 'Simoca', v: 5740}, {l: 'Soldado Maldonado', v: 5741}, {l: 'Sta. Ana', v: 5742}, {l: 'Sta. Cruz', v: 5743}, {
            l: 'Sta. Lucía',
            v: 5744
        }, {l: 'Taco Ralo', v: 5745}, {l: 'Tafí del Valle', v: 5746}, {l: 'Tafí Viejo', v: 5747}, {
            l: 'Tapia',
            v: 5748
        }, {l: 'Teniente Berdina', v: 5749}, {l: 'Trancas', v: 5750}, {l: 'Villa Belgrano', v: 5751}, {
            l: 'Villa Benjamín Araoz',
            v: 5752
        }, {l: 'Villa Chiligasta', v: 5753}, {l: 'Villa de Leales', v: 5754}, {l: 'Villa Quinteros', v: 5755}, {
            l: 'Yánima',
            v: 5756
        }, {l: 'Yerba Buena', v: 5757}, {l: 'Yerba Buena (S)', v: 5758}], l: 'Tucumán', v: 109
    }];

    constructor() {
    }

    getLocalidades(): IntCiudadData[] {
        const ret: IntCiudadData[] = [];
        this.locasData.map(x => ret.push({l: x.l, v: x.v}));
        return ret;
    }

    getCiudad( idLocalidad: number ): IntCiudadData[] {
        let ret: IntCiudadData[] = [];

        if (isNaN(idLocalidad)) {
            return ret;
        }

        ret = this.locasData.filter( l => l.v === idLocalidad).map( localidad => localidad.cities).reduce( (prev, curr) => [...prev, ...curr], [] );
        return ret;
    }

    getNombreProvincia( idLocalidad: number ): IntCiudadData|null {
        if (isNaN(idLocalidad)) {
            return null;
        }
        const ret = this.locasData.filter( l => l.v === idLocalidad);
        return ret && ret[0] ? ret[0] : null;
    }

    getNombreCiudad( idLocalidad: number, idCiudad: number ): IntCiudadData|null {
        let ret = null;

        if (isNaN(idLocalidad) || isNaN(idCiudad)) {
            return ret;
        }

        const tmp = this.locasData.filter( l => l.v === idLocalidad);
        if (tmp && tmp[0]) {
            ret = tmp[0].cities.filter(c => c.v === idCiudad);
        }

        return ret && ret[0] ? ret[0] : null;
    }
}
