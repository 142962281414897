import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntNoGustaronListingResult, IntNoGustaronListingResults} from './nogustaron.model';
import {IntMensajesListings} from '../mensajes/mensajes.model';

@Injectable()
export class NoGustaronService {

    constructor(
        private http: HttpClient,
    ) {
    }


    public listing(): Observable<IntNoGustaronListingResults> {
        return this.http
        .get<IntNoGustaronListingResults>(`${environment.appApi}/${environment.endpoints.dislikes}`);

    }

    /**
     * Scrolls
     * @param 0-based page number
     */
    public scroll(page: number): Observable<IntNoGustaronListingResults> {

        return this.http
        .get<IntNoGustaronListingResults>(`${environment.appApi}/${environment.endpoints.dislikes}` + '?page=' + page);

    }

    public doDislike(u: IntNoGustaronListingResult): Observable<IntNoGustaronListingResults> {

        return this.http
        .post<IntNoGustaronListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${u.userId}${environment.endpoints.userdislike_end}`, {});

    }

    public cancelDislike(u: IntNoGustaronListingResult): Observable<IntNoGustaronListingResults> {

        return this.http
        .post<IntNoGustaronListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${u.userId}${environment.endpoints.userdislike_undo_end}`, {});

    }

}
