import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {IntVisitasGetListing, IntVisitasListingResults} from '../../store/visitas/visitas.model';
import {VisitasInitScroll, VisitasInitSearch} from '../../store/visitas/visitas.actions';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {ProfileOpen} from '../../store/profile/profile.actions';
import * as UsersActions from '../../store/user/user.actions';

@Component({
    selector: 'app-visitas',
    templateUrl: './visitas.component.html',
    styleUrls: ['./visitas.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('EnterLeave', [
            transition(':enter', [
                style({ transform: 'scaleY(0)' }),
                animate('150ms ease', style({ transform: 'scaleY(1)' }))
            ]),
            transition(':leave', [
                animate('75ms ease', style({ transform: 'scaleY(0)', opacity: '0' }))
            ])
        ])
    ]
})
export class VisitasComponent implements OnInit, OnDestroy {

    enumListType: any = {
        Visitas_RECEIVED: IntVisitasGetListing.Visitas_RECEIVED,
        Visitas_SENT: IntVisitasGetListing.Visitas_SENT,
    };
    typeSubs: Subscription = null;
    routeSub: Subscription = null;

    results$: Observable<IntVisitasListingResults> = this.store.pipe(select(fromRoot.getVisitasResults));
    activeTab$: Observable<IntVisitasGetListing> = this.store.pipe(select(fromRoot.getVisitasActiveTab));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getVisitasInProcess));
    currentType: IntVisitasGetListing = IntVisitasGetListing.Visitas_RECEIVED;


    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router
    ) {

        this.typeSubs = this.store.pipe(select(fromRoot.getVisitasActiveTab)).subscribe((tab: IntVisitasGetListing) => {
            this.currentType = tab;
            // this.cd.markForCheck();
        });
    }

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.search(this.route.snapshot.params['tab'] === 'realizadas' ? IntVisitasGetListing.Visitas_SENT : IntVisitasGetListing.Visitas_RECEIVED);
            }
        });

        this.search(this.route.snapshot.params['tab'] === 'realizadas' ? IntVisitasGetListing.Visitas_SENT : IntVisitasGetListing.Visitas_RECEIVED);
    }

    ngOnDestroy(): void {
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
        if (this.typeSubs !== null) {
            this.typeSubs.unsubscribe();
        }
    }

    search(t: IntVisitasGetListing) {
        this.store.dispatch(new VisitasInitSearch({type: t}));
    }

    triggerScroll() {
        this.store.dispatch(new VisitasInitScroll({type: this.currentType}));
    }

    viewProfile(userId: string) {
        this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: 'sent'}));
        setTimeout( () => {
            this.store.dispatch(new UsersActions.UserCounters());
        }, 5000 );
    }
}
