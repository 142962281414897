import {Action} from '@ngrx/store';
import {IntProfileReply} from './profile.model';
import {IntDashboardListingResult, IntDashboardListingResults} from '../dashboard/dashboard.model';

export enum ProfileActionTypes {
    ProfileOpen                 = '[Profile API] Open Profile',
    ProfileOpenSuccess          = '[Profile API] Open Profile success',
    ProfileDoLike               = '[Profile API] DoLike Profile',
    ProfileDoLikeSuccess        = '[Profile API] DoLike Profile success',
    ProfileDoDislike            = '[Profile API] DoDislike Profile',
    ProfileDoDislikeSuccess     = '[Profile API] DoDislike Profile success',
    ProfileDoUndoLike           = '[Profile API] DoUndoLike Profile',
    ProfileDoUndoLikeSuccess    = '[Profile API] DoUndoLike Profile success',
    ProfileClose                = '[Profile API] Close Profile',
    ProfileReport               = '[Profile API] Report Profile',
    ProfileReportClose          = '[Profile API] Report Close Profile',
    ProfileReportSubmit         = '[Profile API] Report Submit',
    ProfileFail                 = '[Profile API] Fail',
	ProfileMoreUsers			= '[Profile API] Get more users',
	ProfileMoreUsersSuccess		= '[Profile API] Get more users Success',
	ProfileDoLikeJDL			= '[Profile API] DoLike from Juego de Likes',
	ProfileDoLikeJDLSuccess		= '[Profile API] Success DoLike from Juego de Likes',
}

export class ProfileOpen implements Action {
    readonly type = ProfileActionTypes.ProfileOpen;

    constructor(public payload: { userId: number, type: 'sent' | 'rcv' | null, source?: string }) {
    }
}

export class ProfileOpenSuccess implements Action {
    readonly type = ProfileActionTypes.ProfileOpenSuccess;

    constructor(public payload: { profile: IntProfileReply }) {
    }
}

export class ProfileDoLike implements Action {
	readonly type = ProfileActionTypes.ProfileDoLike;

	constructor(public payload: { userId: string }) {
	}
}

export class ProfileDoLikeJDL implements Action {
    readonly type = ProfileActionTypes.ProfileDoLikeJDL;
    constructor(public payload: { userId: string }) {
    }
}
export class ProfileDoLikeJDLSuccess implements Action {
	readonly type = ProfileActionTypes.ProfileDoLikeJDLSuccess;
	constructor(public payload: { userId: string }) {
	}
}

export class ProfileDoLikeSuccess implements Action {
    readonly type = ProfileActionTypes.ProfileDoLikeSuccess;

    constructor(public payload: { userId: string }) {
    }
}

export class ProfileDoDislike implements Action {
    readonly type = ProfileActionTypes.ProfileDoDislike;

    constructor(public payload: { userId: string }) {
    }
}

export class ProfileDoDislikeSuccess implements Action {
    readonly type = ProfileActionTypes.ProfileDoDislikeSuccess;

    constructor(public payload: { userId: string }) {
    }
}

export class ProfileDoUndoLike implements Action {
    readonly type = ProfileActionTypes.ProfileDoUndoLike;

    constructor(public payload: { userId: string }) {
    }
}

export class ProfileDoUndoLikeSuccess implements Action {
    readonly type = ProfileActionTypes.ProfileDoUndoLikeSuccess;

    constructor(public payload: { userId: string }) {
    }
}

export class ProfileClose implements Action {
    readonly type = ProfileActionTypes.ProfileClose;
}


export class ProfileReport implements Action {
    readonly type = ProfileActionTypes.ProfileReport;

    constructor(public payload: { userId: string, userNick: string }) {
    }
}
export class ProfileReportSubmit implements Action {
    readonly type = ProfileActionTypes.ProfileReportSubmit;

    constructor(public payload: { userId: string, reportId: number, reportText: string }) {
    }
}

export class ProfileReportClose implements Action {
    readonly type = ProfileActionTypes.ProfileReportClose;
}

export class ProfileFail implements Action {
    readonly type = ProfileActionTypes.ProfileFail;
}

export class ProfileMoreUsers implements Action {
	readonly type = ProfileActionTypes.ProfileMoreUsers;
}

export class ProfileMoreUsersSuccess implements Action {
	readonly type = ProfileActionTypes.ProfileMoreUsersSuccess;
	constructor(public payload: { results: IntDashboardListingResult []}){};
}

export type ProfileActions =
    ProfileOpen
    | ProfileOpenSuccess
    | ProfileDoLike
    | ProfileDoLikeSuccess
    | ProfileDoDislike
    | ProfileDoDislikeSuccess
    | ProfileDoUndoLike
    | ProfileDoUndoLikeSuccess
    | ProfileClose
    | ProfileReport
    | ProfileReportClose
    | ProfileReportSubmit
    | ProfileFail
	| ProfileMoreUsers
	| ProfileMoreUsersSuccess
	| ProfileDoLikeJDL
	| ProfileDoLikeJDLSuccess;
