import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {
    IntFacebookGalleryAlbums,
    IntFacebookGalleryPicture, IntFacebookLoginData, IntLoginData,
    IntProfileNewImage,
    IntProfileV2Data,
} from '../../store/user/user.model';
import {Observable, Subscription} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';
import {CropperComponent, IntCropperImagesResult} from '../cropper/cropper.component';
import {
	AttachFacebookToken,
	ProfileNewImage, ProfileNewImageFromFacebook,
	UserProfileApodoLocalidad,
	UserProfileDescrip,
	UserProfileV2, UserSetCountModalsReg
} from '../../store/user/user.actions';
import {JuegoLikesComponent} from '../juego-likes/juego-likes.component';
import {zcDeepCopy} from '../../services/zc.globals';
import {Router} from '@angular/router';
declare var FB: any;

@Component({
    selector: 'app-modales-post-registro',
    templateUrl: './modales-post-registro.component.html',
    styleUrls: ['./modales-post-registro.component.scss']
})
export class ModalesPostRegistroComponent implements OnInit, OnDestroy {

    @ViewChild('appcropper', {static: true}) cropper: CropperComponent;

    isStep1: boolean = false;
    isStep2: boolean = false;
    isStep3: boolean = false;
    isStep4: boolean = false;
    valueStep: string = '';
    actualStep: number = 0;
    modalsData: any;
    idProvince: number = 0;
    idCity: number = 0;

    objProfile: IntProfileV2Data = null;
    subsPro: Subscription = null;
    subsSession: Subscription = null;
	subsModals: Subscription = null;
	subsJuego: Subscription = null;
    loadedProfile$: Observable<any> = this.store.pipe(select(fromRoot.getCurrentProfileV2));

    facebookAlbums: IntFacebookGalleryAlbums = null;
    MAX_IMAGES = 12;
    importingFromFacebook = false;
    connectingToFacebook = false;
    selectedImagesFacebook: IntFacebookGalleryPicture[] = [];
    sessionData: IntLoginData = null;
	legales3dx3p: any = null

    postRegisterStep2Form = new FormGroup({
        step2Nickname: new FormControl(''),
        step2Description: new FormControl('')
    });
    postRegisterStep3Form = new FormGroup({
        step3Personal: new FormControl(''),
        step3Profesion: new FormControl(''),
        step3Life: new FormControl('')
    });

    constructor(private store: Store<fromRoot.State>,
                private dialogRef: MatDialogRef<ModalesPostRegistroComponent>,
                public dialog: MatDialog,
                public cd: ChangeDetectorRef,
                private router: Router) {
    }

    // btn promo
    onClickBtnPromo() {
        this.onClose();
        this.router.navigateByUrl('/suscribite/27?showLegals3dx3p=false');
    }

    // For Step One
    sendImage() {
        this.cropper.initializeCropper();
    }

    croppedImage(e: IntCropperImagesResult) {
        if (e !== null) {
            this.doUploadImage(e);
        }
    }

    doUploadImage(f: IntCropperImagesResult) {
        const img: IntProfileNewImage = {
            img: f.cropped
        };
        this.store.dispatch(new ProfileNewImage({image: img}));
        this.onNextStep();
    }

    importFacebook() {
        this.connectingToFacebook = true;
        const token = (this.sessionData !== null) && this.sessionData.longAccessToken ? this.sessionData.longAccessToken : '';
        const requestPath = '/me/albums?fields=id,name,photos.limit(999999){id,picture}&limit=999999&locale=es_LA' + '&access_token=' + token;
        FB.api(requestPath, 'get', (resAlbums: any) => {
            if (resAlbums.error) {
                FB.login((response) => {
                    if (response.authResponse && response.status) {

                        if (response.status === 'connected') {
                            const data: IntFacebookLoginData = {
                                accessToken: response.authResponse.accessToken,
                                signedRequest: response.authResponse.signedRequest,
                                userID: response.authResponse.userID
                            };

                            this.store.dispatch(new AttachFacebookToken({authData: data}));
                            setTimeout(() => this.importFacebook(), 150);
                        } else {
                            this.connectingToFacebook = false;
                            this.cd.markForCheck();
                        }

                    } else {
                        this.connectingToFacebook = false;
                        this.facebookAlbums = null;
                        this.selectedImagesFacebook = [];
                        this.cd.markForCheck();
                    }
                    // }, {scope: 'email,public_profile,user_about_me,user_birthday,user_hometown,user_location,user_photos'});
                }, {scope: 'email,public_profile,user_birthday,user_hometown,user_location,user_photos'});

            } else if (resAlbums.data && resAlbums.data.length) {
                this.connectingToFacebook = false;
                this.facebookAlbums = resAlbums;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
            } else {
                this.connectingToFacebook = false;
                this.facebookAlbums = null;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
            }
        });
    }

    uploadFacebookImage(i: IntFacebookGalleryPicture) {
        this.importingFromFacebook = true;
        this.store.dispatch(new ProfileNewImageFromFacebook({images: [i.picture]}));
        setTimeout(() => {
            this.doCloseFacebookAlbums();
            this.onNextStep();
        }, 750);
    }

    doCloseFacebookAlbums() {
        this.connectingToFacebook = false;
        this.facebookAlbums = null;
    }

    // For Step Two
    saveStepTwo() {
        //
        const _nickname: string = this.postRegisterStep2Form.value.step2Nickname;
        const _description: string = this.postRegisterStep2Form.value.step2Description;
        if (_nickname.length > 0) {
            this.store.dispatch(new UserProfileApodoLocalidad({apodo: _nickname, idLocalidad: this.idCity, idProvincia: this.idProvince}));
        }
        if (_description.length > 0) {
            this.store.dispatch(new UserProfileDescrip({desc: _description}));
        }
        this.onNextStep();
        //
    }

    onNextStep() {
        //
        this.modalsData[this.actualStep].showStep = '0';
        const nextIdx 	= this.modalsData.findIndex(x => x.showStep === '1');
        // check if all modal are complete
		if( nextIdx === -1){
			this.onClose();
		}else{
			this.actualStep = nextIdx;
			// check if premiun, dont show 3dx3p
			if(this.sessionData.sessionInfo.me.billing.premium == true
				&& this.modalsData[nextIdx].id == 4){ this.onClose(); return; }
			if (nextIdx === -1) {
				this.onClose();
			} else {
				this.onShowNextStep(parseInt(this.modalsData[nextIdx].id));
			}
		}
        //
    }

    onShowNextStep(idStep: number) {
        //
        this.isStep1 = false;
        this.isStep2 = false;
        this.isStep3 = false;
        this.isStep4 = false;
        switch (idStep) {
            case 1:
                this.isStep1 = true;
                break;
            case 2:
                this.isStep2 = true;
                break;
            case 3:
                this.isStep3 = true;
                break;
            case 4:
                this.isStep4 = true;
                break;
        }
        //
    }

    onClose() {
        if (this.actualStep !== -1) {
            this.modalsData[this.actualStep].showStep = '0';
        }
        this.dialogRef.close();
        // Check if open juego likes
        if(!this.isStep4){
			this.subsJuego = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
				if (!session.sessionInfo.me.billing.premium && session.sessionInfo.me.gcpImage !== null) {
					//
					this.dialog.open(JuegoLikesComponent, {
						width: '893px',
						height: '710px',
						disableClose: true,
						panelClass: 'custom-modal'
					});
					//
				}
			});
		}
    }

    ngOnInit() {
        //
        this.subsSession = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
            this.sessionData = session
            if (session != null) {
				// emit modals counter
				this.store.dispatch(new UserSetCountModalsReg());
				//
				this.subsModals = this.store.pipe(select(fromRoot.countModals)).subscribe( count =>{
					if(count > 0){
						const modals: any = session.sessionInfo.me.post_register_modals[0];
						this.legales3dx3p = session.sessionInfo.me.post_register_modals.legales.original.legal;
						let idx = modals.findIndex(x => x.showStep === '1');
						if (idx !== -1) {
							this.modalsData = zcDeepCopy(modals);
							// check if premiun and if modals count mayor 3
							if(count > 3 && this.sessionData.sessionInfo.me.billing.premium == true ){
								this.modalsData[0].showStep = '0';
								idx += 1;
							}
							this.actualStep = idx;
							this.onShowNextStep(parseInt(modals[idx].id, 10));
							if (modals[3] && modals[3].value) {
								this.valueStep = modals[3].value.slice(0, -2) + ',' + modals[3].value.slice(-2);
							}
						}
					}
				});
            }
        });

        this.subsPro = this.store.pipe(select(fromRoot.getCurrentProfileV2)).subscribe(profileobj => {
            if (profileobj) {
                this.objProfile = profileobj;
                this.idProvince = parseInt(this.objProfile.location.provinceId, 10);
                this.idCity = parseInt(this.objProfile.location.cityId, 10);
            } else {
                this.store.dispatch(new UserProfileV2);
            }
        });
        //
    }

    ngOnDestroy(): void {
        if (this.subsPro !== null) {
            this.subsPro.unsubscribe();
        }
        if (this.subsSession !== null) {
            this.subsSession.unsubscribe();
        }
		if(this.subsJuego !== null) {
			this.subsJuego.unsubscribe();
		}
		if(this.subsModals !== null){
			this.subsModals.unsubscribe();
		}
    }

}
