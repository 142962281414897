import {Action} from '@ngrx/store';
import {
	IntAbTest,
	IntAccountDataInfo,
	IntAccountMainAlerts,
	IntAutologin, IntAutologinResponse, IntConversionFunnel, IntCountRegModals,
	IntFacebookLoginData,
	IntJuegoLikesReq,
	IntJuegoLikesResponse,
	IntJuegoLikesSkip,
	IntLoginData,
	IntLoginPostData,
	IntMatchInfo, IntMobbexCheckout, IntMobbexCheckoutSuccess,
	IntPasswordChangeReply,
	IntPasswordResetData,
	IntPaywallData,
	IntProfileNewImage,
	IntProfileUpdateReply,
	IntProfileV2Data,
	IntProfileV2DataQuestionData,
	IntProfileV2GcpImage, IntRefundReply,
	IntSessionCounters,
	IntSignupPostData
} from './user.model';

export enum UserActionTypes {
    LogInUser                   = '[User API] Login',
    LoggedInUser                = '[User API] LoggedIn',
    LogInFail                   = '[User API] LoggedFail',
    LogInFacebookUserStart      = '[User API] LogInFacebookUserStart',
    LogInFacebookUserSuccess    = '[User API] LogInFacebookUserSuccess',
    LogInFacebookUserSignupData = '[User API] LogInFacebookUserSignupData',
    AttachFacebookToken         = '[User API] AttachFacebookToken',
    LogInFacebookFail           = '[User API] LogInFacebookFail',
    ErrorUser                   = '[User API] Error',
    LogoutUser                  = '[User API] Logout',
    LoggedOutUser               = '[User API] LoggedOut',

    UserCounters                = '[User API] Counters',
    UserCountersSuccess         = '[User API] Counters success',

	UserRefund					= "[User API] Refund ",
	UserRefundSuccess     		= '[User API] Refund success',
	UserRefundFails     		= '[User API] Refund fails',

    UserProfileV2               = '[User API] Profile V2',
    UserProfileV2Success        = '[User API] Profile V2 success',

    UserAccountData             = '[User API] AccountData',
    UserAccountDataSuccess      = '[User API] AccountData success',
    UserAccountAlerts           = '[User API] AccountData Alerts',
    UserAccountAlertsSuccess    = '[User API] AccountData Alerts success',

    LogInFromStoredToken        = '[User API] LogInFromStoredToken',
    LogInFromStoredTokenSuccess = '[User API] LogInFromStoredToken Success',
    LogInFromStoredTokenFail    = '[User API] LogInFromStoredToken Error',
	LogInRelog    				= '[User API] LogInReLog',

    UserProfileDescrip          = '[User API] Profile Descrip',
    UserProfileDescripSuccess   = '[User API] Profile Descrip Success',
    UserProfileApodoLocalidad   = '[User API] Profile ApodoLocalidad',
    UserProfileApodoLocalidadSuccess = '[User API] Profile ApodoLocalidad Success',
    UserProfileField            = '[User API] Profile Field',
    UserProfileFieldSuccess     = '[User API] Profile Field Success',
    UserProfileCompletitudUpdate = '[User API] Profile Completitud upd',

    UserForgot                  = '[User API] Forgot',
    UserForgotFail              = '[User API] Forgot Fail',
    UserForgotSuccess           = '[User API] Forgot Success',
    UserForgotReset             = '[User API] Forgot reset',

    UserSignup                  = '[User API] Signup',
    UserSignupFail              = '[User API] Signup Fail',
    UserSignupSuccess           = '[User API] Signup Success',

    UserResetpwd                = '[User API] Resetpwd',
    UserResetpwdFail            = '[User API] Resetpwd Fail',
    UserResetpwdSuccess         = '[User API] Resetpwd Success',

    ProfileNewImage             = '[User API] NewImage',
    ProfileNewImageFromFacebook = '[User API] ProfileNewImageFromFacebook',
    ProfileNewImageSuccess      = '[User API] NewImage Success',
    ProfileDeleteImage          = '[User API] DeleteImage',
    ProfileDeleteImageSuccess   = '[User API] DeleteImage Success',
    ProfileSetImage             = '[User API] SetImage',
    ProfileSetImageSuccess      = '[User API] SetImage Success',

    ProfileAgeRange             = '[User API] AgeRange',
    ProfileAgeRangeSuccess      = '[User API] AgeRange Success',

    Paywallget                  = '[User API] Paywallget',
    PaywallgetSuccess           = '[User API] Paywallget Success',

    SeHaFormadoUnaPareja        = '[User API] Matched',

    UserGenericFail             = '[User API] Generic fail',

	UserJuegoLikes				= '[User API] Juego Likes',
	UserJuegoLikesSuccess		= '[User API] Juego Likes Success',
	UserJuegoLikesRemoveBoth	= '[User API] Juego Likes Remove Both',
	UserJuegoLikeSkip			= '[User API] Juego Likes Skip',

	UserAutologin				= '[User API] Autologin',
	UserAutologinSuccess		= '[User API] Autologin Success',
	UserAutologinFail			= '[User API] Autologin Fail',
	UserAutologinMessage		= '[User API] Autologin Message Open',
	UserAutologinClear			= '[User API] Autologin Clear',
	UserAutologinLike			= '[User API] Autologin Set Like',
	UserAutologinDisLike		= '[User API] Autologin Set DisLike',
	UserSetCountModalsReg		= '[User API] Set count post register modals',
	UserSetCountModalsRegSuccess= '[User API] Set count post register modals success',

	UserReloadSession			= '[User API] Get User reloaded session',
	UserReloadSessionSuccess	= '[User API] Get User reloaded session success',

	UserSetConversionFunnel			= '[User API] Set User Conversion Funnel',
	UserSetConversionFunnelSuccess	= '[User API] Set User Conversion Funnel success',

	UserMobbexCheckout				= '[User API] Set Checkout',
	UserMobbexCheckoutSuccess		= '[User API] Set Checkout success',
	UserMobbexCheckoutFail	 		= '[User API] Set Checkout fail',

	UserAbTest						= '[User API] Set Ab tracker desktop'
}

/* AUTOLOGIN */
export class SetAutologin implements Action {
	readonly type = UserActionTypes.UserAutologin;
	constructor(public payload: { data: IntAutologin}) {}
}
export class SetAutologinSuccess implements Action {
	readonly type = UserActionTypes.UserAutologinSuccess;
	constructor(public payload: { data: IntAutologinResponse}) {
	}
}
export class GetAutologinMessage implements Action {
	readonly type = UserActionTypes.UserAutologinMessage;
	constructor(public payload: { iduser: number}) {
	}
}
export class SetAutologinClear implements Action {
	readonly type = UserActionTypes.UserAutologinClear;
	constructor() {
	}
}
export class SetAutologinLike implements Action {
	readonly type = UserActionTypes.UserAutologinLike;
	constructor(public payload: { iduser: string}) {
	}
}
export class SetAutologinDisLike implements Action {
	readonly type = UserActionTypes.UserAutologinDisLike;
	constructor(public payload: { iduser: string}) {
	}
}

/* JUEGO LIKES*/
export class SetJuegoLikesSkip implements Action {
	readonly type = UserActionTypes.UserJuegoLikeSkip;
	constructor(public payload: { juegoData: IntJuegoLikesSkip }) {}
}
export class SetJuegoLikesRemove implements Action {
	readonly type = UserActionTypes.UserJuegoLikesRemoveBoth;
	constructor() {}
}
export class GetJuegoLikes implements Action {
	readonly type = UserActionTypes.UserJuegoLikes;
	constructor(public payload: { juegoData: IntJuegoLikesReq }) {}
}
export class GetJuegoLikesSuccess implements Action {
	readonly type = UserActionTypes.UserJuegoLikesSuccess;
	constructor(public payload: { juegoData: IntJuegoLikesResponse }) {}
}
/**/

/* Custom Conversion Flow */
export class SetConversionFunnel implements Action {
	readonly type = UserActionTypes.UserSetConversionFunnel;
	constructor(public payload: { data: IntConversionFunnel }) {
	}
}
export class SetConversionFunnelSuccess implements Action {
	readonly type = UserActionTypes.UserSetConversionFunnelSuccess;
	constructor() {}
}
/* -- */

export class LogInUser implements Action {
    readonly type = UserActionTypes.LogInUser;

    constructor(public payload: { credentials: IntLoginPostData }) {
    }
}

export class LoggedInUser implements Action {
    readonly type = UserActionTypes.LoggedInUser;

    constructor(public payload: { loginData: IntLoginData }) {
    }
}

export class LogInFail implements Action {
    readonly type = UserActionTypes.LogInFail;

    constructor(public payload: { loginData: IntLoginData }) {
    }
}

export class LogInFacebookUserStart implements Action {
    readonly type = UserActionTypes.LogInFacebookUserStart;
}

export class LogInFacebookUserSuccess implements Action {
    readonly type = UserActionTypes.LogInFacebookUserSuccess;

    constructor(public payload: { authData: IntFacebookLoginData }) {
    }
}

export class UserReloadSession implements Action{
	readonly type = UserActionTypes.UserReloadSession
	constructor() {}
}
export class UserReloadSessionSuccess implements Action{
	readonly type = UserActionTypes.UserReloadSessionSuccess
	constructor(public payload: {result: IntLoginData}) {}
}

export class AttachFacebookToken implements Action {
    readonly type = UserActionTypes.AttachFacebookToken;

    constructor(public payload: { authData: IntFacebookLoginData }) {
    }
}

export class LogInFacebookUserSignupData implements Action {
    readonly type = UserActionTypes.LogInFacebookUserSignupData;

    constructor(public payload: { signupData: IntSignupPostData }) {
    }
}

export class LogInFacebookFail implements Action {
    readonly type = UserActionTypes.LogInFacebookFail;

    constructor(public payload: { error: string }) {
    }
}

export class UserForgot implements Action {
    readonly type = UserActionTypes.UserForgot;

    constructor(public payload: { forgotData: IntLoginPostData }) {
    }
}

export class UserForgotFail implements Action {
    readonly type = UserActionTypes.UserForgotFail ;

    constructor(public payload: { forgotData: IntPasswordChangeReply }) {
    }
}

export class UserForgotSuccess implements Action {
    readonly type = UserActionTypes.UserForgotSuccess;

    constructor(public payload: { forgotData: IntPasswordChangeReply }) {
    }
}

export class UserForgotReset implements Action {
    readonly type = UserActionTypes.UserForgotReset;
}

export class LogInReLog implements Action {
	readonly type = UserActionTypes.LogInRelog;

	constructor(public payload: { recovery_token: string }) {}
}

export class LogInFromStoredToken implements Action {
    readonly type = UserActionTypes.LogInFromStoredToken;
}

export class LogInFromStoredTokenSuccess implements Action {
    readonly type = UserActionTypes.LogInFromStoredTokenSuccess;

    constructor(public payload: { loginData: IntLoginData }) {
    }
}

export class LogInFromStoredTokenFail implements Action {
    readonly type = UserActionTypes.LogInFromStoredTokenFail;
}

export class LogoutUser implements Action {
    readonly type = UserActionTypes.LogoutUser;
}

export class LoggedOutUser implements Action {
    readonly type = UserActionTypes.LoggedOutUser;
}

export class UserProfileV2 implements Action {
    readonly type = UserActionTypes.UserProfileV2;
}

export class UserProfileV2Success implements Action {
    readonly type = UserActionTypes.UserProfileV2Success;

    constructor(public payload: { profileData: IntProfileV2Data }) {
    }
}

export class UserAccountData implements Action {
    readonly type = UserActionTypes.UserAccountData;
}

export class UserAccountDataSuccess implements Action {
    readonly type = UserActionTypes.UserAccountDataSuccess;

    constructor(public payload: { accountData: IntAccountDataInfo }) {
    }
}

export class UserAccountAlerts implements Action {
    readonly type = UserActionTypes.UserAccountAlerts;
    constructor(public payload: { platform: string, notif: string, selected: boolean }) {
    }
}

export class UserAccountAlertsSuccess implements Action {
    readonly type = UserActionTypes.UserAccountAlertsSuccess;

    constructor(public payload: { alerts: IntAccountMainAlerts }) {
    }
}

export class UserResetpwd implements Action {
    readonly type = UserActionTypes.UserResetpwd;

    constructor(public payload: { credentials: IntPasswordResetData }) {
    }
}

export class UserResetpwdSuccess implements Action {
    readonly type = UserActionTypes.UserResetpwdSuccess;
}

export class UserResetpwdFail implements Action {
    readonly type = UserActionTypes.UserResetpwdFail;

    constructor(public payload: { reply: IntPasswordChangeReply }) {
    }
}

export class ErrorUser implements Action {
    readonly type = UserActionTypes.ErrorUser;

    constructor(public payload: { e: any }) {
    }
}

export class UserCounters implements Action {
    readonly type = UserActionTypes.UserCounters;
}

export class UserCountersSuccess implements Action {
    readonly type = UserActionTypes.UserCountersSuccess;

    constructor(public payload: { counters: IntSessionCounters }) {
    }
}

export class UserProfileDescrip implements Action {
    readonly type = UserActionTypes.UserProfileDescrip;

    constructor(public payload: { desc: string }) {
    }
}

export class UserProfileDescripSuccess implements Action {
    readonly type = UserActionTypes.UserProfileDescripSuccess;

    constructor(public payload: { desc: string }) {
    }
}

export class UserProfileApodoLocalidad implements Action {
    readonly type = UserActionTypes.UserProfileApodoLocalidad;

    constructor(public payload: { apodo: string, idLocalidad: number, idProvincia: number }) {
    }
}

export class UserProfileApodoLocalidadSuccess implements Action {
    readonly type = UserActionTypes.UserProfileApodoLocalidadSuccess;

    constructor(public payload: { apodo: string, idLocalidad: number, idProvincia: number }) {
    }
}

export class UserProfileField implements Action {
    readonly type = UserActionTypes.UserProfileField;

    constructor(public payload: { f: IntProfileV2DataQuestionData, mainKey: 'personal' | 'partner', sectionIx: number, listIx: number }) {
    }
}

export class UserProfileFieldSuccess implements Action {
    readonly type = UserActionTypes.UserProfileFieldSuccess;

    constructor(public payload: { f: IntProfileV2DataQuestionData, mainKey: 'personal' | 'partner', sectionIx: number, listIx: number }) {
    }
}

export class UserProfileCompletitudUpdate implements Action {
    readonly type = UserActionTypes.UserProfileCompletitudUpdate;

    constructor(public payload: { completitud: number }) {
    }
}

export class UserSignup implements Action {
    readonly type = UserActionTypes.UserSignup;

    constructor(public payload: { user: IntSignupPostData }) {
    }
}

export class UserSignupFail implements Action {
    readonly type = UserActionTypes.UserSignupFail;

    constructor(public payload: { response: IntLoginData }) {
    }
}

export class UserSignupSuccess implements Action {
    readonly type = UserActionTypes.UserSignupSuccess;

    constructor(public payload: { loginData: IntLoginData }) {
    }
}

export class ProfileNewImage implements Action {
    readonly type = UserActionTypes.ProfileNewImage;

    constructor(public payload: { image: IntProfileNewImage }) {
    }
}

export class ProfileNewImageFromFacebook implements Action {
    readonly type = UserActionTypes.ProfileNewImageFromFacebook;

    constructor(public payload: { images: Array<string> }) {
    }
}

export class ProfileNewImageSuccess implements Action {
    readonly type = UserActionTypes.ProfileNewImageSuccess;

    constructor(public payload: { reply: IntProfileUpdateReply }) {
    }
}

export class ProfileDeleteImage implements Action {
    readonly type = UserActionTypes.ProfileDeleteImage;

    constructor(public payload: { image: IntProfileV2GcpImage }) {
    }
}

export class ProfileDeleteImageSuccess implements Action {
    readonly type = UserActionTypes.ProfileDeleteImageSuccess;

    constructor(public payload: { reply: IntProfileUpdateReply, image: IntProfileV2GcpImage }) {
    }
}

export class ProfileSetImage implements Action {
    readonly type = UserActionTypes.ProfileSetImage;

    constructor(public payload: { image: IntProfileV2GcpImage }) {
    }
}

export class ProfileSetImageSuccess implements Action {
    readonly type = UserActionTypes.ProfileSetImageSuccess;

    constructor(public payload: { reply: IntProfileUpdateReply, image: IntProfileV2GcpImage }) {
    }
}


export class ProfileAgeRange implements Action {
    readonly type = UserActionTypes.ProfileAgeRange;

    constructor(public payload: { min: number, max: number }) {
    }
}

export class ProfileAgeRangeSuccess implements Action {
    readonly type = UserActionTypes.ProfileAgeRangeSuccess;

    constructor(public payload: { min: number, max: number }) {
    }
}

export class Paywallget implements Action {
    readonly type = UserActionTypes.Paywallget;

    constructor(public payload: { promo: string, nick: string, avatar: string, idabono: string }) {
    }
}

export class PaywallgetSuccess implements Action {
    readonly type = UserActionTypes.PaywallgetSuccess;

    constructor(public payload: { paywall: IntPaywallData }) {
    }
}

export class SeHaFormadoUnaPareja implements Action {
    readonly type = UserActionTypes.SeHaFormadoUnaPareja;

    constructor(public payload: { match: IntMatchInfo }) {
    }
}

export class UserGenericFail implements Action {
    readonly type = UserActionTypes.UserGenericFail;

    constructor(public payload: { error: string }) {
    }
}

// Refund
export class UserRefund implements Action {
	readonly type = UserActionTypes.UserRefund;
	constructor(public payload: { iduser: string } ) {}
}
export class UserRefundSuccess implements Action {
    readonly type = UserActionTypes.UserRefundSuccess;
    constructor(public payload: {result: IntRefundReply}) {}
}
export class UserRefundFails implements Action {
	readonly type = UserActionTypes.UserRefundFails;
	constructor(public payload: {result: IntRefundReply}) {}
}

// Set count post register modals
export class UserSetCountModalsReg implements Action {
	readonly type = UserActionTypes.UserSetCountModalsReg;
	constructor() {}
}
export class UserSetCountModalsRegSuccess implements Action {
	readonly type = UserActionTypes.UserSetCountModalsRegSuccess;
	constructor(public payload: {result: IntCountRegModals}) {}
}

// Mobbex Payments
export class UserMobbexCheckout implements Action{
	readonly type = UserActionTypes.UserMobbexCheckout;
	constructor(public payload: {data: IntMobbexCheckout}) {
	}
}
export class UserMobbexCheckoutSuccess implements Action{
	readonly type = UserActionTypes.UserMobbexCheckoutSuccess;
	constructor(public payload: { data: IntMobbexCheckoutSuccess}) {
	}
}

export class UserAbTest implements Action{
	readonly type = UserActionTypes.UserAbTest;
	constructor(public payload: {data: IntAbTest}) {
	}
}


export type UserActions =
    LogInUser
    | LoggedInUser
    | LogInFail
    | LogInFacebookUserStart
    | LogInFacebookUserSuccess
    | AttachFacebookToken
    | LogInFacebookUserSignupData
    | LogInFacebookFail
    | UserForgot
    | UserForgotFail
    | UserForgotSuccess
    | UserForgotReset
    | LogoutUser
    | LoggedOutUser
    | LogInFromStoredToken
    | LogInFromStoredTokenSuccess
    | LogInFromStoredTokenFail
    | UserCounters
    | UserCountersSuccess
    | UserProfileV2
    | UserProfileV2Success
    | UserAccountData
    | UserAccountDataSuccess
    | UserAccountAlerts
    | UserAccountAlertsSuccess
    | UserProfileDescrip
    | UserProfileDescripSuccess
    | UserProfileField
    | UserProfileFieldSuccess
    | UserProfileApodoLocalidad
    | UserProfileApodoLocalidadSuccess
    | UserProfileCompletitudUpdate
    | UserSignup
    | UserSignupFail
    | UserSignupSuccess
    | UserResetpwd
    | UserResetpwdSuccess
    | UserResetpwdFail
    | ProfileNewImage
    | ProfileNewImageFromFacebook
    | ProfileNewImageSuccess
    | ProfileDeleteImage
    | ProfileDeleteImageSuccess
    | ProfileSetImage
    | ProfileSetImageSuccess
    | ProfileAgeRange
    | ProfileAgeRangeSuccess
    | UserGenericFail
    | Paywallget
    | PaywallgetSuccess
    | SeHaFormadoUnaPareja
    | ErrorUser
	| GetJuegoLikes
	| GetJuegoLikesSuccess
	| SetJuegoLikesRemove
	| SetJuegoLikesSkip
	| SetAutologin
	| SetAutologinSuccess
	| GetAutologinMessage
	| SetAutologinClear
	| SetAutologinLike
	| SetAutologinDisLike
    | UserRefund
	| UserRefundSuccess
	| UserRefundFails
	| UserSetCountModalsReg
	| UserSetCountModalsRegSuccess
	| UserReloadSession
	| UserReloadSessionSuccess
	| LogInReLog
	| SetConversionFunnel
	| SetConversionFunnelSuccess
	| UserMobbexCheckout
	| UserMobbexCheckoutSuccess
	| UserAbTest;
