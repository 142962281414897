import {Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnInit, Output, EventEmitter, ElementRef, Input} from '@angular/core';
import {Platform} from '@alyle/ui';
import {
    ImgCropperConfig,
    ImgCropperEvent,
    LyResizingCroppingImages,
    ImgCropperErrorEvent,
    ImgResolution
} from '@alyle/ui/resizing-cropping-images';

export interface IntCropperImagesResult {
    original: Blob;
    cropped: Blob;
    filename: string;
}

@Component({
    selector: 'app-cropper',
    templateUrl: './cropper.component.html',
    styleUrls: ['./cropper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class CropperComponent implements OnInit, AfterViewInit {

    @ViewChild(LyResizingCroppingImages, { static: true }) cropper: LyResizingCroppingImages;
    @ViewChild('_fileInput', { static: true }) _fileInput: ElementRef;

    @Input() cropperWidth: number = 360;
    @Input() cropperHeight: number = 280;
    cropperWindowHeight: number = 350;

    croppedImage: string = null;
    originalImage: string = null;
    fileName = null;

    fileUploadDummy = null;

    displayCropper = false;

    @Output() cropped: EventEmitter<IntCropperImagesResult | null> = new EventEmitter();

    scale: number;
    cropperConfig: ImgCropperConfig = {
        autoCrop: true,
        width: 360,
        height: 280,
        fill: '#000', // Default transparent if type = png else #000,
        type: 'image/jpeg'
    };

    constructor() {
    }

    /**
     * Entry point del componente, llamar a este método para disparar el dialogo de selección de archivo
     */
    initializeCropper() {
        let compWidth = window.innerWidth;
        if (compWidth > 650) {
            compWidth = 650;
        }

        if (this.cropperHeight === 0) {
            this.cropperHeight = 1;
        }
        const aspectRatio = this.cropperWidth / this.cropperHeight;

        if (this.cropperHeight > 350) {
            // if the height is larger than 350
            const diff = this.cropperHeight - 350;
            this.cropperHeight = 350;
            this.cropperWidth =  Math.ceil(this.cropperHeight * aspectRatio);
        }

        // console.debug(this.cropperWidth, compWidth);

        if (this.cropperWidth > compWidth) {
            // if the width was larger than the container, adjust
            const diff = this.cropperWidth - compWidth;
            this.cropperWidth = compWidth - 30;
            this.cropperHeight =  Math.ceil(this.cropperWidth / aspectRatio);
        }

        this.cropperWindowHeight = this.cropperHeight + 30;


        this.cropper.config.width = this.cropperWidth;
        this.cropper.config.height = this.cropperHeight;

        this.fileUploadDummy = null;
        this.cropper.clean();
        this._fileInput.nativeElement.click();
    }

    doSelectImage(e: Event) {
        this.setFileInputImage(e);
    }

    setFileInputImage(event: Event) {
        if (this._fileInput.nativeElement.files.length !== 1) {
            return;
        }

        if (!this._fileInput.nativeElement.files[0].type.match('image.*')) {
            return;
        }

        this.fileName = this._fileInput.nativeElement.files[0].name;
        this.cropper.selectInputEvent(event);
        this.displayCropper = true;
    }

    confirm() {
        this.displayCropper = false;
        this.cropped.emit(this.getImagesBlobs());
        this.fileUploadDummy = null;
    }
    cancel() {
        this.fileUploadDummy = null;
        this.cropper.clean();
        this.displayCropper = false;
        this.cropped.emit(null);
    }

    ngAfterViewInit() {

        if (Platform.isBrowser) {
            const config = {
                // scale: 1,
                // position: {
                //     x: 642.380608078103,
                //     y: 236.26357452128866
                // }
            };
            // this.cropper.setImageUrl(
            //     'https://firebasestorage.googleapis.com/v0/b/alyle-ui.appspot.com/o/img%2Flarm-rmah-47685-unsplash-1.png?alt=media&token=96a29be5-e3ef-4f71-8437-76ac8013372c',
            //     () => {
            //         this.cropper.setScale(config.scale, true);
            //         this.cropper.updatePosition(config.position.x, config.position.y);
            //         // this.cropper.rotate(90);
            //     }
            // );
        }
    }

    ngOnInit() {
    }


    /**
     * Returns original and cropped blobs
     */
    getImagesBlobs(): IntCropperImagesResult | null {
        if (this.croppedImage === null) {
            this.cropper.clean();
            return null;
        } else {
            const r = this.dataURItoBlob(this.croppedImage);
            const o = this.dataURItoBlob(this.originalImage);
            this.cropper.clean();
            return {
                original: o,
                cropped: r,
                filename: this.fileName
            };
        }
    }


    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        const ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], {type: mimeString});
    }

    onCropped(e: ImgCropperEvent) {
        this.croppedImage = e.dataURL;
        this.originalImage = e.originalDataURL;
        // console.log('cropped img: ', e);
    }

    onloaded(e: ImgCropperEvent) {
        // console.log('img loaded', e);
    }

    onerror(e: ImgCropperErrorEvent) {
        this.cancel();
        // console.warn(`'${e.name}' is not a valid image`, e);
    }
}
