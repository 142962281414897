import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injectable,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    IntLoginData,
    IntProfileV2Data,
    IntProfileV2DataQuestionData,
    IntProfileV2DataQuestionDataOptions
} from '../../store/user/user.model';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {
	ProfileAgeRange,
	UserProfileApodoLocalidad,
	UserProfileDescrip,
	UserProfileField,
	UserProfileV2, UserReloadSession
} from '../../store/user/user.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import {Observable, of, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap, map, catchError, filter} from 'rxjs/operators';
import {IntCiudadData, LocalidadesService} from '../../services/localidades.service';
import {MatCheckboxChange, MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {TagSearchService} from '../../services/tagsearch.service.service';
import {ProfileClose, ProfileOpen} from '../../store/profile/profile.actions';
import {EditProfileComponent} from './editprofile.component';
import {SetUploadPhoto, ZendeskShow} from '../../store/ui/ui.actions';
import {zcDeepCopy} from '../../services/zc.globals';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(private tagSearch: TagSearchService) {
    }

    searchMovie(term: string) {
        if (term === '') {
            return of([]);
        }

        return this.tagSearch.doSearch('movies', term).pipe(
            map(r => r.data)
        );
    }

    searchMusic(term: string) {
        if (term === '') {
            return of([]);
        }

        return this.tagSearch.doSearch('music', term).pipe(
            map(r => r.data)
        );
    }

    searchBook(term: string) {
        if (term === '') {
            return of([]);
        }

        return this.tagSearch.doSearch('books', term).pipe(
            map(r => r.data)
        );
    }
}


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('OpenClose', [
            transition(':enter', [
                style({height: '0', overflow: 'hidden'}),
                animate('150ms ease', style({height: '*'}))
            ]),
            transition(':leave', [
                animate('150ms ease', style({height: '0'}))
            ])
        ])
    ]
})
export class ProfileComponent implements OnInit, OnDestroy {

    @ViewChild('inputTagBooks') inputTagBooks: ElementRef;
    @ViewChild('inputTagMusic') inputTagMusic: ElementRef;
    @ViewChild('inputTagMovies') inputTagMovies: ElementRef;

    activeTab: string = 'personal';

    userobj: IntLoginData = null;
    subs: Subscription = null;

    profileobj: IntProfileV2Data = null;
    subsPro: Subscription = null;
    routeSub: Subscription = null;
    subUploadPhoto: Subscription = null;

    ciudades: IntCiudadData[] = [];
    localidades: IntCiudadData[] = [];

    showGallery: boolean = false;
    loading: boolean = true;

    dataProfileLocation: any;

    opcionesActividadesVacaciones: IntProfileV2DataQuestionDataOptions = [];
    opcionesActividadesTiempoLibre: IntProfileV2DataQuestionDataOptions = [];

    addingValues: any = {
        Peliculas: null,
        Musica: null,
        Libros: null,

        Description: '',
        Apodo: '',
        Localidad: '',
        Provincia: '',
    };

    ageRange: any = {
        min: 18,
        max: 99
    };

    uiStatus: any = {
        PeliculasLoading: false,
        MusicaLoading: false,
        LibrosLoading: false,

        updatePeliculas: false,
        updateMusica: false,
        updateLibros: false,

        // updateDescription: false,
        updateProfile: false,
    };

    tagFormatter = (result: any) => result.value;

    dscState$: Observable<boolean> = this.store.pipe(select(fromRoot.getCurrentDescState));

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private searchService: SearchService,
        private localidadesService: LocalidadesService,
        public utils: ZcUtilsService,
        public dialog: MatDialog,
        private router: Router,
		private toastr: ToastrService
    ) {
        this.showGallery = false;

		// refresh session
		this.store.dispatch(new UserReloadSession());

        // refresh profile data
        this.store.dispatch(new UserProfileV2());

        this.localidades = this.localidadesService.getLocalidades();

        this.subs = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(user => {
            this.userobj = (user === null) ? {} : zcDeepCopy(user);   // deep copy user object to edit without modify the main object
            this.cd.markForCheck();
        });
        this.subsPro = this.store.pipe(select(fromRoot.getCurrentProfileV2)).subscribe(profileobj => {
            // deep copy profile object to edit without modify the main object
            this.profileobj = (profileobj === null) ? {} : zcDeepCopy(profileobj);

            // Description
            if (this.profileobj && this.profileobj.moderationDescription && this.profileobj.moderationDescription.description !== null) {
                // Prioritaze new descriptions
                this.addingValues.Description = this.profileobj.moderationDescription.description;
            } else {
                this.addingValues.Description = this.profileobj.description || '';
            }

            // Age range
            if (this.profileobj && this.profileobj.ageRange) {
                this.ageRange = this.profileobj.ageRange;
                // this.ageRange.min = parseInt(this.profileobj.ageRange.min);
                // this.ageRange.max = parseInt(this.profileobj.ageRange.max);
            }

            // Opciones vacaciones y tiempo libre, solo si no se han completado ya (esto evita flickering al actualizar datos)
            if (this.profileobj.personal) {
                if (!this.opcionesActividadesVacaciones.length && this.profileobj.personal[5].list[0].options) {
                    this.opcionesActividadesVacaciones = zcDeepCopy(this.profileobj.personal[5].list[0].options);
                }
                if (!this.opcionesActividadesTiempoLibre.length && this.profileobj.personal[5].list[1].options) {
                    this.opcionesActividadesTiempoLibre = zcDeepCopy(this.profileobj.personal[5].list[1].options);
                }
            }


            this.dataProfileLocation = this.getUserLocalidad() + ', ' + this.getUserProvincia();
            this.cd.markForCheck();
        });
        this.subUploadPhoto = this.store.pipe(select(fromRoot.isUploadPhoto)).subscribe(isUp => {
            if (isUp) {
                this.store.dispatch(new SetUploadPhoto(false));
                this.openUpdateBox('perfil');
            }
        });
    }

    ngOnInit() {
		// hide zendesk
		this.store.dispatch(new ZendeskShow(false));
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsPro !== null) {
            this.subsPro.unsubscribe();
        }
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
        if (this.subUploadPhoto !== null) {
            this.subUploadPhoto.unsubscribe();
        }
    }

    isImagenModeracion() {
        if (this.profileobj && Array.isArray(this.profileobj.images)) {
            const ret = this.profileobj.images.filter(o => !o.approved);
            return ret.length > 0;
        } else {
            return false;
        }
    }

    formatter = (x: any) => x.value;

    searchMovie = (text$: Observable<string>) =>
        text$.pipe(
            filter(t => t !== null && t !== '' && (t.length > 3)),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                this.uiStatus.PeliculasLoading = true;
                this.cd.markForCheck();
            }),
            switchMap(term =>
                this.searchService.searchMovie(term).pipe(
                    tap(() => this.uiStatus.PeliculasLoading = false),
                    catchError(() => {
                        this.uiStatus.PeliculasLoading = false;
                        return of([]);
                    }))
            )
        );

    searchMusic = (text$: Observable<string>) =>
        text$.pipe(
            filter(t => t !== null && t !== '' && (t.length > 3)),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                this.uiStatus.MusicaLoading = true;
                this.cd.markForCheck();
            }),
            switchMap(term =>
                this.searchService.searchMusic(term).pipe(
                    tap(() => this.uiStatus.MusicaLoading = false),
                    catchError(() => {
                        this.uiStatus.MusicaLoading = false;
                        return of([]);
                    }))
            )
        );

    searchBook = (text$: Observable<string>) =>
        text$.pipe(
            filter(t => t !== null && t !== '' && (t.length > 3)),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                this.uiStatus.LibrosLoading = true;
                this.cd.markForCheck();
            }),
            switchMap(term =>
                this.searchService.searchBook(term).pipe(
                    tap(() => this.uiStatus.LibrosLoading = false),
                    catchError(() => {
                        this.uiStatus.LibrosLoading = false;
                        return of([]);
                    }))
            )
        );


    getUserLocalidad() {
        if (this.profileobj === null || !this.profileobj.location) {
            return '';
        }
        const ret = this.localidadesService.getNombreCiudad(parseInt(this.profileobj.location.provinceId, 10), parseInt(this.profileobj.location.cityId, 10));
        return ret === null ? '' : ret.l;
    }

    getUserProvincia() {
        if ((this.profileobj === null) || !this.profileobj.location) {
            return '';
        }
        const ret = this.localidadesService.getNombreProvincia(parseInt(this.profileobj.location.provinceId, 10));
        return ret === null ? '' : ret.l;
    }

    openUpdateBox(box: string) {
        switch (box) {
            case 'descripcion':
                this.addingValues.Description = this.profileobj.description || '';
                this.uiStatus.updateDescription = true;
                break;

            case 'perfil':

                this.dialog.open(EditProfileComponent, {
                	disableClose: false,
                    closeOnNavigation: true
                });

                // this.addingValues.Apodo = this.profileobj.moderationUsername.username || this.profileobj.username || '';
                // this.addingValues.Localidad = parseInt(this.profileobj.location.cityId, 10) || null;
                // this.addingValues.Provincia = parseInt(this.profileobj.location.provinceId, 10) || null;
                // this.ciudades = this.localidadesService.getCiudad(parseInt(this.addingValues.Provincia, 10));

                // this.uiStatus.updateProfile = true;
                break;

            case 'peliculas':
                this.addingValues.Peliculas = null;
                this.uiStatus.updatePeliculas = true;
                setTimeout(() => {
                    this.inputTagMovies.nativeElement.focus();
                }, 150);
                break;

            case 'musica':
                this.addingValues.Musica = null;
                this.uiStatus.updateMusica = true;
                setTimeout(() => {
                    this.inputTagMusic.nativeElement.focus();
                }, 150);
                break;

            case 'libros':
                this.addingValues.Libros = null;
                this.uiStatus.updateLibros = true;
                setTimeout(() => {
                    this.inputTagBooks.nativeElement.focus();
                }, 150);
                break;
        }
    }

    closeUpdateBox(box: string) {
        switch (box) {
            // case 'descripcion':
            //     this.uiStatus.updateDescription = false;
            //     break;

            case 'perfil':
                this.uiStatus.updateProfile = false;
                break;

            case 'peliculas':
                this.uiStatus.updatePeliculas = false;
                break;

            case 'musica':
                this.uiStatus.updateMusica = false;
                break;

            case 'libros':
                this.uiStatus.updateLibros = false;
                break;
        }
    }

    confirmField(box: string) {
        switch (box) {

            case 'perfil':

                if ((this.profileobj.username === '') || (!this.addingValues.Localidad) || (!this.addingValues.Provincia)) {
                    return;
                }

                this.store.dispatch(new UserProfileApodoLocalidad({
                    apodo: this.addingValues.Apodo,
                    idLocalidad: parseInt(this.addingValues.Localidad, 10),
                    idProvincia: parseInt(this.addingValues.Provincia, 10)
                }));
                this.uiStatus.updateProfile = false;
                break;
        }
    }

    updateCiudades() {
        this.addingValues.Localidad = '';
        this.ciudades = this.localidadesService.getCiudad(parseInt(this.addingValues.Provincia, 10));
    }

    descriptionChanged() {
        // Compare old and new description
        if (this.profileobj.description === this.addingValues.Description || this.addingValues.Description === '') {
            // Update cancelled, restore values
            this.addingValues.Description = (this.profileobj.description === null ? '' : this.profileobj.description);
            return;
        }
        // Update description
        this.store.dispatch(new UserProfileDescrip({desc: this.addingValues.Description}));
    }

    ageRangeChanged() {
        // Reorder if inverted
        if (this.ageRange.min > this.ageRange.max) {
            let tmp = this.ageRange.min;
            this.ageRange.min = this.ageRange.max;
            this.ageRange.max = tmp;
        }
        // Add or substract if equal
        if (this.ageRange.min == this.ageRange.max) {
            this.ageRange.max = parseInt(this.ageRange.max) + 1;
        }

        this.store.dispatch(new ProfileAgeRange({min: parseInt(this.ageRange.min), max: parseInt(this.ageRange.max)}));
    }

    /**
     * Agrega/quita actividad de vacaciones o tiempo libre
     * @param mainKey
     * @param sectionIx
     * @param listIx
     * @param value
     */
    updateActividad(mainKey: 'personal' | 'partner', sectionIx: number, listIx: number, value: string) {
        const f: IntProfileV2DataQuestionData = zcDeepCopy(this.profileobj[mainKey][sectionIx].list[listIx]);
        const ix = f.selectedValues.indexOf(value);
        if (ix >= 0) {
            f.selectedValues.splice(ix, 1);
        } else {
            f.selectedValues.push(value);
        }
        while (f.selectedValues.length > 3) {
            f.selectedValues.shift();
        }
        this.store.dispatch(new UserProfileField({f: f, mainKey: mainKey, sectionIx: sectionIx, listIx: listIx}));
    }

    /**
     * Agrega o quita tags música/películas/libros Similar a updateActividad pero agrega sobre options
     * @param mainKey
     * @param sectionIx
     * @param listIx
     * @param value
     * @param removeIfExists
     */
    updateTags(mainKey: 'personal' | 'partner', sectionIx: number, listIx: number, value: string, removeIfExists: boolean) {
        const opts: any = this.profileobj[mainKey][sectionIx].list[listIx].options;
        const ix = opts.indexOf(value);
        if (ix >= 0) {
            if (!removeIfExists) {
                // si existe y el parámetro removeIfExists es false, retornar, se puede dar el caso cuando selecciona el tag 2 veces de la lista
                return;
            }
            opts.splice(ix, 1);
        } else {
            opts.push(value);
        }
        const f: any = this.profileobj[mainKey][sectionIx].list[listIx];
        this.store.dispatch(new UserProfileField({f: f, mainKey: mainKey, sectionIx: sectionIx, listIx: listIx}));
    }

    updateField(mainKey: 'personal' | 'partner', sectionIx: number, listIx: number) {
        const f: IntProfileV2DataQuestionData = zcDeepCopy(this.profileobj[mainKey][sectionIx].list[listIx]);
        // console.log(f);
        this.store.dispatch(new UserProfileField({f: f, mainKey: mainKey, sectionIx: sectionIx, listIx: listIx}));
    }

    updateIdiomas(event: MatCheckboxChange, mainKey: 'personal' | 'partner', sectionIx: number, listIx: number, value: string) {
        const f: IntProfileV2DataQuestionData = zcDeepCopy(this.profileobj[mainKey][sectionIx].list[listIx]);

        const ix = f.selectedValues.indexOf(value);
        if (ix >= 0) {
            f.selectedValues.splice(ix, 1);
        } else {
            f.selectedValues.push(value);
        }

        this.store.dispatch(new UserProfileField({f: f, mainKey: mainKey, sectionIx: sectionIx, listIx: listIx}));
    }

    closeGallery() {
        this.showGallery = false;
    }

    movieSelected(movie: any) {
        if (this.utils.isUndefinedOrNull(movie) || !movie.value) {
            return;
        }
        const sel = movie.value;
        this.addingValues.Peliculas = null;
        if (this.utils.isUndefinedOrNull(this.profileobj.personal[5].list[2].options)) {
            this.profileobj.personal[5].list[2].options = [];
        }
        this.updateTags('personal', 5, 2, sel, false);
    }

    musicSelected(music: any) {
        if (this.utils.isUndefinedOrNull(music) || !music.value) {
            return;
        }
        const sel = music.value;
        this.addingValues.Musica = null;
        if (this.utils.isUndefinedOrNull(this.profileobj.personal[5].list[3].options)) {
            this.profileobj.personal[5].list[3].options = [];
        }
        this.updateTags('personal', 5, 3, sel, false);
    }

    bookSelected(book: any) {
        if (this.utils.isUndefinedOrNull(book) || !book.value) {
            return;
        }
        const sel = book.value;
        this.addingValues.Libros = null;
        if (this.utils.isUndefinedOrNull(this.profileobj.personal[5].list[4].options)) {
            this.profileobj.personal[5].list[4].options = [];
        }
        this.updateTags('personal', 5, 4, sel, false);
    }

    updateAgeRange() {
        let ageMin = parseInt(this.profileobj.ageRange.min, 10);
        if (isNaN(ageMin) || (ageMin < 18)) {
            ageMin = 18;
            this.profileobj.ageRange.min = '18';
        }

        let ageMax = parseInt(this.profileobj.ageRange.max, 10);
        if (isNaN(ageMax) || (ageMax < 18) || (ageMax > 99)) {
            ageMax = 99;
            this.profileobj.ageRange.max = '99';
        }

        this.store.dispatch(new ProfileAgeRange({min: ageMin, max: ageMax}));
    }

    miPerfil() {
        this.store.dispatch(new ProfileOpen({userId: parseInt(this.profileobj.id, 10), type: null}));
    }

    saveChanges() {
		this.toastr.info('', '¡Se actualizó tu perfil!',
			{progressBar: true, positionClass: 'toast-top-center'});
	}


}
