import {Action} from '@ngrx/store';
import {
    IntDashboardGetListing,
    IntDashboardGetListingScroll,
    IntDashboardListingResult,
    IntDashboardListingResults, IntDashboardSearchParams, IntSearchOptionSection
} from './dashboard.model';

export enum DashboardActionTypes {
    DashboardSearchOptions = '[Dashboard API] Search opts',
    DashboardSearchOptionsSuccess = '[Dashboard API] Search opts success',
    DashboardInitSearch = '[Dashboard API] Init search',
    DashboardDoSearch = '[Dashboard API] Do search',
    DashboardInitScroll = '[Dashboard API] Init Scroll',
    DashboardDoScroll = '[Dashboard API] Do scroll',
    DashboardFail = '[Dashboard API] Fail',
    DashboardActionFail = '[Dashboard API] Action Fail',
    DashboardDoLike = '[Dashboard API] Do like',
    DashboardDoLiked = '[Dashboard API] Do liked',
    DashboardDoLikedByID = '[Dashboard API] Do liked by ID',
    DashboardDoUndoLike = '[Dashboard API] Do undo like',
    DashboardDoUndoLiked = '[Dashboard API] Do undo liked',
    DashboardDoUndoLikedByID = '[Dashboard API] Do undo liked by ID',
    DashboardDoDislike = '[Dashboard API] Do dislike',
    DashboardDoDisliked = '[Dashboard API] Do disliked',
    DashboardDoDislikedByID = '[Dashboard API] Do disliked by ID',
    DashboardCleanup = '[Dashboard API] Cleanup',
}

export class DashboardInitSearch implements Action {
    readonly type = DashboardActionTypes.DashboardInitSearch;

    constructor(public payload: { type: IntDashboardGetListing, searchParams?: IntDashboardSearchParams }) {
    }
}

export class DashboardDoSearch implements Action {
    readonly type = DashboardActionTypes.DashboardDoSearch;

    constructor(public payload: { results: IntDashboardListingResults }) {
    }
}

export class DashboardInitScroll implements Action {
    readonly type = DashboardActionTypes.DashboardInitScroll;

    constructor(public payload: { hash: IntDashboardGetListingScroll }) {
    }
}

export class DashboardDoScroll implements Action {
    readonly type = DashboardActionTypes.DashboardDoScroll;

    constructor(public payload: { results: IntDashboardListingResults }) {
    }
}

export class DashboardDoLike implements Action {
    readonly type = DashboardActionTypes.DashboardDoLike;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoLiked implements Action {
    readonly type = DashboardActionTypes.DashboardDoLiked;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoLikedByID implements Action {
    readonly type = DashboardActionTypes.DashboardDoLikedByID;

    constructor(public payload: { userId: string }) {
    }
}

export class DashboardDoUndoLike implements Action {
    readonly type = DashboardActionTypes.DashboardDoUndoLike;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoUndoLiked implements Action {
    readonly type = DashboardActionTypes.DashboardDoUndoLiked;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoUndoLikedByID implements Action {
    readonly type = DashboardActionTypes.DashboardDoUndoLikedByID;

    constructor(public payload: { userId: string }) {
    }
}

export class DashboardDoDislike implements Action {
    readonly type = DashboardActionTypes.DashboardDoDislike;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoDisliked implements Action {
    readonly type = DashboardActionTypes.DashboardDoDisliked;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardDoDislikedByID implements Action {
    readonly type = DashboardActionTypes.DashboardDoDislikedByID;

    constructor(public payload: { userId: string }) {
    }
}

export class DashboardFail implements Action {
    readonly type = DashboardActionTypes.DashboardFail;

    constructor(public payload: { results: IntDashboardListingResults }) {
    }
}

export class DashboardActionFail implements Action {
    readonly type = DashboardActionTypes.DashboardActionFail;

    constructor(public payload: { user: IntDashboardListingResult }) {
    }
}

export class DashboardSearchOptions implements Action {
    readonly type = DashboardActionTypes.DashboardSearchOptions;
}

export class DashboardCleanup implements Action {
    readonly type = DashboardActionTypes.DashboardCleanup;
}

export class DashboardSearchOptionsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardSearchOptionsSuccess;

    constructor(public payload: { options: IntSearchOptionSection[] }) {
    }
}


export type DashboardActions =
    DashboardInitSearch
    | DashboardDoSearch
    | DashboardInitScroll
    | DashboardDoScroll
    | DashboardDoLike
    | DashboardDoLiked
    | DashboardDoLikedByID
    | DashboardDoUndoLike
    | DashboardDoUndoLiked
    | DashboardDoUndoLikedByID
    | DashboardDoDislike
    | DashboardDoDisliked
    | DashboardDoDislikedByID
    | DashboardActionFail
    | DashboardSearchOptions
    | DashboardSearchOptionsSuccess
    | DashboardCleanup
    | DashboardFail;
