import { Component, OnInit } from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../store";
import {Subscription} from "rxjs";
import {IntAbTest, IntLoginData} from "../../store/user/user.model";
import {toInteger} from "@ng-bootstrap/ng-bootstrap/util/util";
import {ActivatedRoute, Router} from "@angular/router";
import {UserAbTest} from "../../store/user/user.actions";

@Component({
  selector: 'app-abtest',
  templateUrl: './abtest.component.html',
  styleUrls: ['./abtest.component.scss']
})
export class AbtestComponent implements OnInit {

	subsSess: Subscription = null;
	session: IntLoginData = null;
  constructor(private store: Store<fromRoot.State>,
			  private route: ActivatedRoute,
			  private router: Router) {
	  //
	  // get parameter
	  let promo = '';
	  promo = this.route.snapshot.params['promo'];
	  let abtestData: IntAbTest;
	  // get session info
	  this.subsSess = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
		  this.session = session;
		  let idUser	= this.session.sessionInfo.me.id;
		  // redirect based on user id && promo
		  if(!promo){
			  if(parseInt(idUser) & 1){
				  abtestData = {id_plan: 16, id_gateway_pago: 'mp'};
				  this.store.dispatch(new UserAbTest({data: abtestData}));
				  this.router.navigateByUrl('/suscribite/16');
			  }else{
				  abtestData = {id_plan: 27, id_gateway_pago: 'mobbex'};
				  this.store.dispatch(new UserAbTest({data: abtestData}));
				  this.router.navigateByUrl('/suscribitemb/27');
			  }
		  }
	  });
	  //
  }
  ngOnInit(): void {}

}
