import {Action} from '@ngrx/store';
import {IntRecomendadosListing} from './recomendados.model';

export enum RecomendadosActionTypes {
    RecomendadosListing     = '[Recomendados API] Get Listing',
	RecomendadosListingDone = '[Recomendados API] Get Listing Done',
	RecomendadosListingFail = '[Recomendados API] Get Listing Fail'
}

export class RecomendadosListing implements Action {
    readonly type = RecomendadosActionTypes.RecomendadosListing;
}
export class RecomendadosListingDone implements Action {
	readonly type = RecomendadosActionTypes.RecomendadosListingDone;
	constructor(public payload: { results: IntRecomendadosListing }) {
	}
}
export class RecomendadosListingFail implements Action {
	readonly type = RecomendadosActionTypes.RecomendadosListingFail;
	constructor(public payload: { results: IntRecomendadosListing }) {
	}
}

export type RecomendadosActions =
    RecomendadosListing
	| RecomendadosListingDone
	| RecomendadosListingFail;
