import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError, debounceTime,
    map, mergeMap, tap,
} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {DashboardService} from './dashboard.service';
import * as dashboardActions from './dashboard.actions';
import * as uiActions from '../ui/ui.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';

/**
 * Effects file is for isolating and managing side effects of the application in one place
 * Http requests, Sockets, Routing, LocalStorage, etc
 */

@Injectable()
export class DashboardEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private dashboardService: DashboardService,
    ) {
    }


    @Effect()
    initListing$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardInitSearch>(dashboardActions.DashboardActionTypes.DashboardInitSearch),
        mergeMap((action) =>
            this.dashboardService.listOrSearch( action.payload.type, action.payload.searchParams ).pipe(
                map((results:any) => {
                    if ((results.status === 200) && results.data) {
                        return new dashboardActions.DashboardDoSearch({results: results.data});
                    } else {
                        return new dashboardActions.DashboardFail({results: results});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardFail({results: err})))
            )
        )
    );

    @Effect()
    initScroll$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardInitScroll>(dashboardActions.DashboardActionTypes.DashboardInitScroll),
        debounceTime(1000),
        mergeMap((action) =>
            this.dashboardService.scroll( action.payload.hash ).pipe(
                map((results: any) => {
                    if (results.data && results.data.hits) {
                        return new dashboardActions.DashboardDoScroll({results: results.data});
                    } else {
                        return new dashboardActions.DashboardFail({results: results});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardFail({results: err})))
            )
        )
    );

    @Effect()
    doLike$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardDoLike>(dashboardActions.DashboardActionTypes.DashboardDoLike),
        mergeMap((action) =>
            this.dashboardService.doLike(action.payload.user.id_usuario).pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        return new dashboardActions.DashboardDoLiked({user: action.payload.user});
                    } else {
                        return new dashboardActions.DashboardActionFail({user: action.payload.user});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardActionFail({user: action.payload.user})))
            )
        )
    );


    @Effect()
    doDisike$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardDoDislike>(dashboardActions.DashboardActionTypes.DashboardDoDislike),
        mergeMap((action) =>
            this.dashboardService.doDislike(action.payload.user.id_usuario).pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        this.store.dispatch(new uiActions.SidebarGetVisitsSent());
                        this.store.dispatch(new uiActions.SidebarGetVisitsRcv());

                        return new dashboardActions.DashboardDoDisliked({user: action.payload.user});
                    } else {
                        return new dashboardActions.DashboardActionFail({user: action.payload.user});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardActionFail({user: action.payload.user})))
            )
        )
    );

    @Effect()
    cancelLike$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardDoUndoLike>(dashboardActions.DashboardActionTypes.DashboardDoUndoLike),
        mergeMap((action) =>
            this.dashboardService.cancelLike(action.payload.user.id_usuario).pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        return new dashboardActions.DashboardDoUndoLiked({user: action.payload.user});
                    } else {
                        return new dashboardActions.DashboardActionFail({user: action.payload.user});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardActionFail({user: action.payload.user})))
            )
        )
    );


    @Effect()
    searchOptions$ = this.actions$.pipe(
        ofType<dashboardActions.DashboardSearchOptions>(dashboardActions.DashboardActionTypes.DashboardSearchOptions),
        mergeMap((action) =>
            this.dashboardService.searchOptions().pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        return new dashboardActions.DashboardSearchOptionsSuccess({options: results.data});
                    } else {
                        return new dashboardActions.DashboardSearchOptionsSuccess({options: []});
                    }
                }),
                catchError(err => of(new dashboardActions.DashboardSearchOptionsSuccess({options: []})))
            )
        )
    );

}
