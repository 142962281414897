export const environment = {
    production: true,
    recordarLoginKey: 'recordarLogin',
    accessTokenKey: 'accesstoken',
    recoveryTokenKey: 'recoverytoken',
    whitelistedDomains: ['66.97.38.223:8080', 'apigatest.zonacitas.com', 'apiga.zonacitas.com','api.ga.local.zonacitas.com:8080'],
    blacklistedRoutes: [],
	modalesPostRegistro: 'post-register-modals',
	juegoLikes: 'juegoLikes',
    //facebookAppID: '908403015915417', DEV
    facebookAppID: '699437746811946',
    mercadoPagoID: 'db4614b2-07a6-42af-8495-a28dc58e7701',
	// mercadoPagoID: 'TEST-113119e6-0700-9263-e053-0f4910ac9c07',
    //appApi: 'https://apigatest.zonacitas.com/v1',
	//appApi_base: 'https://apigatest.zonacitas.com/',
	appApi: 'https://apiga.zonacitas.com/v1',
	appApi_base: 'https://apiga.zonacitas.com/',
	//appApi: 'http://api.ga.local.zonacitas.com:8080/v1',
	//appApi_base: 'http://api.ga.local.zonacitas.com:8080/',
    search_base: 'https://argentina.zonacitas.com/crossbrowser/',

    endpoints: {
        // Auth
        autologin: 'autologin',
        counters: 'counters',
        session: 'session',
		reloadsession: 'reloadsession',
        authenticate: 'authenticate',
        logout: 'logout',
        relog: 'relog',
        dislikes: 'user/dislikes',
        likes: 'user/likes',

        userlikedislike_base: 'user/',
        userlike_end: '/like',
        userlike_undo_end: '/undo-like',
        userdislike_end: '/dislike',
        userdislike_undo_end: '/undo-dislike',
		userjuegolikes: 'juegolikes',
		userrecomendados: 'v2/user/matching',
		usersetmodalscount: 'v2/user/postmodalcount',

        matches: 'user/matches',
        searchoptionsV2: 'v2/user/search-options',
        visits: 'user/visits',
        resetpassword: 'reset-password',
        regensession: 'regen-session',
        userreport: 'report',
        setpassword: 'set-password',

        //Dashboard
        dashboardlisting: 'dashboard/listing',
        dashboardscroll: 'dashboard/scroll',
        dashboardsearch: 'dashboard/search',

        //Messages
        messagesimagePermission: 'messages/imagePermission',
        messagesdelete: 'messages',
        messagesgetchat: 'messages',
        messageslist: 'messages/list',
        messagesmarkasread: 'messages',
        messagessend: 'messages',

        //Profile
        profiledeleteimage_v2: 'v2/profile/image',
        profilegetedit: 'profile/edit',
        profilegetedit_v2: 'v2/profile/edit',
        profiledataform: 'profile/data-form',
        profiledatasheet: 'profile/data-sheet',
        profiledescription: 'profile/description',
        profileimageupdate: 'profile/image',
        profileimagedefault_v2: 'v2/profile/image',
        profileview: 'profile',
        profiletagsearch: 'v2/profile/tags',

        //Account
        accountcancelrenewalstep1: 'account/renewal/1',
        accountcancelrenewalstep2: 'account/renewal/2',
        accountcancelrenewalstep3: 'account/renewal/3',
        accountagerange: 'account/age-range',
        accountnotifications: 'account/notifications',
        accountchangepassword: 'account/password',
        accountgetdata: 'account',
        accountdelete: 'account',
        accountRefund: 'user/refund',

        //Notifications
        accountfcm: 'account/fcm',
        pusherauthBase: 'https://argentina.zonacitas.com/',
        pusherauth: 'pusher/auth',
        accountwebpush: 'account/webpush',

        //Payments
        paymentmp: 'payment/mp',

        //Sidebar
        sidebar: 'v2/sidebar',

        //Paywall
        paywallget: 'v2/paywall',
        mercadopago: 'payment/mp',

		// Ab test Paywall
		abtesttracker: 'v2/abtracker/desktop',

        //Register
        register: 'register',

        //Social
        facebookconnect: 'facebook/connect',

		// validate captcha
		captcha: 'captcha',

		// Conversion Funnel
		conversionfunnel: 'v2/user/conversionfunnel',

		// Mobbex
		mobbexCheckout: 'v1/payment/mobbex/checkout',
		mobbexSetSubscriber: 'v1/payment/mobbex/createSubscriber'

    },
    pusher: {
        key: 'f9e49925c915bca98363',
        debug: false,
        cluster: 'PUSHER_CLUSTER',
        chatChannelPrefix: 'presence-chat-',
        notifChannelPrefix: 'presence-notifications-'
    }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
