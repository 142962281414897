import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IntProfileV2Data, IntProfileV2DataQuestionData} from '../../store/user/user.model';
import {UserProfileField} from '../../store/user/user.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';

@Component({
    selector: 'app-profile-selectfield',
    templateUrl: './selectfield.component.html',
    styleUrls: ['./selectfield.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectfieldComponent {

    @Input() profileobj: IntProfileV2Data;
    @Input() mainKey: 'personal' | 'partner';
    @Input() sectionIx: number;
    @Input() listIx: number;
    @Input() id: string;
    @Input() mandatory: boolean = false;

    constructor(
        private store: Store<fromRoot.State>
    ) {
    }

    checkSelected() {
        return this.profileobj[this.mainKey][this.sectionIx].list[this.listIx].selectedValues ? this.profileobj[this.mainKey][this.sectionIx].list[this.listIx].selectedValues.length > 0 : false;
    }

    updateField() {
        if(!this.profileobj[this.mainKey][this.sectionIx].list[this.listIx].selectedValues){
            this.profileobj[this.mainKey][this.sectionIx].list[this.listIx].selectedValues = null
        }

        const f: IntProfileV2DataQuestionData = this.profileobj[this.mainKey][this.sectionIx].list[this.listIx];
        this.store.dispatch(new UserProfileField({f: f, mainKey: this.mainKey, sectionIx: this.sectionIx, listIx: this.listIx}));
    }
}
