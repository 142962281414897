import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError,
    map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {GustaronService} from './gustaron.service';
import * as GustaronActions from './gustaron.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';

/**
 * Effects file is for isolating and managing side effects of the application in one place
 * Http requests, Sockets, Routing, LocalStorage, etc
 */

@Injectable()
export class GustaronEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private gustaronService: GustaronService,
    ) {
    }

    @Effect()
    initListing$ = this.actions$.pipe(
        ofType<GustaronActions.GustaronInitSearch>(GustaronActions.GustaronActionTypes.GustaronInitSearch),
        mergeMap((action) =>
            this.gustaronService.listing(action.payload.type).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new GustaronActions.GustaronDoSearch({type: action.payload.type, results: results});
                    } else {
                        return new GustaronActions.GustaronFail({results: results});
                    }
                }),
                catchError(err => of(new GustaronActions.GustaronFail({results: err})))
            )
        )
    );

    @Effect()
    initScroll$ = this.actions$.pipe(
        ofType<GustaronActions.GustaronInitScroll>(GustaronActions.GustaronActionTypes.GustaronInitScroll),
        withLatestFrom(this.store),
        mergeMap((params) => {
            return this.gustaronService.scroll( params[0].payload.type, params[1].gustaron.currentPage ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new GustaronActions.GustaronDoScroll({type: params[0].payload.type, results: results});
                    } else {
                        return new GustaronActions.GustaronFail({results: results});
                    }
                }),
                catchError(err => of(new GustaronActions.GustaronFail({results: err})))
            );
        })
    );


    @Effect()
    cancelLike$ = this.actions$.pipe(
        ofType<GustaronActions.GustaronCancel>(GustaronActions.GustaronActionTypes.GustaronCancel),
        mergeMap((action) =>
            this.gustaronService.cancelLike(action.payload.user).pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        return new GustaronActions.GustaronCanceled({user: action.payload.user});
                    } else {
                        return new GustaronActions.GustaronFail({results: results});
                    }
                }),
                catchError(err => of(new GustaronActions.GustaronFail({results: err})))
            )
        )
    );
}
