import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {async, Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {
	IntConversionFunnel,
	IntLoginData,
	IntPaywallAbono,
	IntPaywallCCPost,
	IntPaywallCCReplyFail,
	IntPaywallData,
	IntPaywallTicketReply
} from '../../store/user/user.model';
import {LogInReLog, Paywallget, SetConversionFunnel, UserProfileV2, UserReloadSession} from '../../store/user/user.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../store/user/users.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {GaService} from '../../services/ga.service';
import { MatDialog } from '@angular/material';
import { TicketComponent } from './ticket.component';
import {FormControl} from '@angular/forms';
import {PaymentCCInfo, PaymentFails, PaymentLoading, PaymentSuccess, ZendeskShow} from '../../store/ui/ui.actions';
import {ToastrService} from 'ngx-toastr';

declare var MercadoPago: any;

@Component({
    selector: 'app-suscribite',
    templateUrl: './suscribite.component.html',
    styleUrls: ['./suscribite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuscribiteComponent implements OnInit, OnDestroy {

    @ViewChild('paymentcheckoutdata') paymentcheckoutdata: ElementRef;

    paywall: any = null;
    session: IntLoginData = null;
    loading = true;
    loadingPago = true;
    pagoError = '';
    fromPromo: boolean = false;
	showDiscount:boolean = true;
	arrSimplePlan: any = ['800','803'];

    subs: Subscription = null;
    subsProfile: Subscription = null;
    subsLoad: Subscription = null;
    routeSub: Subscription = null;

    abonosReverse: any = null;

	mp: any = null;

    // Selected plan
    enumListType: any = {
        CREDIT_CARD: "credit_card",
        OTHER: "other",
    };
    selectedAbono: IntPaywallAbono = null;
    activeTab: string = this.enumListType.CREDIT_CARD;
    showSecurityCodeAmex: boolean = false;
	showSecurityCodeOthers: boolean = false;

    ccinfo = {
        cardholderName: null,
        cardNumber: null,
        cardExpirationMonth: '',
        cardExpirationYear: '',
        securityCode: null,
        docNumber: null,
        payerEmail: null
    };

    ccPaymentFinished = false;
    ccMPInvalid = false;
    ccMPID = '';
    ccMPPmtType = '';
    ccMPName = '';
    ccMPCodSeg = '';
	ccMPURLImg = '';
	ccMPIssuerBank = '';
	showLegals3dx3p:boolean = true;

    offlinePaymentFinished = false;
    loadingOfflinePay = false;
    offPayBrand: any;
    offPayBrandCode: any;
    offPayCodeOne: any;
    offPayCodeTwo: any;
    offPayLink: any;
    offPayEmail: any;
    offPayMethods = {
        'RP': 'RapiPago',
        'PF': 'Pago Fácil',
    };
    offPayMethodsCodes = {
        'RP': 'rapipago',
        'PF': 'pagofacil',
    };
    offPayMethodsGATrackCodes = {
        'RP': 'RapiPago',
        'PF': 'PagoFacil',
    };

	isPaymentLoading$: Observable<boolean> =  this.store.pipe(select(fromRoot.isPaymentLoading));
	isPaymentSuccess$: Observable<boolean> =  this.store.pipe(select(fromRoot.isPaymentSuccess));
	paymentUrlImg$: Observable<string> = this.store.pipe(select(fromRoot.paymentUrlImg));
	paymentBank$: Observable<string> = this.store.pipe(select(fromRoot.paymentBank));
	isPremiun$: Observable<boolean> = this.store.pipe(select(fromRoot.isPremiun));
	paymentType$: Observable<string> = this.store.pipe(select(fromRoot.paymentType));
	isPaymentFails$: Observable<number> =  this.store.pipe(select(fromRoot.isPaymentFails));
	paymentFailsMsg$: Observable<string> =  this.store.pipe(select(fromRoot.paymentFailsMsg));

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private userService: UsersService,
        private utils: ZcUtilsService,
        private route: ActivatedRoute,
        private router: Router,
		public dialog: MatDialog,
        private ga: GaService,
        private modalService: NgbModal,
		private toastr: ToastrService
    ) {
		//
		let promo = '';
		let nick = '';
		let avatar = '';
		let idabono = '';
		//
		promo = this.route.snapshot.params['promo'];
		nick = this.route.snapshot.params['nick'];
		avatar = this.route.snapshot.params['avatar'];
		idabono = this.route.snapshot.queryParamMap.get('idabono');
		if(this.route.snapshot.queryParamMap.get('showLegals3dx3p')){
			this.showLegals3dx3p = false;
		}

		console.log(promo);
		console.log(idabono);

		// Conversion funnel
		let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
		if(promo === undefined || idabono === null){
			dataConversionFunnel.step = 1;
			this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
		}
		//
		this.store.dispatch(new Paywallget( { promo: promo, nick: nick, avatar: avatar, idabono: idabono } ));
		//
        this.subsLoad = this.store.pipe(select(fromRoot.getUserPaywallLoading)).subscribe(loading => {
            this.loading = loading;
            this.cd.markForCheck();
        });
		//
        this.subsProfile = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
            this.session = session;
            this.offPayEmail = session && session.sessionInfo ? session.sessionInfo.me.email : '';
            this.cd.markForCheck();
        });
    	//
	}

    ngOnInit() {
		// hide zendesk
		this.store.dispatch(new ZendeskShow(false));
		// Load Paywall data
		this.subs = this.store.pipe(select(fromRoot.getUserPaywall)).subscribe(retPaywall => {
			this.paywall = retPaywall;
			this.cd.markForCheck();

			// Get param from URL
			if(retPaywall !== null){
				// Get abonos reverse
				this.abonosReverse = this.paywall.abonosReverse;

				// Track GA
				this.paywall.data.abonos.forEach((abono) => {
					const event = {
						'ecommerce': {
							'currencyCode': 'ARS',
							'impressions': [
								{
									'name': abono.descripcion_externa,
									'id': abono.idabono,
									'price': (abono.totalvalor + (abono.totaldecimal / 100)),
									'variant': abono.tipoPago,
									'list': abono.tipoPago,
									'position': abono.posicion
								}
							]
						}
					};
					this.ga.gaTrack(event);
				});

				// check if PROMO
				if(this.paywall.data.abonos.length == 1) {
					this.selectAbono(this.paywall.data.abonos[0]);
				}
			}
		});
		// init MP
		this.mp = new MercadoPago(environment.mercadoPagoID, {
			locale: 'es-AR',
		})
		//
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsProfile !== null) {
            this.subsProfile.unsubscribe();
        }
        if (this.subsLoad !== null) {
            this.subsLoad.unsubscribe();
        }
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
    }

    openMasInfo(modalContent) {
        this.modalService.open(modalContent, {centered: true, size: 'lg'});
    }

    initCCInfo() {
        this.ccPaymentFinished = false;
        this.ccMPInvalid = false;
        this.ccMPID = '';
        this.ccMPPmtType = '';
        this.ccMPName = '';
        this.ccMPCodSeg = '';

        this.ccinfo = {
            cardholderName: null,
            cardNumber: null,
            cardExpirationMonth: '',
            cardExpirationYear: '',
            securityCode: null,
            docNumber: null,
            payerEmail: this.session.sessionInfo.me.email
        };
        this.pagoError = '';
        this.loadingPago = false;
    }

    selectAbono(a: IntPaywallAbono) {
		window.scroll(0,0);
		this.initCCInfo();
		this.selectedAbono = a;

		this.showDiscount = this.arrSimplePlan.includes(this.selectedAbono.idabono);

		// Conversion funnel
		let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
		dataConversionFunnel.step = 2;
		dataConversionFunnel.idabono = this.selectedAbono.idabono;
		this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));

		const event = {
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': a.tipoPago,
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'list': a.tipoPago,
						'position': a.posicion
					}]
				},
			}
		};
		this.ga.gaTrack(event);

		const eventAdd = {
			'event': 'addToCart',
			'ecommerce': {
				'currencyCode': 'ARS',
				'add': {
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'quantity': 1
					}]
				},
			}
		};
		this.ga.gaTrack(eventAdd);

		const eventOut = {
			'event': 'checkout',
			'ecommerce': {
				'checkout': {
					'actionField': {'step': 1, 'option': 'MercadoPago'},
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'quantity': 1
					}]
				},
			}
		};
		this.ga.gaTrack(eventOut);
	}

    selectOffpay(pf: string) {

        //this.loadingOfflinePay = true;
        this.offPayBrand = this.offPayMethods[pf];
        this.offPayBrandCode = pf;

		try{
			this.userService.getTicketCode( this.selectedAbono.idabono, this.offPayMethodsCodes[pf] )
				.subscribe( (ret: IntPaywallTicketReply) => {
					const code = ret.payment_code.toString();
					this.offPayCodeOne = code.substring(0, 5);
					this.offPayCodeTwo = code.substring(5);
					this.offPayLink = ret.payment_url;
					// open result
					this.dialog.open(TicketComponent, {
						width: '535px',
						height: '578px',
						disableClose: false,
						data: {
							payment_code: pf,
							payment_method: this.offPayMethods[pf],
							payment_code_one: this.offPayCodeOne,
							payment_code_two: this.offPayCodeTwo,
							payment_ticket_url: this.offPayLink
						}
					});
					//
					const event = {
						'event': 'checkoutOption',
						'ecommerce': {
							'checkout_option': {
								'actionField': {'step': 1, 'option': this.offPayMethodsGATrackCodes[pf]}
							}
						}
					};
					this.ga.gaTrack(event);
					// Re log app
					this.store.dispatch(new LogInReLog({'recovery_token': sessionStorage.getItem(environment.recoveryTokenKey)}))
				});
		}catch(error){
			this.offPayBrand = '';
			this.offPayBrandCode = '';
			this.offPayCodeOne = '';
			this.offPayCodeTwo = '';
			this.offPayLink = '';
			this.loadingOfflinePay = false;
			this.offlinePaymentFinished = false;
			this.cd.markForCheck();
		}
    }

    async ccchange() {
        this.ccMPInvalid = false;
        this.ccMPID = '';
        this.ccMPPmtType = '';
        this.ccMPName = '';
        this.ccMPCodSeg = '';
		this.ccMPURLImg = '';

        if (this.ccinfo.cardNumber === '') {
			this.ccMPURLImg = '';
			return;
        }

		try {
			const validTC = await this.mp.getPaymentMethods({bin:  this.ccinfo.cardNumber});
			if(validTC.results){
				this.ccMPPmtType 	= validTC.results[0].payment_type_id;
				this.ccMPID			= validTC.results[0].id;
				this.ccMPIssuerBank	= validTC.results[0].issuer.name;
				switch (this.ccMPID){
					case 'visa':
						this.ccMPURLImg = '/assets/images/suscribite/visa.png';
						break;
					case 'master':
						this.ccMPURLImg = '/assets/images/suscribite/master.png';
						break;
					case 'amex':
						this.ccMPURLImg = '/assets/images/suscribite/amex.png';
						break;
					default: this.ccMPURLImg = '';
				}
				this.store.dispatch(new PaymentCCInfo({imgUrl: this.ccMPURLImg, issuerBank: this.ccMPIssuerBank}));
			}
		}catch(error){
			this.store.dispatch(new PaymentCCInfo({imgUrl: '', issuerBank: ''}));
			this.ccMPInvalid = true;
		}
    }
	async pagar(form) {
		// Conversion funnel
		const dataConversionFunnel: IntConversionFunnel = {step: 0 , dsc: '', idabono: ''};
		dataConversionFunnel.idabono = this.selectedAbono.idabono;
		dataConversionFunnel.step = 3;
		this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
		// ui reset
		this.pagoError = '';
		this.store.dispatch(new PaymentLoading(true));
		// validate form
		if (form.status === 'INVALID') {
			Object.keys(form.controls).forEach(key => {
				form.controls[key].markAsTouched();
			});
			this.store.dispatch(new PaymentLoading(false));
			return;
		}
		// load cardtoken params
		const cardTokenParams = {
			cardNumber: this.ccinfo.cardNumber ,
			cardholderName: this.ccinfo.cardholderName,
			cardExpirationMonth: this.ccinfo.cardExpirationMonth,
			cardExpirationYear: this.ccinfo.cardExpirationYear,
			securityCode: this.ccinfo.securityCode
		}
		// try payment
		try{
			const cardToken1 = await this.mp.createCardToken(cardTokenParams);
			const cardToken2 = await this.mp.createCardToken(cardTokenParams);
			let ccMPCardToken1 =  cardToken1.id;
			let ccMPCardToken2 =  cardToken2.id;
			//
			// HACK: MP circuito nuevo
			let idPlanNew;
			let tipoPagoNew;
			idPlanNew     = this.selectedAbono.idabono;
			tipoPagoNew   = this.selectedAbono.tipoPago;
			//
			let ccPostData: IntPaywallCCPost = {
				cardToken1: ccMPCardToken1,
				cardToken2: ccMPCardToken2,
				idPlan: parseInt(idPlanNew, 10),
				idPlanRegular: parseInt(this.selectedAbono.idabonoregular, 10),
				idUser: 0,
				payerEmail: this.ccinfo.payerEmail,
				paymentMethod: this.ccMPID,
				tipoPago: tipoPagoNew,
				dni: this.ccinfo.docNumber
			};
			// call API
			this.userService.processMPPurchase(ccPostData).subscribe(
				(ret) => {
					if (ret.status && (ret.status === 201)) {
						this.store.dispatch(new PaymentLoading(false));

						// Pago completado exitosamente
						const event = {
							'ecommerce': {
								'purchase': {
									'actionField': {
										'id': ret.transaction_id,
										'price': (this.selectedAbono.totalvalor + (this.selectedAbono.totaldecimal / 100)),
									},
									'products': [{
										'name': this.selectedAbono.descripcion_externa,
										'id': this.selectedAbono.idabono,
										'price': (this.selectedAbono.totalvalor + (this.selectedAbono.totaldecimal / 100)),
										'variant': this.selectedAbono.tipoPago,
										'quantity': 1
									}]
								}
							}
						};
						this.ga.gaTrack(event);
						this.ga.triggerGenericEvent('evento', 'marketing', 'compra');
						
						// Conversion funnel
						let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
						dataConversionFunnel.step = 4;
						this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));

						// actuzalizar sessión
						this.store.dispatch(new UserReloadSession());
						this.store.dispatch(new UserProfileV2());
						this.store.dispatch(new PaymentSuccess(true));

					} else 	{
						// Conversion funnel
						let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
						dataConversionFunnel.step = 5;
						this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
						//
						this.pagoError = 'Error al intentar procesar el pago. ' + (ret.error || '');
						this.store.dispatch(new PaymentLoading(false));
					}
				},
				(err) => {
					// Conversion funnel
					let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
					dataConversionFunnel.step = 5;
					this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
					//
					if(err.status === 500){
						this.store.dispatch(new PaymentFails({error_nro: 500, message: 'Error al procesar tu pago'}));
					}else{
						this.store.dispatch(new PaymentFails({error_nro: 400, message: err.error.message}));
					}
					this.store.dispatch(new PaymentLoading(false));
				});
		}catch (error){
			// Conversion funnel
			let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
			dataConversionFunnel.step = 5;
			this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
			//
			this.store.dispatch(new PaymentLoading(false));
		}
	}

	onSecurityCode(show: boolean = false){
		//
		this.showSecurityCodeOthers = false;
		this.showSecurityCodeAmex = false;
		if(show){
			if(this.ccMPID === 'amex'){
				this.showSecurityCodeAmex = true;
			}else{
				this.showSecurityCodeOthers = true;
			}
		}
		//
	}

	onPaymentSuccessRedirect(){
		this.store.dispatch(new PaymentSuccess(false));
		this.router.navigateByUrl('/dashboard');
	}

}
