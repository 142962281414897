import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {Observable, Subscription} from 'rxjs';
import { IntPasswordResetData} from '../../store/user/user.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserResetpwd} from '../../store/user/user.actions';
import {SetIsStaticPage} from '../../store/ui/ui.actions';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoverComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('passInput') passInput: ElementRef;

    resetData = {
        password: '',
        password1: '',
        token: ''
    };

    formSubmitted = false;

    resetErrors = {
        password: '',
        password1: ''
    };

    resetResult$: Observable<string> = this.store.pipe(select(fromRoot.getUserResetMessage));
    resetProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserResetProcess));
    resetSuccess$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserResetSuccess));

    routeSub: Subscription = null;

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private route: ActivatedRoute,
        private router: Router
    ) {
		this.store.dispatch(new SetIsStaticPage(true ));
    }

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.resetData.token = this.route.snapshot.params['token'];
            }
        });
        this.resetData.token = this.route.snapshot.params['token'];
    }

    ngOnDestroy(): void {
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.passInput) {
                this.passInput.nativeElement.focus();
            }
        }, 250);
    }

    reset() {
        this.formSubmitted = true;
        if (this.checkForm()) {
            return;
        } else {
            const rd: IntPasswordResetData = {
                newPassword: this.resetData.password,
                recoveryToken: this.resetData.token
            };

            this.store.dispatch(new UserResetpwd({credentials: rd}));
        }
    }

    checkForm() {
        if (!this.formSubmitted) {
            return false;
        }

        this.resetErrors = {
            password: '',
            password1: ''
        };

        let errors = false;

        if (this.utils.isUndefinedOrNull(this.resetData.password) || (this.resetData.password.trim() === '')) {
            errors = true;
            this.resetErrors.password = 'Ingresá tu contraseña';
        } else if (!this.utils.validatePasswordLength(this.resetData.password)) {
            errors = true;
            this.resetErrors.password = 'Ingresá al menos 6 caracteres para tu contraseña';
        } else if (!this.utils.validatePasswordChars(this.resetData.password)) {
            errors = true;
            this.resetErrors.password = 'Ingresá solo letras, números y caracteres especiales comunes para tu contraseña';
        } else if (this.resetData.password !== this.resetData.password1) {
            errors = true;
            this.resetErrors.password1 = 'Tu nueva contraseña y su confirmación no coinciden';
        }

        return errors;
    }

	goHome(){
		this.router.navigateByUrl('/dashboard');
	}

}
