import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {IntSearchOptionValueLabel, IntValueLabelOption} from '../store/dashboard/dashboard.model';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ZcUtilsService {

    constructor(private http: HttpClient) {}

	public usuariosZC: any = [3107002, 3107007, 1444080, 1449424];

    public motivosDenuncia: IntValueLabelOption[] = [
        {v: 0, l: ''},
        {v: 1, l: 'Estafador'},
        {v: 2, l: 'Mentiroso'},
        {v: 3, l: 'Lenguaje inadecuado'},
        {v: 4, l: 'Discriminación'},
        {v: 5, l: 'Comercial'},
        {v: 6, l: 'Prostitución'},
        {v: 7, l: 'Pornografía'},
        {v: 8, l: 'Acosador'},
        {v: 9, l: 'Pervertido'},
        {v: 10, l: 'Otro'},
        {v: 11, l: 'Texto ofensivo'},
        {v: 12, l: 'Imagen inadecuada'},
    ];
    public motivosDenunciaShort: IntValueLabelOption[] = [
        {v: 11, l: 'Me escribió un texto ofensivo'},
        {v: 12, l: 'Me envió una foto inadecuada'},
        {v: 13, l: 'Perfil falso'},
        {v: 99, l: 'Otro tipo de denuncia'},
    ];


    validateEmail(email: string): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validatePasswordLength(pass: string): boolean {
        return pass.length >= 6;
    }

    validatePasswordChars(pass: string): boolean {
        const re = /([a-zA-Z0-9\.\_\-\$\^\!\~\@\#\%\\\/\&\*\(\)\,\;\:\'\"\[\]\{\}\+\|]+)$/;
        return re.test(pass);
    }

    validateCaptchaGoogle(token: string){
    	//
		return this.http.get<any>(`${environment.appApi}/${environment.endpoints.captcha}/`+token);
		//
	}

    isUndefinedOrNull(s: any): boolean {
        return ((typeof s === 'undefined') || (s === null) || (s === '') || (s === 'null'));
    }

    /**
     * Generates a temporal ID number (Date * random number)
     */
    generateTemporalID(): number {
        return Math.ceil(Date.now() * Math.random());
    }

    getMessageTimestamp(timestamp: string): string {
        if (this.isUndefinedOrNull(timestamp)) {
            return '';
        }
        return moment(timestamp).format('DD/MM/YYYY HH:mm');
    }

    getMessageHour(timestamp: string): string {
        if (this.isUndefinedOrNull(timestamp)) {
            return '';
        }
        return moment(timestamp).format('HH:mm');
    }

    /**
     * Devuelve la versión 80x80 de la foto
     * @param genderId
     * @param gcpImage
     */
    getUserThumbURL(genderId: string, gcpImage: string): string {
        if (this.isUndefinedOrNull(gcpImage)) {
            // return '/assets/images/' + (genderId === '1' ? 'foto_error_hombre_180.png' : 'foto_error_mujer_180.png');
            return '/assets/images/foto_error_generico.png';
        } else if (gcpImage.startsWith('https://')) {
            // for cases the full image URL is provided
            return gcpImage;
        } else {
            return 'https://thumbork8s.zonacitas.com/unsafe/80x80/smart/users/' + gcpImage;
        }
    }

    /**
     * Devuelve la versión 320x320 de la foto
     * @param genderId
     * @param gcpImage
     */
    getUserImageURL(genderId: string, gcpImage: string, profile?: boolean): string {
    	if (this.isUndefinedOrNull(gcpImage)) {
            // return '/assets/images/' + (genderId === '1' ? 'foto_error_hombre_180.png' : 'foto_error_mujer_180.png');
            return '/assets/images/foto_error_generico.png';
        } else if (gcpImage.startsWith('https://')) {
            // for cases the full image URL is provided
            return gcpImage;
        } else {
    		let retUrl = 'https://thumbork8s.zonacitas.com/unsafe/320x320/smart/users/';
    		if(profile) {
				retUrl = 'https://thumbork8s.zonacitas.com/unsafe/320x250/smart/users/';
			}
            return retUrl + gcpImage;
        }
    }

    /**
     * Devuelve el nombre de la imagen, con gender fallback
     * @param genderId
     * @param gcpImage
     */
    getUserImageName(genderId: string, gcpImage: string): string {
        if (this.isUndefinedOrNull(gcpImage)) {
            // return '/assets/images/' + (genderId === '1' ? 'foto_error_hombre_180.png' : 'foto_error_mujer_180.png');
            return '/assets/images/foto_error_generico.png';
        } else {
            return gcpImage;
        }
    }

	/**
	 * Devuelve la imagen para el paywall
	 * @param genderId
	 * @param gcpImage
	 */
	getUserImageForPaywall(genderId: string, gcpImage: string): string {
		if (this.isUndefinedOrNull(gcpImage)) {
			return 'foto_error_hombre_180.png';
		} else {
			return gcpImage;
		}
	}

    /** Devuelve la foto para el juego de likes */
    getJuegoLikePhoto(image: string): string {
		if (this.isUndefinedOrNull(image)) {
			return '/assets/images/foto_error_generico.png';
		} else {
			const _image: string =  'https://thumbork8s.zonacitas.com/unsafe/300x250/smart/users/' + image;
			return _image;
		}
	}

    /**
     * Devuelve la versión 320x320 de la foto (sin gender fallback)
     * @param genderId
     * @param gcpImage
     */
    getGCPImageURL(gcpImage: string, profileView?: boolean): string {
        if (this.isUndefinedOrNull(gcpImage)) {
			return '/assets/images/foto_error_generico.png';
        } else if ((gcpImage === 'foto_error_hombre_180.png') || (gcpImage === 'foto_error_mujer_180.png')) {
            // return '/assets/images/' + gcpImage;
            return '/assets/images/foto_error_generico.png';
        } else if (gcpImage.startsWith('https://')) {
            // for cases the full image URL is provided
            return gcpImage;
        } else {
			let retUrl = 'https://thumbork8s.zonacitas.com/unsafe/320x320/smart/users/';
			if(profileView) {
				retUrl = 'https://thumbork8s.zonacitas.com/unsafe/260x260/smart/users/';
			}
			return retUrl + gcpImage;
        }
    }

    /**
     * Devuelve la versión full de la foto (sin gender fallback)
     * @param genderId
     * @param gcpImage
     */
    getGCPFullImageURL(gcpImage: string): string {
        if (this.isUndefinedOrNull(gcpImage)) {
            return '';
        } else if (gcpImage.startsWith('https://')) {
            // for cases the full image URL is provided
            return gcpImage;
        } else {
            return 'https://thumbork8s.zonacitas.com/unsafe/smart/users/' + gcpImage;
        }
    }

    getMultipleOptionsLabel(values: IntSearchOptionValueLabel[], selected: number | string | Array<string | number>): string {
        let ret = 'Indiferente';

        if (this.isUndefinedOrNull(selected)) {
            return ret;
        } else if (selected === '') {
            return ret;
        } else if (Array.isArray(selected) && (selected.length === 0)) {
            return ret;
        } else if (Array.isArray(selected) && (selected.length === 1)) {
            const tmp = values.filter(v => v.value === selected[0]);
            ret = (Array.isArray(tmp) && tmp[0]) ? tmp[0].label : '-';
        } else if (Array.isArray(selected) && (selected.length > 1)) {
            ret = selected.length + ' seleccionados';
        }

        return ret;
    }

    getNoticeURL(id: any) {
		let url;
		switch (id) {
			case '6':
				url = '/suscribite/27';
				break;
			case '11':
				url = '/suscribite/28';
				break;
			case '12':
				url = '/suscribite/28';
				break;
			case '13':
				url = '/suscribite/29';
				break;
			case '14':
				url = '/suscribite/30';
				break;
			case '15':
				url = '/suscribite/30';
				break;
			case '16':
				url = '/suscribite/31';
				break;
			case '17':
				url = '/suscribite/31';
				break;
			case '18':
				url = '/suscribite/32';
				break;
			case '19':
				url = '/suscribite/32';
				break;
			case '23':
				url = '/suscribite/32';
				break;
			default:
				url = '/suscribite/'+id;
				break;
		}
    	return url;
	}

	checkZCUsers(userId: any){
    	return this.usuariosZC.includes(userId);
	}

}
