import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {IntNoGustaronListingResult, IntNoGustaronListingResults} from '../../store/nogustaron/nogustaron.model';
import {NoGustaronCancel, NoGustaronInitScroll, NoGustaronInitSearch} from '../../store/nogustaron/nogustaron.actions';
import {ProfileOpen} from '../../store/profile/profile.actions';

@Component({
    selector: 'app-nomegustan',
    templateUrl: './nomegustan.component.html',
    styleUrls: ['./nomegustan.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('EnterLeave', [
            transition(':enter', [
                style({ transform: 'scaleY(0)' }),
                animate('150ms ease', style({ transform: 'scaleY(1)' }))
            ]),
            transition(':leave', [
                animate('75ms ease', style({ transform: 'scaleY(0)', opacity: '0' }))
            ])
        ])
    ]
})
export class NomegustanComponent implements OnInit {

    results$: Observable<IntNoGustaronListingResults> = this.store.pipe(select(fromRoot.getNoGustaronResults));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getNoGustaronInProcess));

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnInit() {
        this.search();
    }

    search() {
        this.store.dispatch(new NoGustaronInitSearch());
    }

    triggerScroll() {
        this.store.dispatch(new NoGustaronInitScroll());
    }

    cancelDislike(r: IntNoGustaronListingResult) {
        this.store.dispatch(new NoGustaronCancel({user: r}));
    }

    viewProfile(userId: string) {
        this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: 'sent', source: 'nomegustan'}));
    }
}
