import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

export interface IntTagSearchReply {
    'status': number;
    'error'?: string;
    'data': [
        {
            value: string;
            type: string;
            image: string | null;
        }
        ];
}


@Injectable({
    providedIn: 'root'
})
export class TagSearchService {

    constructor(
        private http: HttpClient,
    ) {
    }


    /**
     * Get tags search
     * @param type tipo de tag a buscar
     */
    doSearch(type: 'movies' | 'books' | 'music', term: string): Observable<IntTagSearchReply> {
        return this.http
        .get<IntTagSearchReply>(`${environment.appApi_base}${environment.endpoints.profiletagsearch}/${type}?q=` + encodeURIComponent(term));
    }
}
