import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CropperComponent, IntCropperImagesResult} from '../../components/cropper/cropper.component';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {
    IntFacebookGalleryAlbums, IntFacebookGalleryPicture,
    IntFacebookLoginData,
    IntLoginData,
    IntProfileNewImage,
    IntProfileV2Data,
    IntProfileV2GcpImage,
} from '../../store/user/user.model';
import {Observable, Subscription} from 'rxjs';
import {BodyScrollLock, BodyScrollUnLock} from '../../store/ui/ui.actions';
import {
    AttachFacebookToken,
    ProfileDeleteImage,
    ProfileNewImage, ProfileNewImageFromFacebook,
    ProfileSetImage,
    UserProfileApodoLocalidad
} from '../../store/user/user.actions';
import { MatDialogRef} from '@angular/material';
import { IntCiudadData, LocalidadesService } from 'src/app/services/localidades.service';
import {zcDeepCopy} from '../../services/zc.globals';

declare var FB: any;
declare var $: any;

const ranges: any = ['\ud83c[\udf00-\udfff]', '\ud83d[\udc00-\ude4f]','\ud83d[\ude80-\udeff]'];

@Component({
    selector: 'app-editprofile',
    templateUrl: './editprofile.component.html',
    styleUrls: ['./editprofile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

    @ViewChild('appcropper', { static: true }) cropper: CropperComponent;

    MAX_IMAGES = 12;

    profileobj: IntProfileV2Data = null;
    subsPro: Subscription = null;

    showUploadOptions: boolean = false

    ciudades: IntCiudadData[] = [];
    localidades: IntCiudadData[] = [];

    addingValues: any = {
        Apodo: '',
        Localidad: '',
        Provincia: '',
    };

    // profileImages: IntGalleryPicture[] = [];
    // profileImagesSub: Subscription = null;
    sessionSubs: Subscription = null;
    profileObj$: Observable<IntProfileV2Data> = this.store.pipe(select(fromRoot.getCurrentProfileV2));
    // isGalleryOpen = false;
    importingFromFacebook = false;
    connectingToFacebook = false;
    // galleryStartIndex = 0;

    selectedImages: IntProfileV2GcpImage[] = [];
    selectedImagesFacebook: IntFacebookGalleryPicture[] = [];

    facebookAlbums: IntFacebookGalleryAlbums = null;

    sessionData: IntLoginData = null;

    @Output() close: EventEmitter<void> = new EventEmitter();

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private localidadesService: LocalidadesService,
        private utils: ZcUtilsService,
        private dialogRef: MatDialogRef<EditProfileComponent>,
    ) {

        this.subsPro = this.store.pipe(select(fromRoot.getCurrentProfileV2)).subscribe(profileobj => {
            this.profileobj = zcDeepCopy(profileobj);   // copy user object to edit without modify the main object

            // Personal data
            this.addingValues.Apodo = this.profileobj.moderationUsername.username || this.profileobj.username || '';
            this.addingValues.Localidad = parseInt(this.profileobj.location.cityId, 10) || null;
            this.addingValues.Provincia = parseInt(this.profileobj.location.provinceId, 10) || null;

            this.localidades = this.localidadesService.getLocalidades();
            this.ciudades = this.localidadesService.getCiudad(parseInt(this.addingValues.Provincia, 10));

            this.cd.markForCheck();
        });

        // this.profileImagesSub = this.store.pipe(select(fromRoot.getCurrentProfileV2Pictures)).subscribe(images => {
        //     this.profileImages = images;
        //     this.profileImages.forEach(i => {
        //         i.thumb = this.utils.getUserImageURL('1', i.thumb);
        //         i.image = this.utils.getGCPFullImageURL(i.image);
        //     });

        //     // este observable es disparado cuando hubo importación de facebook también, cerrar la importación desde face
        //     if (this.facebookAlbums !== null) {
        //         this.selectedImagesFacebook = [];
        //         this.importingFromFacebook = false;
        //         this.connectingToFacebook = false;
        //         this.facebookAlbums = null;
        //     }
        // });

        this.sessionSubs = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe((sessionData: IntLoginData) => {
            this.sessionData = sessionData;
        });
    }

    ngOnInit() {
        this.store.dispatch(new BodyScrollLock());
        this.dialogRef.afterClosed().subscribe(() => this.store.dispatch(new BodyScrollUnLock()));
    }

    ngOnDestroy() {
        // if (this.profileImagesSub !== null) {
        //     this.profileImagesSub.unsubscribe();
        // }
        if (this.sessionSubs !== null) {
            this.sessionSubs.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        $('#carousel-container').caroufredsel({
            responsive: true,
            circular: true,
            infinite: true,
            items: {
                visible: {
                    min: 1,
                    max: 1
                }
            },
			pagination: {
                container: "#foo2_pag",
                items: 1
            },
            scroll: 1,
            auto: {
                play: true
            }
        });
    }

    updateCiudades() {
        this.addingValues.Localidad = '';
        this.ciudades = this.localidadesService.getCiudad(parseInt(this.addingValues.Provincia, 10));
    }

    confirmChanges(){
        // Validation
        if ((this.addingValues.Apodo === '') || (!this.addingValues.Localidad) || (!this.addingValues.Provincia)) {
            return;
        }

        this.store.dispatch(new UserProfileApodoLocalidad({
            apodo: this.removeEmojis(this.addingValues.Apodo),
			idLocalidad: parseInt(this.addingValues.Localidad, 10),
            idProvincia: parseInt(this.addingValues.Provincia, 10)
        }));

		this.dialogRef.close();
    }

    setDefaultImage(image) {
        this.store.dispatch(new ProfileSetImage({image}));
    }

    deleteImage(image) {
        this.store.dispatch(new ProfileDeleteImage({image}));
    }

    sendImage() {
    	this.cropper.initializeCropper();
    }
    croppedImage(e: IntCropperImagesResult) {
        if (e !== null) {
            this.doUploadImage(e);
        }
    }
    doUploadImage(f: IntCropperImagesResult) {
        const img: IntProfileNewImage = {
            img: f.cropped
        };
        this.store.dispatch(new ProfileNewImage({image: img}));
        this.showUploadOptions = false;
    }

	removeEmojis(data){
		return data.replace(new RegExp(ranges.join('|'), 'g'), '');
	}

    // doClose() {
    //     this.store.dispatch(new BodyScrollUnLock());
    //     // this.close.emit();
    // }


    // doPress(i: IntProfileV2GcpImage) {
    //     if (!i.selected) {
    //         this.selectedImages.push(i);
    //         i.selected = true;
    //     }
    // }

    // checkChange(e: MatCheckboxChange, i: IntProfileV2GcpImage) {
    //     i.selected = e.checked;
    //     if (e.checked) {
    //         this.selectedImages.push(i);
    //     } else {
    //         const ix = this.selectedImages.findIndex(e => {
    //             return e.imageName === i.imageName;
    //         });
    //         if (ix >= 0) {
    //             this.selectedImages.splice(ix, 1);
    //         }
    //     }
    // }

    // handleTap(i: IntProfileV2GcpImage, index: number) {
    //     if (i.selected) {
    //         const ix = this.selectedImages.findIndex(e => {
    //             return e.imageName === i.imageName;
    //         });private dialogRef: MatDialogRef<EditProfileComponent>,

    //         if (ix >= 0) {
    //             this.selectedImages.splice(ix, 1);
    //         }

    //         i.selected = false;
    //     } else if (this.selectedImages.length > 0) {
    //         // only select image if there were previously another one selected
    //         this.selectedImages.push(i);
    //         i.selected = true;
    //     } else {
    //         // open image gallery
    //         this.galleryStartIndex = index;
    //         this.isGalleryOpen = true;
    //     }
    // }


    // closeGallery() {
    //     this.isGalleryOpen = false;
    // }

    importFacebook() {
        this.connectingToFacebook = true;
        const token = (this.sessionData !== null) && this.sessionData.longAccessToken ? this.sessionData.longAccessToken : '';
        const requestPath = '/me/albums?fields=id,name,photos.limit(999999){id,picture}&limit=999999&locale=es_LA' + '&access_token=' + token;
        FB.api(requestPath, 'get', (resAlbums: any) => {
            if (resAlbums.error) {
                FB.login((response) => {
                    if (response.authResponse && response.status) {

                        if (response.status === 'connected') {
                            const data: IntFacebookLoginData = {
                                accessToken: response.authResponse.accessToken,
                                signedRequest: response.authResponse.signedRequest,
                                userID: response.authResponse.userID
                            };

                            this.store.dispatch(new AttachFacebookToken({authData: data}));
                            setTimeout(() => this.importFacebook(), 150);
                        } else {
                            this.connectingToFacebook = false;
                            this.cd.markForCheck();
                        }

                    } else {
                        this.connectingToFacebook = false;
                        this.facebookAlbums = null;
                        this.selectedImagesFacebook = [];
                        this.cd.markForCheck();
                    }
                // }, {scope: 'email,public_profile,user_about_me,user_birthday,user_hometown,user_location,user_photos'});
                }, {scope: 'email,public_profile,user_birthday,user_hometown,user_location,user_photos'});

            } else if (resAlbums.data && resAlbums.data.length) {
                this.connectingToFacebook = false;
                this.facebookAlbums = resAlbums;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
				console.log(this.facebookAlbums);
            } else {
                this.connectingToFacebook = false;
                this.facebookAlbums = null;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
            }
        });
    }

    uploadFacebookImages() {
        const images = [];
        this.selectedImagesFacebook.forEach((e) => {
            if (e.selected) {
                images.push(e.picture);
            }
        });
        if (images.length) {
            this.importingFromFacebook = true;
            this.store.dispatch(new ProfileNewImageFromFacebook({images: images}));
            setTimeout(() => {this.doCloseFacebookAlbums()}, 750);
        }
    }

    doCloseFacebookAlbums() {
        this.connectingToFacebook = false;
        this.facebookAlbums = null;
    }

    selectFacebookImage(i: IntFacebookGalleryPicture) {
        // Check if 12 photos limit reached
        if(this.selectedImagesFacebook.length + this.profileobj.gcpImages.length >= 12){
            return false;
        }

        if (i.selected) {
            // Remove from selected
            const ix = this.selectedImagesFacebook.findIndex(e => {
                return e.picture === i.picture;
            });

            if (ix >= 0) {
                this.selectedImagesFacebook.splice(ix, 1);
            }

            i.selected = false;
        } else {
            // Select image
            this.selectedImagesFacebook.push(i);
            i.selected = true;
        }
    }

    // handleTapFacebook(i: IntFacebookGalleryPicture, index: number) {
    //     if (i.selected) {
    //         const ix = this.selectedImagesFacebook.findIndex(e => {
    //             return e.picture === i.picture;
    //         });

    //         if (ix >= 0) {
    //             this.selectedImagesFacebook.splice(ix, 1);
    //         }

    //         i.selected = false;
    //     } else {
    //         this.selectedImagesFacebook.push(i);
    //         i.selected = true;
    //     }
    // }

    // checkChangeFacebook(e: MatCheckboxChange, i: IntFacebookGalleryPicture) {
    //     i.selected = e.checked;
    //     if (e.checked) {
    //         this.selectedImagesFacebook.push(i);
    //     } else {
    //         const ix = this.selectedImagesFacebook.findIndex(e => {
    //             return e.picture === i.picture;
    //         });
    //         if (ix >= 0) {
    //             this.selectedImagesFacebook.splice(ix, 1);
    //         }
    //     }
    // }

	closeModal() {
		this.dialogRef.close();
	}

}
