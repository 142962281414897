import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntGustaronGetListing, IntGustaronListingResult, IntGustaronListingResults} from './gustaron.model';

@Injectable()
export class GustaronService {

    constructor(
        private http: HttpClient,
    ) {
    }


    public listing(listType: IntGustaronGetListing): Observable<IntGustaronListingResults> {
        return this.http
        .get<IntGustaronListingResults>(`${environment.appApi}/` + (listType === IntGustaronGetListing.GUSTARON_LIKES ? environment.endpoints.likes : environment.endpoints.matches));

    }

    /**
     * Scrolls
     * @param 0-based page number
     */
    public scroll(listType: IntGustaronGetListing, page: number): Observable<IntGustaronListingResults> {

        return this.http
        .get<IntGustaronListingResults>(`${environment.appApi}/` + (listType === IntGustaronGetListing.GUSTARON_LIKES ? environment.endpoints.likes : environment.endpoints.matches) + '?page=' + page);

    }

    public doLike(u: IntGustaronListingResult): Observable<IntGustaronListingResults> {

        return this.http
        .post<IntGustaronListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${u.userId}${environment.endpoints.userlike_end}`, {});

    }

    public cancelLike(u: IntGustaronListingResult): Observable<IntGustaronListingResults> {

        return this.http
        .post<IntGustaronListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${u.userId}${environment.endpoints.userlike_undo_end}`, {});

    }
}
