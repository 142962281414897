import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {SetIsStaticPage, ZendeskShow} from '../../store/ui/ui.actions';

@Component({
  selector: 'app-declaracion',
  templateUrl: './declaracion.component.html',
  styleUrls: ['./declaracion.component.scss']
})
export class DeclaracionComponent implements OnInit {

  constructor(
	  private store: Store<fromRoot.State>,
  ) {
	  this.store.dispatch(new ZendeskShow(false));
	  this.store.dispatch(new SetIsStaticPage(true));
  }

  ngOnInit() {
  }

}
