import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewChild,
    EventEmitter,
    Input
} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {IntProfileV2Data} from '../../store/user/user.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {IntUISidebarMensajesTab, IntUISidebarUserLinks, IntUISidebarVisitasTab} from '../../store/ui/ui.model';
import {SidebarSetMensajesTab, SidebarSetVisitasTab} from '../../store/ui/ui.actions';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {GaService} from '../../services/ga.service';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {
    IntDashboardGetListing,
    IntDashboardSearchParams,
    IntSearchOptionSection
} from '../../store/dashboard/dashboard.model';
import {DashboardInitSearch} from '../../store/dashboard/dashboard.actions';
import {BodyScrollUnLock} from '../../store/ui/ui.actions';
import {IntCiudadData, LocalidadesService} from '../../services/localidades.service';
import {Options} from 'ng5-slider';
import {MatDialog, MatDialogRef} from '@angular/material';
import {AdvancedSearchComponent} from '../advanced-search/advanced-search.component';
import {Router} from '@angular/router';
import {JuegoLikesComponent} from '../juego-likes/juego-likes.component';
import {GetJuegoLikes} from '../../store/user/user.actions';
import {zcDeepCopy} from '../../services/zc.globals';

@Component({
    selector: 'zc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('OpenClose', [
            transition(':enter', [
                style({height: '0'}),
                animate('150ms ease', style({height: '*'}))
            ]),
            transition(':leave', [
                animate('150ms ease', style({height: '0'}))
            ])
        ]),
        trigger('EnterLeave', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('250ms ease', style({opacity: '1'}))
            ]),
        ])
    ]
})
export class SidebarComponent implements OnInit, OnDestroy {

    @ViewChild('lastItem') lastItem: ElementRef;
    @ViewChild('affix1') affix1: ElementRef;
    @HostBinding('class.affixed') affixed: boolean = false;

    @Input() collapsedSearchBox: boolean = false;
    @Input() showBannerCaja1: boolean = false;
	@Input() showBannerCaja2: boolean = false;
    @Input() showVisits: boolean = true;
    @Input() showMessages: boolean = true;

    manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    ciudades: IntCiudadData[] = [];
    localidades: IntCiudadData[] = [];

    sliderDistanciaOptions: Options = {
        hidePointerLabels: true,
        hideLimitLabels: true,
        floor: 0,
        ceil: 100,
        step: 2
    };

    sliderEdadOptions: Options = {
        hidePointerLabels: true,
        hideLimitLabels: true,
        pushRange: true,
        floor: 18,
        ceil: 99,
        step: 1
    };

    visitasTabs = {
        UI_SIDE_VISITAS_RECIBIDAS: IntUISidebarVisitasTab.UI_SIDE_VISITAS_RECIBIDAS,
        UI_SIDE_VISITAS_REALIZADAS: IntUISidebarVisitasTab.UI_SIDE_VISITAS_REALIZADAS
    };
    msgTabs = {
        UI_SIDE_MSG_RECIBIDOS: IntUISidebarMensajesTab.UI_SIDE_MSG_RECIBIDOS,
        UI_SIDE_MSG_ENVIADOS: IntUISidebarMensajesTab.UI_SIDE_MSG_ENVIADOS
    };

    subs: Subscription = null;
    subSession: Subscription = null;
    imageUser: string = null;
    affix2Top: number = 0;

    profileData$: Observable<IntProfileV2Data> = this.store.pipe(select(fromRoot.getCurrentProfileV2));
    showProfilePorque: boolean = false;
    showProfileTips: boolean = false;

    notice: any;

    uiVisitas$: Observable<IntUISidebarVisitasTab> = this.store.pipe(select(fromRoot.getCurrentSidebarVisitas));
    uiMensajes$: Observable<IntUISidebarMensajesTab> = this.store.pipe(select(fromRoot.getCurrentSidebarMensajes));

	isLoadingSidebarVisits$: Observable<boolean> = this.store.pipe(select(fromRoot.isLoadingSidebarVisitas));
	isLoadingSidebarMsgs$: Observable<boolean> = this.store.pipe(select(fromRoot.isLoadingSidebarMsgs));

    sidebarMsgSent$: Observable<IntUISidebarUserLinks[]> = this.store.pipe(select(fromRoot.getSidebarMsgSent));
    sidebarMsgRcv$: Observable<IntUISidebarUserLinks[]> = this.store.pipe(select(fromRoot.getSidebarMsgRcv));
    sidebarVisitsSent$: Observable<IntUISidebarUserLinks[]> = this.store.pipe(select(fromRoot.getSidebarVisitsSent));
    sidebarVisitsRcv$: Observable<IntUISidebarUserLinks[]> = this.store.pipe(select(fromRoot.getSidebarVisitsRcv));

    // Search
    searchParams: IntDashboardSearchParams = {
        'id_provincia': '',
        'id_localidad': '',
        'distancia': '',
        'edad_min': '',
        'edad_max': '',
        'apodo': '',
        'estado_civil': '',
        'hijos': '',
        'religion': [],
        'busco': '',
        'altura': '',
        'estudios': '',
        'deseo_hijos': '',
        'fuma': '',
        'bebe': '',
        'signo': ''
    };

    search_edad_min: any;
    search_edad_max: any;

    searchOptions: IntSearchOptionSection[] = [];
    subsSearchOptions: Subscription = null;

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private ga: GaService,
        private localidadesService: LocalidadesService,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        private router: Router
    ) {

        // search options
        this.subsSearchOptions = this.store.pipe(select(fromRoot.getDashboardSearchOptions)).subscribe((options) => {
            this.searchOptions = options;
        });

        this.localidades = this.localidadesService.getLocalidades();
    }

    ngOnInit() {
        this.subSession = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe((session) => {
            if (session) {
                const sessMe = session.sessionInfo.me;
                this.imageUser = sessMe.gcpImage;
                //
				if(session.sessionInfo.notice != null) {
					this.notice = session.sessionInfo.notice.sector;
					if(this.notice['dashboard']){
						this.showBannerCaja1 = true;
					}
					if(this.notice['box2']){
						this.showBannerCaja2 = true;
					}
				}
            }
        });

        const source = fromEvent(window, 'scroll');

        this.subs = source.pipe(
            throttleTime(10)
        )
        .subscribe(
            x => {
                if (!this.affix1) {
                    return;
                }

                const af1rect = this.affix1.nativeElement.getBoundingClientRect();

                if (this.affix2Top !== af1rect.bottom) {
                    this.affix2Top = af1rect.bottom;
                    this.cd.markForCheck();
                }


                const rect = this.lastItem.nativeElement.getBoundingClientRect();
                const newff = rect.bottom < 64; // top menu
                if (newff !== this.affixed) {
                    this.affixed = newff;
                    this.cd.markForCheck();
                }
            });

        this.store.dispatch(new BodyScrollUnLock());
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsSearchOptions !== null) {
            this.subsSearchOptions.unsubscribe();
        }
        if (this.subSession !== null) {
            this.subSession.unsubscribe();
        }
    }

    setVisitasTab(activeTab: IntUISidebarVisitasTab) {
        this.store.dispatch(new SidebarSetVisitasTab({activeTab: activeTab}));
    }

    setMsgTab(activeTab: IntUISidebarMensajesTab) {
        this.store.dispatch(new SidebarSetMensajesTab({activeTab: activeTab}));
    }

    viewPerfil(userId: number) {
        this.store.dispatch(new ProfileOpen({userId: userId, type: 'sent'}));
        this.ga.triggerGenericEvent('evento', 'vista_perfil', 'barra_lateral');
    }

    openMessages(user: IntUISidebarUserLinks) {
        this.store.dispatch(new MessagingOpenChat({
            userId: user.userId,
            retreiveChatHistory: true,
            userName: user.username,
            userAvatar: user.gcpImage,
            gender: user.gender
        }));
        this.ga.triggerGenericEvent('evento', 'chat', 'barra_lateral');
    }

    doSearch() {
        const dispatchParams = zcDeepCopy(this.searchParams);
        dispatchParams.distancia = '' + dispatchParams.distancia;
        this.store.dispatch(new DashboardInitSearch({type: IntDashboardGetListing.DASH_SEARCH, searchParams: dispatchParams}));
        this.store.dispatch(new BodyScrollUnLock());
        //
        //this.router.navigateByUrl('/dashboard/search');
    }

    updateCiudades() {
        this.searchParams.id_localidad = '';
        this.ciudades = this.localidadesService.getCiudad(parseInt(this.searchParams.id_provincia, 10));
    }

    getDistancia() {
        const c = this.localidadesService.getNombreCiudad(parseInt(this.searchParams.id_provincia, 10), parseInt(this.searchParams.id_localidad, 10));
        if (this.utils.isUndefinedOrNull(this.searchParams.distancia) || (this.searchParams.distancia === '') || (c === null)) {
            this.searchParams.distancia = 0;
        }

        if (c === null) {
            return '';
        } else {
            if ((this.searchParams.distancia === 0) || (this.searchParams.distancia === '0') || (this.searchParams.distancia === '')) {
                return 'Buscar sólo en ' + c.l;
            } else {
                return `Buscar hasta ${this.searchParams.distancia}km. de ${c.l}`;
            }
        }
    }

    openAdvancedSearch() {
        this.dialog.open(AdvancedSearchComponent, {
            width: '1000px',
            height: '720px',
            disableClose: false
        });
    }

    cleanAdvSearch(close: boolean) {
        this.searchParams = {
            'id_provincia': this.searchParams.id_provincia,
            'id_localidad': this.searchParams.id_localidad,
            'distancia': this.searchParams.distancia,
            'edad_min': this.searchParams.edad_min,
            'edad_max': this.searchParams.edad_max,
            'apodo': '',
            'estado_civil': '',
            'hijos': '',
            'religion': [],
            'busco': '',
            'altura': '',
            'estudios': '',
            'deseo_hijos': '',
            'fuma': '',
            'bebe': '',
            'signo': ''
        };
    }

    getEdad() {
        let emin = parseInt(this.search_edad_min, 10);
        if (isNaN(emin) || emin === 0) {
            emin = 18;
        }

        let emax = parseInt(this.search_edad_max, 10);
        if (isNaN(emax)) {
            emax = 99;
        }

        if ((emin === 18 && emax === 99) || (emax === emin)) {
            return 'Todas las edades';
        } else {
            return `Desde ${emin} hasta ${emax} años`;
        }
    }

    sliderUpdate() {
        this.search_edad_min = this.searchParams.edad_min;
        this.search_edad_max = this.searchParams.edad_max;
    }

    openJuegosLikes() {
        //
        this.dialog.open(JuegoLikesComponent, {
            width: '893px',
            height: '710px',
            disableClose: false,
            panelClass: 'custom-modal'
        });
        //
    }
}
