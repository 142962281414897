export enum IntGustaronGetListing {
    GUSTARON_MATCHES = 'matches',
    GUSTARON_LIKES = 'likes',
}

export interface IntGustaronListingResult {
    age: string;
    city: string;
    gcpImage: string;
    genderId: string;
    image: string;
    province: string;
    timestamp: string;
    userId: string;
    username: string;
    _hide?: boolean;
    estado: string;
}

export interface IntGustaronListingResults {
    'status'?: number;
    'error'?: string;
    'more'?: boolean;
    'data'?: IntGustaronListingResult[];
}
