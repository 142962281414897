import {IntProfileReply, IntProfileReport} from './profile.model';
import {ProfileActions, ProfileActionTypes} from './profile.actions';
import {zcDeepCopy} from '../../services/zc.globals';
import {IntDashboardListingResult, IntDashboardListingResults} from '../dashboard/dashboard.model';

export interface ProfileState {
    profile: IntProfileReply | null;
    profileReport: IntProfileReport | null;
    moreUsers: IntDashboardListingResult [] | null;
    inProcess: boolean;
    inProcessAction: boolean;
    inProcessMoreUsers: boolean;
}


export const initialState: ProfileState = {
    profile: null,
    profileReport: null,
	moreUsers: null,
    inProcess: false,
    inProcessAction: false,
	inProcessMoreUsers: false,
};

export function reducer(
    state = initialState,
    action: ProfileActions
): ProfileState {
    switch (action.type) {

        case ProfileActionTypes.ProfileOpen: {
            return {
                ...state,
                inProcess: true,
            };
        }

        case ProfileActionTypes.ProfileOpenSuccess: {
            return {
                ...state,
                inProcess: false,
                profile: action.payload.profile
            };
        }

        case ProfileActionTypes.ProfileDoLike: {
            return {
                ...state,
                inProcessAction: true,
            };
        }

        case ProfileActionTypes.ProfileDoLikeSuccess: {
            const tmp = zcDeepCopy(state.profile);
            tmp.data.favorito = true;
            return {
                ...state,
                inProcessAction: false,
                profile: tmp
            };
        }

        case ProfileActionTypes.ProfileDoDislike: {
            return {
                ...state,
                inProcessAction: true,
            };
        }

        case ProfileActionTypes.ProfileDoUndoLike: {
            return {
                ...state,
                inProcessAction: true,
            };
        }

        case ProfileActionTypes.ProfileDoUndoLikeSuccess: {
            const tmp = zcDeepCopy(state.profile);
            tmp.data.favorito = false;
            return {
                ...state,
                inProcessAction: false,
                profile: {...tmp}
            };
        }

        case ProfileActionTypes.ProfileDoDislikeSuccess: {
            return {
                ...state,
                inProcessAction: false,
                profile: null
            };
        }

        case ProfileActionTypes.ProfileClose: {
            return {
                ...state,
                profile: null,
                inProcess: false
            };
        }

        case ProfileActionTypes.ProfileReport: {
            const tmp: IntProfileReport = {
                userId: action.payload.userId,
                userNick: action.payload.userNick
            };

            return {
                ...state,
                profileReport: tmp,
            };
        }

        case ProfileActionTypes.ProfileReportClose: {
            return {
                ...state,
                profileReport: null,
            };
        }

        case ProfileActionTypes.ProfileFail: {
            return {
                ...state,
                profile: null,
                inProcess: false
            };
        }

		case ProfileActionTypes.ProfileMoreUsers: {
			return {
				...state,
				inProcessMoreUsers: true
			}
		}

		case ProfileActionTypes.ProfileMoreUsersSuccess: {

			let copyResults = action.payload.results.filter(item => item.isNotice !== true);
			//let _mixedData = this.shuffleArray(retData);
			let resultMoreProfiles = copyResults.slice(0,4);

			return {
				...state,
				inProcessMoreUsers: false,
				moreUsers: resultMoreProfiles
			}
		}

        default: {
            return state;
        }
    }
}
