import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {IntFacebookLoginData, IntSignupPostData, IntSignupErrors} from '../../store/user/user.model';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {LogInFacebookFail, LogInFacebookUserStart, LogInFacebookUserSuccess, LogInUser, UserSignup} from '../../store/user/user.actions';
import {IntCiudadData, LocalidadesService} from '../../services/localidades.service';
import {ZcUtilsService} from '../../services/zc-utils.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


import {
	AuthService,
	FacebookLoginProvider
} from 'ng-social-login-module';
import { SocialUser } from 'ng-social-login-module';

declare var FB: any;

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent implements OnDestroy {

    @ViewChild('signupMainContainer', { static: true }) signupMainContainer: ElementRef;

    formdata : FormGroup;

    signupData: IntSignupPostData = {
        email: null,
        password: null,
        birthdate: null,
        gender: null,
        genderWanted: null,
        province: null,
        city: null
    };

    formSubmitted = false;
    hide = true;

    signupErrors: any = {
        gender: 'Seleccioná que sos y que buscás',
        email: {
            required: 'El email es obligatorio',
            email: 'El email no es valido',
        },
        password: {
            required: 'Ingresá tu contraseña',
            minlength: 'Ingresá una contraseña más larga',
        },
        birthdate: {
            required: 'Elegí',
        },
        province: 'Seleccioná tu provincia',
        city: 'Seleccioná tu ciudad'
    };

    signupResult$: Observable<IntSignupErrors> = this.store.pipe(select(fromRoot.getUserSignupMessage));
    signupProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserSignupProcess));
    signupFacebookDataSubs: Subscription = null;
    loginProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserLoginProgress));

    loginFacebookResult$: Observable<string> = this.store.pipe(select(fromRoot.getUserFacebookLoginMessage));
    loginFacebookProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserFacebookLoginProgress));

    ciudades: IntCiudadData[] = [];
    localidades: IntCiudadData[] = [];

    dataDias: any = [
    	{ label: '1', value: 1},
		{ label: '2', value: 2},
		{ label: '3', value: 3},
		{ label: '4', value: 4},
		{ label: '5', value: 5},
		{ label: '6', value: 6},
		{ label: '7', value: 8},
		{ label: '9', value: 9},
		{ label: '10', value: 10},
		{ label: '11', value: 11},
		{ label: '12', value: 12},
		{ label: '13', value: 13},
		{ label: '14', value: 14},
		{ label: '15', value: 15},
		{ label: '16', value: 16},
		{ label: '17', value: 17},
		{ label: '18', value: 18},
		{ label: '19', value: 19},
		{ label: '20', value: 20},
		{ label: '21', value: 21},
		{ label: '22', value: 22},
		{ label: '23', value: 23},
		{ label: '24', value: 24},
		{ label: '25', value: 25},
		{ label: '26', value: 26},
		{ label: '27', value: 27},
		{ label: '28', value: 28},
		{ label: '29', value: 29},
		{ label: '30', value: 30},
		{ label: '31', value: 31}];
    dataMeses: any = [{ label: 'Enero(01)', value: 1 },
		{ label: 'Febrero(02)', value: 2 },
		{ label: 'Marzo(03)', value: 3 },
		{ label: 'Abril(04)', value: 4 },
		{ label: 'Mayo(05)', value: 5 },
		{ label: 'Junio(06)', value: 6 },
		{ label: 'Julio(07)', value: 7 },
		{ label: 'Agosto(08)', value: 8 },
		{ label: 'Septiembre(09)', value: 9 },
		{ label: 'Octubre(10)', value: 10 },
		{ label: 'Noviembre(11)', value: 11 },
		{ label: 'Diciembre(12)', value: 12 }];
	dataYears: any;
	validateCaptcha: boolean = false;
	validateLabelCaptcha: boolean = false;

	labelSelect: string = '';
	labelSelectDisable: boolean = true;

	private user: SocialUser;

    constructor(
        private localidadesService: LocalidadesService,
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private cd: ChangeDetectorRef,
		private utilService: ZcUtilsService,
		private authService: AuthService
    ) {
        this.localidades = this.localidadesService.getLocalidades();

        this.signupFacebookDataSubs = this.store.pipe(select(fromRoot.getUserFacebookSignupData)).subscribe((signupFacebookData: IntSignupPostData) => {
            if (signupFacebookData !== null) {
                this.signupData = signupFacebookData;
                this.cd.markForCheck();
                this.signupMainContainer.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        });
    }

    ngOnDestroy(): void {
        if (this.signupFacebookDataSubs !== null) {
            this.signupFacebookDataSubs.unsubscribe();
        }
    }

    updateCiudades() {
    	this.signupData.city = null;
    	const _valueSelected = parseInt(this.formdata.get('province').value,10);
        let _valueLabelSelect = 'Localidad';
    	if(_valueSelected === 85) {
    		_valueLabelSelect = 'Barrio';
		}
    	this.labelSelectDisable = false;
    	this.labelSelect = _valueLabelSelect;
    	this.ciudades = this.localidadesService.getCiudad(parseInt(this.formdata.get('province').value, 10));
    }

    ngOnInit() {
		// social login listener
		this.authService.authState.subscribe((user) => {
			this.user = user;
			if(user != null){
				switch (this.user.provider){
					case 'FACEBOOK':
						const data: IntFacebookLoginData = {
							accessToken: this.user.token,
							signedRequest: this.user.token,
							userID: this.user.id
						};

						this.store.dispatch(new LogInFacebookUserSuccess({ authData: data }));
						break;
				}
			}else{
				//this.store.dispatch(new LogInFacebookFail({ error: 'No se ha podido iniciar sesión con tu cuenta de Facebook, verificá los datos ingresados.' }));
			}
		});

    	this.dataYears = this.getYearsRegister();


        this.formdata = new FormGroup({
           gender: new FormControl('', Validators.required),
           email: new FormControl('', [Validators.required, Validators.email]),
           password: new FormControl('', [Validators.required, Validators.minLength(6)]),
           birthdate_day: new FormControl('', Validators.required),
		   birthdate_month: new FormControl('', Validators.required),
		   birthdate_year: new FormControl('', Validators.required),
           province: new FormControl('', Validators.required),
           city: new FormControl('', Validators.required),
		   recaptcha: new FormControl('')
        });
     }

    sendFormRegister() {
		if(!this.validateCaptcha) { this.validateLabelCaptcha = true; }
        // Check if valid
        //if (this.formdata.valid && this.validateCaptcha) {
		if (this.formdata.valid) {
            // Parse gender
            let parsedGender = this.formdata.value.gender.split('-');
            let gender = parsedGender[0];
            let genderWanted = parsedGender[1];

            // Parse form data to interface
            this.signupData = {
                email: this.formdata.value.email,
                password: this.formdata.value.password,
                birthdate: this.formdata.value.birthdate_day.toLocaleString() + '/' +
							this.formdata.value.birthdate_month.toLocaleString() + '/' +
							this.formdata.value.birthdate_year.toString(),
                gender: gender,
                genderWanted: genderWanted,
                province: this.formdata.value.province,
                city: this.formdata.value.city,
				zcD: 'desktop'
            };

            this.store.dispatch(new UserSignup({user: this.signupData}));

            this.signupResult$.subscribe( errors => {
                if(errors && (errors.gender || errors.genderWanted)){
                    this.formdata.get('gender').setErrors({'api': true, 'apiMessage' : 'Seleccioná que sos y que buscás'});
                }
                if(errors && errors.email){
                    this.formdata.get('email').setErrors({'api': true, 'apiMessage' : errors.email});
                }
                if(errors && errors.password){
                    this.formdata.get('password').setErrors({'api': true, 'apiMessage' : errors.password});
                }
                if(errors && errors.birthdate){
                    this.formdata.get('birthdate').setErrors({'api': true, 'apiMessage' : errors.birthdate});
                }
                if(errors && errors.province){
                    this.formdata.get('province').setErrors({'api': true, 'apiMessage' : errors.province});
                }
                if(errors && errors.city){
                    this.formdata.get('city').setErrors({'api': true, 'apiMessage' : errors.city});
                }
            });

        }else{
            return
        }
    }

	socialLogin(socialId){
		switch (socialId){
			case 'facebook':
				this.store.dispatch(new LogInFacebookUserStart());
				this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
				break;
		}

	}

    facebookLogin() {
        /*FB.login((response) => {
            if (response.authResponse && response.status) {

				console.log(response);
                if (response.status === 'connected') {
                    const data: IntFacebookLoginData = {
                        accessToken: response.authResponse.accessToken,
                        signedRequest: response.authResponse.signedRequest,
                        userID: response.authResponse.userID
                    };

                    this.store.dispatch(new LogInFacebookUserSuccess({ authData: data }));
                }
                else {
                    this.store.dispatch(new LogInFacebookFail({ error: 'No se ha podido iniciar sesión con tu cuenta de Facebook, verificá los datos ingresados.' }));
                }

            } else {
                // canceló el login
                this.store.dispatch(new LogInFacebookFail({ error: null }));
            }
        // }, {scope: 'email,public_profile,user_about_me,user_birthday,user_hometown,user_location,user_photos'});
        }, {scope: 'email,public_profile'});*/
    }

    private getYearsRegister(){
    	//
		var i;
		let ret = [];
    	const year 			= new Date().getFullYear();
    	const year_begin 	= year - 18;
    	const year_end		= year - 90;
    	for( i=year_begin; i > year_end; i--) {
			ret.push({label: i.toString(), value: i});
		}
    	//
		return ret;
	}

	resolved(captchaResponse: string) {
    	//
		this.validateLabelCaptcha = false;
		this.utilService.validateCaptchaGoogle(captchaResponse).subscribe( response => {
			if(!response.success) {
				// console.log('GOOGGLE ReCaptcha Error');
			}else{
				this.validateCaptcha = true;
			}
		});
    	//
	}
}
