import {Action} from '@ngrx/store';
import {IntMessagingChat, IntMessagingMessage, IntMessagingMessageImage, IntMessagingNewMessage} from './messaging.model';
import {IntUISidebarUserLinks} from '../ui/ui.model';

export enum MessagingActionTypes {
    MessagingOpenChat           = '[Messaging API] Open chat',
    MessagingOpenChatSuccess    = '[Messaging API] Open chat success',
    MessagingScrollChat         = '[Messaging API] Scroll chat',
    MessagingScrollChatSuccess  = '[Messaging API] Scroll chat success',
    MessagingSendMessage        = '[Messaging API] Send chat',
    MessagingMessageReceived    = '[Messaging API] Receive chat',
    MessagingMessageReceivedAdd = '[Messaging API] Receive chat add',
    MessagingSendMessageSuccess = '[Messaging API] Send chat success',
    MessagingCloseChat          = '[Messaging API] Close chat',
    MessagingFail               = '[Messaging API] Fail',
    MessagingCleanup            = '[Messaging API] Cleanup',
	MessagingIsOnline			= '[Messaging API] Is Online',
	MessagingIsTyping			= '[Messaging API] Is Typing'
}

export class MessagingIsOnline implements Action {
	readonly type = MessagingActionTypes.MessagingIsOnline;
	constructor(public payload: boolean) {}
}

export class MessagingIsTyping implements Action {
	readonly type = MessagingActionTypes.MessagingIsTyping;
	constructor(public payload: boolean) {}
}

export class MessagingOpenChat implements Action {
    readonly type = MessagingActionTypes.MessagingOpenChat;

    constructor(public payload: { userId: number, gender: string, retreiveChatHistory: boolean, userName: string, userAvatar: string }) {
    }
}

export class MessagingOpenChatSuccess implements Action {
    readonly type = MessagingActionTypes.MessagingOpenChatSuccess;

    constructor(public payload: { chat: IntMessagingChat }) {
    }
}

export class MessagingSendMessage implements Action {
    readonly type = MessagingActionTypes.MessagingSendMessage;

    constructor(public payload: { message: IntMessagingNewMessage, userLink: IntUISidebarUserLinks }) {
    }
}

export class MessagingSendMessageSuccess implements Action {
    readonly type = MessagingActionTypes.MessagingSendMessageSuccess;

    constructor(public payload: { message: IntMessagingNewMessage, imagen: boolean | IntMessagingMessageImage }) {
    }
}

export class MessagingScrollChat implements Action {
    readonly type = MessagingActionTypes.MessagingScrollChat;
}

export class MessagingScrollChatSuccess implements Action {
    readonly type = MessagingActionTypes.MessagingScrollChatSuccess;

    constructor(public payload: { chat: IntMessagingChat }) {
    }
}

export class MessagingMessageReceived implements Action {
    readonly type = MessagingActionTypes.MessagingMessageReceived;

    constructor(public payload: { chat: IntMessagingMessage }) {
    }
}

export class MessagingMessageReceivedAdd implements Action {
    readonly type = MessagingActionTypes.MessagingMessageReceivedAdd;

    constructor(public payload: { chat: IntMessagingMessage }) {
    }
}

export class MessagingCloseChat implements Action {
    readonly type = MessagingActionTypes.MessagingCloseChat;
}

export class MessagingFail implements Action {
    readonly type = MessagingActionTypes.MessagingFail;
}

export class MessagingCleanup implements Action {
    readonly type = MessagingActionTypes.MessagingCleanup;
}


export type MessagingActions =
    MessagingOpenChat
    | MessagingOpenChatSuccess
    | MessagingScrollChat
    | MessagingScrollChatSuccess
    | MessagingMessageReceived
    | MessagingMessageReceivedAdd
    | MessagingCloseChat
    | MessagingSendMessage
    | MessagingSendMessageSuccess
    | MessagingCleanup
    | MessagingFail
	| MessagingIsOnline
	| MessagingIsTyping;
