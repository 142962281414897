import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntUISidebarUserResults} from './ui.model';

@Injectable()
export class UiService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public getSidebarData( type: 'received-messages' | 'sent-messages' | 'received-visits' | 'sent-visits' ): Observable<IntUISidebarUserResults> {
        return this.http
        .get<IntUISidebarUserResults>(`${environment.appApi_base}${environment.endpoints.sidebar}/${type}`);
    }
}
