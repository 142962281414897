import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError,
    map, mergeMap, withLatestFrom,
} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {NoGustaronService} from './nogustaron.service';
import * as NoGustaronActions from './nogustaron.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';

/**
 * Effects file is for isolating and managing side effects of the application in one place
 * Http requests, Sockets, Routing, LocalStorage, etc
 */

@Injectable()
export class NoGustaronEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private noGustaronService: NoGustaronService,
    ) {
    }

    @Effect()
    initListing$ = this.actions$.pipe(
        ofType<NoGustaronActions.NoGustaronInitSearch>(NoGustaronActions.NoGustaronActionTypes.NoGustaronInitSearch),
        mergeMap((action) =>
            this.noGustaronService.listing().pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new NoGustaronActions.NoGustaronDoSearch({results: results});
                    } else {
                        return new NoGustaronActions.NoGustaronFail({results: results});
                    }
                }),
                catchError(err => of(new NoGustaronActions.NoGustaronFail({results: err})))
            )
        )
    );

    @Effect()
    initScroll$ = this.actions$.pipe(
        ofType<NoGustaronActions.NoGustaronInitScroll>(NoGustaronActions.NoGustaronActionTypes.NoGustaronInitScroll),
        withLatestFrom(this.store),
        mergeMap((params) => {
            return this.noGustaronService.scroll( params[1].nogustaron.currentPage ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new NoGustaronActions.NoGustaronDoScroll({results: results});
                    } else {
                        return new NoGustaronActions.NoGustaronFail({results: results});
                    }
                }),
                catchError(err => of(new NoGustaronActions.NoGustaronFail({results: err})))
            )
        })
    );

    @Effect()
    cancelDislike$ = this.actions$.pipe(
        ofType<NoGustaronActions.NoGustaronCancel>(NoGustaronActions.NoGustaronActionTypes.NoGustaronCancel),
        mergeMap((action) =>
            this.noGustaronService.cancelDislike(action.payload.user).pipe(
                map((results: any) => {
                    if (results.status === 200) {
                        return new NoGustaronActions.NoGustaronCanceled({user: action.payload.user});
                    } else {
                        return new NoGustaronActions.NoGustaronFail({results: results});
                    }
                }),
                catchError(err => of(new NoGustaronActions.NoGustaronFail({results: err})))
            )
        )
    );
}
