export enum IntMensajesListings {
    MENSAJES_RECEIVED = 'received',
    MENSAJES_SENT = 'sent',
    MENSAJES_CONVERSATIONS = 'conversation',
}

export interface IntMensajesListingMessage {
    'userId': string;
    'userName': string;
    'userGcpImage': string;
    'userGenderId': string;
    'userImg': string;
    'new': string;
    'timestamp': string;
    'date': string;
    'messageType': string;
    'messageText': string;
    'canRead': string;
    'selected'?: boolean;
    'estado': string;

    // ads
    'isNotice'?: boolean;
    'img'?: string;
    'link'?: string;
}

export interface IntMensajesListingResult {
    'type': string;
    'more': boolean;
    'chats': IntMensajesListingMessage[];
}

export interface IntMensajesListingResults {
    'status'?: number;
    'error'?: string;
    'data'?: IntMensajesListingResult;
}

export interface IntMensajesUsersIdList {
    'users': Array<string>;
}
