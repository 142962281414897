import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../store/user/users.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {IntLoginData, IntRefundReply} from '../../store/user/user.model';
import {UserRefund} from '../../store/user/user.actions';

@Component({
    selector: 'app-refund',
    templateUrl: './refund.component.html',
    styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit, OnDestroy {

    userId = '';
    subsSession: Subscription = null;
	isLoading$: Observable<boolean> = this.store.pipe(select(fromRoot.isLoadingRefund));
	isErrorRefund$: Observable<boolean> = this.store.pipe(select(fromRoot.isErrorRefund));
	isDoneRefund$: Observable<boolean> = this.store.pipe(select(fromRoot.isDoneRefund));
	idTramite$: Observable<string> = this.store.pipe(select(fromRoot.idTramiteRefund));

    constructor(
        private store: Store<fromRoot.State>,
        private router: Router,
        private cd: ChangeDetectorRef,
        public userService: UsersService
    ) {
        this.subsSession = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
            if (!session || !session.sessionInfo.me.billing.btnRefund) {
                // no puede arrepentirse, redirigir
                this.router.navigateByUrl('/dashboard');
            } else {
                this.userId = session.sessionInfo.me.id;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subsSession !== null) {
            this.subsSession.unsubscribe();
        }
    }

    ngOnInit() {
    }

    confirmar() {
		this.store.dispatch(new UserRefund({iduser: this.userId}));
    }

}
