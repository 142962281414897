import {Component, Input, OnInit} from '@angular/core';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {GaService} from '../../services/ga.service';

@Component({
    selector: 'app-btnescribile',
    templateUrl: './btnescribile.component.html',
    styleUrls: ['./btnescribile.component.scss']
})
export class BtnescribileComponent implements OnInit {

    @Input() userId: string;
    @Input() userName: string;
    @Input() userAvatar: string;
    @Input() gaSource: string;
    @Input() gender: string;

    constructor(
        private ga: GaService,
        private store: Store<fromRoot.State>
    ) {
    }

    ngOnInit() {
    }

    sendMessage() {
        this.store.dispatch(new MessagingOpenChat({
            userId: parseInt(this.userId, 10),
            retreiveChatHistory: true,
            userName: this.userName,
            userAvatar: this.userAvatar,
            gender: this.gender
        }));

        if (this.gaSource) {
            this.ga.triggerGenericEvent('evento', 'chat', this.gaSource);
        }
    }
}
