import {IntGustaronGetListing, IntGustaronListingResults} from './gustaron.model';
import {GustaronActions, GustaronActionTypes} from './gustaron.actions';

export interface GustaronState {
    currentResults: IntGustaronListingResults;
    currentType: IntGustaronGetListing;
    currentPage: number;
    inProcess: boolean;
}


export const initialState: GustaronState = {
    currentResults: {
        data: []
    },
    currentType: IntGustaronGetListing.GUSTARON_MATCHES,
    currentPage: 1,
    inProcess: false
};

export function reducer(
    state = initialState,
    action: GustaronActions
): GustaronState {
    switch (action.type) {

        case GustaronActionTypes.GustaronCleanup: {
            return {
                ...initialState,
            };
        }

        case GustaronActionTypes.GustaronInitSearch: {
            return {
                ...state,
                currentType: action.payload.type,
                currentResults: {
                    data: [],
                    error: null,
                    more: false,
                    status: null
                },
                inProcess: true,
                currentPage: 1
            };
        }

        case GustaronActionTypes.GustaronDoSearch: {
            // if message type has been changed, discard result
            if (action.payload.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null

                },
                inProcess: false,
                currentPage: 1
            };
        }

        case GustaronActionTypes.GustaronInitScroll: {
            return {
                ...state,
                inProcess: true
            };
        }

        case GustaronActionTypes.GustaronDoScroll: {
            // if message type has been changed, discard result
            if (action.payload.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null
                },
                inProcess: false,
                currentPage: state.currentPage + 1
            };
        }

        case GustaronActionTypes.GustaronFail: {
            return {
                ...state,
                currentResults: {
                    error: action.payload.results.error || null,
                    status: action.payload.results.status || null,
                    more: false,
                    data: []
                },
                inProcess: false,
                currentPage: 1
            };
        }


        case GustaronActionTypes.GustaronCancel: {
            return {
                ...state,
                inProcess: true
            };
        }

        case GustaronActionTypes.GustaronCanceled: {
            const tmp = state.currentResults;
            const users = tmp.data.filter( u => u.userId !== action.payload.user.userId );
            tmp.data = [...users];

            return {
                ...state,
                currentResults: {...tmp},
                inProcess: false
            };
        }

        case GustaronActionTypes.GustaronToggleByID: {
            const tmp = state.currentResults;
            tmp.data.forEach( (o) => {
                if (o.userId === action.payload.userId) {
                    // mostrar o esconder de acuerdo a liked
                    o._hide = !action.payload.liked;
                }
            });

            return {
                ...state,
                currentResults: {...tmp},
                inProcess: false
            };
        }


        default: {
            return state;
        }
    }
}
