import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IntProfileReply} from './profile.model';
import {IntDashboardListingResults} from '../dashboard/dashboard.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        private http: HttpClient,
    ) {
    }

    /**
     * Get chat messages
     * @param userId
     * @param lastMessage: null to get the initial conversation, message ID to get the previous messages
     */
    getProfile(userId: number): Observable<IntProfileReply> {
        return this.http
        .get<IntProfileReply>(`${environment.appApi}/${environment.endpoints.profileview}/` + userId);
    }

    reportProfile(userId: number, reportId: number, reportText: string): Observable<IntProfileReply> {
        return this.http
        .post<IntProfileReply>(`${environment.appApi}/${environment.endpoints.userreport}/` + userId, {id: userId, reportId: reportId, reportText: reportText});
    }

    public getMoreUsers(): Observable<IntDashboardListingResults> {
		return this.http
			.get<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.dashboardlisting}/recent` );
	}

}
