import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {async, Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {
	IntConversionFunnel,
	IntLoginData, IntMobbexCheckout,
	IntPaywallAbono,
	IntPaywallCCPost,
	IntPaywallCCReplyFail,
	IntPaywallData,
	IntPaywallTicketReply
} from '../../store/user/user.model';
import {LogInReLog, Paywallget, SetConversionFunnel, UserProfileV2, UserReloadSession} from '../../store/user/user.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../store/user/users.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {GaService} from '../../services/ga.service';
import { MatDialog } from '@angular/material';
import {FormControl} from '@angular/forms';
import {
	PaymentCCInfo,
	PaymentFails,
	PaymentLoading,
	PaymentSuccess,
	PaymentThankYouPage, PaymentThankYouPageError,
	ZendeskShow
} from '../../store/ui/ui.actions';
import {ToastrService} from 'ngx-toastr';
import {TicketComponent} from "../suscribite/ticket.component";

declare var MercadoPago: any;
declare var MobbexEmbed: any;

@Component({
    selector: 'app-suscribite_mobbex',
    templateUrl: './suscribite_mobbex.component.html',
    styleUrls: ['./suscribite_mobbex.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Suscribite_mobbexComponent implements OnInit, OnDestroy {

    @ViewChild('paymentcheckoutdata') paymentcheckoutdata: ElementRef;

    paywall: any = null;
    session: IntLoginData = null;
    loading = true;
    loadingPago = true;
    pagoError = '';
    fromPromo: boolean = false;
	showDiscount:boolean = true;
	arrSimplePlan: any = ['800','803','6100','6001'];

    subs: Subscription = null;
    subsProfile: Subscription = null;
    subsLoad: Subscription = null;
    routeSub: Subscription = null;

    abonosReverse: any = null;

	mp: any = null;

    // Selected plan
    enumListType: any = {
        CREDIT_CARD: "credit_card",
        OTHER: "other",
    };
    selectedAbono: IntPaywallAbono = null;
    activeTab: string = this.enumListType.CREDIT_CARD;
    showSecurityCodeAmex: boolean = false;
	showSecurityCodeOthers: boolean = false;

    ccinfo = {
		userName: '',
        docNumber: null,
        payerEmail: null
    };

    ccPaymentFinished = false;
    ccMPInvalid = false;
    ccMPID = '';
    ccMPPmtType = '';
    ccMPName = '';
    ccMPCodSeg = '';
	ccMPURLImg = '';
	ccMPIssuerBank = '';
	showLegals3dx3p:boolean = true;

    offlinePaymentFinished = false;
    loadingOfflinePay = false;
    offPayBrand: any;
    offPayBrandCode: any;
    offPayCodeOne: any;
    offPayCodeTwo: any;
    offPayLink: any;
    offPayEmail: any;
    offPayMethods = {
        'RP': 'RapiPago',
        'PF': 'Pago Fácil',
    };
    offPayMethodsCodes = {
        'RP': 'rapipago',
        'PF': 'pagofacil',
    };
    offPayMethodsGATrackCodes = {
        'RP': 'RapiPago',
        'PF': 'PagoFacil',
    };

	isPaymentLoading$: Observable<boolean> =  this.store.pipe(select(fromRoot.isPaymentLoading));
	isPaymentSuccess$: Observable<boolean> =  this.store.pipe(select(fromRoot.isPaymentSuccess));
	isPaymentThankYouPage$: Observable<boolean> = this.store.pipe(select(fromRoot.isPaymentThankYouPage));
	isPaymentThankYouPageError$: Observable<boolean> = this.store.pipe(select(fromRoot.isPaymentThankYouPageError));
	paymentThankYouPageError$: Observable<string> = this.store.pipe(select(fromRoot.paymentThankYouPageError));
	paymentUrlImg$: Observable<string> = this.store.pipe(select(fromRoot.paymentUrlImg));
	paymentBank$: Observable<string> = this.store.pipe(select(fromRoot.paymentBank));
	isPremiun$: Observable<boolean> = this.store.pipe(select(fromRoot.isPremiun));
	paymentType$: Observable<string> = this.store.pipe(select(fromRoot.paymentType));
	isPaymentFails$: Observable<number> =  this.store.pipe(select(fromRoot.isPaymentFails));
	paymentFailsMsg$: Observable<string> =  this.store.pipe(select(fromRoot.paymentFailsMsg));


    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private userService: UsersService,
        private utils: ZcUtilsService,
        private route: ActivatedRoute,
        private router: Router,
		public dialog: MatDialog,
        private ga: GaService,
        private modalService: NgbModal,
		private toastr: ToastrService,
		private window: Window
    ) {
		//
		let promo = '';
		let nick = '';
		let avatar = '';
		let idabono = '';
		//
		promo = this.route.snapshot.params['promo'];
		nick = this.route.snapshot.params['nick'];
		avatar = this.route.snapshot.params['avatar'];
		idabono = this.route.snapshot.queryParamMap.get('idabono');
		if(this.route.snapshot.queryParamMap.get('showLegals3dx3p')){
			this.showLegals3dx3p = false;
		}

		// Conversion funnel
		let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
		if(promo === undefined || idabono === null){
			dataConversionFunnel.step = 1;
			this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));
		}
		//
		this.store.dispatch(new Paywallget( { promo: promo, nick: nick, avatar: avatar, idabono: idabono } ));
		//
        this.subsLoad = this.store.pipe(select(fromRoot.getUserPaywallLoading)).subscribe(loading => {
            this.loading = loading;
            this.cd.markForCheck();
        });
		//
        this.subsProfile = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(session => {
            this.session = session;
            this.offPayEmail = session && session.sessionInfo ? session.sessionInfo.me.email : '';
            this.cd.markForCheck();
        });
    	//
	}

    ngOnInit() {
		// hide zendesk
		this.store.dispatch(new ZendeskShow(false));
		// Load Paywall data
		this.subs = this.store.pipe(select(fromRoot.getUserPaywall)).subscribe(retPaywall => {
			this.paywall = retPaywall;
			this.cd.markForCheck();

			// Get param from URL
			if(retPaywall !== null){
				// Get abonos reverse
				this.abonosReverse = this.paywall.abonosReverse;

				// Track GA
				this.paywall.data.abonos.forEach((abono) => {
					const event = {
						'ecommerce': {
							'currencyCode': 'ARS',
							'impressions': [
								{
									'name': abono.descripcion_externa,
									'id': abono.idabono,
									'price': (abono.totalvalor + (abono.totaldecimal / 100)),
									'variant': abono.tipoPago,
									'list': abono.tipoPago,
									'position': abono.posicion
								}
							]
						}
					};
					this.ga.gaTrack(event);
				});

				// check if PROMO
				if(this.paywall.data.abonos.length == 1) {
					this.selectAbono(this.paywall.data.abonos[0]);
				}
			}
		});
		// init MP
		/*this.mp = new MercadoPago(environment.mercadoPagoID, {
			locale: 'es-AR',
		})*/
		//
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsProfile !== null) {
            this.subsProfile.unsubscribe();
        }
        if (this.subsLoad !== null) {
            this.subsLoad.unsubscribe();
        }
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
    }

    openMasInfo(modalContent) {
        this.modalService.open(modalContent, {centered: true, size: 'lg'});
    }

    initCCInfo() {
        this.ccPaymentFinished = false;
        this.ccMPInvalid = false;
        this.ccMPID = '';
        this.ccMPPmtType = '';
        this.ccMPName = '';
        this.ccMPCodSeg = '';

        this.ccinfo = {
			userName: '',
            docNumber: null,
            payerEmail: this.session.sessionInfo.me.email
        };
        this.pagoError = '';
        this.loadingPago = false;
    }

	pagar(fData){
		if(fData.form.status === 'VALID'){
			this.store.dispatch(new PaymentLoading(true));
			// create checkout
			const dataCheckout: IntMobbexCheckout = {
				email: this.ccinfo.payerEmail,
				dni: this.ccinfo.docNumber,
				username: this.ccinfo.userName,
				idplan: this.selectedAbono.idabono,
				sandbox: false
			}
			this.userService.setMobbexSubscriber(dataCheckout).subscribe(
				(ret) => {
					if(ret.status && ret.status == 200){
						//
						this.store.dispatch(new PaymentLoading(false));
						const idSubscription	= ret.idsubscription;
						const idSubscriber		= ret.idsubscriber;
						const options = {
							id: idSubscription,
							sid: idSubscriber,
							type: "subscriber_source",
							onResult: (data) => {
								// OnResult es llamado cuando se toca el Botón Cerrar
								console.log(data);
								if(data.status.code == 200){
									// Pago completado exitosamente
									const event = {
										'ecommerce': {
											'purchase': {
												'actionField': {
													'id': '',
													'price': (this.selectedAbono.totalvalor + (this.selectedAbono.totaldecimal / 100)),
												},
												'products': [{
													'name': this.selectedAbono.descripcion_externa,
													'id': this.selectedAbono.idabono,
													'price': (this.selectedAbono.totalvalor + (this.selectedAbono.totaldecimal / 100)),
													'variant': this.selectedAbono.tipoPago,
													'quantity': 1
												}]
											}
										}
									};
									this.ga.gaTrack(event);
									this.ga.triggerGenericEvent('evento', 'marketing', 'compra');
									this.store.dispatch(new PaymentThankYouPage(true));
								}else if(data.status.code == 400){
									this.store.dispatch(new PaymentThankYouPageError({code: data.status.code}));
									mbbxButton.close();
								}else{
									this.store.dispatch(new PaymentThankYouPageError({code: data.status.code}));
									mbbxButton.close();
								}
							}
						};
						const mbbxButton = MobbexEmbed.init(options);
						mbbxButton.open();
						//
					}
				}
			);
			//
		}else{
			const controls = fData.form.controls;
			controls.docNumber.touched = (controls.docNumber.status === 'INVALID');
			controls.userName.touched = (controls.userName.status === 'INVALID');
			controls.payerEmail.touched = (controls.payerEmail.status === 'INVALID');
		}
	}


    selectAbono(a: IntPaywallAbono) {
		window.scroll(0,0);
		this.initCCInfo();
		this.selectedAbono = a;

		this.showDiscount = this.arrSimplePlan.includes(this.selectedAbono.idabono);

		// Conversion funnel
		let dataConversionFunnel: IntConversionFunnel = {step:0,dsc:'',idabono:''};
		dataConversionFunnel.step = 2;
		dataConversionFunnel.idabono = this.selectedAbono.idabono;
		this.store.dispatch(new SetConversionFunnel({data: dataConversionFunnel}));

		const event = {
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': a.tipoPago,
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'list': a.tipoPago,
						'position': a.posicion
					}]
				},
			}
		};
		this.ga.gaTrack(event);

		const eventAdd = {
			'event': 'addToCart',
			'ecommerce': {
				'currencyCode': 'ARS',
				'add': {
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'quantity': 1
					}]
				},
			}
		};
		this.ga.gaTrack(eventAdd);

		const eventOut = {
			'event': 'checkout',
			'ecommerce': {
				'checkout': {
					'actionField': {'step': 1, 'option': 'MercadoPago'},
					'products': [{
						'name': a.descripcion_externa,
						'id': a.idabono,
						'price': (a.totalvalor + (a.totaldecimal / 100)),
						'variant': a.tipoPago,
						'quantity': 1
					}]
				},
			}
		};
		this.ga.gaTrack(eventOut);
	}

	onPaymentSuccessRedirect(){
		this.store.dispatch(new PaymentSuccess(false));
		this.router.navigateByUrl('/dashboard');
	}

	selectOffpay(pf: string) {

		//this.loadingOfflinePay = true;
		this.offPayBrand = this.offPayMethods[pf];
		this.offPayBrandCode = pf;

		try{
			this.userService.getTicketCode( this.selectedAbono.idabono, this.offPayMethodsCodes[pf] )
				.subscribe( (ret: IntPaywallTicketReply) => {
					const code = ret.payment_code.toString();
					this.offPayCodeOne = code.substring(0, 5);
					this.offPayCodeTwo = code.substring(5);
					this.offPayLink = ret.payment_url;
					// open result
					this.dialog.open(TicketComponent, {
						width: '535px',
						height: '578px',
						disableClose: false,
						data: {
							payment_code: pf,
							payment_method: this.offPayMethods[pf],
							payment_code_one: this.offPayCodeOne,
							payment_code_two: this.offPayCodeTwo,
							payment_ticket_url: this.offPayLink
						}
					});
					//
					const event = {
						'event': 'checkoutOption',
						'ecommerce': {
							'checkout_option': {
								'actionField': {'step': 1, 'option': this.offPayMethodsGATrackCodes[pf]}
							}
						}
					};
					this.ga.gaTrack(event);
					// Re log app
					this.store.dispatch(new LogInReLog({'recovery_token': sessionStorage.getItem(environment.recoveryTokenKey)}))
				});
		}catch(error){
			this.offPayBrand = '';
			this.offPayBrandCode = '';
			this.offPayCodeOne = '';
			this.offPayCodeTwo = '';
			this.offPayLink = '';
			this.loadingOfflinePay = false;
			this.offlinePaymentFinished = false;
			this.cd.markForCheck();
		}
	}

}
