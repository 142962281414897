import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ProfileClose, ProfileMoreUsers, ProfileOpen} from '../../store/profile/profile.actions';
import {GaService} from '../../services/ga.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent implements OnInit {

	@Input() section: string;
	@Input() isSidebar: boolean = false;

	isLoadingMoreUsers$: Observable<boolean> = this.store.pipe(select(fromRoot.getMoreUsersInProcess));
	resultsMoreUsers$: Observable<any> = this.store.pipe(select(fromRoot.getMoreUsers));

	leyendMessage: string;

	segustan: any = ['¡Empezá a visitar perfiles!'];
	mensajes_recibidos: any = ['Aun no recibiste mensajes!!', 'Empeza a visitar usuarios para recibir mensajes'];
	mensajes_enviados: any = ['No enviaste mensajes, empezá ahora!!!'];
	visitas_recibidas: any = ['No recibiste visitas, empezá a visitar perfiles!!!'];
	visitas_realizadas_sidebar: any = ['Aún no realizaste '];
	mensajes_enviados_sidebar: any = ['No enviaste mensajes, empezá ahora!!!'];
	mensajes_recibidos_sidebar: any = ['Aun no recibiste mensajes!!'];
	visitas_recibidas_sidebar: any = ['No recibiste visitas aún, comenza a ver perfiles!!!'];
	no_megustan: any = ['¡Empezá a visitar perfiles!'];

	constructor(
		private store: Store<fromRoot.State>,
		private ga: GaService,
		private router: Router,
	) {
		this.store.dispatch(new ProfileMoreUsers());
	}

	ngOnInit(): void {
		 this.getMessageLeyend(this.section);
	}

	getMessageLeyend(section){
		//
		let idx;
		switch (section){
			case 'segustan':
				idx = this.getRandomFromArray(this.segustan);
				this.leyendMessage = this.segustan[idx];
				break;
			case 'mensajes_enviados_sidebar':
				idx = this.getRandomFromArray(this.mensajes_enviados_sidebar);
				this.leyendMessage = this.mensajes_enviados_sidebar[idx];
				break;
			case 'mensajes_recibidos_sidebar':
				idx = this.getRandomFromArray(this.mensajes_recibidos_sidebar);
				this.leyendMessage = this.mensajes_recibidos_sidebar[idx];
				break;
			case 'visitas_recibidas_sidebar':
				idx = this.getRandomFromArray(this.visitas_recibidas_sidebar);
				this.leyendMessage = this.visitas_recibidas_sidebar[idx];
				break;
			case 'visitas_realizadas_sidebar':
				idx = this.getRandomFromArray(this.visitas_realizadas_sidebar);
				this.leyendMessage = this.visitas_realizadas_sidebar[idx];
				break;
			case 'mensajes_recibidos':
				idx = this.getRandomFromArray(this.mensajes_recibidos);
				this.leyendMessage = this.mensajes_recibidos[idx];
				break;
			case 'visitas_recibidas':
				idx = this.getRandomFromArray(this.visitas_recibidas);
				this.leyendMessage = this.visitas_recibidas[idx];
				break;
			case 'mensajes_enviados':
				idx = this.getRandomFromArray(this.mensajes_enviados);
				this.leyendMessage = this.mensajes_enviados[idx];
				break;
			case 'no_megustan':
				idx = this.getRandomFromArray(this.no_megustan);
				this.leyendMessage = this.no_megustan[idx];
				break;
			default:
				this.leyendMessage = 'Sin información';
				break;
		}
		//
	}

	getRandomFromArray(obj: any){
		return Math.floor(Math.random() * obj.length);
	}

	goToDashboard(){
		this.router.navigateByUrl('/dashboard');
	}

	openMoreProfile(iduser){
		//
		this.store.dispatch(new ProfileOpen({userId: parseInt(iduser, 10), type: 'sent'}));
		this.ga.triggerGenericEvent('evento', 'vista_perfil', 'masperfiles');
		//
	}

}
