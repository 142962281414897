import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {catchError, map, mergeMap, tap} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';
import {
	BodyScrollLock,
	BodyScrollUnLock,
	SidebarGetVisitsRcv,
	SidebarGetVisitsSent,
	UpdateSidebarVisitsRcv,
	UpdateSidebarVisitsSent
} from '../ui/ui.actions';
import * as ProfileActions from './profile.actions';
import {ProfileService} from './profile.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {Router} from '@angular/router';
import {DashboardDoDislikedByID, DashboardDoLikedByID, DashboardDoUndoLikedByID} from '../dashboard/dashboard.actions';
import {GustaronToggleByID} from '../gustaron/gustaron.actions';
import {NoGustaronToggleByID} from '../nogustaron/nogustaron.actions';
import {ToastrService} from 'ngx-toastr';
import {IntUISidebarUserLinks} from '../ui/ui.model';
import {IntProfileReply} from './profile.model';
import {MatDialog} from '@angular/material/dialog';
import {UserProfileComponent} from '../../components/userprofile/userprofile.component';
import {MensajesInitSearch} from "../mensajes/mensajes.actions";
import {IntMensajesListings} from "../mensajes/mensajes.model";

@Injectable()
export class ProfileEffects {
    constructor(
        private actions$: Actions,
        private store: Store<fromRoot.State>,
        private profileService: ProfileService,
        private dashboardService: DashboardService,
        private route: Router,
        private toastr: ToastrService,
        public dialog: MatDialog
    ) {
    }

    @Effect()
	getMoreUsers$ = this.actions$.pipe(
		ofType<ProfileActions.ProfileMoreUsers>(ProfileActions.ProfileActionTypes.ProfileMoreUsers),
		mergeMap( () => {
				return this.profileService.getMoreUsers().pipe(
					map( (results: any) => {
						if(results.status === 200){
							return new ProfileActions.ProfileMoreUsersSuccess({results: results.data.hits });
						}
					})
				)
			}
		));

    @Effect()
    openProfile$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileOpen>(ProfileActions.ProfileActionTypes.ProfileOpen),
        mergeMap((action) => {
                setTimeout(() => {
                    // open modal
                    this.dialog.open(UserProfileComponent, {
                        height: '95%',
                        width: '1000px',
                    });
                }, 100);

                this.store.dispatch(new BodyScrollLock());

                return this.profileService.getProfile(action.payload.userId).pipe(
                    map((results: IntProfileReply) => {
                        if ((results.status === 200) && results.data) {

                            // Actualizar sidebar
                            const userLink: IntUISidebarUserLinks = {
                                userId: parseInt(results.data.id, 10),
                                gcpImage: results.data.gcpImages.profile,
                                image: results.data.images === null ? null : results.data.images.profile,
                                gender: results.data.ficha.gender,
                                username: results.data.ficha.apodo_aprobado,
                                age: results.data.ficha.edad
                            };

                            // type puede ser null, cuando se abre el perfil del mismo user
                            if (action.payload.type === 'sent') {
                                this.store.dispatch(new UpdateSidebarVisitsSent({user: userLink}));
                            } else if (action.payload.type === 'rcv') {
                                this.store.dispatch(new UpdateSidebarVisitsRcv({user: userLink}));
                            }

                            results._source = action.payload.source;
                            return new ProfileActions.ProfileOpenSuccess({profile: results});
                        } else {
                            return new ProfileActions.ProfileFail();
                        }
                    }),
                    catchError(err => of(new ProfileActions.ProfileFail()))
                );
            }
        )
    );

    @Effect({dispatch: false})
    closeProfile$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileClose>(ProfileActions.ProfileActionTypes.ProfileClose),
        tap(() => this.store.dispatch(new BodyScrollUnLock()))
    );

    @Effect({dispatch: false})
    failProfile$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileFail>(ProfileActions.ProfileActionTypes.ProfileFail),
        tap(() => this.store.dispatch(new BodyScrollUnLock()))
    );


    @Effect({dispatch: false})
    reportProfile$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileReport>(ProfileActions.ProfileActionTypes.ProfileReport),
        tap(() => this.store.dispatch(new BodyScrollLock()))
    );

    @Effect({dispatch: false})
    closeReport$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileReportClose>(ProfileActions.ProfileActionTypes.ProfileReportClose),
        tap(() => this.store.dispatch(new BodyScrollUnLock()))
    );

    @Effect()
    profileSubmit$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileReportSubmit>(ProfileActions.ProfileActionTypes.ProfileReportSubmit),
        mergeMap((action) =>
            this.profileService.reportProfile(parseInt(action.payload.userId, 10), action.payload.reportId, action.payload.reportText).pipe(
                map((results: any) => {
                    if (results.status === 200) {

                        // disparar acciones para sincronizar UI de acuerdo a la ruta activa
                        // envía acciones de dislike ya que estan eliminan al user de los listados
                        switch (this.route.url) {
                            case '/gustaron':
                                this.store.dispatch(new GustaronToggleByID({userId: action.payload.userId, liked: false}));
                                break;

                            case '/dashboard':
                            case '/dashboard/search':
                                this.store.dispatch(new DashboardDoDislikedByID({userId: action.payload.userId}));
                                break;
                        }

                        this.toastr.success('El perfil ha sido reportado');
                    } else {
                        this.toastr.error('Ha ocurrido un error al reportar el perfil, intenta nuevamente en unos instantes');
                    }
                    return new ProfileActions.ProfileClose();
                }),
                catchError(err => of(new ProfileActions.ProfileFail()))
            )
        )
    );


	@Effect()
	doLikeJDL$ = this.actions$.pipe(
		ofType<ProfileActions.ProfileDoLikeJDL>(ProfileActions.ProfileActionTypes.ProfileDoLikeJDL),
		mergeMap((action) =>
			this.dashboardService.doLike(action.payload.userId).pipe(
				map((results: any) => {
					if (results.status === 200) {
						return new ProfileActions.ProfileDoLikeJDLSuccess({userId: action.payload.userId});
					}
				}))));

    @Effect()
    doLike$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileDoLike>(ProfileActions.ProfileActionTypes.ProfileDoLike),
        mergeMap((action) =>
            this.dashboardService.doLike(action.payload.userId).pipe(
                map((results: any) => {
                    if (results.status === 200) {

                        // disparar acciones para sincronizar UI de acuerdo a la ruta activa
                        switch (this.route.url) {
                            case '/gustaron':
                                this.store.dispatch(new GustaronToggleByID({userId: action.payload.userId, liked: true}));
                                break;
                            case '/nogustaron':
                                this.store.dispatch(new NoGustaronToggleByID({userId: action.payload.userId, liked: true}));
                                break;
                            case '/dashboard':
                            case '/dashboard/search':
                                this.store.dispatch(new DashboardDoLikedByID({userId: action.payload.userId}));
                                break;
                        }
						return new ProfileActions.ProfileDoLikeSuccess({userId: action.payload.userId});
                    } else {
                        return new ProfileActions.ProfileFail();
                    }
                }),
                catchError(err => of(new ProfileActions.ProfileFail()))
            )
        )
    );


    @Effect()
    doDisike$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileDoDislike>(ProfileActions.ProfileActionTypes.ProfileDoDislike),
        mergeMap((action) =>
            this.dashboardService.doDislike(action.payload.userId).pipe(
                map((results: any) => {
                    if (results.status === 200) {

                        // disparar acciones para sincronizar UI de acuerdo a la ruta activa
                        switch (this.route.url) {
                            case '/gustaron':
                                this.store.dispatch(new GustaronToggleByID({userId: action.payload.userId, liked: false}));
                                break;

                            case '/nogustaron':
                                this.store.dispatch(new NoGustaronToggleByID({userId: action.payload.userId, liked: false}));
                                break;

                            case '/dashboard':
                            case '/dashboard/search':
                                this.store.dispatch(new DashboardDoDislikedByID({userId: action.payload.userId}));
                                break;
							case '/mensajes':
								this.store.dispatch(new MensajesInitSearch({type: IntMensajesListings.MENSAJES_RECEIVED}))
								break;
                        }

                        this.store.dispatch(new BodyScrollUnLock());

                        this.store.dispatch(new SidebarGetVisitsSent());
                        this.store.dispatch(new SidebarGetVisitsRcv());

                        return new ProfileActions.ProfileDoDislikeSuccess({userId: action.payload.userId});
                    } else {
                        return new ProfileActions.ProfileFail();
                    }
                }),
                catchError(err => of(new ProfileActions.ProfileFail()))
            )
        )
    );

    @Effect()
    cancelLike$ = this.actions$.pipe(
        ofType<ProfileActions.ProfileDoUndoLike>(ProfileActions.ProfileActionTypes.ProfileDoUndoLike),
        mergeMap((action) =>
            this.dashboardService.cancelLike(action.payload.userId).pipe(
                map((results: any) => {
                    if (results.status === 200) {

                        // disparar acciones para sincronizar UI de acuerdo a la ruta activa
                        switch (this.route.url) {
                            case '/gustaron':
                                this.store.dispatch(new GustaronToggleByID({userId: action.payload.userId, liked: false}));
                                break;

                            case '/dashboard':
                            case '/dashboard/search':
                                this.store.dispatch(new DashboardDoUndoLikedByID({userId: action.payload.userId}));
                                break;
                        }

                        return new ProfileActions.ProfileDoUndoLikeSuccess({userId: action.payload.userId});
                    } else {
                        return new ProfileActions.ProfileFail();
                    }
                }),
                catchError(err => of(new ProfileActions.ProfileFail()))
            )
        )
    );
}
