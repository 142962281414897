import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntMensajesListings, IntMensajesListingResults, IntMensajesUsersIdList} from './mensajes.model';

@Injectable()
export class MensajesService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public listing(listtype: IntMensajesListings): Observable<IntMensajesListingResults> {
        return this.http
        .get<IntMensajesListingResults>(`${environment.appApi}/${environment.endpoints.messageslist}/` + listtype,
			{ headers:{'platform':'desktop'}});

    }

    /**
     * Scrolls
     * @param listtype
     * @param 0-based page number
     */
    public scroll(listtype: IntMensajesListings, page: number): Observable<IntMensajesListingResults> {
        return this.http
        .get<IntMensajesListingResults>(`${environment.appApi}/${environment.endpoints.messageslist}/` + listtype + '/' + (page),
			{ headers:{'platform':'desktop'}});

    }

    public markAsRead(listtype: IntMensajesListings, users: IntMensajesUsersIdList): Observable<IntMensajesListingResults> {

        return this.http
        .patch<IntMensajesListingResults>(`${environment.appApi}/${environment.endpoints.messagesmarkasread}/` + listtype, users);

    }

    public deleteMessages(listtype: IntMensajesListings, users: IntMensajesUsersIdList): Observable<IntMensajesListingResults> {

        return this.http
        .request<IntMensajesListingResults>('DELETE', `${environment.appApi}/${environment.endpoints.messagesdelete}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: users
        });

    }

}
