import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntAutologin} from '../../store/user/user.model';
import {LoggedInUser, SetAutologin} from '../../store/user/user.actions';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {from, Subscription} from 'rxjs';
import {UsersService} from '../../store/user/users.service';

@Component({
  selector: 'app-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.scss']
})
export class AutologinComponent implements OnInit, OnDestroy {

  constructor(
  	private store: Store<fromRoot.State>,
  	private route: ActivatedRoute,
	private router: Router,
	private userService: UsersService
  ) { this.checkAutologin(); }

  autologinData: IntAutologin = {
  	mailToken: null,
	action: null,
	receptorId: null,
	origin: null,
	idabono: null
  }

  subAutologin: Subscription = null;

  ngOnInit() {
	  this.subAutologin = this.store.pipe(select(fromRoot.getAutologin)).subscribe( data => {
	  	if(data !== null) {
	  		if(data.autologin.success) {
				// resets token and set news
				this.userService.clearTokenStorage();
				this.userService.setTokens(data.token, data.recovery_token);
				// after login dispatch user details
				this.store.dispatch(new LoggedInUser({loginData: data}));
			}else{
				this.logout();
			}
		}
	  });
  }

  ngOnDestroy() {
  	if(this.subAutologin !== null) { this.subAutologin.unsubscribe(); }
  }

  private checkAutologin(){
  	//
	  const token = this.route.snapshot.paramMap.get('token');
	  if(token){
		//
		  this.autologinData = {
		  	mailToken: token,
			action: this.route.snapshot.paramMap.get('action'),
			receptorId: parseInt(this.route.snapshot.paramMap.get('userid')),
			origin: this.route.snapshot.paramMap.get('db')
		  }

		  // HACK para paywall 25
		  let _idabono = this.route.snapshot.queryParamMap.get('idabono');
		  if( _idabono != null) {
		  	this.autologinData.action = 'o';
			this.autologinData.idabono = _idabono;
		  }
		  //
		  this.store.dispatch(new SetAutologin({data: this.autologinData}));
		//
	  }else{
	  	this.logout();
	  }
	//
  }

  private logout() {
	  this.router.navigateByUrl('logout');
  }

}
