import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError,
    map, mergeMap, tap, withLatestFrom,
} from 'rxjs/operators';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {MensajesService} from './mensajes.service';
import * as UsersActions from '../user/user.actions';
import * as MensajesActions from './mensajes.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';
import {IntMensajesUsersIdList} from './mensajes.model';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class MensajesEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private mensajesService: MensajesService,
		private toastr: ToastrService,
    ) {
    }

    @Effect()
    initListing$ = this.actions$.pipe(
        ofType<MensajesActions.MensajesInitSearch>(MensajesActions.MensajesActionTypes.MensajesInitSearch),
        mergeMap((action) =>
            this.mensajesService.listing( action.payload.type ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new MensajesActions.MensajesDoSearch({results: results});
                    } else {
                        return new MensajesActions.MensajesFail({results: results});
                    }
                }),
                catchError(err => of(new MensajesActions.MensajesFail({results: err})))
            )
        )
    );

    @Effect()
    initScroll$ = this.actions$.pipe(
        ofType<MensajesActions.MensajesInitScroll>(MensajesActions.MensajesActionTypes.MensajesInitScroll),
        withLatestFrom(this.store),
        mergeMap(( params ) => {
            return this.mensajesService.scroll(params[0].payload.type, params[1].mensajes.currentPage ).pipe(
                map((results: any) => {
                    if (results.data && results.data.chats) {
                        return new MensajesActions.MensajesDoScroll({results: results});
                    } else {
                        return new MensajesActions.MensajesFail({results: results});
                    }
                }),
                catchError(err => of(new MensajesActions.MensajesFail({results: err})))
            );
        })
    );


    @Effect()
    markAsRead$ = this.actions$.pipe(
        ofType<MensajesActions.MensajesMarkAsRead>(MensajesActions.MensajesActionTypes.MensajesMarkAsRead),
        withLatestFrom(this.store),
        mergeMap((params) => {
            const users: IntMensajesUsersIdList = {users: []};
            params[1].mensajes.currentResults.data.chats.filter( msg => msg.selected ).map((msg) => users.users.push(msg.userId));

            // if no user was selected, do nothing
            if (users.users.length === 0) {
                return of(new MensajesActions.MensajesMarkedAsRead());
            }

           return this.mensajesService.markAsRead( params[0].payload.type, users ).pipe(
                map( (result: any) => new MensajesActions.MensajesMarkedAsRead()),
                tap( () => this.store.dispatch(new UsersActions.UserCounters()) ),
                catchError(err => of(new MensajesActions.MensajesFail({results: err})))
           );
        })
    );


    @Effect()
    delete$ = this.actions$.pipe(
        ofType<MensajesActions.MensajesDeleteSelected>(MensajesActions.MensajesActionTypes.MensajesDeleteSelected),
        withLatestFrom(this.store),
        mergeMap((params) => {
            const users: IntMensajesUsersIdList = {users: []};
            params[1].mensajes.currentResults.data.chats.filter( msg => msg.selected ).map((msg) => users.users.push(msg.userId));

            // if no user was selected, do nothing
            if (users.users.length === 0) return of(new MensajesActions.MensajesDeleted());


            const reloadMessages = params[1].mensajes.currentResults.data.chats.length === users.users.length;

            return this.mensajesService.deleteMessages( params[0].payload.type, users ).pipe(
                tap( () => this.store.dispatch(new UsersActions.UserCounters()) ),
                map( (result: any) => {
					this.toastr.info('', 'Se borraron los mensajes',
						{progressBar: true, positionClass: 'toast-top-center'});
                    if (reloadMessages) {
                        // if there are no messages, fire reload!
                        return new MensajesActions.MensajesInitSearch({type: params[0].payload.type});
                    } else {
                        return new MensajesActions.MensajesDeleted();
                    }
                }),
                catchError(err => of(new MensajesActions.MensajesFail({results: err})))
            );
        })
    );
}
