export enum IntDashboardGetListing {
    DASH_RECENT = 'recent',
    DASH_NEW = 'new',
    DASH_ONLINE = 'online',
    DASH_SEARCH = 'search'
}

export interface IntValueLabelOption {
    l: string;
    v: number|string;
}

export interface IntDashboardGetListingScroll {
    scrollHash: string;
}

export interface IntDashboardSearchParams {
    'edad_min': string|number;
    'edad_max': string|number;
    'id_provincia': string;
    'id_localidad': string;
    'distancia': string|number;
    'apodo': string;
    'estado_civil': Array<string>|string;
    'hijos': string;
    'religion': Array<string>|string;
    'busco': Array<string>|string;
    'altura': Array<string>|string;
    'estudios': Array<string>|string;
    'deseo_hijos': string;
    'fuma': Array<string>|string;
    'bebe': Array<string>|string;
    'signo': Array<string>|string;
}

export interface IntDashboardListingResult {
    'ciudad': string;
    'id_usuario': string;
    'imagen': string;
    'imagen_gcp': string;
    'edad': number;
    'apodo': string;
    'estado': string;
    'favorito': boolean;
    'score': number;
    'has_imagen': true;
    'gender': string;
    'created': string;
    'last_activity': string;
    'distancia': string;
    '_loading'?: boolean;

    'isNotice'?: boolean;
    'link'?: string;
    'img'?: string;
    'imgDesktop'?: string;
}

export interface IntDashboardListingResults {
    'status'?: number;
    'error'?: string;
    'scroll_id'?: string;
    'more'?: boolean;
    'hits'?: IntDashboardListingResult[];
    totOnline?: number;
}

export interface IntSearchOptionValueLabel {
    label: string;
    value: string;
}
export interface IntSearchOptionField {
    fieldId: string;
    fieldName: string;
    field: string;
    type: 'm' | 's';
    options: IntSearchOptionValueLabel[];
}
export interface IntSearchOptionSection {
    section: string;
    list: IntSearchOptionField[];
}
export interface IntSearchOptionReply {
    status: number;
    data: IntSearchOptionSection[];
    'error'?: string;
}
