import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

import * as uiActions from './ui.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {UiService} from './ui.service';


@Injectable()
export class UiEffects {
    constructor(
        private actions$: Actions,
        public titleService: Title,
        public uiService: UiService
    ) {
    }


    @Effect({dispatch: false})
    settitle$ = this.actions$.pipe(
        ofType<uiActions.SetCurrentTitle>(uiActions.UiActionTypes.SetCurrentTitle),
        tap((action) => {
            this.titleService.setTitle(action.payload ? action.payload : 'Zona Citas');
        })
    );

    @Effect()
    sidebarGetMsgSent$ = this.actions$.pipe(
        ofType<uiActions.SidebarGetMsgSent>(uiActions.UiActionTypes.SidebarGetMsgSent),
        mergeMap((action) =>
            this.uiService.getSidebarData( 'sent-messages' ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new uiActions.SidebarGetMsgSentSuccess({users: results.data});
                    } else {
                        return new uiActions.SidebarGetFail();
                    }
                }),
                catchError(err => of(new uiActions.SidebarGetFail()))
            )
        )
    );

    @Effect()
    sidebarGetMsgRcv$ = this.actions$.pipe(
        ofType<uiActions.SidebarGetMsgRcv>(uiActions.UiActionTypes.SidebarGetMsgRcv),
        mergeMap((action) =>
            this.uiService.getSidebarData( 'received-messages' ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new uiActions.SidebarGetMsgRcvSuccess({users: results.data});
                    } else {
                        return new uiActions.SidebarGetFail();
                    }
                }),
                catchError(err => of(new uiActions.SidebarGetFail()))
            )
        )
    );

    @Effect()
    sidebarGetVisitsSent = this.actions$.pipe(
        ofType<uiActions.SidebarGetVisitsSent>(uiActions.UiActionTypes.SidebarGetVisitsSent),
        mergeMap((action) =>
            this.uiService.getSidebarData( 'sent-visits' ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new uiActions.SidebarGetVisitsSentSuccess({users: results.data});
                    } else {
                        return new uiActions.SidebarGetFail();
                    }
                }),
                catchError(err => of(new uiActions.SidebarGetFail()))
            )
        )
    );

    @Effect()
    sidebarGetVisitsRcv$ = this.actions$.pipe(
        ofType<uiActions.SidebarGetVisitsRcv>(uiActions.UiActionTypes.SidebarGetVisitsRcv),
        mergeMap((action) =>
            this.uiService.getSidebarData( 'received-visits' ).pipe(
                map((results: any) => {
                    if ((results.status === 200) && results.data) {
                        return new uiActions.SidebarGetVisitsRcvSuccess({users: results.data});
                    } else {
                        return new uiActions.SidebarGetFail();
                    }
                }),
                catchError(err => of(new uiActions.SidebarGetFail()))
            )
        )
    );

}
