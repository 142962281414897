import {AfterViewInit, Component, ElementRef, Injectable, Input, SecurityContext, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-dialogs-service-modal-content',
    template: `
        <div class="modal-body dialogs-body">
            <p *ngIf="!prompt" [innerHTML]="getcaption()"></p>
            <input *ngIf="prompt" #inputprompt type="text" class="form-control" [(ngModel)]="current_val" maxlength="255"/>
        </div>
        <div class="modal-footer text-center">
            <a href="javascript:void(0)" *ngIf="!confirm && !prompt" class="btn rosa-zc" (click)="activeModal.close(false)">Cerrar</a>
            
            <a href="javascript:void(0)" *ngIf="confirm" class="btn rosa-zc" (click)="activeModal.close(true)"><span class="fa fa-check"></span> Confirmar</a>
            &nbsp;
            <a href="javascript:void(0)" *ngIf="confirm" class="btn btn-gray-zc" (click)="activeModal.close(false)"><span class="fa fa-times"></span> Cancelar</a>

            <a href="javascript:void(0)" *ngIf="prompt" class="btn rosa-zc" (click)="activeModal.close(current_val)"><span class="fa fa-check"></span> Confirmar</a>
            &nbsp;
            <a href="javascript:void(0)" *ngIf="prompt" class="btn btn-gray-zc" (click)="activeModal.close(false)"><span class="fa fa-times"></span> Cancelar</a>
        </div>
    `
})
export class _DialogsServiceModalContent implements AfterViewInit {
    @ViewChild('inputprompt') inputEl: ElementRef;
    @Input() caption;
    @Input() current_val;
    @Input() confirm: boolean;
    @Input() prompt: boolean;

    constructor(public activeModal: NgbActiveModal, public sanitizer: DomSanitizer) {
    }

    ngAfterViewInit() {
        if (this.prompt) {
            this.inputEl.nativeElement.focus();
        }
    }

    title() {
        return this.confirm ? 'Confirm' : (this.prompt ? this.caption : 'Alert');
    }

    getcaption() {

        return this.sanitizer.sanitize(SecurityContext.HTML, (this.confirm ? '<span class="fa fa-question-circle"></span> ' : '')  +  this.caption);

    }
}


@Injectable({
    providedIn: 'root'
})
export class DialogsService {

    constructor(public modalService: NgbModal) {
    }

    public confirm(s: string, cb: Function) {
        const modalRef = this.modalService.open(_DialogsServiceModalContent, {size: 'lg', centered: true, backdrop: 'static'});
        modalRef.componentInstance.caption = s;
        modalRef.componentInstance.confirm = true;
        modalRef.componentInstance.prompt = false;
        modalRef.result.then((ret) => {
                if (ret) {
                    cb.call(cb);
                }
            },
            () => {
                return false;
            });
    }

    public prompt(s: string, current_val: any, cb: Function) {
        const modalRef = this.modalService.open(_DialogsServiceModalContent, {size: 'lg', centered: true, backdrop: 'static'});
        modalRef.componentInstance.caption = s;
        modalRef.componentInstance.current_val = current_val;
        modalRef.componentInstance.confirm = false;
        modalRef.componentInstance.prompt = true;
        modalRef.result.then((ret) => {
                if (ret !== false) {
                    cb.call(cb, ret);
                }
            },
            () => {
                return false;
            });
    }

    public alert(s: string) {
        const modalRef = this.modalService.open(_DialogsServiceModalContent, {size: 'sm', centered: true, backdrop: 'static'});
        modalRef.componentInstance.caption = s;
        modalRef.componentInstance.confirm = false;
        modalRef.componentInstance.prompt = false;
    }

}
