import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {IntGAGenericEvent} from '../store/user/user.model';

declare var dataLayer: any;

@Injectable({
    providedIn: 'root'
})
export class GaService {

    constructor() {
    }

    public gaTrack(event: IntGAGenericEvent | any) {
		dataLayer.push(event);
    }

    public triggerGenericEvent(event: 'modal' | 'evento', category: string, action: string) {
        this.triggerGenericEventValue(event, category, action, '');
    }

    public triggerGenericEventValue(event: 'modal' | 'evento', category: string, action: string, value: any) {
        // Map de categorías a labels
        const cats_labels = {
            'juego_like': {
                'impresion': 'Impresion del juego de like',
                'siguiente_perfil': 'Clic en siguiente perfil en el juego de like',
                'cambio_foto': 'Clic en ver otra foto en el juego de like',
                'ver_mas': 'Clic en ver mas en el juego de like',
                'cerrar': 'Clic en cerrar el juego de like',
            },

            'post_registro': {
                'promo_clic': 'Clic en promo 4to modal 3dx3$',
                'promo_cerrar': 'Cerrar promo 4to modal 3dx3$',
            },

            'no_me_gusta': {
                'juego_like': 'No me gusta desde el juego de like',
                'vista_perfil': 'No me gusta desde vista de perfil',
                'recientes': 'No me gusta desde el listado de recientes',
                'online': 'No me gusta desde el listado de online',
                'busqueda': 'No me gusta desde el listado de busqueda',
                'nuevos': 'No me gusta desde el listado de nuevos',
				'autologin': 'DisLike desde mail transaccional',
            },

            'me_gusta': {
                'juego_like': 'Me gusta desde el juego de like',
                'vista_perfil': 'Me gusta desde vista de perfil',
                'recientes': 'Me gusta desde el listado de recientes',
                'online': 'Me gusta desde el listado de online',
                'busqueda': 'Me gusta desde el listado de busqueda',
                'nuevos': 'Me gusta desde el listado de nuevos',
				'autologin': 'Like desde mail transaccional',
            },

            'se_gustan': {
                'impresion': 'Impresion del modal de se gustan',
            },

            'vista_perfil': {
                'online': 'Vista de perfil desde el listado de online',
                'alerta_evento': 'Vista de perfil desde alerta de eventos',
                'recientes': 'Vista de perfil desde el listado de recientes',
                'busqueda': 'Vista de perfil desde el listado de busqueda',
                'mensajes': 'Vista de perfil desde la pagina de mensajes',
                'barra_lateral': 'Vista de perfil desde la barra lateral',
                'nuevos': 'Vista de perfil desde el listado de nuevos',
                'chat': 'Vista de perfil desde el chat',
				'autologin': 'Vista de mail transaccional',
				'masperfiles': 'Vista de perfil desde Mas Perfiles'
            },

            'chat': {
                'mensajes_recibidos': 'Abrir chat desde la pagina de mensajes recibidos',
                'alerta_evento': 'Abrir chat desde alerta de eventos',
                'barra_lateral': 'Abrir chat desde la barra lateral',
                'recientes': 'Abrir chat desde el listado de recientes',
                'online': 'Abrir chat desde el listado de online',
                'busqueda': 'Abrir chat desde el listado de busqueda',
                'mensajes_enviados': 'Abrir chat desde la pagina de mensajes enviados',
                'se_gustan': 'Abrir chat desde el modal de se gustan',
                'nuevos': 'Abrir chat desde el listado de nuevos',
				'autologin': 'Abre chat desde mail transaccional',
				'visitas': 'Abre desde el listado de visitas'
            },

            'descripcion': {
                'mi_perfil': 'Completar descripcion en mi perfil',
                'post_registro': 'Completar descripcion en modal post registro',
            },

            'subir_foto': {
                'mi_perfil': 'Subir foto en mi perfil',
                'post_registro': 'Subir foto en modal post registro',
            },

            'registro': {
                'h': 'Registro hombre',
                'm': 'Registro mujer',
                'g': 'Registro gay',
                'l': 'Registro lesbiana',
				'e': 'Edad'
            },

            'scroll': {
                'recientes': 'Scroll en el listado de recientes',
                'online': 'Scroll en el listado de online',
                'busqueda': 'Scroll en ellistado de busqueda',
                'nuevos': 'Scroll en el listado de nuevos',
            },

            'marketing': {
                'compra': 'Compra completada',
				'promomail': 'Promo enviada por mail'
            },
        };

        if (cats_labels[category] && cats_labels[category][action]) {
            const gaEvent: IntGAGenericEvent = {
                event: event,
                category: category,
                action: action,
                label: cats_labels[category][action],
                value: value
            };

            this.gaTrack(gaEvent);
        } else if (!environment.production) {
            alert(`GA Category ${category} and Action ${action} not defined`);
        }
    }
}
