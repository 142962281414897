import {IntNoGustaronListingResults} from './nogustaron.model';
import {NoNoGustaronActions, NoGustaronActionTypes} from './nogustaron.actions';

export interface NoGustaronState {
    currentResults: IntNoGustaronListingResults;
    currentPage: number;
    inProcess: boolean;
}


export const initialState: NoGustaronState = {
    currentResults: {
        data: []
    },
    currentPage: 1,
    inProcess: false
};

export function reducer(
    state = initialState,
    action: NoNoGustaronActions
): NoGustaronState {
    switch (action.type) {

        case NoGustaronActionTypes.NoGustaronCleanup: {
            return {
                ...initialState,
            };
        }
        case NoGustaronActionTypes.NoGustaronInitSearch: {
            return {
                ...state,
                currentResults: {
                    data: [],
                    error: null,
                    more: false,
                    status: null
                },
                inProcess: true,
                currentPage: 1
            };
        }

        case NoGustaronActionTypes.NoGustaronDoSearch: {
            // if message type has been changed, discard result
            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null

                },
                inProcess: false,
                currentPage: 1
            };
        }

        case NoGustaronActionTypes.NoGustaronInitScroll: {
            return {
                ...state,
                inProcess: true
            };
        }

        case NoGustaronActionTypes.NoGustaronDoScroll: {
            // if message type has been changed, discard result
            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null
                },
                inProcess: false,
                currentPage: state.currentPage + 1
            };
        }

        case NoGustaronActionTypes.NoGustaronFail: {
            return {
                ...state,
                currentResults: {
                    error: action.payload.results.error || null,
                    status: action.payload.results.status || null,
                    more: false,
                    data: []
                },
                inProcess: false,
                currentPage: 1
            };
        }

        case NoGustaronActionTypes.NoGustaronCancel: {
            return {
                ...state,
                inProcess: true
            };
        }

        case NoGustaronActionTypes.NoGustaronCanceled: {
            const tmp = state.currentResults;
            const users = tmp.data.filter( u => u.userId !== action.payload.user.userId );
            tmp.data = [...users];

            return {
                ...state,
                currentResults: {...tmp},
                inProcess: false
            };
        }

        case NoGustaronActionTypes.NoGustaronToggleByID: {
            const tmp = state.currentResults;
            tmp.data.forEach( (o) => {
                if (o.userId === action.payload.userId) {
                    // mostrar o esconder de acuerdo a liked
                    o._hide = action.payload.liked;
                }
            });

            return {
                ...state,
                currentResults: {...tmp},
                inProcess: false
            };
        }



        default: {
            return state;
        }
    }
}
