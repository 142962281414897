import {Action} from '@ngrx/store';
import {IntNoGustaronListingResult, IntNoGustaronListingResults} from './nogustaron.model';

export enum NoGustaronActionTypes {
    NoGustaronInitSearch    = '[NoGustaron API] Init search',
    NoGustaronDoSearch      = '[NoGustaron API] Do search',
    NoGustaronInitScroll    = '[NoGustaron API] Init Scroll',
    NoGustaronDoScroll      = '[NoGustaron API] Do scroll',
    NoGustaronCancel        = '[NoGustaron API] Cancel dislike',
    NoGustaronCanceled      = '[NoGustaron API] Canceled dislike',
    NoGustaronToggleByID    = '[NoGustaron API] Toggle by ID',
    NoGustaronFail          = '[NoGustaron API] Fail',
    NoGustaronCleanup       = '[NoGustaron API] Cleanup',
}

export class NoGustaronInitSearch implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronInitSearch;
}

export class NoGustaronDoSearch implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronDoSearch;

    constructor(public payload: { results: IntNoGustaronListingResults }) {
    }
}

export class NoGustaronInitScroll implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronInitScroll;
}

export class NoGustaronDoScroll implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronDoScroll;

    constructor(public payload: { results: IntNoGustaronListingResults }) {
    }
}

export class NoGustaronFail implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronFail;

    constructor(public payload: { results: IntNoGustaronListingResults }) {
    }
}

export class NoGustaronCancel implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronCancel;

    constructor(public payload: { user: IntNoGustaronListingResult }) {
    }
}

export class NoGustaronCanceled implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronCanceled;

    constructor(public payload: { user: IntNoGustaronListingResult }) {
    }
}

export class NoGustaronToggleByID implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronToggleByID;

    constructor(public payload: { userId: string, liked: boolean }) {
    }
}

export class NoGustaronCleanup implements Action {
    readonly type = NoGustaronActionTypes.NoGustaronCleanup;
}

export type NoNoGustaronActions =
    NoGustaronInitSearch
    | NoGustaronDoSearch
    | NoGustaronInitScroll
    | NoGustaronDoScroll
    | NoGustaronCancel
    | NoGustaronCanceled
    | NoGustaronToggleByID
    | NoGustaronCleanup
    | NoGustaronFail;
