import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ActivatedRoute, Router} from '@angular/router';
import {GaService} from '../../services/ga.service';
import {RecomendadosListing} from '../../store/recomendados/recomendados.actions';
import {Observable} from 'rxjs';
import {IntRecomendadosListing} from '../../store/recomendados/recomendados.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {rowsIn} from '../../directives/animations';

@Component({
	selector: 'app-recomendados',
	templateUrl: './recomendados.component.html',
	styleUrls: ['./recomendados.component.scss'],
	animations: [ rowsIn ]
})
export class RecomendadosComponent implements OnInit {

  isLoading$: Observable<boolean> = this.store.pipe(select(fromRoot.isMatchingLoaded));
  results$: Observable<IntRecomendadosListing> = this.store.pipe(select(fromRoot.getMatchingResults));

  constructor(private store: Store<fromRoot.State>,
			  private route: ActivatedRoute,
			  private ga: GaService,
			  private router: Router) { }

  ngOnInit(): void {
	this.store.dispatch(new RecomendadosListing())
  }

	viewProfile(userId: string) {
		this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: null}));
		this.ga.triggerGenericEvent('evento', 'vista_perfil', 'mensajes');
	}

}
