import {Action} from '@ngrx/store';
import {
	IntPaymentFail,
	IntUIBannerMkt,
	IntUICCInfo,
	IntUISidebarMensajesTab,
	IntUISidebarUserLinks,
	IntUISidebarVisitasTab
} from './ui.model';


export enum UiActionTypes {
    SetCurrentTitle = '[UI] SET CURRENT TITLE',
    BodyScrollLock = '[UI] BODY SCROLL LOCK',
    BodyGenderClass = '[UI] BODY GENDER',
    BodyScrollUnLock = '[UI] BODY SCROLL UNLOCK',
    SidebarSetVisitasTab = '[UI] SIDEBAR Set visitas tab',
    SidebarSetMensajesTab = '[UI] SIDEBAR Set mensajes tab',
    SidebarGetMsgSent = '[UI] SIDEBAR Get messages sent',
    SidebarGetMsgSentSuccess = '[UI] SIDEBAR Get messages sent Success',
    SidebarGetMsgRcv = '[UI] SIDEBAR Get messages rcv',
    SidebarGetMsgRcvSuccess = '[UI] SIDEBAR Get messages rcv Success',
    SidebarGetVisitsSent = '[UI] SIDEBAR Get visits sent',
    SidebarGetVisitsSentSuccess = '[UI] SIDEBAR Get visits sent Success',
    SidebarGetVisitsRcv = '[UI] SIDEBAR Get visits rcv',
    SidebarGetVisitsRcvSuccess = '[UI] SIDEBAR Get visits rcv Success',
    SidebarGetFail = '[UI] SIDEBAR Get fail',

    UpdateSidebarMsgRcv = '[UI] SIDEBAR Update msg rcv',
    UpdateSidebarMsgSent = '[UI] SIDEBAR Update msg sent',
    UpdateSidebarVisitsRcv = '[UI] SIDEBAR Update visits rcv',
    UpdateSidebarVisitsSent = '[UI] SIDEBAR Update visits sent',
    UICleanup = '[UI] Cleanup',

    BannerMkt = '[UI] BannerMkt',

	UIStatic = '[UI] IsStaticPage',

	UIUploadPhoto = '[UI] Upload Photo',

	UISuscribiteMenu		= '[UI] Menu Suscribite',
	UISuscribiteMenuBack    = '[UI] Menu Suscribite Back',

	PaymentLoading			= '[UI] Payment Loading',
	PaymentSuccess			= '[UI] Payment Success',
	PaymentCCInfo			= '[UI] Payment Credit Card Info',
	PaymentFails			= '[UI] Payment fails',
	PaymentThankYouPage		= '[UI] Payment Thankyou page',
	PaymentThankYouPageError= '[UI] Payment Thankyou error',

	ZendeskShow				= '[UI] Zendesk show',

	LoginErrorContainerShow	= '[UI] Login error container show',

}

export class LoginErrorContainerShow implements Action{
	readonly type = UiActionTypes.LoginErrorContainerShow
	public constructor(public payload: boolean) {}
}


export class ZendeskShow implements Action {
	readonly type = UiActionTypes.ZendeskShow
	public constructor(public payload: boolean) {}
}

export class PaymentFails implements Action {
	readonly type = UiActionTypes.PaymentFails
	public constructor(public payload: IntPaymentFail) {}
}

export class PaymentCCInfo implements Action {
	readonly type = UiActionTypes.PaymentCCInfo
	public constructor(public payload: IntUICCInfo) {}
}
export class PaymentLoading implements Action {
	readonly type = UiActionTypes.PaymentLoading
	public constructor(public payload: boolean) {}
}
export class PaymentSuccess implements Action {
	readonly type = UiActionTypes.PaymentSuccess
	public constructor(public payload: boolean) {}
}
export class PaymentThankYouPage implements Action {
	readonly type = UiActionTypes.PaymentThankYouPage
	public constructor(public payload: boolean) {}
}
export class PaymentThankYouPageError implements Action {
	readonly type = UiActionTypes.PaymentThankYouPageError
	public constructor(public payload: {code: string}) {}
}


export class SetSuscribiteMenuBack implements Action {
	readonly type = UiActionTypes.UISuscribiteMenuBack
	public constructor(public payload: boolean) { }
}

export class SetSuscribiteMenu implements Action {
	readonly type = UiActionTypes.UISuscribiteMenu
	public constructor(public payload: boolean) { }
}

export class SetUploadPhoto implements Action {
	readonly type = UiActionTypes.UIUploadPhoto
	public constructor(public payload?: boolean) {
	}
}

export class SetIsStaticPage implements Action {
	readonly type = UiActionTypes.UIStatic
	public constructor(public payload?: boolean) {
	}
}

export class UpdateSidebarMsgRcv implements Action {
    readonly type = UiActionTypes.UpdateSidebarMsgRcv;
    public constructor(public payload: { user: IntUISidebarUserLinks }) { }
}

export class UpdateSidebarMsgSent implements Action {
    readonly type = UiActionTypes.UpdateSidebarMsgSent;
    public constructor(public payload: { user: IntUISidebarUserLinks }) { }
}

export class UpdateSidebarVisitsRcv implements Action {
    readonly type = UiActionTypes.UpdateSidebarVisitsRcv;
    public constructor(public payload: { user: IntUISidebarUserLinks }) { }
}

export class UpdateSidebarVisitsSent implements Action {
    readonly type = UiActionTypes.UpdateSidebarVisitsSent;
    public constructor(public payload: { user: IntUISidebarUserLinks }) { }
}

export class SidebarGetFail implements Action {
    readonly type = UiActionTypes.SidebarGetFail;
}

export class SidebarGetMsgSent implements Action {
    readonly type = UiActionTypes.SidebarGetMsgSent;
}

export class SidebarGetMsgRcv implements Action {
    readonly type = UiActionTypes.SidebarGetMsgRcv;
}

export class SidebarGetVisitsSent implements Action {
    readonly type = UiActionTypes.SidebarGetVisitsSent;
}

export class SidebarGetVisitsRcv implements Action {
    readonly type = UiActionTypes.SidebarGetVisitsRcv;
}

export class SidebarGetMsgSentSuccess implements Action {
    readonly type = UiActionTypes.SidebarGetMsgSentSuccess;
    public constructor(public payload: { users: IntUISidebarUserLinks[] }) {
    }
}

export class SidebarGetMsgRcvSuccess implements Action {
    readonly type = UiActionTypes.SidebarGetMsgRcvSuccess;
    public constructor(public payload: { users: IntUISidebarUserLinks[] }) {
    }
}

export class SidebarGetVisitsSentSuccess implements Action {
    readonly type = UiActionTypes.SidebarGetVisitsSentSuccess;
    public constructor(public payload: { users: IntUISidebarUserLinks[] }) {
    }
}

export class SidebarGetVisitsRcvSuccess implements Action {
    readonly type = UiActionTypes.SidebarGetVisitsRcvSuccess;
    public constructor(public payload: { users: IntUISidebarUserLinks[] }) {
    }
}



export class SetCurrentTitle implements Action {
    readonly type = UiActionTypes.SetCurrentTitle;

    constructor(public payload?: string) {
    }
}

export class BodyScrollLock implements Action {
    readonly type = UiActionTypes.BodyScrollLock;
}

export class BodyGenderClass implements Action {
    readonly type = UiActionTypes.BodyGenderClass;
    public constructor(public payload: { class: string }) {
    }
}

export class BodyScrollUnLock implements Action {
    readonly type = UiActionTypes.BodyScrollUnLock;
}

export class SidebarSetVisitasTab implements Action {
    readonly type = UiActionTypes.SidebarSetVisitasTab;

    public constructor(public payload: { activeTab: IntUISidebarVisitasTab }) {
    }
}

export class SidebarSetMensajesTab implements Action {
    readonly type = UiActionTypes.SidebarSetMensajesTab;

    public constructor(public payload: { activeTab: IntUISidebarMensajesTab }) {
    }
}

export class BannerMkt implements Action {
    readonly type = UiActionTypes.BannerMkt;

    public constructor(public payload: { mkt: IntUIBannerMkt }) {
    }
}
export class UICleanup implements Action {
    readonly type = UiActionTypes.UICleanup;
}

export type UiActions =
    SetCurrentTitle |
    BodyGenderClass |
    BodyScrollLock |
    BodyScrollUnLock |
    SidebarSetMensajesTab |
    SidebarSetVisitasTab |
    SidebarGetMsgSent  |
    SidebarGetMsgSentSuccess  |
    SidebarGetMsgRcv |
    SidebarGetMsgRcvSuccess |
    SidebarGetVisitsSent |
    SidebarGetVisitsSentSuccess |
    SidebarGetVisitsRcv |
    SidebarGetVisitsRcvSuccess |
    SidebarGetFail |
    UpdateSidebarMsgRcv |
    UpdateSidebarMsgSent |
    UpdateSidebarVisitsRcv |
    UpdateSidebarVisitsSent |
    UICleanup |
    BannerMkt |
	SetIsStaticPage |
	SetUploadPhoto |
	SetSuscribiteMenu|
	SetSuscribiteMenuBack |
	PaymentLoading |
	PaymentSuccess |
	PaymentCCInfo |
	PaymentFails |
	PaymentThankYouPage |
	PaymentThankYouPageError |
	ZendeskShow |
	LoginErrorContainerShow;
