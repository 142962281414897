import {HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable, EMPTY, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserActionTypes} from '../store/user/user.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../store';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

    private lastActivity: Date = null;

    constructor(
        private route: Router,
        private toastr: ToastrService,
        private store: Store<fromRoot.State>
    ) {
        this.lastActivity = new Date();

        // verifica cada 30 segundos la actividad del usuario, desloguea si supera 30 minutos
        window.setInterval(() => {
            if ((new Date).getTime() - this.lastActivity.getTime() > 1800000) {
                this.store.dispatch({type: UserActionTypes.LogoutUser});
            }
        }, 1000);       // 30 * 60 * 1000
    }

    resetActivityTimer() {
        this.lastActivity = new Date();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // check request for SVG without JWT
        const _url = request.url;
        if (_url.indexOf('assets') !== -1) {
            request = request.clone({headers: request.headers.delete('Authorization', '')});
        }

        // solo resetar el timer de actividad si las URL NO machean URLs disparadas por eventos externos
        if (
            (_url.indexOf('counters') === -1) &&
            (_url.indexOf('received-visits') === -1)
        ) {
            this.resetActivityTimer();
        }

        return next.handle(request).pipe(
            catchError(
                (err) => {
                    if (err instanceof HttpErrorResponse) {
                    	if (err.status === 500) {

                            // show toast
                            this.toastr.error('Estamos teniendo problemas de comunicación... no sos vos, somos nosotros...', 'Oops', {
                                progressBar: true,
                                progressAnimation: 'increasing',
                                timeOut: 5000,
                                positionClass: 'toast-top-center'
                            });
                            return throwError(err);

                        } else if (err.status === 401) {

                            if ((this.route.url !== '/') && (this.route.url !== '/micuenta')) {
                                this.store.dispatch({type: UserActionTypes.LogoutUser});
                                return EMPTY;
                            } else {
                                return throwError(err);
                            }

                        } else if ((err.status === 400) && (err.url !== `${environment.appApi}/${environment.endpoints.logout}`)) {
                            if (err.error && err.error['error'] && (err.error['error'] === 'token_not_provided')) {
                                this.store.dispatch({type: UserActionTypes.LogoutUser});
                                return EMPTY;
                            } else {
                                return throwError(err);
                            }
                        } else if ((err.status === 400) && (err.url === `${environment.appApi}/${environment.endpoints.logout}`)) {
                            return throwError(err);
                        } else {
                            return throwError(err);
                        }
                    }

                    return throwError(err);
                }
            )
        );
    }
}
