import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {IntAccountDataInfo, IntLoginData} from '../../store/user/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangepassComponent} from './changepass.component';
import {BajaComponent} from './baja.component';
import {LogoutUser, ProfileAgeRange, UserAccountAlerts, UserAccountData} from '../../store/user/user.actions';
import {MatCheckboxChange, MatSlideToggleChange, MatDialog} from '@angular/material';
import {Options} from 'ng5-slider';
import {ToastrService} from 'ngx-toastr';
import {BajadebautComponent} from "./bajadebaut.component";

@Component({
    selector: 'app-cuenta',
    templateUrl: './cuenta.component.html',
    styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent implements OnInit {

    sliderEdadOptions: Options = {
        hidePointerLabels: false,
        hideLimitLabels: true,
        pushRange: true,
        // noSwitching: true,
        floor: 18,
        ceil: 99,
        step: 1
    };

    user$: Observable<IntLoginData> = this.store.pipe(select(fromRoot.getCurrentSession));
    accountData$: Observable<IntAccountDataInfo> = this.store.pipe(select(fromRoot.getCurrentAccountData));

    constructor(
        private store: Store<fromRoot.State>,
        public modalService: NgbModal,
		public dialog: MatDialog,
		private toastr: ToastrService,
    ) {
        this.store.dispatch(new UserAccountData());
    }

    ngOnInit() {
    }

    changePass() {
        this.dialog.open(ChangepassComponent, {
            panelClass: 'change-pass-dialog',
            width: '400px',
            disableClose: false,
            closeOnNavigation: true
        });
    }

    baja() {
        this.dialog.open(BajaComponent, {
            panelClass: 'baja-dialog',
            width: '400px',
            height: '300px',
            disableClose: false,
            closeOnNavigation: true
        });
    }

    updateNotifications(event: MatSlideToggleChange, platform: string, notif: string) {
        this.store.dispatch(new UserAccountAlerts({platform: platform, notif: notif, selected: event.checked}));
    }

    updateAgeRange(e: any) {
        this.store.dispatch(new ProfileAgeRange({min: e.value, max: e.highValue}));
    }

    cancelRenewal(){
        this.dialog.open(BajadebautComponent, {
			panelClass: 'change-pass-dialog',
			width: '600px',
			disableClose: true,
			closeOnNavigation: true
		});
    }

    dummySave(title: string) {
		this.toastr.info('Se guardaron los datos',title,{progressBar: true, positionClass: 'toast-top-center'});
    }
}
