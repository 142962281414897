import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IntGalleryPicture} from './gallery.component';
import {Expo} from 'gsap/all';
import {ZcUtilsService} from '../../services/zc-utils.service';

declare var TweenLite: any;

const ZOOM_LIST = [1, 2, 4, 8];

@Component({
    selector: 'app-gallery-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

    @ViewChild('img', { static: true }) img: ElementRef;

    @Input() image: IntGalleryPicture;
    @Input() imageIndex: number;
    @Input() gender: string = '1';

    @Output() zoomChange: EventEmitter<number> = new EventEmitter();

    _currentZoom = 0;

    scaleMIN = 0;
    scaleMAX = 0;
    scaleFrom = 0;
    scaleTo = 0;
    status = false;

    x = 0;
    xFrom = 0;
    xTo = 0;

    y = 0;
    yFrom = 0;
    yTo = 0;

    time = 0;


    @Input('currentZoom')
    set currentZoom(value: number) {
        this.setZoom(ZOOM_LIST[value]);     // this._currentZoom is updated in setZoom
    }

    @Input('currentIndex')
    set currentIndex(value: number) {
        if (value !== this.imageIndex) {
            this.setZoom(1);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // reposition the image
        this.setImageInitPosition();
    }


    constructor(
        private utils: ZcUtilsService,
    ) {
        this.scaleMIN = ZOOM_LIST[0];
        this.scaleMAX = ZOOM_LIST[ZOOM_LIST.length - 1];
        this.scaleFrom = this.scaleMIN;
        this.scaleTo = this.scaleFrom;
        this.status = false;
    }

    ngOnInit() {
    }

    onload() {
        this.setImageInitPosition();
    }

    setImageInitPosition() {
        // Init GSAP Matrix
        const w = window.innerWidth;
        const h = window.innerHeight;
        TweenLite.set(this.img.nativeElement, {
            x: (w - this.img.nativeElement.clientWidth) * 0.5,
            y: (h - this.img.nativeElement.clientHeight) * 0.5,
            scale: this.scaleMIN
        });
    }

    /**
     * Set Position
     * @param e
     */
    setPosition(e) {
        this.x = this.xFrom + e.deltaX;
        this.y = this.yFrom + e.deltaY;
        this.setHalfPosition();

        TweenLite.set(this.img.nativeElement, {x: this.xTo, y: this.yTo});
    }

    /**
     * Set Half Position
     */
    setHalfPosition() {
        const a = this.checkImageAxis();
        const x = (a.x === true) ? this.x : 0;
        const y = (a.y === true) ? this.y : 0;
        this.xTo = ((window.innerWidth - this.img.nativeElement.clientWidth) * 0.5) + x;
        this.yTo = ((window.innerHeight - this.img.nativeElement.clientHeight) * 0.5) + y;
    }

    /**
     * Check Image Axis
     * @returns {{x: boolean, y: boolean}}
     */
    checkImageAxis() {
        return {
            x: this.img.nativeElement.clientWidth * this.scaleFrom > window.innerWidth,
            y: this.img.nativeElement.clientHeight * this.scaleFrom > window.innerHeight
        };
    }

    @HostListener('pan', ['$event'])
    pan(e) {
        if (this.getZoomStatus() === true) {
            this.setPosition(e);
        }
    }

    /**
     * Pan End
     * @param e
     */
    @HostListener('panend', ['$event'])
    panEnd(e) {
        this.xFrom = this.x;
        this.yFrom = this.y;
        this.checkImagePosition();
    }

    @HostListener('tap', ['$event'])
    tap(e) {
        const time = new Date().getTime();
        if ((time - this.time < 250) && (this.status === false)) {
            this.zoomTap();
        }
        this.time = time;
    }

    getDefault(event: any) {
        event.target.src = this.utils.getUserImageURL(this.gender, null);
    }

    /**
     * Zoom Tap
     */
    zoomTap() {
        let next = ZOOM_LIST.filter((zoom) => zoom > this.scaleFrom)[0];
        if (!next) {
            next = ZOOM_LIST[0];
        }
        this.setZoom(next);
    }

    /**
     * Zoom In
     */
    zoomIn() {
        const next = ZOOM_LIST.filter((zoom) => zoom > this.scaleFrom)[0];
        if (next) {
            this.setZoom(next);
        }
    }

    /**
     * Zoom Out
     */
    zoomOut() {
        const next = ZOOM_LIST.filter((zoom) => zoom < this.scaleFrom);
        if (next.length !== 0) {
            this.setZoom(next[next.length - 1]);
        }
    }

    /**
     * Set Zoom
     * @param zoom
     */
    setZoom(zoom) {
        const tmp = ZOOM_LIST.indexOf(zoom);
        if (this._currentZoom !== tmp) {
            this._currentZoom = tmp;
            this.zoomChange.emit(this._currentZoom);
        } else {
            return;
        }

        this.xFrom = this.x = 0;
        this.yFrom = this.y = 0;
        this.setHalfPosition();

        this.scaleFrom = this.scaleTo = zoom;

        this.status = true;

        TweenLite.to(this.img.nativeElement, 0.5, {
            scale: this.scaleTo,
            x: this.xTo,
            y: this.yTo,
            ease: Expo.easeOut,
            onComplete: () => {
                this.status = false;
            }
        });
    }

    /**
     * Set Scale
     * @param scale
     */
    setScale(scale) {
        const s = this.scaleFrom * scale;
        this.scaleTo = Math.min(this.scaleMAX, Math.max(this.scaleMIN, s));
        TweenLite.set(this.img.nativeElement, {scale: this.scaleTo});
    }

    /**
     * Get Status
     * @returns {boolean}
     */
    getZoomStatus() {
        return (this._currentZoom > 0);

        // if (
        //     this.img.nativeElement.clientWidth * this.scaleFrom > window.innerWidth ||
        //     this.img.nativeElement.clientHeight * this.scaleFrom > window.innerHeight
        // ) {
        //     return true;
        // } else {
        //     return this.status || (this._currentZoom > 0);
        // }
    }


    /**
     * Check Image Position
     */
    checkImagePosition() {
        const a = this.checkImageAxis();
        const b = this.img.nativeElement.getBoundingClientRect();

        const left = b.left;
        const right = window.innerWidth - b.right;
        const top = b.top;
        const bottom = window.innerHeight - b.bottom;

        let x = this.x;
        let y = this.y;

        // Left
        if (left > 0) {
            x = x - left;
        }

        // Right
        if (right > 0) {
            x = x + right;
        }

        // top
        if (top > 0) {
            y = y - top;
        }

        // bottom
        if (bottom > 0) {
            y = y + bottom;
        }

        const xs = (a.x === true && x !== this.x);
        const ys = (a.y === true && y !== this.y);

        if (xs === true || ys === true) {
            this.xFrom = this.x = x;
            this.yFrom = this.y = y;
            this.setHalfPosition();

            TweenLite.to(this.img.nativeElement, 0.5, {
                x: this.xTo,
                y: this.yTo,
                ease: Expo.easeOut,
                delay: 0.1
            });
        }
    }


    /**
     * Pich
     * @param e
     */
    /*
    pinch (e) {
        switch (e.type) {
            // Start
            case 'pinchstart':
                this.status = true;
                this.scaleTo = this.scaleFrom;
                break;

            // End
            case 'pinchend':
                this.scaleFrom = this.scaleTo;

                this.xFrom = this.x = 0;
                this.yFrom = this.y = 0;
                this.setHalfPosition();

                TweenLite.to(this.img.nativeElement, 0.5, {
                    x: this.xTo,
                    y: this.yTo,
                    ease: Expo.easeOut,
                    onComplete: () => {
                        this.status = false;
                    }
                });
                break;

            // Pich
            case 'pinch':
                this.setScale(e.scale);
                break;
        }
    }
     */
}
