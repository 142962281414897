import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {SetIsStaticPage, ZendeskShow} from '../../store/ui/ui.actions';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  constructor(
	  private store: Store<fromRoot.State>,
  ) {
	this.store.dispatch(new ZendeskShow(false));
  	this.store.dispatch(new SetIsStaticPage(true));
  }

  ngOnInit() {
  }

}
