import {IntMensajesListings, IntMensajesListingResults} from './mensajes.model';
import {MensajesActions, MensajesActionTypes} from './mensajes.actions';

export interface MensajesState {
    currentResults: IntMensajesListingResults;
    currentType: IntMensajesListings;
    currentPage: number;
    inProcess: boolean;
}


export const initialState: MensajesState = {
    currentResults: {
        data: {
            type: null,
            more: false,
            chats: []
        }
    },
    currentType: IntMensajesListings.MENSAJES_RECEIVED,
    currentPage: 1,
    inProcess: false
};

export function reducer(
    state = initialState,
    action: MensajesActions
): MensajesState {
    switch (action.type) {

        case MensajesActionTypes.MensajesCleanup: {
            return {
                ...initialState,
            };
        }

        case MensajesActionTypes.MensajesInitSearch: {
            return {
                ...state,
                currentType: action.payload.type,
                currentResults: {
                    data: {
                        type: null,
                        more: false,
                        chats: []
                    },
                    error: null,
                    status: null
                },
                inProcess: true
            };
        }

        case MensajesActionTypes.MensajesDoSearch: {
            // if message type has been changed, discard result
            if (action.payload.results.data.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...state.currentResults.data.chats, ...action.payload.results.data.chats],
                        more: action.payload.results.data.more,
                        type: action.payload.results.data.type
                    },
                    status: action.payload.results.status || null

                },
                currentPage: 1,
                inProcess: false
            };
        }

        case MensajesActionTypes.MensajesInitScroll: {
            return {
                ...state,
                inProcess: true
            };
        }

        case MensajesActionTypes.MensajesDoScroll: {
            // if message type has been changed, discard result
            if (action.payload.results.data.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...state.currentResults.data.chats, ...action.payload.results.data.chats],
                        more: action.payload.results.data.more,
                        type: action.payload.results.data.type
                    },
                    error: null
                },
                currentPage: state.currentPage + 1,
                inProcess: false
            };
        }


        case MensajesActionTypes.MensajesSelectOne: {
            const tmp = {...state.currentResults};
            tmp.data.chats.map((o, key) => {
                if (o.userId === action.payload.userId) {
                    o.selected = o.selected ? false : true; // maybe undefined and fails
                }
            });
            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...tmp.data.chats],
                        more: state.currentResults.data.more,
                        type: state.currentResults.data.type
                    }
                }
            };
        }

        case MensajesActionTypes.MensajesSelectAll: {
            const tmp = {...state.currentResults};
            tmp.data.chats.forEach((o, key) => {
                o.selected = true;
            });
            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...tmp.data.chats],
                        more: state.currentResults.data.more,
                        type: state.currentResults.data.type
                    }
                }
            };
        }

        case MensajesActionTypes.MensajesSelectNone: {
            const tmp = {...state.currentResults};
            tmp.data.chats.forEach((o, key) => {
                o.selected = false;
            });
            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...tmp.data.chats],
                        more: state.currentResults.data.more,
                        type: state.currentResults.data.type
                    }
                }
            };
        }

        case MensajesActionTypes.MensajesDeleted: {
            const newChats = state.currentResults.data.chats.filter(msg => !msg.selected);

            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...newChats],
                        more: state.currentResults.data.more,
                        type: state.currentResults.data.type
                    },
                    error: null
                },
            };
        }

        case MensajesActionTypes.MensajesMarkedAsRead: {
            const tmp = {...state.currentResults};
            tmp.data.chats.forEach((o, key) => {
                if (o.selected) {
                    o.selected = false;
                    o.new = '0';
                }
            });

            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...tmp.data.chats],
                        more: tmp.data.more,
                        type: tmp.data.type
                    },
                    error: null
                },
            };
        }

        case MensajesActionTypes.MensajesMarkAsReadByUserId: {
            const chats = [...state.currentResults.data.chats];
            chats.forEach((o, key) => {
                if (parseInt(o.userId, 10) === action.payload.userId) {
                    o.new = '0';
                }
            });

            return {
                ...state,
                currentResults: {
                    data: {
                        chats: [...chats],
                        more: state.currentResults.data.more,
                        type: state.currentResults.data.type
                    },
                    error: null
                },
            };
        }


        case MensajesActionTypes.MensajesFail: {
            return {
                ...state,
                currentResults: {
                    error: action.payload.results.error,
                    data: {
                        type: null,
                        more: false,
                        chats: []
                    }
                },
                currentPage: 1,
                inProcess: false
            };
        }

        default: {
            return state;
        }
    }
}
