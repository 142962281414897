import {IntRecomendadosListing} from './recomendados.model';
import {RecomendadosActions, RecomendadosActionTypes} from './recomendados.actions';

export interface RecomendadosState {
    currentResults: IntRecomendadosListing;
    inProcess: boolean;
}

export const initialState: RecomendadosState = {
    currentResults: { data: [] },
    inProcess: false
};

export function reducer(
    state = initialState,
    action: RecomendadosActions
): RecomendadosState {
    switch (action.type) {

		case RecomendadosActionTypes.RecomendadosListing: {
            return {
                ...state,
				inProcess: true
            };
        }

		case RecomendadosActionTypes.RecomendadosListingDone: {
			//
			let result = action.payload.results.data;
			let newResult = [];
			let tmpArr = [];
			let cont: number = 0;
			for(const item in result){
				if(item === '3' || item === '6' || item === '9'){
					if(parseInt(item) > 3){ tmpArr.push(result[item]); }
					newResult[cont] = tmpArr;
					tmpArr = [];
					cont += 1;
				}else{
					tmpArr.push(result[item]);
				}
			}
			return {
				...state,
				inProcess: false,
				currentResults: { data: newResult }
			}
		}

        default: {
            return state;
        }
    }
}
