import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
    IntDashboardGetListing,
    IntDashboardGetListingScroll,
    IntDashboardListingResults,
    IntDashboardSearchParams,
    IntSearchOptionReply
} from './dashboard.model';

@Injectable()
export class DashboardService {

    constructor(
        private http: HttpClient,
    ) {
    }


    public listOrSearch(listType: IntDashboardGetListing, searchParams?: IntDashboardSearchParams): Observable<IntDashboardListingResults> {
        if (typeof searchParams === 'undefined') {
            return this.listing(listType);
        } else {
            return this.search(searchParams);
        }
    }

    public listing(listType: IntDashboardGetListing): Observable<IntDashboardListingResults> {
        return this.http
        .get<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.dashboardlisting}/` + listType,{
			headers: {'platform':'desktop'}
		});
    }

    public scroll(hash: IntDashboardGetListingScroll): Observable<IntDashboardListingResults> {
        return this.http
        .get<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.dashboardscroll}/${hash.scrollHash}`,
			{headers: {'platform':'desktop'}});
    }

    public search(searchParams: IntDashboardSearchParams): Observable<IntDashboardListingResults> {
        return this.http
        .post<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.dashboardsearch}`, searchParams,
			{headers: {'platform':'desktop'}});
    }

    public doLike(id_usuario: string): Observable<IntDashboardListingResults> {
        return this.http
        .post<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${id_usuario}${environment.endpoints.userlike_end}`, {});
    }

    public doDislike(id_usuario: string): Observable<IntDashboardListingResults> {
        return this.http
        .post<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${id_usuario}${environment.endpoints.userdislike_end}`, {});
    }

    public cancelLike(id_usuario: string): Observable<IntDashboardListingResults> {
        return this.http
        .post<IntDashboardListingResults>(`${environment.appApi}/${environment.endpoints.userlikedislike_base}${id_usuario}${environment.endpoints.userlike_undo_end}`, {});
    }

    public searchOptions(): Observable<IntSearchOptionReply> {
        return this.http
       .get<IntSearchOptionReply>(`${environment.appApi_base}/${environment.endpoints.searchoptionsV2}` );
    }
}
