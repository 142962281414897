import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CropperComponent, IntCropperImagesResult} from '../../components/cropper/cropper.component';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {
    IntFacebookGalleryAlbums, IntFacebookGalleryPicture,
    IntFacebookLoginData,
    IntLoginData,
    IntProfileNewImage,
    IntProfileV2Data,
    IntProfileV2GcpImage,
    IntSignupPostData
} from '../../store/user/user.model';
import {Observable, Subscription} from 'rxjs';
import {BodyScrollLock, BodyScrollUnLock} from '../../store/ui/ui.actions';
import {
    AttachFacebookToken,
    LogInFacebookFail,
    LogInFacebookUserSuccess,
    ProfileDeleteImage,
    ProfileNewImage, ProfileNewImageFromFacebook,
    ProfileSetImage
} from '../../store/user/user.actions';
import {MatCheckboxChange} from '@angular/material';
import {IntGalleryPicture} from '../../components/gallery/gallery.component';

declare var FB: any;

@Component({
    selector: 'app-profilegallery',
    templateUrl: './profilegallery.component.html',
    styleUrls: ['./profilegallery.component.scss']
})
export class ProfilegalleryComponent implements OnInit, OnDestroy {

    @ViewChild('appcropper', { static: true }) cropper: CropperComponent;

    MAX_IMAGES = 12;

    profileImages: IntGalleryPicture[] = [];
    profileImagesSub: Subscription = null;
    sessionSubs: Subscription = null;
    profileObj$: Observable<IntProfileV2Data> = this.store.pipe(select(fromRoot.getCurrentProfileV2));
    isGalleryOpen = false;
    importingFromFacebook = false;
    connectingToFacebook = false;
    galleryStartIndex = 0;

    selectedImages: IntProfileV2GcpImage[] = [];
    selectedImagesFacebook: IntFacebookGalleryPicture[] = [];

    facebookAlbums: IntFacebookGalleryAlbums = null;

    sessionData: IntLoginData = null;

    @Output() close: EventEmitter<void> = new EventEmitter();

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private utils: ZcUtilsService,
    ) {
        this.profileImagesSub = this.store.pipe(select(fromRoot.getCurrentProfileV2Pictures)).subscribe(images => {
            this.profileImages = images;
            this.profileImages.forEach(i => {
                i.thumb = this.utils.getUserImageURL('1', i.thumb);
                i.image = this.utils.getGCPFullImageURL(i.image);
            });

            // este observable es disparado cuando hubo importación de facebook también, cerrar la importación desde face
            if (this.facebookAlbums !== null) {
                this.selectedImagesFacebook = [];
                this.importingFromFacebook = false;
                this.connectingToFacebook = false;
                this.facebookAlbums = null;
            }
        });

        this.sessionSubs = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe((sessionData: IntLoginData) => {
            this.sessionData = sessionData;
        });
    }

    ngOnInit() {
        this.store.dispatch(new BodyScrollLock());
    }

    ngOnDestroy() {
        if (this.profileImagesSub !== null) {
            this.profileImagesSub.unsubscribe();
        }
        if (this.sessionSubs !== null) {
            this.sessionSubs.unsubscribe();
        }
    }


    doClose() {
        this.store.dispatch(new BodyScrollUnLock());
        this.close.emit();
    }

    sendImage() {
    	this.cropper.initializeCropper();
    }

    croppedImage(e: IntCropperImagesResult) {
        if (e !== null) {
            this.doUploadImage(e);
        }
    }


    doUploadImage(f: IntCropperImagesResult) {
        const img: IntProfileNewImage = {
            img: f.cropped
        };

        this.store.dispatch(new ProfileNewImage({image: img}));
    }

    doPress(i: IntProfileV2GcpImage) {
        if (!i.selected) {
            this.selectedImages.push(i);
            i.selected = true;
        }
    }

    checkChange(e: MatCheckboxChange, i: IntProfileV2GcpImage) {
        i.selected = e.checked;
        if (e.checked) {
            this.selectedImages.push(i);
        } else {
            const ix = this.selectedImages.findIndex(e => {
                return e.imageName === i.imageName;
            });
            if (ix >= 0) {
                this.selectedImages.splice(ix, 1);
            }
        }
    }

    handleTap(i: IntProfileV2GcpImage, index: number) {
        if (i.selected) {
            const ix = this.selectedImages.findIndex(e => {
                return e.imageName === i.imageName;
            });

            if (ix >= 0) {
                this.selectedImages.splice(ix, 1);
            }

            i.selected = false;
        } else if (this.selectedImages.length > 0) {
            // only select image if there were previously another one selected
            this.selectedImages.push(i);
            i.selected = true;
        } else {
            // open image gallery
            this.galleryStartIndex = index;
            this.isGalleryOpen = true;
        }
    }

    deleteSelected() {
        this.selectedImages.forEach(e => {
            this.store.dispatch(new ProfileDeleteImage({image: e}));
            e.selected = false;
        });
        this.selectedImages = [];
    }

    setProfile() {
        if (this.selectedImages.length !== 1) {
            return;
        }

        this.store.dispatch(new ProfileSetImage({image: this.selectedImages[0]}));

        // deselect image
        this.selectedImages[0].selected = false;
        this.selectedImages.splice(0, 1);
    }

    closeGallery() {
        this.isGalleryOpen = false;
    }

    importFacebook() {
        this.connectingToFacebook = true;
        const token = (this.sessionData !== null) && this.sessionData.longAccessToken ? this.sessionData.longAccessToken : '';
        const requestPath = '/me/albums?fields=id,name,photos.limit(999999){id,picture}&limit=999999&locale=es_LA' + '&access_token=' + token;
        FB.api(requestPath, 'get', (resAlbums: any) => {
            if (resAlbums.error) {
                FB.login((response) => {
                    if (response.authResponse && response.status) {

                        if (response.status === 'connected') {
                            const data: IntFacebookLoginData = {
                                accessToken: response.authResponse.accessToken,
                                signedRequest: response.authResponse.signedRequest,
                                userID: response.authResponse.userID
                            };

                            this.store.dispatch(new AttachFacebookToken({authData: data}));
                            setTimeout(() => this.importFacebook(), 150);
                        } else {
                            this.connectingToFacebook = false;
                            this.cd.markForCheck();
                        }

                    } else {
                        this.connectingToFacebook = false;
                        this.facebookAlbums = null;
                        this.selectedImagesFacebook = [];
                        this.cd.markForCheck();
                    }
                // }, {scope: 'email,public_profile,user_about_me,user_birthday,user_hometown,user_location,user_photos'});
                }, {scope: 'email,public_profile,user_birthday,user_hometown,user_location,user_photos'});

            } else if (resAlbums.data && resAlbums.data.length) {
                this.connectingToFacebook = false;
                this.facebookAlbums = resAlbums;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
            } else {
                this.connectingToFacebook = false;
                this.facebookAlbums = null;
                this.selectedImagesFacebook = [];
                this.cd.markForCheck();
            }
        });
    }

    selectFacebookImage() {
        const images = [];
        this.selectedImagesFacebook.forEach((e) => {
            if (e.selected) {
                images.push(e.picture);
            }
        });
        if (images.length) {
            this.importingFromFacebook = true;
            this.store.dispatch(new ProfileNewImageFromFacebook({images: images}));
        }
    }

    doCloseFacebookAlbums() {
        this.facebookAlbums = null;
    }

    doPressFacebook(i: IntFacebookGalleryPicture) {
        if (i.selected) {
            const ix = this.selectedImagesFacebook.findIndex(e => {
                return e.picture === i.picture;
            });

            if (ix >= 0) {
                this.selectedImagesFacebook.splice(ix, 1);
            }

            i.selected = false;
        } else {
            this.selectedImagesFacebook.push(i);
            i.selected = true;
        }
    }

    handleTapFacebook(i: IntFacebookGalleryPicture, index: number) {
        if (i.selected) {
            const ix = this.selectedImagesFacebook.findIndex(e => {
                return e.picture === i.picture;
            });

            if (ix >= 0) {
                this.selectedImagesFacebook.splice(ix, 1);
            }

            i.selected = false;
        } else {
            this.selectedImagesFacebook.push(i);
            i.selected = true;
        }
    }

    checkChangeFacebook(e: MatCheckboxChange, i: IntFacebookGalleryPicture) {
        i.selected = e.checked;
        if (e.checked) {
            this.selectedImagesFacebook.push(i);
        } else {
            const ix = this.selectedImagesFacebook.findIndex(e => {
                return e.picture === i.picture;
            });
            if (ix >= 0) {
                this.selectedImagesFacebook.splice(ix, 1);
            }
        }
    }

}
