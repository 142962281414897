import {Injectable} from '@angular/core';
import {of} from 'rxjs';

import {
    catchError, debounceTime,
    map, mergeMap, tap, withLatestFrom,
} from 'rxjs/operators';

import * as userActions from './user.actions';
import * as uiActions from '../ui/ui.actions';
import * as dashActions from '../dashboard/dashboard.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {IntAccountMainAlerts, IntConversionFunnel, IntLoginData, IntPasswordChangeReply, IntProfileUpdateReply} from './user.model';
import {UsersService} from './users.service';
import {Router} from '@angular/router';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {PusherService} from '../../services/pusher.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../index';
import {ToastrService} from 'ngx-toastr';
import {GaService} from '../../services/ga.service';
import {DashboardCleanup} from '../dashboard/dashboard.actions';
import {VisitasCleanup} from '../visitas/visitas.actions';
import {BodyScrollLock, UICleanup, UpdateSidebarVisitsRcv, UpdateSidebarVisitsSent} from '../ui/ui.actions';
import {GustaronCleanup} from '../gustaron/gustaron.actions';
import {MensajesCleanup} from '../mensajes/mensajes.actions';
import {MessagingCleanup, MessagingOpenChat} from '../messaging/messaging.actions';
import {NoGustaronCleanup} from '../nogustaron/nogustaron.actions';
import {ProfileService} from '../profile/profile.service';
import {IntProfileReply} from '../profile/profile.model';
import {IntUISidebarUserLinks} from '../ui/ui.model';
import * as ProfileActions from '../profile/profile.actions';
import {DashboardService} from '../dashboard/dashboard.service';
import {SetConversionFunnelSuccess, UserMobbexCheckoutSuccess, UserReloadSession} from './user.actions';

/**
 * Effects file is for isolating and managing side effects of the application in one place
 * Http requests, Sockets, Routing, LocalStorage, etc
 */

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private ga: GaService,
        private userService: UsersService,
		private profileService: ProfileService,
        private toastrService: ToastrService,
        private pusherService: PusherService,
		private dashboardService: DashboardService
    ) {
    }

    /* AUTOLOGIN */
	@Effect()
	autologin$ = this.actions$.pipe(
		ofType<userActions.SetAutologin>(userActions.UserActionTypes.UserAutologin),
		map( action => action.payload.data),
		mergeMap((data) =>
			this.userService.setAutologin(data).pipe(
				map((response: any) => {
					return new userActions.SetAutologinSuccess({data: response});
				}),
				catchError(err => {
					this.toastrService.error('Error al obtener información del servidor');
					return of(new userActions.UserGenericFail({error: 'Error al obtener información del servidor'}));
				})
			))
	)
	@Effect({dispatch: false})
	autologinMessage$ = this.actions$.pipe(
		ofType<userActions.GetAutologinMessage>(userActions.UserActionTypes.UserAutologinMessage),
		mergeMap((action) => {
			return this.profileService.getProfile(action.payload.iduser).pipe(
				map((results: IntProfileReply) => {
					if ((results.status === 200) && results.data) {
						this.store.dispatch(new MessagingOpenChat({
							userId: parseInt(results.data.id, 10),
							retreiveChatHistory: false,
							userName: results.data.ficha.apodo_aprobado,
							userAvatar: this.utils.getUserImageName(results.data.ficha.gender, results.data.gcpImages.profile),
							gender: results.data.ficha.gender
						}));
					}
				}))
		}
	));
	@Effect({dispatch:false})
	autologinLike$ = this.actions$.pipe(
		ofType<userActions.SetAutologinLike>(userActions.UserActionTypes.UserAutologinLike),
		mergeMap( (action) => {
			return this.dashboardService.doDislike(action.payload.iduser).pipe(
				map((results: any) => {
					if (results.status === 200) {
						this.toastrService.success('Te ha gustado!!!','',
							{ positionClass: 'toast-top-center',
									timeOut: 2000});
					}
				})
	)}))
	@Effect({dispatch:false})
	autologinDisLike$ = this.actions$.pipe(
		ofType<userActions.SetAutologinDisLike>(userActions.UserActionTypes.UserAutologinDisLike),
		mergeMap( (action) => {
			return this.dashboardService.doDislike(action.payload.iduser).pipe(
				map((results: any) => {
					if (results.status === 200) {
						this.toastrService.success('No te gusta!!!','',
							{ positionClass: 'toast-top-center',
								timeOut: 2000});
					}
				})
			)}))

	@Effect()
	reloadsession$ = this.actions$.pipe(
		ofType<userActions.UserReloadSession>(userActions.UserActionTypes.UserReloadSession),
		mergeMap( () => {
			return this.userService.getReloadSession().pipe(
				map( (result: IntLoginData) =>{
					return new userActions.UserReloadSessionSuccess({result: result});
				})
			)
		})
	)

    @Effect()
    login$ = this.actions$.pipe(
        ofType<userActions.LogInUser>(userActions.UserActionTypes.LogInUser),
        map(action => action.payload.credentials),
        mergeMap((credentials) => {

            if (
                !credentials ||
                !credentials.email ||
                (credentials.email.trim() === '') ||
                !credentials.password ||
                (credentials.email.trim() === '')
            ) {

                return of(new userActions.LogInFail({loginData: {error: 'Completa todos los campos para continuar'}}));

            } else if (!this.utils.validateEmail(credentials.email)) {

                return of(new userActions.LogInFail({loginData: {error: 'Ingresa un e-mail válido para continuar'}}));

            } else {
                return this.userService.login(credentials).pipe(
                    map((loginData: IntLoginData) => {

                        this.userService.clearTokenStorage();

                        if (loginData.status === 200) {
                            // set tokens
                            this.userService.setTokens(loginData.token, loginData.recovery_token);
                            this.userService.setPostRegisterModals(loginData.sessionInfo.me.post_register_modals);
                            this.userService.setJuegoLikeInit(loginData);
                            return new userActions.LoggedInUser({loginData: loginData});

                        } else {

                            return new userActions.LogInFail({loginData: loginData});

                        }
                    }),
                    catchError(err => {
                            if (err.error && err.error.status && err.error.error && (err.error.status === 401) && (err.error.error === 'invalid_credentials_DB')) {
                                err.error.error = 'E-mail o contraseña inválido';
                            }

                            if (err.error && err.error.status && err.error.error && (err.error.status === 500)) {
                                // show toast?
                                err.error.error = 'Error de comunicación, intenta nuevamente en unos instantes';
                            }

                            return of(new userActions.LogInFail({
                                loginData: err.error || {
                                    status: 401,
                                    error: 'Ha ocurrido un error inesperado al intentar comunicarse con el servidor, por favor intente nuevamente en unos momentos'
                                }
                            }));
                        }
                    ));
            }

        })
    );

    @Effect()
    loginFromFacebook$ = this.actions$.pipe(
        ofType<userActions.LogInFacebookUserSuccess>(userActions.UserActionTypes.LogInFacebookUserSuccess),
        map(action => action.payload.authData),
        mergeMap((authData) =>
            this.userService.getFacebookSession(authData).pipe(
                map((loginData: IntLoginData) => {
                    if (loginData.status === 200) {
                        this.userService.clearTokenStorage();
                        if (loginData.register_data && loginData.register_data.email) {
                            // user is a new FB account, must proceed with signup
                            loginData.register_data.socialMedia = 'facebook';
                            loginData.register_data.socialMediaData = {
                                accessToken: authData.accessToken,
                                userID: authData.userID
                            };

                            return new userActions.LogInFacebookUserSignupData({signupData: loginData.register_data});

                        } else {
                            // user has already signed up, set tokens
                            this.userService.setTokens(loginData.token, loginData.recovery_token);

                            return new userActions.LoggedInUser({loginData: loginData});
                        }
                    } else {
                        return new userActions.LogInFacebookFail({error: 'No se ha podido iniciar sesión con tu cuenta de Facebook, verificá los datos ingresados.'});
                    }
                }),
                catchError(err => of(new userActions.LogInFacebookFail({error: 'No se ha podido iniciar sesión con tu cuenta de Facebook, verificá los datos ingresados.'})))
            )
        )
    );


    @Effect()
    loginFromToken$ = this.actions$.pipe(
        ofType<userActions.LogInFromStoredToken>(userActions.UserActionTypes.LogInFromStoredToken),
        mergeMap(() =>
            this.userService.getSession().pipe(
                map((loginData: IntLoginData) => {
                    if (loginData.status === 200) {
                        return new userActions.LogInFromStoredTokenSuccess({loginData: loginData});
                    } else {
                        return new userActions.LogInFromStoredTokenFail();
                    }
                }),
                catchError(err => of(new userActions.LogInFromStoredTokenFail()))
            )
        )
    );

	@Effect({dispatch: false})
	relog$ = this.actions$.pipe(
		ofType<userActions.LogInReLog>(userActions.UserActionTypes.LogInRelog),
		map(action => action.payload.recovery_token),
		mergeMap( (recovery_token) => {
			return this.userService.relogin(recovery_token).pipe(
				map((loginData: IntLoginData) => {
					this.userService.clearTokenStorage();
					this.userService.setTokens(loginData.token, loginData.recovery_token);
				})
			);
		})
	)

    @Effect()
    signup$ = this.actions$.pipe(
        ofType<userActions.UserSignup>(userActions.UserActionTypes.UserSignup),
        map(action => action.payload.user),
        mergeMap((user) =>
            this.userService.signup(user).pipe(
                map((signupData: IntLoginData) => {

                    this.userService.clearTokenStorage();

                    if (signupData.status === 200) {

						this.ga.triggerGenericEventValue('evento',
															'registro',
														signupData.sessionInfo.me.idgender === '1' ? 'h' : 'm',
															signupData.registerData.edad);
                        // set tokens
						this.userService.setPostRegisterModals(signupData.sessionInfo.me.post_register_modals);
						this.userService.setJuegoLikeInit(signupData);
                        this.userService.setTokens(signupData.token, signupData.recovery_token);

						// config user init ui
						this.store.dispatch(new userActions.LoggedInUser({loginData: signupData}));

                        return new userActions.UserSignupSuccess({loginData: signupData});
                    } else {
                        return new userActions.UserSignupFail({response: signupData});
                    }
                }),
                catchError(err => of(new userActions.UserSignupFail({
                    response: {
                        errors: err.error.errors
                    }
                })))
            )
        )
    );

    @Effect()
    forgot$ = this.actions$.pipe(
        ofType<userActions.UserForgot>(userActions.UserActionTypes.UserForgot),
        map(action => action.payload.forgotData),
        mergeMap((forgotData) =>
            this.userService.forgot(forgotData).pipe(
                map((reply: IntPasswordChangeReply) => {
                    if (reply.status === 200) {
                        return new userActions.UserForgotSuccess({forgotData: reply});
                    } else {
                        return new userActions.UserForgotFail({forgotData: reply});
                    }
                }),
                catchError(err => of(new userActions.UserForgotFail({
                    forgotData: {
                        status: 100,
                        error: err.error.error ? (err.error.error === 'User does not exist' ? 'El e-mail ingresado no coincide con ninguna cuenta' : err.error.error) : 'Error inesperado al intentar registrar tu cuenta'
                    }
                })))
            )
        )
    );

    @Effect()
    resetPwd$ = this.actions$.pipe(
        ofType<userActions.UserResetpwd>(userActions.UserActionTypes.UserResetpwd),
        map(action => action.payload.credentials),
        mergeMap((credentials) =>
            this.userService.forgotSetPassword(credentials).pipe(
                map((reply: IntPasswordChangeReply) => {
                    if (reply.status === 200) {
                        return new userActions.UserResetpwdSuccess();
                    } else {
                        return new userActions.UserResetpwdFail({reply: reply});
                    }
                }),
                catchError(err => of(new userActions.UserResetpwdFail({
                    reply: {
                        status: 100,
                        error: err.error.error ? (err.error.error === 'User does not exist' ? 'El e-mail ingresado no coincide con ninguna cuenta' : err.error.error) : 'Error inesperado al intentar registrar tu cuenta'
                    }
                })))
            )
        )
    );

	@Effect()
	juegolikes$ = this.actions$.pipe(
		ofType<userActions.GetJuegoLikes>(userActions.UserActionTypes.UserJuegoLikes),
		map(action => action.payload.juegoData),
		mergeMap((data) =>
			this.userService.getJuegoLikes(data).pipe(
				map((response: any) => {
					return new userActions.GetJuegoLikesSuccess({juegoData: response});
				}),
				catchError(err => {
					this.toastrService.error('Error al obtener información del servidor');
					return of(new userActions.UserGenericFail({error: 'Error al obtener información del servidor'}));
				})
			))
	);

    @Effect()
    counters$ = this.actions$.pipe(
        ofType<userActions.UserCounters>(userActions.UserActionTypes.UserCounters),
        mergeMap(() =>
            this.userService.getCounters().pipe(
                map((ret: any) => {
                    if (ret.status && (ret.status === 200) && ret.data && ret.data.counters) {
                        return new userActions.UserCountersSuccess({counters: ret.data.counters});
                    } else {
                        this.toastrService.error('Error al obtener información del servidor');
                        return new userActions.UserGenericFail({error: 'Error al obtener información del servidor'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al obtener información del servidor');
                    return of(new userActions.UserGenericFail({error: 'Error al obtener información del servidor'}));
                })
            )
        )
    );


    @Effect()
    logeedinFromToken$ = this.actions$.pipe(
        ofType<userActions.LogInFromStoredTokenSuccess>(userActions.UserActionTypes.LogInFromStoredTokenSuccess),
        withLatestFrom(this.store),
        tap((params) => this.pusherService.setupPusher(params[1].user.currentSession.sessionInfo.me.id)),
        tap((params) => this.store.dispatch(new uiActions.BodyGenderClass({class: 'gender-' + params[1].user.currentSession.sessionInfo.me.idgender}))),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetMsgRcv())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetMsgSent())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetVisitsSent())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetVisitsRcv())),
        tap((params) => this.store.dispatch(new dashActions.DashboardSearchOptions())),
        map(() => new userActions.UserProfileV2())
    );

    @Effect()
    logeedin$ = this.actions$.pipe(
        ofType<userActions.LoggedInUser>(userActions.UserActionTypes.LoggedInUser),
        withLatestFrom(this.store),
        tap(() => this.router.navigate(['/dashboard'])),
        tap((params) => this.pusherService.setupPusher(params[1].user.currentSession.sessionInfo.me.id)),
        tap((params) => this.store.dispatch(new uiActions.BodyGenderClass({class: 'gender-' + params[1].user.currentSession.sessionInfo.me.idgender}))),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetMsgRcv())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetMsgSent())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetVisitsSent())),
        tap((params) => this.store.dispatch(new uiActions.SidebarGetVisitsRcv())),
        tap((params) => this.store.dispatch(new dashActions.DashboardSearchOptions())),
        map(() => new userActions.UserProfileV2())
    );

    @Effect()
    profileV2$ = this.actions$.pipe(
        ofType<userActions.UserProfileV2>(userActions.UserActionTypes.UserProfileV2),
        mergeMap(() =>
            this.userService.getProfileData().pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        return new userActions.UserProfileV2Success({profileData: ret.data});
                    } else {
                        this.toastrService.error('Error al obtener tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al obtener tu perfil'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al obtener tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al obtener tu perfil'}));
                })
            )
        )
    );


    @Effect()
    accountData$ = this.actions$.pipe(
        ofType<userActions.UserAccountData>(userActions.UserActionTypes.UserAccountData),
        mergeMap(() =>
            this.userService.getAccountData().pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        return new userActions.UserAccountDataSuccess({accountData: ret.data});
                    } else {
                        this.toastrService.error('Error al obtener los datos de la cuenta');
                        return new userActions.UserGenericFail({error: 'Error al obtener los datos de la cuenta'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al obtener los datos de la cuenta');
                    return of(new userActions.UserGenericFail({error: 'Error al obtener los datos de la cuenta'}));
                })
            )
        )
    );

    @Effect()
    profileNewImage$ = this.actions$.pipe(
        ofType<userActions.ProfileNewImage>(userActions.UserActionTypes.ProfileNewImage),
        map(action => action.payload.image),
        mergeMap((image) =>
            this.userService.uploadProfileImage(image).pipe(
                map((ret: IntProfileUpdateReply) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        this.ga.triggerGenericEvent('evento', 'subir_foto', 'mi_perfil');

						this.store.dispatch(new UserReloadSession());
                        return new userActions.ProfileNewImageSuccess({reply: ret});
                    } else {
                        this.toastrService.error('Error al subir la foto');
                        return new userActions.UserGenericFail({error: 'Error al subir la foto'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al subir la foto');
                    return of(new userActions.UserGenericFail({error: 'Error al subir la foto'}));
                })
            )
        )
    );

    @Effect()
    profileNewImageFromFacebook$ = this.actions$.pipe(
        ofType<userActions.ProfileNewImageFromFacebook>(userActions.UserActionTypes.ProfileNewImageFromFacebook),
        map(action => action.payload.images),
        mergeMap((images) =>
            this.userService.uploadProfileImageFromFacebook(images).pipe(
                map((ret: IntProfileUpdateReply) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        this.ga.triggerGenericEvent('evento', 'subir_foto', 'mi_perfil');
						this.store.dispatch(new UserReloadSession());
                        return new userActions.ProfileNewImageSuccess({reply: ret});
                    } else {
                        this.toastrService.error('Error al subir la foto');
                        return new userActions.UserGenericFail({error: 'Error al importar desde facebook'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al subir la foto');
                    return of(new userActions.UserGenericFail({error: 'Error al importar desde facebook'}));
                })
            )
        )
    );

    @Effect()
    profileDeleteImage$ = this.actions$.pipe(
        ofType<userActions.ProfileDeleteImage>(userActions.UserActionTypes.ProfileDeleteImage),
        map(action => action.payload.image),
        mergeMap((image) =>
            this.userService.deleteProfileImage(image).pipe(
                map((ret: IntProfileUpdateReply) => {
                    if (ret.status && (ret.status === 200)) {
						this.store.dispatch(new UserReloadSession());
                        return new userActions.ProfileDeleteImageSuccess({reply: ret, image: image});
                    } else {
                        this.toastrService.error('Error al eliminar la foto');
                        return new userActions.UserGenericFail({error: 'Error al eliminar la foto'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al eliminar la foto');
                    return of(new userActions.UserGenericFail({error: 'Error al eliminar la foto'}));
                })
            )
        )
    );

    @Effect()
    profileSetImage$ = this.actions$.pipe(
        ofType<userActions.ProfileSetImage>(userActions.UserActionTypes.ProfileSetImage),
        map(action => action.payload.image),
        mergeMap((image) =>
            this.userService.setProfileImage(image).pipe(
                map((ret: IntProfileUpdateReply) => {
                    if (ret.status && (ret.status === 200)) {
						this.store.dispatch(new UserReloadSession());
                        return new userActions.ProfileSetImageSuccess({reply: ret, image: image});
                    } else {
                        this.toastrService.error('Error al marcar como tu foto de perfil');
                        return new userActions.UserGenericFail({error: 'Error al marcar como tu foto de perfil'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al marcar como tu foto de perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al marcar como tu foto de perfil'}));
                })
            )
        )
    );

    @Effect()
    accountDataAlerts$ = this.actions$.pipe(
        ofType<userActions.UserAccountAlerts>(userActions.UserActionTypes.UserAccountAlerts),
        withLatestFrom(this.store),
        mergeMap((params) => {

            const alerts: IntAccountMainAlerts = JSON.parse(JSON.stringify(params[1].user.accountData.alerts));  // deep copy objects
            alerts[params[0].payload.platform][params[0].payload.notif] = params[0].payload.selected ? '1' : '0';

            return this.userService.setAccountAlerts(alerts).pipe(
                map((ret: any) => {
                    if (ret.status && (ret.status === 200)) {
                        return new userActions.UserAccountAlertsSuccess({alerts: alerts});
                    } else {
                        this.toastrService.error('Error al actualizar tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al actualizar los datos de tu cuenta'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al actualizar tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al actualizar los datos de tu cuenta'}));
                })
            );
        })
    );

    @Effect()
    profileDescription$ = this.actions$.pipe(
        ofType<userActions.UserProfileDescrip>(userActions.UserActionTypes.UserProfileDescrip),
        map(action => action.payload.desc),
        mergeMap((descipcion) =>
            this.userService.updateProfileDesciption(descipcion).pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        this.ga.triggerGenericEvent('evento', 'descripcion', 'mi_perfil');

                        this.store.dispatch(new userActions.UserProfileDescripSuccess({desc: descipcion}));
                        return new userActions.UserProfileCompletitudUpdate({completitud: ret.data.complitud});
                    } else {
                        this.toastrService.error('Error al actualizar tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al actualizar los datos de tu cuenta'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al actualizar tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al actualizar los datos de tu cuenta'}));
                })
            )
        )
    );


    @Effect()
    profileField$ = this.actions$.pipe(
        ofType<userActions.UserProfileField>(userActions.UserActionTypes.UserProfileField),
        debounceTime(500),
        map(action => action.payload),
        mergeMap((payload) =>
            this.userService.updateProfileField(payload.f, payload.sectionIx, payload.listIx).pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        this.store.dispatch(new userActions.UserProfileFieldSuccess(payload));
                        return new userActions.UserProfileCompletitudUpdate({completitud: ret.data.complitud});
                    } else {
                        this.toastrService.error('Error al actualizar tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al actualizar tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'}));
                })
            )
        )
    );


    @Effect()
    profileDataSheet$ = this.actions$.pipe(
        ofType<userActions.UserProfileApodoLocalidad>(userActions.UserActionTypes.UserProfileApodoLocalidad),
        map(action => action.payload),
        mergeMap((payload) =>
            this.userService.updateDatasheet(payload.apodo, payload.idLocalidad, payload.idProvincia).pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        return new userActions.UserProfileApodoLocalidadSuccess(payload);
                    } else {
                        this.toastrService.error('Error al actualizar tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al actualizar tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'}));
                })
            )
        )
    );


    @Effect()
    ageRange$ = this.actions$.pipe(
        ofType<userActions.ProfileAgeRange>(userActions.UserActionTypes.ProfileAgeRange),
        map(action => action.payload),
        mergeMap((payload) =>
            this.userService.updateAgeRange(payload.min, payload.max).pipe(
                map((ret: any) => {
                    if (ret.status && ret.data && (ret.status === 200)) {
                        return new userActions.ProfileAgeRangeSuccess(payload);
                    } else {
                        this.toastrService.error('Error al actualizar tu perfil');
                        return new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'});
                    }
                }),
                catchError(err => {
                    this.toastrService.error('Error al actualizar tu perfil');
                    return of(new userActions.UserGenericFail({error: 'Error al actualizar tu perfil'}));
                })
            )
        )
    );


    @Effect()
    clear$ = this.actions$.pipe(
        ofType<userActions.LogoutUser>(userActions.UserActionTypes.LogoutUser),
        withLatestFrom(this.store),
        mergeMap((params) => {
            return this.userService.logout(params[1].user.currentSession && params[1].user.currentSession.fb_connect_status && (params[1].user.currentSession.fb_connect_status === 'connected')).pipe(
                map(() => {
                    return new userActions.LoggedOutUser();
                }),
                catchError(err => of(new userActions.LoggedOutUser()))
            );
        })
    );

    @Effect({dispatch: false})
    loginfromtokenfail$ = this.actions$.pipe(
        ofType<userActions.LogInFromStoredTokenFail>(userActions.UserActionTypes.LogInFromStoredTokenFail),
        tap(() => this.router.navigate(['/']))
    );

    @Effect({dispatch: false})
    cleared$ = this.actions$.pipe(
        ofType<userActions.LoggedOutUser>(userActions.UserActionTypes.LoggedOutUser),
        tap(() => {
            this.userService.setRecordarLogin(false);
            this.userService.clearTokenStorage();

            this.pusherService.disconnect();
            this.store.dispatch(new UICleanup());
            this.store.dispatch(new DashboardCleanup());
            this.store.dispatch(new VisitasCleanup());
            this.store.dispatch(new GustaronCleanup());
            this.store.dispatch(new NoGustaronCleanup());
            this.store.dispatch(new MensajesCleanup());
            this.store.dispatch(new MessagingCleanup());
            this.router.navigate(['/']);
        })
    );


    @Effect()
    errorhandler$ = this.actions$.pipe(
        ofType<userActions.ErrorUser>(userActions.UserActionTypes.ErrorUser),
        mergeMap((error) => {
            return of({type: 'noop'});
        })
    );

    @Effect()
    paywall$ = this.actions$.pipe(
        ofType<userActions.Paywallget>(userActions.UserActionTypes.Paywallget),
        map(action => action.payload),
        mergeMap((action) =>
            this.userService.getPaywall(action.promo, action.nick, action.avatar, action.idabono).pipe(
                map((ret: any) => {
                    if (ret.status && (ret.status === 200) && ret.data) {
						return new userActions.PaywallgetSuccess({paywall: ret.data});
                    } else {
                        return new userActions.PaywallgetSuccess({paywall: null});
                    }
                }),
                catchError(err => {
                    return of(new userActions.PaywallgetSuccess({paywall: null}));
                })
            )
        )
    );

	@Effect()
	refund$ = this.actions$.pipe(
		ofType<userActions.UserRefund>(userActions.UserActionTypes.UserRefund),
		map(action => action.payload),
		mergeMap( (action) =>
			this.userService.refundAccount(action.iduser).pipe(
				map((ret: any) => {
					if (ret.status == 200) {
						return new userActions.UserRefundSuccess({result: ret});
					} else {
						return new userActions.UserRefundFails({result: ret});
					}
				}),
				catchError(err => {
					console.log('ERROR' + err);
					return of(new userActions.UserRefundFails({result: err}));
				})
			)
		)
	)

	@Effect()
	countmodals$ = this.actions$.pipe(
		ofType<userActions.UserSetCountModalsReg>(userActions.UserActionTypes.UserSetCountModalsReg),
		mergeMap( () =>
			this.userService.setPostRegModalsCount().pipe(
				map( (ret: any) => {
					if(ret.status == 200){
						return new userActions.UserSetCountModalsRegSuccess({result: ret})
					}
				})
			)
		)
	)

	@Effect()
	setConversionFunnel$ = this.actions$.pipe(
		ofType<userActions.SetConversionFunnel>(userActions.UserActionTypes.UserSetConversionFunnel),
		map(action => action.payload.data),
		mergeMap( (params) => {
			return this.userService.setConversionFunnel(params).pipe(
				map( (ret) => {
					if(ret.status == 200){
						return new SetConversionFunnelSuccess();
					}
				} )
			)
		})
	)

	@Effect()
	setMobbexCheckout$ = this.actions$.pipe(
		ofType<userActions.UserMobbexCheckout>(userActions.UserActionTypes.UserMobbexCheckout),
		map(action => action.payload.data),
		mergeMap( (params) => {
			return this.userService.setMobbexCheckout(params).pipe(
				map( (ret) => {
					if(ret.status == 200){
						return new UserMobbexCheckoutSuccess(ret);
					}
				} )
			)
		})
	)

	@Effect({dispatch:false})
	abtest$ = this.actions$.pipe(
		ofType<userActions.UserAbTest>(userActions.UserActionTypes.UserAbTest),
		mergeMap( (action) => {
			return this.userService.setAbTestTracker(action.payload.data).pipe(
				map((results: any) => {
				})
			)}))
}
