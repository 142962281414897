import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {GetJuegoLikes, SetJuegoLikesRemove} from '../../store/user/user.actions';
import {IntJuegoLikesOne, IntJuegoLikesReq} from '../../store/user/user.model';
import {Observable, Subscription} from 'rxjs';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {zcDeepCopy} from '../../services/zc.globals';
import {environment} from '../../../environments/environment';
import {ProfileDoLike, ProfileDoLikeJDL} from '../../store/profile/profile.actions';
import {GaService} from '../../services/ga.service';

@Component({
    selector: 'app-juego-likes',
    templateUrl: './juego-likes.component.html',
    styleUrls: ['./juego-likes.component.scss']
})
export class JuegoLikesComponent implements OnInit, OnDestroy {

    isProcessJuego$: Observable<boolean> = this.store.pipe(select(fromRoot.inProcessJuegoLikes));
    subDataJuego: Subscription = null;
    subSession: Subscription = null;
    dataJuego: IntJuegoLikesOne[] = [];
    loadingImg_one: boolean = true;
    loadingImg_two: boolean = true;
    profileOne_selected: string = 'initial';
	profileTwo_selected: string = 'initial';

    constructor(
        private store: Store<fromRoot.State>,
        private dialogRef: MatDialogRef<JuegoLikesComponent>,
		private ga: GaService,
        private utils: ZcUtilsService,
    ) {
    }

    iReqJuegoLikes: IntJuegoLikesReq = {
        iduser: null,
        idbusco: null,
        idprov: null,
        edadfin: null,
        edadini: null
    };

    onLikeUser(idx: number, idUser: any) {
    	//
        this.dispatchAnimations(idx);
        this.moveJuegoLikes();
        //
		this.store.dispatch(new ProfileDoLikeJDL({userId: idUser}));
		this.ga.triggerGenericEvent('evento', 'me_gusta', 'juego_like');
		//
    }

    onSkip() {
		this.dispatchAnimations(3);
		this.moveJuegoLikes();
    }

    ngOnInit() {
        this.subSession = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe((session) => {
            const sessMe = session.sessionInfo.me;
            this.iReqJuegoLikes.iduser = parseInt(sessMe.id, 10);
            this.iReqJuegoLikes.idbusco = parseInt(sessMe.idgender, 10);
            this.iReqJuegoLikes.idprov = parseInt(sessMe.location.idprovincia, 10);
            this.iReqJuegoLikes.edadini = (sessMe.age - 15);
            this.iReqJuegoLikes.edadfin = (sessMe.age + 10);
            this.store.dispatch(new GetJuegoLikes({juegoData: zcDeepCopy(this.iReqJuegoLikes)}));
        });
        this.subDataJuego = this.store.pipe(select(fromRoot.getDataJuegoLikes)).subscribe((data) => {
            this.dataJuego = data;
        });
    }

    ngOnDestroy(): void {
        if (this.subSession !== null) {
            this.subSession.unsubscribe();
        }
        if (this.subDataJuego !== null) {
            this.subDataJuego.unsubscribe();
        }
    }

    closeModal() {
		localStorage.setItem(environment.juegoLikes, 'false');
        this.dialogRef.close();
    }

    resetUI() {
    	this.profileOne_selected = 'initial';
    	this.profileTwo_selected = 'initial';
        this.loadingImg_two = true;
        this.loadingImg_one = true;
    }

    dispatchAnimations(idx: number){
    	switch (idx){
			case 0:
				this.profileOne_selected = 'selected';
				this.profileTwo_selected = 'notSelected'
				break;
			case 1:
				this.profileTwo_selected = 'selected';
				this.profileOne_selected = 'notSelected';
				break;
			default:
				this.profileTwo_selected = 'notSelected';
				this.profileOne_selected = 'notSelected';
				break;
		}
	}

	moveJuegoLikes() {
		setTimeout( () => {
			this.resetUI();
			this.store.dispatch(new SetJuegoLikesRemove());
		}, 800);
	}

}
