import {ActionReducerMap, createFeatureSelector, createSelector, MetaReducer} from '@ngrx/store';

import * as fromUi from './ui/ui.reducer';
import * as fromUser from './user/user.reducer';
import * as fromDash from './dashboard/dashboard.reducer';
import * as fromGustaron from './gustaron/gustaron.reducer';
import * as fromNoGustaron from './nogustaron/nogustaron.reducer';
import * as fromVisitas from './visitas/visitas.reducer';
import * as fromMensajes from './mensajes/mensajes.reducer';
import * as fromMessaging from './messaging/messaging.reducer';
import * as fromProfile from './profile/profile.reducer';
import * as fromRecomendados from './recomendados/recomendados.reducer';
import {IntDashboardGetListing} from './dashboard/dashboard.model';
import {IntGustaronGetListing} from './gustaron/gustaron.model';
import {IntVisitasGetListing} from './visitas/visitas.model';
import {IntMensajesListings} from './mensajes/mensajes.model';

export interface State {
    ui: fromUi.UiState;
    user: fromUser.UserState | null;
    dashboard: fromDash.DashboardState | null;
    gustaron: fromGustaron.GustaronState | null;
    nogustaron: fromNoGustaron.NoGustaronState | null;
    visitas: fromVisitas.VisitasState | null;
    mensajes: fromMensajes.MensajesState | null;
    messaging: fromMessaging.MessagingState | null;
    profile: fromProfile.ProfileState | null;
    recomendados: fromRecomendados.RecomendadosState | null;
}


export const reducers: ActionReducerMap<State> = {
    ui: fromUi.reducer,
    user: fromUser.reducer,
    dashboard: fromDash.reducer,
    gustaron: fromGustaron.reducer,
    nogustaron: fromNoGustaron.reducer,
    visitas: fromVisitas.reducer,
    mensajes: fromMensajes.reducer,
    messaging: fromMessaging.reducer,
    profile: fromProfile.reducer,
	recomendados: fromRecomendados.reducer
};


// General selectors
export const getUiState = createFeatureSelector<fromUi.UiState>('ui');
export const getUserState = createFeatureSelector<fromUser.UserState>('user');
export const getDashState = createFeatureSelector<fromDash.DashboardState>('dashboard');
export const getGustaronState = createFeatureSelector<fromGustaron.GustaronState>('gustaron');
export const getNoGustaronState = createFeatureSelector<fromNoGustaron.NoGustaronState>('nogustaron');
export const getVisitasState = createFeatureSelector<fromVisitas.VisitasState>('visitas');
export const getMensajesState = createFeatureSelector<fromMensajes.MensajesState>('mensajes');
export const getMessagingState = createFeatureSelector<fromMessaging.MessagingState>('messaging');
export const getProfileState = createFeatureSelector<fromProfile.ProfileState>('profile');
export const getMatchingState = createFeatureSelector<fromRecomendados.RecomendadosState>('recomendados');

// APP ui selectors
export const getCurrentTitle = createSelector(getUiState, (state: fromUi.UiState) => state.currentTitle);
export const getCurrentSidebarVisitas = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarActiveVisitasTab);
export const isLoadingSidebarVisitas = createSelector(getUiState, (state: fromUi.UiState) => state.isLoadingSidebarVisits);
export const getCurrentSidebarMensajes = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarActiveMsgTab);
export const isLoadingSidebarMsgs = createSelector(getUiState, (state: fromUi.UiState) => state.isLoadingSidebarMsgs);
export const getCurrentLockStatus = createSelector(getUiState, (state: fromUi.UiState) => state.bodyScrollLock);
export const getCurrentGenderClass = createSelector(getUiState, (state: fromUi.UiState) => state.bodyGenderClass);
export const getSidebarVisitsRcv = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarVisitsRcv);
export const getSidebarVisitsSent = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarVisitsSend);
export const getSidebarMsgRcv = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarMsgRcv);
export const getSidebarMsgSent = createSelector(getUiState, (state: fromUi.UiState) => state.sidebarMsgSend);
export const getBannerMkt = createSelector(getUiState, (state: fromUi.UiState) => state.bannerMkt);
export const isPageStaic = createSelector(getUiState, (state: fromUi.UiState) => state.isStaticPage);
export const isUploadPhoto = createSelector(getUiState, (state: fromUi.UiState) => state.isUploadPhoto);
export const showMenuSuscribite = createSelector(getUiState, (state: fromUi.UiState) => state.showSuscribiteMenu);
export const isPaymentLoading = createSelector(getUiState, (state: fromUi.UiState) => state.isPaymentLoading);
export const isPaymentSuccess = createSelector(getUiState, (state: fromUi.UiState) => state.isPaymentSuccess);
export const isPaymentThankYouPage = createSelector(getUiState, (state: fromUi.UiState) => state.isPaymentThankYouPage);
export const isPaymentThankYouPageError = createSelector(getUiState, (state: fromUi.UiState) => state.isPaymentThankYouPageError);
export const paymentThankYouPageError = createSelector(getUiState, (state: fromUi.UiState) => state.paymentThankYouPageError);
export const paymentUrlImg	= createSelector(getUiState, (state: fromUi.UiState) => state.paymentCCInfo.imgUrl);
export const paymentBank	= createSelector(getUiState, (state: fromUi.UiState) => state.paymentCCInfo.issuerBank);
export const isPaymentFails = createSelector(getUiState, (state: fromUi.UiState) => state.paymentFails.error_nro);
export const paymentFailsMsg = createSelector(getUiState, (state: fromUi.UiState) => state.paymentFails.message);
export const isZendeskShow = createSelector(getUiState, (state: fromUi.UiState) => state.zendeskshow);
export const isLoginErrorContainerShow = createSelector(getUiState, (state: fromUi.UiState) => state.loginErrorContainerShow);

export const isLoadingRefund = createSelector(getUserState, (state: fromUser.UserState) => state.isLoadingRefund);
export const isErrorRefund = createSelector(getUserState, (state: fromUser.UserState) => state.isErrorRefund);
export const isDoneRefund = createSelector(getUserState, (state: fromUser.UserState) => state.isDoneRefund);
export const idTramiteRefund = createSelector(getUserState, (state: fromUser.UserState) => (state.resultRefund.resultset) ? state.resultRefund.resultset.idTramite : '' );
export const countModals = createSelector(getUserState, (state: fromUser.UserState) => (state.countRegModals) ? state.countRegModals : 0 );

export const getCurrentUserState = createSelector(getUserState, (state: fromUser.UserState) => state);
export const getCurrentSession = createSelector(getUserState, (state: fromUser.UserState) => state.currentSession);
export const getCurrentProfileV2 = createSelector(getUserState, (state: fromUser.UserState) => state.profileV2);
export const getCurrentProfilePersonal = createSelector(getUserState, (state: fromUser.UserState) => state.profileV2.personal);
export const getCurrentAccountData = createSelector(getUserState, (state: fromUser.UserState) => state.accountData);
export const getCurrentProfileV2Pictures = createSelector(getUserState, (state: fromUser.UserState) => state.profileV2 ? state.profileV2.gcpImages.reduce((prev, curr) => [...prev, { image: curr.imageName, thumb: curr.imageName } ], [] ) : []);
export const getCurrentDescState = createSelector(getUserState, (state: fromUser.UserState) => state.profileV2 ? state.profileV2.moderationDescription.approved : true);
export const isPremiun = createSelector(getUserState, (state: fromUser.UserState) => state.currentSession.sessionInfo.me.billing.premium);
export const paymentType = createSelector(getUserState, (state: fromUser.UserState) => (state.currentSession.sessionInfo.me.billing.payment_type.id === '100' || state.currentSession.sessionInfo.me.billing.payment_type.id === '101') ? 'offline' : 'online' );

export const getUserLoginProgress = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.loginInProcess || false : false);
export const getUserLoginMessage = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages || null : null);

export const getMatch = createSelector(getUserState, (state: fromUser.UserState) => state.match);

export const getUserPaywall = createSelector(getUserState, (state: fromUser.UserState) => state.paywall);
export const getUserPaywallLoading = createSelector(getUserState, (state: fromUser.UserState) => state.paywallLoading);

export const getUserFacebookLoginProgress = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.loginInFacebookProcess || false : false);
export const getUserFacebookLoginMessage = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.loginFacebookError || null : null);
export const getUserFacebookSignupData = createSelector(getUserState, (state: fromUser.UserState) => state.facebookSignupData);

export const getUserSignupProcess = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.signupProcess || null : null);
export const getUserSignupMessage = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.signupErrors || null : null);

export const getUserForgotProcess = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.forgotProcess || null : null);
export const getUserForgotMessage = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.forgotError || null : null);
export const getUserForgotSuccess = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.forgotSuccess || null : null);

export const getUserResetProcess = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.resetProcess || null : null);
export const getUserResetMessage = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.resetError || null : null);
export const getUserResetSuccess = createSelector(getUserState, (state: fromUser.UserState) => state.messages ? state.messages.resetSuccess || null : null);

export const inProcessJuegoLikes = createSelector(getUserState, (state: fromUser.UserState) => state.juegoLikesLoading);
export const getDataJuegoLikes = createSelector(getUserState, (state: fromUser.UserState) => state.juegoLikesData ? state.juegoLikesData : []);
export const getAutologin = createSelector(getUserState, (state: fromUser.UserState) => state.autologin ? state.autologin : null);

// Matching
export const isMatchingLoaded = createSelector(getMatchingState, (state: fromRecomendados.RecomendadosState) => state.inProcess ? state.inProcess || false : false );
export const getMatchingResults = createSelector(getMatchingState, (state: fromRecomendados.RecomendadosState) => state.currentResults ? state.currentResults || null : null);

// Dash selectors
export const getDashboardSearchOptions = createSelector(getDashState, (state: fromDash.DashboardState) => state.searchOptions ? state.searchOptions : []);
export const getDashboardResults = createSelector(getDashState, (state: fromDash.DashboardState) => state.currentResults ? state.currentResults || null : null);
export const getDashboardHashID = createSelector(getDashState, (state: fromDash.DashboardState) => state.currentResults ? state.currentResults.scroll_id || null : null);
export const getDashboardActiveTab = createSelector(getDashState, (state: fromDash.DashboardState) => state.currentType ? state.currentType : IntDashboardGetListing.DASH_RECENT);
export const getDashboardInProcess = createSelector(getDashState, (state: fromDash.DashboardState) => state.inProcess ? state.inProcess || false : false);
export const getTotOnline = createSelector(getDashState, (state: fromDash.DashboardState) => state.totOnLine);

// Gustaron selectors
export const getGustaronResults = createSelector(getGustaronState, (state: fromGustaron.GustaronState) => state.currentResults ? state.currentResults || null : null);
export const getGustaronPage = createSelector(getGustaronState, (state: fromGustaron.GustaronState) => state.currentPage || 1);
export const getGustaronActiveTab = createSelector(getGustaronState, (state: fromGustaron.GustaronState) => state.currentType ? state.currentType : IntGustaronGetListing.GUSTARON_MATCHES);
export const getGustaronInProcess = createSelector(getGustaronState, (state: fromGustaron.GustaronState) => state.inProcess ? state.inProcess || false : false);

// NoGustaron selectors
export const getNoGustaronResults = createSelector(getNoGustaronState, (state: fromNoGustaron.NoGustaronState) => state.currentResults ? state.currentResults || null : null);
export const getNoGustaronPage = createSelector(getNoGustaronState, (state: fromNoGustaron.NoGustaronState) => state.currentPage || 1);
export const getNoGustaronInProcess = createSelector(getNoGustaronState, (state: fromNoGustaron.NoGustaronState) => state.inProcess ? state.inProcess || false : false);

// Visitas selectors
export const getVisitasResults = createSelector(getVisitasState, (state: fromVisitas.VisitasState) => state.currentResults ? state.currentResults || null : null);
export const getVisitasPage = createSelector(getVisitasState, (state: fromVisitas.VisitasState) => state.currentPage || 1);
export const getVisitasActiveTab = createSelector(getVisitasState, (state: fromVisitas.VisitasState) => state.currentType ? state.currentType : IntVisitasGetListing.Visitas_RECEIVED);
export const getVisitasInProcess = createSelector(getVisitasState, (state: fromVisitas.VisitasState) => state.inProcess ? state.inProcess || false : false);

// Mensajes selectors
export const getMensajesResults = createSelector(getMensajesState, (state: fromMensajes.MensajesState) => state.currentResults ? state.currentResults || null : null);
export const getMensajesPage = createSelector(getMensajesState, (state: fromMensajes.MensajesState) => state.currentPage || 1);
export const getMensajesActiveTab = createSelector(getMensajesState, (state: fromMensajes.MensajesState) => state.currentType ? state.currentType : IntMensajesListings.MENSAJES_RECEIVED);
export const getMensajesInProcess = createSelector(getMensajesState, (state: fromMensajes.MensajesState) => state.inProcess ? state.inProcess || false : false);

// Messaging selectors
export const getMessagingResults = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.chat);
export const getMessagingUserID = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.chat ? state.chat.user.id : 0);
export const getMessagingProcess = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.inProcess);
export const getMessagingScrollProcess = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.inProcessScroll);
export const getMessagingMasMensajes = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.masMensajes);
export const getCloseChat = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.closeChat);
export const isOnline = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.isOnline);
export const isTyping = createSelector(getMessagingState, (state: fromMessaging.MessagingState) => state.isTyping);

// Profile selectors
export const getProfile = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.profile);
export const getProfileProcess = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.inProcess);
export const getProfileProcessAction = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.inProcessAction);
export const getProfileReport = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.profileReport);
export const getMoreUsersInProcess = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.inProcessMoreUsers);
export const getMoreUsers = createSelector(getProfileState, (state: fromProfile.ProfileState) => state.moreUsers);
