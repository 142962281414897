import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ProfileReportSubmit} from '../../store/profile/profile.actions';
import {animate, style, transition, trigger} from '@angular/animations';
import {ZcUtilsService} from '../../services/zc-utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IntValueLabelOption } from 'src/app/store/dashboard/dashboard.model';

@Component({
    selector: 'app-reporte',
    templateUrl: './reporte.component.html',
    styleUrls: ['./reporte.component.scss'],
    animations: [
        trigger('OpenClose', [
            transition(':enter', [
                style({height: '0'}),
                animate('150ms ease', style({height: '*'}))
            ]),
            transition(':leave', [
                animate('150ms ease', style({height: '0'}))
            ])
       ])]
})
export class ReporteComponent implements OnInit {
    @ViewChild('detalleDenunciaInput', { static: true }) detalleDenunciaInput: ElementRef;

    loading = false;
    displaycollapsible = false;

    motivosDenunciaOptions: IntValueLabelOption[] = null;

    userId: number = 0;
    userNick: string = '';

    motivoDenuncia: number = null;
    detalleDenuncia: string = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private utils: ZcUtilsService,
        private dialogRef: MatDialogRef<ReporteComponent>,
        private store: Store<fromRoot.State>,
    ) {
        this.motivosDenunciaOptions = utils.motivosDenuncia;
        this.userId = data.userId;
        this.userNick = data.userNick;
    }

    ngOnInit() {
    }

    close(reported = false) {
        this.dialogRef.close(reported);
    }

    report() {
        if (this.utils.isUndefinedOrNull(this.motivoDenuncia) || this.utils.isUndefinedOrNull(this.detalleDenuncia)) {
            this.detalleDenunciaInput.nativeElement.focus();
            this.detalleDenunciaInput.nativeElement.blur();
            return false;
        }
        this.loading = true

        this.store.dispatch(new ProfileReportSubmit({userId: this.data.userId, reportId: this.motivoDenuncia, reportText: this.detalleDenuncia}));
        
        setTimeout( () => {
            this.loading = false;
            this.close(true);
        }, 500 );
    }
}
