import { Component, OnInit } from '@angular/core';
import {SetIsStaticPage} from '../../store/ui/ui.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';

@Component({
  selector: 'app-emailblocked',
  templateUrl: './emailblocked.component.html',
  styleUrls: ['./emailblocked.component.scss']
})
export class EmailblockedComponent implements OnInit {

  constructor(
  	private store: Store<fromRoot.State>
  ) {
	  this.store.dispatch(new SetIsStaticPage(true));
  }

  ngOnInit(): void {
  }

}
