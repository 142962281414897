import {IntDashboardGetListing, IntDashboardGetListingScroll, IntDashboardListingResults, IntSearchOptionSection} from './dashboard.model';
import {DashboardActions, DashboardActionTypes} from './dashboard.actions';

export interface DashboardState {
    currentResults: IntDashboardListingResults;
    totOnLine: number;
    currentType: IntDashboardGetListing;
    currentScroll: IntDashboardGetListingScroll | null;
    searchOptions: IntSearchOptionSection[];
    inProcess: boolean;
}


export const initialState: DashboardState = {
    currentResults: {
        hits: []
    },
    totOnLine: 0,
    currentType: IntDashboardGetListing.DASH_RECENT,
    currentScroll: null,
    searchOptions: [],
    inProcess: false
};

export function reducer(
    state = initialState,
    action: DashboardActions
): DashboardState {
    switch (action.type) {

        case DashboardActionTypes.DashboardCleanup: {
            return {
                ...initialState,
            };
        }

        case DashboardActionTypes.DashboardSearchOptionsSuccess: {
            return {
                ...state,
                searchOptions: action.payload.options,
            };
        }

        case DashboardActionTypes.DashboardInitSearch: {
            return {
                ...state,
                currentType: action.payload.type,
                currentResults: {
                    hits: [],
                    more: false,
                    scroll_id: null,
                    error: null,
                    status: null
                },
                inProcess: true
            };
        }

        case DashboardActionTypes.DashboardDoSearch: {
            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits, ...action.payload.results.hits],
                    error: action.payload.results.error || null,
                    more: action.payload.results.more || false,
                    scroll_id: action.payload.results.scroll_id || null,
                    status: action.payload.results.status || null
                },
                totOnLine: action.payload.results.totOnline,
                inProcess: false
            };
        }

        case DashboardActionTypes.DashboardInitScroll: {
            return {
                ...state,
                inProcess: true
            };
        }

        case DashboardActionTypes.DashboardDoScroll: {
            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits, ...action.payload.results.hits],
                    error: action.payload.results.error || null,
                    more: action.payload.results.more || false,
                    scroll_id: action.payload.results.scroll_id || null,
                },
                totOnLine: action.payload.results.totOnline,
                inProcess: false
            };
        }

        case DashboardActionTypes.DashboardFail: {
            return {
                ...state,
                currentResults: {
                    error: action.payload.results.error,
                    scroll_id: null,
                    more: false,
                    hits: []
                },
                inProcess: false
            };
        }

        case DashboardActionTypes.DashboardDoDislike:
        case DashboardActionTypes.DashboardDoLike: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.user.id_usuario) {
                    o._loading = true;
                }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardDoLiked: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.user.id_usuario) {
                    o.favorito = true;
                    o._loading = false;
                }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardDoLikedByID: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.userId) {
                    o.favorito = true;
                    o._loading = false;
                }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardDoUndoLiked: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.user.id_usuario) {
                    o.favorito = false;
                    o._loading = false;
               }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }


        case DashboardActionTypes.DashboardDoUndoLikedByID: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.userId) {
                    o.favorito = false;
                    o._loading = false;
                }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardDoDisliked: {
            const hits = state.currentResults.hits.filter(o => o.id_usuario !== action.payload.user.id_usuario);

            return {
                ...state,
                currentResults: {
                    hits: [...hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardDoDislikedByID: {
            const hits = state.currentResults.hits.filter(o => o.id_usuario !== action.payload.userId);

            return {
                ...state,
                currentResults: {
                    hits: [...hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }

        case DashboardActionTypes.DashboardActionFail: {
            state.currentResults.hits.forEach((o, index) => {
                if (o.id_usuario === action.payload.user.id_usuario) {
                    o._loading = false;
                }
            });

            return {
                ...state,
                currentResults: {
                    hits: [...state.currentResults.hits],
                    error: state.currentResults.error || null,
                    more: state.currentResults.more || false,
                    scroll_id: state.currentResults.scroll_id || null,
                },
            };
        }


        default: {
            return state;
        }
    }
}
