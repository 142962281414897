import {Action} from '@ngrx/store';
import {IntMensajesListings, IntMensajesListingResults, IntMensajesListingResult} from './mensajes.model';

export enum MensajesActionTypes {
    MensajesInitSearch     = '[Mensajes API] Init search',
    MensajesDoSearch       = '[Mensajes API] Do search',
    MensajesInitScroll     = '[Mensajes API] Init Scroll',
    MensajesDoScroll       = '[Mensajes API] Do scroll',
    MensajesFail           = '[Mensajes API] Fail',
    MensajesSelectOne      = '[Mensajes API] Select one',
    MensajesSelectAll      = '[Mensajes API] Select all',
    MensajesSelectNone     = '[Mensajes API] Select none',
    MensajesMarkAsRead     = '[Mensajes API] Mark as read',
    MensajesMarkedAsRead   = '[Mensajes API] Marked as read',
    MensajesDeleteSelected = '[Mensajes API] Delete selected',
    MensajesMarkAsReadByUserId = '[Mensajes API] Marked as read by UserID',
    MensajesDeleted        = '[Mensajes API] Delete finished',
    MensajesCleanup        = '[Mensajes API] Cleanup',
}

export class MensajesInitSearch implements Action {
    readonly type = MensajesActionTypes.MensajesInitSearch;

    constructor(public payload: { type: IntMensajesListings }) {
    }
}
export class MensajesDoSearch implements Action {
    readonly type = MensajesActionTypes.MensajesDoSearch;

    constructor(public payload: { results: IntMensajesListingResults }) {
    }
}
export class MensajesInitScroll implements Action {
    readonly type = MensajesActionTypes.MensajesInitScroll;

    constructor(public payload: { type: IntMensajesListings }) {
    }
}
export class MensajesDoScroll implements Action {
    readonly type = MensajesActionTypes.MensajesDoScroll;

    constructor(public payload: { results: IntMensajesListingResults }) {
    }
}
export class MensajesSelectOne implements Action {
    readonly type = MensajesActionTypes.MensajesSelectOne;

    constructor(public payload: { userId: string }) {
    }
}
export class MensajesSelectAll implements Action {
    readonly type = MensajesActionTypes.MensajesSelectAll;
}
export class MensajesSelectNone implements Action {
    readonly type = MensajesActionTypes.MensajesSelectNone;
}
export class MensajesDeleteSelected implements Action {
    readonly type = MensajesActionTypes.MensajesDeleteSelected;
    constructor(public payload: { type: IntMensajesListings }) {
    }
}
export class MensajesDeleted implements Action {
    readonly type = MensajesActionTypes.MensajesDeleted;
}
export class MensajesMarkAsRead implements Action {
    readonly type = MensajesActionTypes.MensajesMarkAsRead;
    constructor(public payload: { type: IntMensajesListings }) {
    }
}
export class MensajesMarkedAsRead implements Action {
    readonly type = MensajesActionTypes.MensajesMarkedAsRead;
}
export class MensajesMarkAsReadByUserId implements Action {
    readonly type = MensajesActionTypes.MensajesMarkAsReadByUserId;
    constructor(public payload: { userId: number }) {
    }
}

export class MensajesFail implements Action {
    readonly type = MensajesActionTypes.MensajesFail;
    constructor(public payload: { results: IntMensajesListingResults }) {
    }
}

export class MensajesCleanup implements Action {
    readonly type = MensajesActionTypes.MensajesCleanup;
}


export type MensajesActions =
    MensajesInitSearch
    | MensajesDoSearch
    | MensajesInitScroll
    | MensajesDoScroll
    | MensajesSelectOne
    | MensajesSelectAll
    | MensajesSelectNone
    | MensajesDeleteSelected
    | MensajesDeleted
    | MensajesMarkAsRead
    | MensajesMarkedAsRead
    | MensajesMarkAsReadByUserId
    | MensajesCleanup
    | MensajesFail;
