import {IntVisitasGetListing, IntVisitasListingResults} from './visitas.model';
import {VisitasActions, VisitasActionTypes} from './visitas.actions';

export interface VisitasState {
    currentResults: IntVisitasListingResults;
    currentType: IntVisitasGetListing;
    currentPage: number;
    inProcess: boolean;
}


export const initialState: VisitasState = {
    currentResults: {
        data: []
    },
    currentType: IntVisitasGetListing.Visitas_RECEIVED,
    currentPage: 1,
    inProcess: false
};

export function reducer(
    state = initialState,
    action: VisitasActions
): VisitasState {
    switch (action.type) {

        case VisitasActionTypes.VisitasCleanup: {
            return {
                ...initialState
            };
        }

        case VisitasActionTypes.VisitasInitSearch: {
            return {
                ...state,
                currentType: action.payload.type,
                currentResults: {
                    data: [],
                    error: null,
                    status: null,
                    more: false
                },
                inProcess: true,
                currentPage: 1
            };
        }

        case VisitasActionTypes.VisitasDoSearch: {
            // if message type has been changed, discard result
            if (action.payload.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null
                },
                inProcess: false,
                currentPage: 1
            };
        }

        case VisitasActionTypes.VisitasInitScroll: {
            return {
                ...state,
                inProcess: true
            };
        }

        case VisitasActionTypes.VisitasDoScroll: {
            // if message type has been changed, discard result
            if (action.payload.type !== state.currentType) {
                return state;
            }

            return {
                ...state,
                currentResults: {
                    data: [...state.currentResults.data, ...action.payload.results.data],
                    status: action.payload.results.status || null,
                    more: action.payload.results.more || false,
                    error: action.payload.results.error || null
                },
                inProcess: false,
                currentPage: state.currentPage + 1
            };
        }

        case VisitasActionTypes.VisitasFail: {
            return {
                ...state,
                currentResults: {
                    error: action.payload.results.error || null,
                    status: action.payload.results.status || null,
                    more: false,
                    data: []
                },
                inProcess: false,
                currentPage: 1
            };
        }

        default: {
            return state;
        }
    }
}
