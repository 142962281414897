import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IntMessagingChat, IntMessagingImgPermissionReply, IntMessagingNewMessage, IntMessagingNewMessageReply} from './messaging.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    constructor(
        private http: HttpClient,
    ) {
    }

    /**
     * Get chat messages
     * @param userId
     * @param lastMessage: null to get the initial conversation, message ID to get the previous messages
     */
    getChat(userId: number, lastMessage: string | null): Observable<IntMessagingChat> {
        return this.http
        .get<IntMessagingChat>(`${environment.appApi}/${environment.endpoints.messagesgetchat}/` + userId + (lastMessage !== null ? '/' + lastMessage : ''));
    }


    public setImagePermission( userId: string,  permission: boolean ): Observable<IntMessagingImgPermissionReply> {
        const param = {
            permission: permission
        };

        return this.http
        .patch<IntMessagingImgPermissionReply>(`${environment.appApi}/${environment.endpoints.messagesimagePermission}/` + userId, param);
    }


    sendMessage( chat: IntMessagingNewMessage ): Observable<IntMessagingNewMessageReply> {
        if (chat.type === '1') {
            return this.http
            .post<IntMessagingNewMessageReply>(`${environment.appApi}/${environment.endpoints.messagessend}/${chat.userId}`, chat);
        } else {

            const formdata: FormData = new FormData();
            Object.keys(chat).forEach( k => {
                formdata.append(k, chat[k]);
            });

            return this.http
            .post<IntMessagingNewMessageReply>(`${environment.appApi}/${environment.endpoints.messagessend}/${chat.userId}`, formdata);
        }
    }
}
