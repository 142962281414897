import {trigger, state, style, animate, transition, keyframes, query, stagger, group} from '@angular/animations';


export const actionsModalProfile = [
	trigger('actionsModalProfile',[
		state('visible', style({ opacity: '1' }) ),
		transition('void => visible', [animate('180ms',style({opacity: '1'}))])
	])
]

export const likedProfile = [
	trigger('likedProfile',[
		state('visible', style({ opacity: '0.98' }) ),
		state('hidden',  style({ opacity: '0' }) ),
		transition('* => visible', [animate('1200ms ease-out')]),
		transition('visible => hidden', [animate('300ms ease-out')])
	])
]

export const disLikedProfile = [
	trigger('disLikedProfile',[
		state('visible', style({ opacity: '1' }) ),
		state('hidden',  style({ opacity: '0' }) ),
		transition('* => visible', [animate('500ms ease-out')])
	])
]

export const fadeIn = [
	trigger('fadeIn', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateY(10px)' }),
			animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
		])
	])
]

export const fadeOut = [
	trigger('fadeOut', [
		transition(':leave', [
			animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
		])
	])
]

export const rowsIn = [
	trigger('rowsIn', [
		transition(':enter', [
			query(':enter', [
				style({ opacity: 0, transform: 'scale(0.8)' }),
				group([
					animate('500ms', style({opacity: 1})),
					animate('200ms', style({transform: 'scale(1)'}))
				])
			])
		]),
		transition(':leave', [
			query(':leave', [
				style({ opacity: 1, transform: 'scale(1)' }),
				group([
					animate('500ms', style({opacity: 0})),
					animate('200ms', style({transform: 'scale(0.8)'}))
				])
			])
		])
	])
]

export const EnterLeave = [
	trigger('EnterLeave', [
		transition(':enter', [
			style({ transform: 'scaleY(0)' }),
			animate('150ms ease', style({ transform: 'scaleY(1)' }))
		]),
		transition(':leave', [
			animate('75ms ease', style({ transform: 'scaleY(0)', opacity: '0' }))
		])
	])
]
