import {
	IntAccountDataInfo,
	IntAutologinResponse,
	IntConversionFunnel,
	IntJuegoLikesOne,
	IntLoginData,
	IntMatchInfo,
	IntMobbexCheckout,
	IntMobbexCheckoutSuccess,
	IntPaywallData,
	IntProfileV2Data,
	IntRefundReply,
	IntSignupErrors,
	IntSignupPostData,
} from './user.model';
import {UserActions, UserActionTypes} from './user.actions';
import {zcDeepCopy} from '../../services/zc.globals';

// import {State} from '@ngrx/store';


export interface UserState {
    currentSession: IntLoginData | null;
    profileV2?: IntProfileV2Data | null;
    facebookSignupData: IntSignupPostData | null;
    accountData?: IntAccountDataInfo | null;
    paywall: {
    		data: IntPaywallData | null;
    		abonosReverse: any | null;
    };
    match: IntMatchInfo | null;
    paywallLoading: boolean;
    juegoLikesData: IntJuegoLikesOne[] | null;
    juegoLikesLoading: boolean;
    messages?: {
        loginInProcess?: boolean;
        loginError?: string;
		loginErrorMsg?: string;

        loginInFacebookProcess?: boolean;
        loginFacebookError?: string;

        signupProcess?: boolean;
        signupError?: string;
        signupErrors?: IntSignupErrors;

        forgotProcess?: boolean;
        forgotSuccess?: boolean;
        forgotError?: string;

        resetProcess?: boolean;
        resetSuccess?: boolean;
        resetError?: string;
    };
    signupFromTokenLogin?: boolean;
    attemptLogin: boolean;
    autologin: IntAutologinResponse;
    sessionInfo: any;
	isLoadingRefund: boolean;
	resultRefund: IntRefundReply;
	isErrorRefund: boolean;
	isDoneRefund: boolean;
	countRegModals: number;
	conversionFunnel: IntConversionFunnel;
	mobbexCheckout: IntMobbexCheckout;
	mobbexCheckoutSuccess: IntMobbexCheckoutSuccess;
}


export const initialState: UserState = {
    currentSession: null,
    facebookSignupData: null,
    profileV2: null,
    accountData: null,
    paywall: {data: null, abonosReverse: null},
    paywallLoading: false,
    match: null,
    messages: null,
    signupFromTokenLogin: false,
    attemptLogin: false,
    juegoLikesLoading: false,
    juegoLikesData: null,
    autologin: null,
    sessionInfo: null,
	isLoadingRefund: false,
	isErrorRefund: false,
	resultRefund: null,
	isDoneRefund: false,
	countRegModals: 0,
	conversionFunnel: null,
	mobbexCheckout: null,
	mobbexCheckoutSuccess: null
};

export function reducer(
    state = initialState,
    action: UserActions
): UserState {
    switch (action.type) {

		case UserActionTypes.UserMobbexCheckout: {
			return {
				...state,
				mobbexCheckout: action.payload.data
			}
		}
		case UserActionTypes.UserMobbexCheckoutSuccess: {
			return {
				...state,
				mobbexCheckoutSuccess: action.payload.data
			}
		}

		case UserActionTypes.UserSetConversionFunnel:{
			return {
				...state,
				conversionFunnel: action.payload.data
			}
		}

		case UserActionTypes.UserReloadSessionSuccess: {
			return {
				...state,
				currentSession: action.payload.result
			}
		}

        case UserActionTypes.UserAutologin: {
            return {
                ...state,

            };
        }
        case UserActionTypes.UserAutologinSuccess: {
            return {
                ...state,
                autologin: action.payload.data,
                attemptLogin: true
            };
        }
        case UserActionTypes.UserAutologinClear: {
            return {
                ...state,
                autologin: null
            };
        }

        case UserActionTypes.UserJuegoLikesRemoveBoth: {
            const _temp = [...state.juegoLikesData];
            _temp.splice(0, 2);
            return {
                ...state,
                juegoLikesData: _temp
            };
        }
        case UserActionTypes.UserJuegoLikes: {
            return {
                ...state,
                juegoLikesLoading: true
            };
        }
        case UserActionTypes.UserJuegoLikesSuccess: {
            return {
                ...state,
                juegoLikesLoading: false,
                juegoLikesData: action.payload.juegoData.resultset
            };
        }

        case UserActionTypes.LogInUser: {
            return {
                ...state,
                messages: {
                    loginInProcess: true
                },
                signupFromTokenLogin: false,
                attemptLogin: true
            };
        }

        case UserActionTypes.LoggedInUser: {
            return {
                ...state,
                currentSession: action.payload.loginData,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: false
            };
        }

        case UserActionTypes.LogInFail: {

			let $parsedMsg;
			switch (action.payload.loginData.errcode){
				case 1000:
					$parsedMsg = 'El mail es invalido';
					break;
				case 1001:
					$parsedMsg = 'El usuario o contraseña son incorrectos';
					break;
				case 1002:
					$parsedMsg = 'Usuario bloqueado';
					break;
				case 1003:
					$parsedMsg = 'El mail no existe';
					break;
			}

			return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    loginError: action.payload.loginData.error,
					loginErrorMsg: $parsedMsg
                }
            };
        }

        case UserActionTypes.LogInFacebookUserStart: {
            return {
                ...state,
                messages: {
                    loginInFacebookProcess: true
                },
                signupFromTokenLogin: false,
                attemptLogin: true
            };
        }

        case UserActionTypes.AttachFacebookToken: {

            const sess = state.currentSession;
            sess.longAccessToken = action.payload.authData.accessToken;
            sess.fb_connect_status = 'connected;';

            return {
                ...state,
                currentSession: sess,
            };
        }

        case UserActionTypes.LogInFacebookUserSignupData: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: action.payload.signupData,
                messages: null,
            };
        }

        case UserActionTypes.LogInFacebookFail: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    loginFacebookError: action.payload.error
                }
            };
        }

        case UserActionTypes.UserForgot: {
            return {
                ...state,
                messages: {
                    forgotProcess: true
                },
            };
        }

        case UserActionTypes.UserForgotSuccess: {
            return {
                ...state,
                messages: {
                    forgotSuccess: true
                },
            };
        }

        case UserActionTypes.UserForgotReset: {
            return {
                ...state,
                messages: null,
            };
        }

        case UserActionTypes.UserForgotFail: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    forgotError: action.payload.forgotData.error
                }
            };
        }


        case UserActionTypes.UserResetpwd: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    resetProcess: true
                },
            };
        }

        case UserActionTypes.UserResetpwdSuccess: {
            return {
                ...state,
                messages: {
                    resetSuccess: true
                },
            };
        }

        case UserActionTypes.UserResetpwdFail: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    resetError: action.payload.reply.error
                }
            };
        }

        case UserActionTypes.UserSignup: {
            return {
                ...state,
                messages: {
                    signupProcess: true
                },
                signupFromTokenLogin: false,
                attemptLogin: true
            };
        }

        case UserActionTypes.UserSignupFail: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: {
                    signupErrors: action.payload.response.errors
                }
            };
        }

        case UserActionTypes.UserSignupSuccess: {
            return {
                ...state,
                currentSession: action.payload.loginData,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: false
            };
        }

        case UserActionTypes.LogInFromStoredTokenSuccess: {
            return {
                ...state,
                currentSession: action.payload.loginData,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: true,
                attemptLogin: true
            };
        }

        case UserActionTypes.LogInFromStoredTokenFail: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: true, // prevents for new login attempts
                attemptLogin: true
            };
        }


        case UserActionTypes.LoggedOutUser: {
            return {
                ...initialState,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: false
            };
        }

        case UserActionTypes.UserCountersSuccess: {

            const tmp = zcDeepCopy(state.currentSession);
            if (tmp.sessionInfo !== null) {
                tmp.sessionInfo.counters = {...action.payload.counters};
            }

            return {
                ...state,
                currentSession: tmp
            };
        }

        case UserActionTypes.UserProfileV2Success: {

            return {
                ...state,
                profileV2: action.payload.profileData
            };
        }

        case UserActionTypes.UserAccountAlertsSuccess: {

            const tmp = zcDeepCopy(state.accountData);
            tmp.alerts = action.payload.alerts;

            return {
                ...state,
                accountData: tmp
            };
        }

        case UserActionTypes.ProfileNewImageSuccess: {
            const tmp = zcDeepCopy(state.profileV2);

            tmp.complitud = action.payload.reply.data.complitud;

            tmp.gcpImageCount += action.payload.reply.imageData.length;
            action.payload.reply.imageData.forEach((img: any) => {
                tmp.gcpImages.push({
                    id: img.imageId,
                    imageName: img.gcpImage,
                    approved: false,
                    isDefaultImage: false
                });
            });

            return {
                ...state,
                profileV2: tmp
            };
        }

        case UserActionTypes.ProfileDeleteImageSuccess: {
            const tmp = zcDeepCopy(state.profileV2);

            // Remove from images
            const ix = tmp.gcpImages.findIndex(e => {
                return e.imageName === action.payload.image.imageName;
            });
            tmp.gcpImageCount--;
            tmp.gcpImages.splice(ix, 1);

            // If image was default, set the new one
            if (action.payload.image.isDefaultImage) {
                if (!action.payload.reply.newDefaultImage) {
                    // No new default image
                    tmp.gcpImage = '';
                } else {
                    // New default image
                    const imgIx = tmp.gcpImages.findIndex(e => {
                        return e.imageName === action.payload.reply.newDefaultImage.gcpImage;
                    });
                    tmp.gcpImages[imgIx].isDefaultImage = true;
                    tmp.gcpImage = action.payload.reply.newDefaultImage.gcpImage;
                }
            }

            return {
                ...state,
                profileV2: tmp
            };
        }

        case UserActionTypes.ProfileSetImageSuccess: {
            const tmp = zcDeepCopy(state.profileV2);

            // Update profile image
            tmp.gcpImage = action.payload.image.imageName;

            // TODO CHECK DEFAULT

            // Update profile image in image array
            tmp.gcpImages.forEach(e => {
                e.isDefaultImage = (e.imageName === action.payload.image.imageName);
            });

            return {
                ...state,
                profileV2: tmp
            };
        }

        case UserActionTypes.ProfileAgeRangeSuccess: {
            const tmp = zcDeepCopy(state.currentSession) as IntLoginData;

            tmp.sessionInfo.me.age_range.edad_min = '' + action.payload.min;
            tmp.sessionInfo.me.age_range.edad_max = '' + action.payload.max;

            return {
                ...state,
                currentSession: tmp,

            };
        }


        case UserActionTypes.UserAccountDataSuccess: {

            return {
                ...state,
                accountData: action.payload.accountData
            };
        }

        case UserActionTypes.UserProfileDescripSuccess: {

            const tmp = zcDeepCopy(state.profileV2);
            tmp.description = '';
            tmp.moderationDescription.description = action.payload.desc;
            tmp.moderationDescription.approved = false;
            return {
                ...state,
                profileV2: tmp
            };
        }

        case UserActionTypes.UserProfileField: {
            const tmp = zcDeepCopy(state.profileV2);
            tmp[action.payload.mainKey][action.payload.sectionIx].list[action.payload.listIx] = {...action.payload.f};

            return {
                ...state,
                profileV2: tmp
            };
        }
        //
        // case UserActionTypes.UserProfileFieldSuccess: {
        //     const tmp = zcDeepCopy(state.profileV2);
        //     tmp[action.payload.mainKey][action.payload.sectionIx].list[action.payload.listIx] = {...action.payload.f};
        //
        //     return {
        //         ...state,
        //         profileV2: tmp
        //     };
        // }

        case UserActionTypes.UserProfileApodoLocalidadSuccess: {

            const tmp = zcDeepCopy(state.profileV2);

            if (state.profileV2.username !== action.payload.apodo) {
                tmp.username = action.payload.apodo;
                tmp.moderationUsername.username = action.payload.apodo;
                tmp.moderationUsername.approved = false;
            }

            tmp.location.cityId = '' + action.payload.idLocalidad;
            tmp.location.provinceId = '' + action.payload.idProvincia;

            return {
                ...state,
                profileV2: tmp
            };
        }

        case UserActionTypes.UserProfileCompletitudUpdate: {

            const tmp = zcDeepCopy(state.profileV2);
            tmp.complitud = action.payload.completitud;

            return {
                ...state,
                profileV2: tmp
            };
        }


        case UserActionTypes.Paywallget: {
            return {
                ...state,
                paywallLoading: true,
                paywall: null
            };
        }

        case UserActionTypes.PaywallgetSuccess: {
            return {
                ...state,
                paywallLoading: false,
                paywall: {
                	data: action.payload.paywall,
					abonosReverse: action.payload.paywall.abonos.reverse()
				}
            };
        }
        case UserActionTypes.SeHaFormadoUnaPareja: {
            return {
                ...state,
                match: action.payload.match,
            };
        }

        case UserActionTypes.ErrorUser: {
            return {
                ...state,
                currentSession: null,
                profileV2: null,
                facebookSignupData: null,
                messages: null,
                signupFromTokenLogin: false
            };
        }

		case UserActionTypes.UserRefund: {
			return {
				...state,
				isLoadingRefund: true,
				isErrorRefund: false
			}
		}
		case UserActionTypes.UserRefundSuccess: {
			return {
				...state,
				isLoadingRefund:false,
				isErrorRefund: false,
				isDoneRefund: true,
				resultRefund: action.payload.result
			}
		}
		case UserActionTypes.UserRefundFails: {
			return {
				...state,
				isLoadingRefund: false,
				isErrorRefund: true,
				isDoneRefund: true,
				resultRefund: action.payload.result
			}
		}

		case UserActionTypes.UserSetCountModalsReg: {
			return { ...state }
		}
		case UserActionTypes.UserSetCountModalsRegSuccess: {
			return {
				...state,
				countRegModals: action.payload.result.count
			}
		}

        default: {
            return state;
        }
    }
}
