import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {UsersService} from '../../store/user/users.service';
import {IntCancelAutDebtFirst, IntCancelAutDebtPersons} from "../../store/user/user.model";
import {utils} from "protractor";
import {ZcUtilsService} from "../../services/zc-utils.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-bajadebaut',
  templateUrl: './bajadebaut.component.html',
  styleUrls: ['./bajadebaut.component.scss']
})
export class BajadebautComponent implements OnInit {

	data = {
		email: '',
		password: ''
	}
	error = {
		email: '',
		password: ''
	}

	loading:boolean = false;

	firstStepShow: boolean = true;
	secondStepShow: boolean = false;
	thirdStepShow: boolean = false;

	dataPersons: IntCancelAutDebtPersons[] = null;

  constructor(
	  private dialogRef: MatDialogRef<BajadebautComponent>,
	  public userService: UsersService,
	  private utils: ZcUtilsService,
	  private router: Router,
  ) { }

  ngOnInit(): void {
  }


  firstStep(){
	  //
	  this.error = { email: '', password: '' };
	  this.error.email = this.data.email === '' ? 'Debe completar el campo email' : '';
	  this.error.password = this.data.password === '' ? 'Debe completar el campo contraseña' : '';
	  if((this.data.email === '') || (this.data.password === '')){ return; }
	  //
	  const objFirstStep: IntCancelAutDebtFirst = {
		  email: this.data.email,
		  password: this.data.password
	  }
	  this.loading = true;
	  this.userService.deleteDebAutFirst(objFirstStep).subscribe((ret) => {
		  this.loading = false;
		  if(ret.status === 200) {
			  //
			  this.firstStepShow = false;
			  this.secondStepShow = true;
			  this.dataPersons = ret.data;
			  //
		  }
	  });
  }

  secondStep(){
	  //
	  this.loading = true;
	  this.userService.deleteDebAutFinish().subscribe((ret) => {
		  //
		  this.loading = false;
		  if(ret.status == 200){
			  this.secondStepShow 	= false;
			  this.thirdStepShow 	= true;
		  }
		//
	  });
	  //
  }

  goToHome(){
	  this.router.navigateByUrl('/dashboard');
	  this.close();
  }

  close(){
		this.dialogRef.close();

  }

}
