import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../store/user/users.service';
import {IntPasswordChangeReply} from '../../store/user/user.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import { LogoutUser } from 'src/app/store/user/user.actions';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-baja',
    templateUrl: './baja.component.html',
    styleUrls: ['./baja.component.scss']
})
export class BajaComponent implements OnInit {

    password = '';
    error = '';
    loading = false;

    constructor(
        private store: Store<fromRoot.State>,
        private dialogRef: MatDialogRef<BajaComponent>,
        public userService: UsersService
    ) {
    }

    ngOnInit() {
    }

    close(){
        this.dialogRef.close();
    }

    confirmar() {
        this.error = '';
        if (this.password === '') {
            this.error = 'Ingresá tu contraseña para continuar';
            return;
        }

        this.loading = true;

        this.userService.deleteAccount(this.password).subscribe(
            (r: IntPasswordChangeReply) => {
                this.loading = false;
                if (r.status !== 200) {
                    this.error = r.msg || r.error || 'Error no especificado al intentar dar de baja la cuenta';
                } else {
                    this.close();
                }
                this.store.dispatch(new LogoutUser());
            },
            (r: any) => {

                if (!r.error.msg && r.error.error && (r.error.error === 'wrong_password')) {
                    r.error.error = 'Contraseña incorrecta';
                }

                this.loading = false;
                this.error = r.error.msg || r.error.error || 'Error no especificado al intentar dar de baja la cuenta';
            }
        );
    }

}
