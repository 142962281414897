import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntVisitasGetListing, IntVisitasListingResults} from './visitas.model';


@Injectable()
export class VisitasService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public listing(listType: IntVisitasGetListing): Observable<IntVisitasListingResults> {
        return this.http
        .get<IntVisitasListingResults>(`${environment.appApi}/${environment.endpoints.visits}/` + listType,
			{headers: {'platform':'desktop'}});

    }

    /**
     * Scrolls
     * @param listtype
     * @param 0-based page number
     */
    public scroll(listType: IntVisitasGetListing, page: number): Observable<IntVisitasListingResults> {

        return this.http
        .get<IntVisitasListingResults>(`${environment.appApi}/${environment.endpoints.visits}/` + listType + '?page=' + page,
			{headers: {'platform':'desktop'}});

    }

}
