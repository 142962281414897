import {IntAccountDataInfo} from '../user/user.model';

export interface IntPaymentFail {
	error_nro: number;
	message: string;
}

export interface IntUICCInfo {
	'imgUrl': string;
	'issuerBank': string;
}

export enum IntUISidebarVisitasTab {
    UI_SIDE_VISITAS_RECIBIDAS = 'recibidas',
    UI_SIDE_VISITAS_REALIZADAS = 'realizadas'
}

export enum IntUISidebarMensajesTab {
    UI_SIDE_MSG_RECIBIDOS = 'recibidos',
    UI_SIDE_MSG_ENVIADOS = 'enviados'
}

export interface IntUISidebarUserLinks {
    'userId': number;
    'username': string;
    'age': number;
    'gender': string;
    'image': string;
    'gcpImage': string;
}

export interface IntUISidebarUserResults {
    status?: number;
    error?: string;
    type?: string;
    data?: IntUISidebarUserLinks[];
}

export interface IntUIBannerMkt {
    imageDesktop: string;
    imageMobile: string;
    linkDesktop: string;
    linkMobile: string;
    linkFrontSpa?: string;
    GA: any;
}
