import {IntMessagingChat, IntMessagingMessage, IntMessagingMessageImage} from './messaging.model';
import {MessagingActions, MessagingActionTypes} from './messaging.actions';
import * as moment from 'moment';

export interface MessagingState {
    chat: IntMessagingChat | null;
    inProcess: boolean;
    inProcessScroll: boolean;
    masMensajes: boolean;
    closeChat: boolean;
	isOnline: boolean;
	isTyping: boolean;
}

export const initialState: MessagingState = {
    chat: null,
    inProcess: false,
    inProcessScroll: false,
    masMensajes: false,
	closeChat: false,
	isOnline: false,
	isTyping: false
};

export function reducer(
    state = initialState,
    action: MessagingActions
): MessagingState {
    switch (action.type) {

		case MessagingActionTypes.MessagingIsOnline: {
			return {
				...state,
				isOnline: action.payload
			}
		}

		case MessagingActionTypes.MessagingIsTyping: {
			return {
				...state,
				isTyping: action.payload
			}
		}

        case MessagingActionTypes.MessagingCleanup: {
            return {
                ...initialState,
            };
        }

        case MessagingActionTypes.MessagingOpenChat: {
            return {
                ...state,
                chat: null,
                masMensajes: false,
                inProcess: true,
                inProcessScroll: false,
				closeChat: false
            };
        }

        case MessagingActionTypes.MessagingCloseChat: {
            return {
                ...state,
                chat: null,
                masMensajes: false,
                inProcess: false,
                inProcessScroll: false,
				closeChat: true
            };
        }

        case MessagingActionTypes.MessagingOpenChatSuccess: {

			/*TODO: filtro emoji*/
			/*action.payload.chat.conversacion.map((chat) => {
				if(chat.tipoMensaje === '1'){
					const emojiRegex = /\p{Emoji}/u;
					if(emojiRegex.test(chat.texto.toString())){
						chat._isEmoji = true;
					}else{
						chat._isEmoji = false;
					}
				}
			});
			console.log(action.payload.chat.conversacion);*/

			return {
                ...state,
                chat: action.payload.chat,
                masMensajes: action.payload.chat.masMensajes,
                inProcess: false,
                inProcessScroll: false
            };
        }

        case MessagingActionTypes.MessagingScrollChat: {
            return {
                ...state,
                inProcessScroll: true
            };
        }

        case MessagingActionTypes.MessagingScrollChatSuccess: {

            if (state.chat === null) {
                return {
                    ...state,
                };
            }

            const tmp = action.payload.chat;

            if (state.chat.user.id === tmp.user.id) {
                // agregar mensajes solo si el user anterior es el mismo que el del scroll
                tmp.conversacion = [...state.chat.conversacion, ...tmp.conversacion];
            }

            return {
                ...state,
                chat: tmp,
                masMensajes: action.payload.chat.masMensajes,
                inProcess: false,
                inProcessScroll: false
            };
        }

        case MessagingActionTypes.MessagingMessageReceivedAdd: {
            const tmp = action.payload.chat;

            if ((state.chat === null) || (tmp === null)) {
                return {
                    ...state,
                };
            }
            if (state.chat.user.id !== tmp.idemisor) {
                return {
                    ...state,
                };
            }

            const chat = {...state.chat};
            chat.conversacion = [tmp, ...state.chat.conversacion];

            return {
                ...state,
                chat: {...chat},
            };
        }

        case MessagingActionTypes.MessagingSendMessage: {

            if (state.chat === null) {
                return {
                    ...state,
                };
            }


            const tmp = action.payload.message;
            const chat = {...state.chat};

            if (state.chat.user.id === tmp.userId) {
                // agregar mensajes solo si el user anterior es el mismo que el del scroll
                const nuevo: IntMessagingMessage = {
                    id: tmp.temporal_id,
                    idemisor: 0,
                    username: '',
                    date: 'hoy',
                    fecha: moment().format('YYYY-MM-DD HH:mm'),
                    hour: '',
                    gcpImage: '',
                    gender: 1,
                    img: null,
                    soyUserAbreChat: true,
                    texto: tmp.mensaje,
                    original: '',
                    square180: '',
                    tipoMensaje: tmp.type,
                    _pending: true
                };
                chat.conversacion = [nuevo, ...chat.conversacion];
            }

            return {
                ...state,
                chat: {...chat}
            };
        }

        case MessagingActionTypes.MessagingSendMessageSuccess: {

            if (state.chat === null) {
                return {
                    ...state,
                };
            }

            const tmp = action.payload.message;
            const chat = {...state.chat};

            if (chat.user.id === tmp.userId) {
                // actualizar el estado del envío a enviado
                chat.conversacion.map((m, key) => {
                    if (m.id === tmp.temporal_id) {
                        const tmpMsg = {...m};
                        if (tmpMsg.tipoMensaje === '2') {
                            // si es imagen, actualizar con la imagen devuelta por el endpoint
                            tmpMsg.texto = action.payload.imagen as IntMessagingMessageImage;
                        }

                        tmpMsg._pending = false;
                        chat.conversacion[key] = {...tmpMsg};
                    }
                } );
            }

            return {
                ...state,
                chat: {...chat}
            };
        }

        case MessagingActionTypes.MessagingFail: {
            return {
                ...state,
                masMensajes: false,
                inProcess: false,
                inProcessScroll: false
            };
        }

        default: {
            return state;
        }
    }
}
