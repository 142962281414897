import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
	IntDashboardGetListing,
	IntDashboardListingResult,
	IntDashboardListingResults,
	IntDashboardSearchParams,
	IntSearchOptionSection
} from '../../store/dashboard/dashboard.model';
import {
	DashboardCleanup,
	DashboardDoDislike,
	DashboardDoLike,
	DashboardDoUndoLike,
	DashboardInitScroll,
	DashboardInitSearch
} from '../../store/dashboard/dashboard.actions';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {throttleTime} from 'rxjs/operators';
import {animate, style, transition, trigger} from '@angular/animations';
import {IntCiudadData, LocalidadesService} from '../../services/localidades.service';
import {BodyScrollUnLock} from '../../store/ui/ui.actions';
import {Options} from 'ng5-slider';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {GaService} from '../../services/ga.service';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {IntAutologinResponse, IntLoginData} from '../../store/user/user.model';
import {MatDialog} from '@angular/material';
import {ModalesPostRegistroComponent} from '../../components/modales-post-registro/modales-post-registro.component';
import {JuegoLikesComponent} from '../../components/juego-likes/juego-likes.component';
import {GetAutologinMessage, SetAutologinClear, SetAutologinDisLike, SetAutologinLike} from '../../store/user/user.actions';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('EnterLeave', [
            transition(':enter', [
                style({transform: 'scaleY(0)'}),
                animate('150ms ease', style({transform: 'scaleY(1)'}))
            ])
        ]),
        trigger('OpenClose', [
            transition(':enter', [
                style({height: '0'}),
                animate('150ms ease', style({height: '*'}))
            ]),
            transition(':leave', [
                animate('150ms ease', style({height: '0'}))
            ])
        ])
    ]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('infinityScroll') infinityScroll: ElementRef;

    ciudades: IntCiudadData[] = [];
    localidades: IntCiudadData[] = [];

    sliderDistanciaOptions: Options = {
        hidePointerLabels: true,
        hideLimitLabels: true,
        floor: 0,
        ceil: 100,
        step: 2
    };

    sliderEdadOptions: Options = {
        hidePointerLabels: true,
        hideLimitLabels: true,
        pushRange: true,
        // noSwitching: true,
        floor: 18,
        ceil: 99,
        step: 1
    };
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();

    localSession: IntLoginData = null;
    localAutologin: IntAutologinResponse = null;
    routeSub: Subscription = null;
    scrollSubs: Subscription = null;
    subs: Subscription = null;
    subsSearchOptions: Subscription = null;
    subsGaTab: Subscription = null;
    subsSession: Subscription = null;
    subsAutologin: Subscription = null;
	subsListing: Subscription = null;
    showMobileSearch = false;
    showAdvancedSearch = false;
    enumListType: any = {
        DASH_RECENT: IntDashboardGetListing.DASH_RECENT,
        DASH_NEW: IntDashboardGetListing.DASH_NEW,
        DASH_ONLINE: IntDashboardGetListing.DASH_ONLINE,
        DASH_SEARCH: IntDashboardGetListing.DASH_SEARCH,
    };
    searchParams: IntDashboardSearchParams = {
        'id_provincia': '',
        'id_localidad': '',
        'distancia': '',
        'edad_min': '',
        'edad_max': '',
        'apodo': '',
        'estado_civil': '',
        'hijos': '',
        'religion': [],
        'busco': '',
        'altura': '',
        'estudios': '',
        'deseo_hijos': '',
        'fuma': '',
        'bebe': '',
        'signo': ''
    };

    totonline$: Observable<number> = this.store.pipe(select(fromRoot.getTotOnline));
    results$: Observable<IntDashboardListingResults> = this.store.pipe(select(fromRoot.getDashboardResults));
    activeTab$: Observable<IntDashboardGetListing> = this.store.pipe(select(fromRoot.getDashboardActiveTab));
    theActiveTab: IntDashboardGetListing = null;
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getDashboardInProcess));
    currentHash: string = null;
    searchOptions: IntSearchOptionSection[] = [];

    // vars para mantener el valor de los input del buscador, tienen que ser diferentes del slider ya que de otra manera el slider implementa su lógica de
    // intercambiar menor / mayor entre ambas variables, si se busca 18 - 20 y luego se quiere cambiar a 22 - 25, al ingresar 22 en edad desde, el slider
    // automaticamente cambia edad min a 20 y max a 22 siendo reflejado esto en los inputs
    search_edad_min: any;
    search_edad_max: any;

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private ga: GaService,
        private cd: ChangeDetectorRef,
        private localidadesService: LocalidadesService,
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private dialogJuego: MatDialog
    ) {
        // keep track of the last scroll hash
        this.subs = this.store.pipe(select(fromRoot.getDashboardHashID)).subscribe((hash) => {
            this.currentHash = hash;
            // this.cd.markForCheck();
        });

        // search options
        this.subsGaTab = this.store.pipe(select(fromRoot.getDashboardActiveTab)).subscribe((tab) => {
            this.theActiveTab = tab;
        });

		// search options
		this.subsSearchOptions = this.store.pipe(select(fromRoot.getDashboardSearchOptions)).subscribe((options) => {
			this.searchOptions = options;
		});

        this.localidades = this.localidadesService.getLocalidades();

        // check if open post register modals
        this.subsSession = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(
            info => {
                this.localSession = info;
            });

        // check autologin
        this.subsAutologin = this.store.pipe(select(fromRoot.getAutologin)).subscribe(
            info => {
                this.localAutologin = info;
            });
    }

    ngOnInit() {
		//
		this.store.dispatch(new DashboardInitSearch({type: IntDashboardGetListing.DASH_RECENT}));
		//
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.startsWith('/dashboard/perfil/')) {
                    this.store.dispatch(new ProfileOpen({userId: parseInt(this.route.snapshot.params['id'], 10), type: 'sent'}));
                }
                this.showMobileSearch = this.router.url === '/dashboard/search';
                this.cd.markForCheck();
            }
        });
        if (this.router.url.startsWith('/dashboard/perfil/')) {
            this.store.dispatch(new ProfileOpen({userId: parseInt(this.route.snapshot.params['id'], 10), type: 'sent'}));
        }

        this.showMobileSearch = this.router.url === '/dashboard/search';
    }

    triggerInfinitySearch() {
        if (!this.utils.isUndefinedOrNull(this.infinityScroll)) {
            const infinityScrollRect = this.infinityScroll.nativeElement.getBoundingClientRect();
            if (window.innerHeight > infinityScrollRect.top) {
                this.triggerScroll();
            }
        }
    }

    ngAfterViewInit() {
		// check if came from autologin
        if (this.localAutologin) {
            this.autologinFlow();
        }

        // load modales post registro
        if (this.localSession) {
            let modals = localStorage.getItem(environment.modalesPostRegistro);
            let juego = localStorage.getItem(environment.juegoLikes);
            if(modals == 'true') {
				this.modalsPostRegisterFlow();
			}else if(juego == 'true'){
            	this.showJuegoLikes();
			}
        }

        this.scrollSubs = fromEvent(window, 'scroll').pipe(
            throttleTime(10)
        )
        .subscribe(
            x => {
                this.triggerInfinitySearch();
            }
        );
    }

    ngOnDestroy(): void {
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
        if (this.scrollSubs !== null) {
            this.scrollSubs.unsubscribe();
        }
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsSearchOptions !== null) {
            this.subsSearchOptions.unsubscribe();
        }
        if (this.subsGaTab !== null) {
            this.subsGaTab.unsubscribe();
        }
        if (this.subsSession !== null) {
            this.subsSession.unsubscribe();
        }
    }

    search(t: IntDashboardGetListing) {
		this.store.dispatch(new DashboardInitSearch({type: t}));
        window.scrollTo(0, 0);
    }

    triggerScroll() {
        if (this.currentHash == null) {
            return;
        }
        this.store.dispatch(new DashboardInitScroll({hash: {scrollHash: this.currentHash}}));
        this.ga.triggerGenericEvent('evento', 'scroll', this.getGaTabCode());
    }

    getGaTabCode() {
        switch (this.theActiveTab) {
            case IntDashboardGetListing.DASH_NEW:
                return 'nuevos';
            case IntDashboardGetListing.DASH_RECENT:
                return 'recientes';
            case IntDashboardGetListing.DASH_ONLINE:
                return 'online';
            case IntDashboardGetListing.DASH_SEARCH:
                return 'busqueda';
        }
    }

    doLike(r: IntDashboardListingResult) {
        this.store.dispatch(new DashboardDoLike({user: r}));
        this.ga.triggerGenericEvent('evento', 'me_gusta', this.getGaTabCode());
    }

    doUnlike(r: IntDashboardListingResult) {
        this.store.dispatch(new DashboardDoUndoLike({user: r}));
    }

    doDislike(r: IntDashboardListingResult) {
        this.store.dispatch(new DashboardDoDislike({user: r}));

        // trigger infinity search in case we're out of results and the div is in view
        setTimeout(() => {
            this.triggerInfinitySearch();
        }, 3000);

        this.ga.triggerGenericEvent('evento', 'no_me_gusta', this.getGaTabCode());
    }

    sendMessage(r: IntDashboardListingResult) {
        this.store.dispatch(new MessagingOpenChat({
            userId: parseInt(r.id_usuario, 10),
            retreiveChatHistory: false,
            userName: r.apodo,
            userAvatar: this.utils.getUserImageName(r.gender, r.imagen_gcp),
            gender: r.gender
        }));
    }

    doSearch() {
        // if (fail) this.searchParams = {};
        this.searchParams.distancia = '' + this.searchParams.distancia;
        this.store.dispatch(new DashboardInitSearch({ type: IntDashboardGetListing.DASH_SEARCH, searchParams: this.searchParams }));
        if (this.showMobileSearch) {
            this.showMobileSearch = false;
            this.store.dispatch(new BodyScrollUnLock());
        }
    }

    closeMobileSearch() {
        this.showMobileSearch = false;
        this.store.dispatch(new BodyScrollUnLock());
    }

    updateCiudades() {
        this.searchParams.id_localidad = '';
        this.ciudades = this.localidadesService.getCiudad(parseInt(this.searchParams.id_provincia, 10));
    }

    getDistancia() {
        const c = this.localidadesService.getNombreCiudad(parseInt(this.searchParams.id_provincia, 10), parseInt(this.searchParams.id_localidad, 10));
        if (this.utils.isUndefinedOrNull(this.searchParams.distancia) || (this.searchParams.distancia === '') || (c === null)) {
            this.searchParams.distancia = 0;
        }

        if (c === null) {
            return '';
        } else {
            if ((this.searchParams.distancia === 0) || (this.searchParams.distancia === '0') || (this.searchParams.distancia === '')) {
                return 'Buscar sólo en ' + c.l;
            } else {
                return `Buscar hasta ${this.searchParams.distancia}km. de ${c.l}`;
            }
        }
    }

    cleanAdvSearch(close: boolean) {
        this.searchParams = {
            'id_provincia': this.searchParams.id_provincia,
            'id_localidad': this.searchParams.id_localidad,
            'distancia': this.searchParams.distancia,
            'edad_min': this.searchParams.edad_min,
            'edad_max': this.searchParams.edad_max,
            'apodo': '',
            'estado_civil': '',
            'hijos': '',
            'religion': [],
            'busco': '',
            'altura': '',
            'estudios': '',
            'deseo_hijos': '',
            'fuma': '',
            'bebe': '',
            'signo': ''
        };

        if (close) {
            this.showAdvancedSearch = false;
        }
    }

    getEdad() {
        let emin = parseInt(this.search_edad_min, 10);
        if (isNaN(emin)) {
            emin = 0;
        }

        let emax = parseInt(this.search_edad_max, 10);
        if (isNaN(emax)) {
            emax = 0;
        }

        if ((emin === 0) && (emax === 0)) {
            return 'Todas las edades';
        } else {
            return `de ${emin} a ${emax} años`;
        }
    }

    changeMin() {
        let emin = parseInt(this.search_edad_min, 10);
        if (isNaN(emin)) {
            emin = 0;
        }
        let emax = parseInt(this.search_edad_max, 10);
        if (isNaN(emax)) {
            emax = 0;
        }

        if (emin < 18) {
            emin = 18;
        }
        this.searchParams.edad_min = emin;

        if (emin > emax) {
            this.search_edad_max = emin;
            this.searchParams.edad_max = emin;
            this.manualRefresh.emit();
        }
    }

    changeMax() {
        let emin = parseInt(this.search_edad_min, 10);
        if (isNaN(emin)) {
            emin = 0;
        }
        let emax = parseInt(this.search_edad_max, 10);
        if (isNaN(emax)) {
            emax = 0;
        }

        if (emax < 18) {
            emax = 18;
        }
        this.searchParams.edad_max = emax;

        if (emax < emin) {
            this.search_edad_min = emax;
            this.searchParams.edad_min = emax;
            this.manualRefresh.emit();
        }
    }

    sliderUpdate() {
        this.search_edad_min = this.searchParams.edad_min;
        this.search_edad_max = this.searchParams.edad_max;
    }

    private modalsPostRegisterFlow() {
        //
		localStorage.setItem(environment.modalesPostRegistro,'false');
		// launch post modals
		setTimeout(() => {
			this.dialog.open(ModalesPostRegistroComponent, {
				width: '1000px',
				height: '700px', disableClose: true
			});
		}, 1000);
        //
    }

    private showJuegoLikes() {
		// Check if open juego likes
		localStorage.setItem(environment.juegoLikes, 'false');
		setTimeout(() => {
			this.dialogJuego.open(JuegoLikesComponent, {
				width: '893px',
				height: '710px',
				disableClose: true,
				panelClass: 'custom-modal'
			});
		}, 1000);
		//
	}

    private autologinFlow() {

    	//console.log(this.localSession.autologin);

        // dispatch action from autologin
        const action: string = this.localSession.autologin.action;
        const idUser: string = this.localSession.autologin.id;
        const idAbonoHash: string = this.localSession.autologin.idHash;
        switch (action) {
            case 'v':
                this.store.dispatch(new ProfileOpen({userId: parseInt(idUser, 10), type: 'sent'}));
                this.ga.triggerGenericEvent('evento', 'vista_perfil', 'autologin');
                break;
            case 'm':
                this.store.dispatch(new GetAutologinMessage({iduser: parseInt(idUser, 10)}));
                this.ga.triggerGenericEvent('evento', 'chat', 'autologin');
                break;
            case 'like':
                this.store.dispatch(new SetAutologinLike({iduser: idUser}));
                this.ga.triggerGenericEvent('evento', 'me_gusta', 'autologin');
                break;
            case 'dislike':
                this.store.dispatch(new SetAutologinDisLike({iduser: idUser}));
                this.ga.triggerGenericEvent('evento', 'no_me_gusta', 'autologin');
                break;
			case 'p':
				//iduser, en este caso es el id de paywall
				let _url = 'suscribite/' + idUser;
				this.router.navigate([_url]);
				this.ga.triggerGenericEvent('evento', 'marketing', 'promomail');
				break;
			case 'o':
				let _urlGeneric = 'suscribite/25/' + idAbonoHash;
				this.router.navigate([_urlGeneric]);
				this.ga.triggerGenericEvent('evento', 'marketing', 'promomail');
				break;
			case 'c':
				this.router.navigate(['micuenta']);
				break;
			case 'visitas':
				this.router.navigate(['visitas']);
				break;
			case 'edit':
				this.router.navigate(['perfil']);
				break;
        }
        this.store.dispatch(new SetAutologinClear());
        //
    }
}
