import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {IntMensajesListingResults, IntMensajesListings} from '../../store/mensajes/mensajes.model';
import {
    MensajesDeleteSelected,
    MensajesInitScroll,
    MensajesInitSearch, MensajesMarkAsRead,
    MensajesSelectAll,
    MensajesSelectNone, MensajesSelectOne
} from '../../store/mensajes/mensajes.actions';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {DialogsService} from '../../services/dialogs.service';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {ProfileOpen} from '../../store/profile/profile.actions';
import {GaService} from '../../services/ga.service';
import {rowsIn} from '../../directives/animations';
import {stopPropagation} from 'ngx-scrollbar/lib/scrollbar/common';

@Component({
    selector: 'app-mensajes',
    templateUrl: './mensajes.component.html',
    styleUrls: ['./mensajes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ rowsIn ]
})
export class MensajesComponent implements OnInit, OnDestroy {

    enumListType: any = {
        MENSAJES_RECEIVED: IntMensajesListings.MENSAJES_RECEIVED,
        MENSAJES_CONVERSATIONS: IntMensajesListings.MENSAJES_CONVERSATIONS,
        MENSAJES_SENT: IntMensajesListings.MENSAJES_SENT,
    };

    typeSubs: Subscription = null;
    routeSub: Subscription = null;

    results$: Observable<IntMensajesListingResults> = this.store.pipe(select(fromRoot.getMensajesResults));
    activeTab$: Observable<IntMensajesListings> = this.store.pipe(select(fromRoot.getMensajesActiveTab));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.getMensajesInProcess));
	loadingOne$: Observable<boolean> = this.store.pipe(select(fromRoot.getMessagingProcess));
    currentType: IntMensajesListings = IntMensajesListings.MENSAJES_RECEIVED;

    activeLoading: number = 0;

    allSelected: boolean = false;

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private utils: ZcUtilsService,
        private dialogService: DialogsService,
        private route: ActivatedRoute,
        private ga: GaService,
        private router: Router
    ) {
        this.typeSubs = this.store.pipe(select(fromRoot.getMensajesActiveTab)).subscribe((tab: IntMensajesListings) => {
            this.currentType = tab;
            // this.cd.markForCheck();
        });

    }

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.search(this.route.snapshot.params['tab'] === 'enviados' ? IntMensajesListings.MENSAJES_SENT : IntMensajesListings.MENSAJES_RECEIVED);
            }
        });

        this.search(this.route.snapshot.params['tab'] === 'enviados' ? IntMensajesListings.MENSAJES_SENT : IntMensajesListings.MENSAJES_RECEIVED);
    }

    ngOnDestroy(): void {
        if (this.typeSubs !== null) {
            this.typeSubs.unsubscribe();
        }
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
    }

    refresh() {
        this.search(this.currentType);
    }

    search(listType: IntMensajesListings) {
        this.store.dispatch(new MensajesInitSearch({type: listType}));
    }

    triggerScroll() {
        this.store.dispatch(new MensajesInitScroll({type: this.currentType}));
    }

    toggleSelected(){
        if(this.allSelected){
            this.store.dispatch(new MensajesSelectAll());
        }else{
            this.store.dispatch(new MensajesSelectNone());
        }
    }

    markAsRead() {
        this.store.dispatch(new MensajesMarkAsRead({type: this.currentType}));
    }

    deleteSelected() {
        this.store.dispatch(new MensajesDeleteSelected({type: this.currentType}));
        // this.dialogService.confirm('¿Confirma eliminar los mensajes seleccionados?', () => {
        //     this.store.dispatch(new MensajesDeleteSelected({type: this.currentType}));
        // });
    }

    openMessages(userId: string, userName: string, userAvatar: string, gender: string, idx: number) {
        this.activeLoading = idx;
    	this.store.dispatch(new MessagingOpenChat({
            userId: parseInt(userId, 10),
            retreiveChatHistory: true,
            userName: userName,
            userAvatar: userAvatar,
            gender: gender
        }));
        this.ga.triggerGenericEvent('evento', 'chat', this.enumListType === IntMensajesListings.MENSAJES_SENT ? 'mensajes_enviados' : 'mensajes_recibidos');
    }

    getRandomImage(index) {
        return (index % 8) + 1;
    }

    selectOne(userId: string) {
        this.store.dispatch(new MensajesSelectOne({userId: userId}));
    }

    viewProfile(userId: string) {
    	if(!this.utils.checkZCUsers(userId)){
			this.store.dispatch(new ProfileOpen({userId: parseInt(userId, 10), type: 'sent'}));
			this.ga.triggerGenericEvent('evento', 'vista_perfil', 'mensajes');
		}
    }
}
