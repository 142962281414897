import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {
	IntAbTest,
	IntAccountData,
	IntAccountMainAlerts,
	IntAutologin,
	IntAutologinResponse, IntCancelAutDebtFirst, IntCancelAutDebtFirstSuccess,
	IntConversionFunnel,
	IntConversionFunnelReply,
	IntCountRegModals,
	IntFacebookLoginData,
	IntJuegoLikesReq,
	IntJuegoLikesResponse,
	IntLoginData,
	IntLoginPostData,
	IntMobbexCheckout, IntMobbexCheckoutSuccess, IntMobbexSubscriberSuccess,
	IntPasswordChange,
	IntPasswordChangeReply,
	IntPasswordResetData,
	IntPaywallCCPost,
	IntPaywallCCReply,
	IntPaywallReply,
	IntPaywallTicketReply,
	IntProfileNewImage,
	IntProfileUpdateReply,
	IntProfileV2Data,
	IntProfileV2DataQuestionData,
	IntProfileV2GcpImage,
	IntRefundReply,
	IntSessionCountersReply,
	IntSignupPostData
} from './user.model';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {zcDeepCopy} from '../../services/zc.globals';

declare var FB: any;

@Injectable()
export class UsersService {

    constructor(
        private utils: ZcUtilsService,
        private http: HttpClient,
    ) {
    }

    public getRecordarLogin() {
        return localStorage.getItem(environment.recordarLoginKey) === '1';
    }

    public setRecordarLogin(recordar: boolean) {
        if (!recordar) {
            localStorage.clear();
        } else {
            localStorage.setItem(environment.recordarLoginKey, '1');
        }
    }

    public setTokens(accessToken: string, recoveryToken: string): void {
        if (this.getRecordarLogin()) {
            localStorage.setItem(environment.accessTokenKey, accessToken);
            localStorage.setItem(environment.recoveryTokenKey, recoveryToken);
        } else {
            sessionStorage.setItem(environment.accessTokenKey, accessToken);
            sessionStorage.setItem(environment.recoveryTokenKey, recoveryToken);
        }
    }

    public setPostRegisterModals(modals: any){
    	let ret = modals[0].filter(function(modal){
    		return (modal.showStep ==1);
		})
		let modales: string = (ret.length > 0) ? 'true' : 'false';
		localStorage.setItem(environment.modalesPostRegistro, modales);
	}

	public setJuegoLikeInit(session: any) {
    	let ret;
    	if(!session.sessionInfo.me.billing.premium && session.sessionInfo.me.gcpImage !== null) {
			ret = 'true';
		}else{ ret = 'false'; }
    	localStorage.setItem(environment.juegoLikes, ret);
	}

    public getAccessToken(): string | null {
        if (this.getRecordarLogin()) {
            return localStorage.getItem(environment.accessTokenKey);
        } else {
            return sessionStorage.getItem(environment.accessTokenKey);
        }
    }

    public getRecoveryToken(): string | null {
        if (this.getRecordarLogin()) {
            return localStorage.getItem(environment.recoveryTokenKey);
        } else {
            return sessionStorage.getItem(environment.recoveryTokenKey);

        }
    }

    public clearTokenStorage(): void {
        // save remember flag to set it back
        const recordarLogin = this.getRecordarLogin();
        localStorage.clear();
        sessionStorage.clear();
        if (recordarLogin) {
            this.setRecordarLogin(true);
        }
    }

	// Session
    public getSession(): Observable<IntLoginData> {
        const helper = new JwtHelperService();
        const tok = helper.decodeToken(this.getAccessToken());
        const is_expired = helper.isTokenExpired(this.getAccessToken());

        if (tok === null) {
            return throwError('');
        } else if (is_expired) {
            return this.relogin(this.getRecoveryToken());
        } else {
            return this.http
            .get<IntLoginData>(`${environment.appApi}/${environment.endpoints.session}`);
        }

    }
	// Reload session
	public getReloadSession(): Observable<IntLoginData> {
		return this.http
			.get<IntLoginData>(`${environment.appApi}/${environment.endpoints.reloadsession}`);
	}

    // Autologin
	public setAutologin(dataReq: IntAutologin): Observable<IntAutologinResponse> {
		// HACK para paywall generico
    	if(dataReq.idabono != null) {
			return this.http
				.get<IntAutologinResponse>(`${environment.appApi}/
			${environment.endpoints.autologin}/
			${dataReq.mailToken}/${dataReq.action}/${dataReq.receptorId}?idabono=${dataReq.idabono}`)
		}else if(dataReq.origin !== null){
			return this.http
				.get<IntAutologinResponse>(`${environment.appApi}/
			${environment.endpoints.autologin}/
			${dataReq.mailToken}/${dataReq.action}/${dataReq.receptorId}/${dataReq.origin}`)
		}else{
			return this.http
				.get<IntAutologinResponse>(`${environment.appApi}/
			${environment.endpoints.autologin}/
			${dataReq.mailToken}/${dataReq.action}/${dataReq.receptorId}`)
		}
	}
    // juego likes
	public getJuegoLikes(dataReq: IntJuegoLikesReq): Observable<IntJuegoLikesResponse> {
    	return this.http
		.get<IntJuegoLikesResponse>(`${environment.appApi}/
		${environment.endpoints.userjuegolikes}/
		${dataReq.iduser}/${dataReq.idbusco}/${dataReq.idprov}/
		${dataReq.edadini}/${dataReq.edadfin}`);
	}

    public getCounters(): Observable<IntSessionCountersReply> {
        return this.http
        .get<IntSessionCountersReply>(`${environment.appApi}/${environment.endpoints.counters}`);
    }

    public getProfileData(): Observable<IntProfileV2Data> {
        return this.http
        .get<IntProfileV2Data>(`${environment.appApi_base}${environment.endpoints.profilegetedit_v2}`);
    }

    public login(credentials: IntLoginPostData): Observable<IntLoginData> {

        return this.http
        .post<IntLoginData>(`${environment.appApi}/${environment.endpoints.authenticate}`, credentials);

    }

    public signup(user: IntSignupPostData): Observable<IntLoginData> {

        return this.http
        .post<IntLoginData>(`${environment.appApi}/${environment.endpoints.register}`, user);

    }

    public getFacebookSession(authData: IntFacebookLoginData): Observable<IntLoginData> {

        const postAuthData = {
            accessToken: authData.accessToken,
            userID: authData.userID
        };

        return this.http
        .post<IntLoginData>(`${environment.appApi}/${environment.endpoints.facebookconnect}`, postAuthData);

    }

    public forgot(credentials: IntLoginPostData): Observable<IntPasswordChangeReply> {

        return this.http
        .post<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.resetpassword}`, credentials);

    }

    public forgotSetPassword(credentials: IntPasswordResetData): Observable<IntPasswordChangeReply> {

        return this.http
        .post<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.setpassword}`, credentials);

    }

    public relogin(recoveryToken: string): Observable<IntLoginData> {

        return this.http
        .post<IntLoginData>(`${environment.appApi}/${environment.endpoints.relog}`, {recovery_token: recoveryToken});

    }

    public getAccountData(): Observable<IntAccountData> {

        return this.http
        .get<IntLoginData>(`${environment.appApi}/${environment.endpoints.accountgetdata}`);

    }

    public setAccountAlerts(alerts: IntAccountMainAlerts): Observable<IntPasswordChangeReply> {

        return this.http
        .patch<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.accountnotifications}`, alerts);

    }

    public logout(logoutFromFB: boolean): Observable<any> {
        if (logoutFromFB && FB) {
            // logout from facebook account
            FB.logout(function (response) {
            });
        }
        return this.http
        .post(`${environment.appApi}/${environment.endpoints.logout}`, {'deviceType': 3});

    }

    public changePassword(pass: IntPasswordChange): Observable<IntPasswordChangeReply> {

        return this.http
        .patch<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.accountchangepassword}`, pass);

    }

    public deleteAccount(pass: string): Observable<IntPasswordChangeReply> {

        const payload = {password: pass};

        return this.http
        .request<IntPasswordChangeReply>('DELETE', `${environment.appApi}/${environment.endpoints.accountdelete}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: payload
        });
    }

	public setPostRegModalsCount(): Observable<IntCountRegModals>{
		return this.http
			.post<IntCountRegModals> (`${environment.appApi_base}/${environment.endpoints.usersetmodalscount}`,'');
	}

    public refundAccount(iduser: string): Observable<IntRefundReply> {
        const params = {id: iduser};
        return this.http
        .post<IntRefundReply>(`${environment.appApi}/${environment.endpoints.accountRefund}`, params);
    }

    public updateProfileDesciption(desc: string): Observable<IntProfileUpdateReply> {
        return this.http
        .patch<IntProfileUpdateReply>(`${environment.appApi}/${environment.endpoints.profiledescription}`, {descripcion: desc});
    }

    public updateAgeRange(min: number, max: number): Observable<IntProfileUpdateReply> {
        return this.http
        .patch<IntProfileUpdateReply>(`${environment.appApi}/${environment.endpoints.accountagerange}`, {min: min, max: max});
    }

    public updateProfileField(f: IntProfileV2DataQuestionData, sectionIx: number, listIx: number): Observable<IntProfileUpdateReply> {

        let key = 'selectedValues';
        const tmpObj = zcDeepCopy(f);

        // si se está actualizando tags de música/libros/películas utilizas options en vez de selectedValues
        if ((sectionIx === 5) && ((listIx >= 2))) {
            key = 'options';
        }

        // para campos de selección múltiple, asegurar que se envía un array
        if ((tmpObj.type === 'm') && !Array.isArray(tmpObj[key])) {
            tmpObj[key] = [tmpObj[key]];
        }

        const params = {};
        params[tmpObj.fieldName] = {
            id: tmpObj.fieldId,
            tipo: tmpObj.type,
            valor: tmpObj[key],
        };

        return this.http
        .patch<IntProfileUpdateReply>(`${environment.appApi}/${environment.endpoints.profiledataform}`, params);
    }

    public updateDatasheet(apodo: string, idLocalidad: number | string, idProvincia: number | string): Observable<IntProfileUpdateReply> {

        const params = {
            'apodo': apodo,
            'provincia': '' + idProvincia,
            'localidad': '' + idLocalidad
        };

        return this.http
        .patch<IntProfileUpdateReply>(`${environment.appApi}/${environment.endpoints.profiledatasheet}`, params);
    }

    public uploadProfileImage(image: IntProfileNewImage): Observable<IntPasswordChangeReply> {

        const formdata: FormData = new FormData();
        formdata.append('img', image.img);

        return this.http
        .post<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.profileimageupdate}`, formdata);

    }

    public uploadProfileImageFromFacebook(images: Array<string>): Observable<IntPasswordChangeReply> {

        return this.http
        .post<IntPasswordChangeReply>(`${environment.appApi}/${environment.endpoints.profileimageupdate}`, {img: images});

    }

    public setProfileImage(image: IntProfileV2GcpImage): Observable<IntPasswordChangeReply> {
        return this.http
        .patch<IntPasswordChangeReply>(`${environment.appApi_base}/${environment.endpoints.profileimagedefault_v2}/${image.id}`, {});

    }

    public deleteProfileImage(image: IntProfileV2GcpImage): Observable<IntPasswordChangeReply> {
        return this.http
        .request<IntPasswordChangeReply>('DELETE', `${environment.appApi_base}/${environment.endpoints.profiledeleteimage_v2}/${image.id}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        });
    }

    public getPaywall(promo: string, nick: string, image: string, idabono: string): Observable<IntPaywallReply> {
        if ((typeof promo !== 'undefined') && (typeof nick !== 'undefined') && (typeof image !== 'undefined') && (promo !== null) && (nick !== null) && (image !== null)) {
			return this.http
				.get<IntPaywallReply>(`${environment.appApi_base}${environment.endpoints.paywallget}/${promo}/${nick}/${image}`);
		} else if(typeof promo !== 'undefined' && typeof idabono !== 'undefined') {
			return this.http
				.get<IntPaywallReply>(`${environment.appApi_base}${environment.endpoints.paywallget}/${promo}?idabono=${idabono}`);
        } else if(typeof promo !== 'undefined') {
			return this.http
				.get<IntPaywallReply>(`${environment.appApi_base}${environment.endpoints.paywallget}/${promo}`);
        } else {
            return this.http
            .get<IntPaywallReply>(`${environment.appApi_base}${environment.endpoints.paywallget}`);
        }

    }

    public getTicketCode(idPlan: string, paymentMethod: string): Observable<IntPaywallTicketReply> {
        const formdata: FormData = new FormData();
        formdata.append('idPlan', idPlan);
        formdata.append('paymentMethod', paymentMethod);
        formdata.append('tipoPago', 'ticket');

        return this.http
        .post<IntPaywallTicketReply>(`${environment.appApi}/${environment.endpoints.mercadopago}`, formdata);
    }

    public processMPPurchase(ccPost: IntPaywallCCPost): Observable<IntPaywallCCReply> {
        const formdata: FormData = new FormData();
        // tslint:disable-next-line:forin
        for (const key in ccPost) {
            formdata.append(key, ccPost[key]);
        }

        return this.http
        .post<IntPaywallCCReply>(`${environment.appApi}/${environment.endpoints.mercadopago}`, formdata);
    }

	public setConversionFunnel(data: IntConversionFunnel): Observable<IntConversionFunnelReply> {
		return this.http
			.post<IntConversionFunnelReply>(`${environment.appApi_base}${environment.endpoints.conversionfunnel}`, data);
	}

	public setMobbexCheckout(data: IntMobbexCheckout): Observable<IntMobbexCheckoutSuccess> {
		return this.http
			.post<IntMobbexCheckoutSuccess>(`${environment.appApi_base}${environment.endpoints.mobbexCheckout}`, data);
	}

	public setMobbexSubscriber(data: IntMobbexCheckout): Observable<IntMobbexSubscriberSuccess> {
		return this.http
			.post<IntMobbexSubscriberSuccess>(`${environment.appApi_base}${environment.endpoints.mobbexSetSubscriber}`, data);
	}

	public setAbTestTracker(data: IntAbTest): Observable<any> {
		return this.http
			.post<any>(`${environment.appApi_base}${environment.endpoints.abtesttracker}`, data);
	}

	public deleteDebAutFirst(data: IntCancelAutDebtFirst): Observable<any> {
		const payload = data;

		return this.http
			.request<any>('DELETE', `${environment.appApi}/${environment.endpoints.accountcancelrenewalstep1}`, {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
				body: payload
			});
	}

	public deleteDebAutFinish(): Observable<any> {
		return this.http
			.request<any>('DELETE', `${environment.appApi}/${environment.endpoints.accountcancelrenewalstep3}`, {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
				}),
			});
	}

}
