import {Component, Inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

    password = '';
    error = '';
    loading = false;

    constructor(
        private store: Store<fromRoot.State>,
        private dialogRef: MatDialogRef<TicketComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    goToLink(url: string){
    	window.open(url,'_blank');
	}

    close(){
        this.dialogRef.close();
    }

    confirmar() {

        this.loading = true;
    }

}
