import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as fromRoot from '../../store';
import {select, Store} from '@ngrx/store';
import {
  IntDashboardGetListing,
  IntDashboardSearchParams,
  IntSearchOptionSection
} from '../../store/dashboard/dashboard.model';
import { IntCiudadData, LocalidadesService } from 'src/app/services/localidades.service';
import { Options } from 'ng5-slider';
import { Subscription } from 'rxjs';
import { DashboardInitSearch } from 'src/app/store/dashboard/dashboard.actions';
import { ZcUtilsService } from 'src/app/services/zc-utils.service';
import {MatDialogRef} from '@angular/material'
import {Router} from '@angular/router';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  ciudades: IntCiudadData[] = [];
  localidades: IntCiudadData[] = [];

  sliderDistanciaOptions: Options = {
      hidePointerLabels: true,
      hideLimitLabels: true,
      floor: 0,
      ceil: 100,
      step: 2
  };

  sliderEdadOptions: Options = {
    hidePointerLabels: true,
    hideLimitLabels: true,
    pushRange: true,
    // noSwitching: true,
    floor: 18,
    ceil: 99,
    step: 1
  };

  searchParams: IntDashboardSearchParams = {
    'id_provincia': '',
    'id_localidad': '',
    'distancia': '',
    'edad_min': '',
    'edad_max': '',
    'apodo': '',
    'estado_civil': '',
    'hijos': '',
    'religion': '',
    'busco': '',
    'altura': '',
    'estudios': '',
    'deseo_hijos': '',
    'fuma': '',
    'bebe': '',
    'signo': ''
  };
  // vars para mantener el valor de los input del buscador, tienen que ser diferentes del slider ya que de otra manera el slider implementa su lógica de
  // intercambiar menor / mayor entre ambas variables, si se busca 18 - 20 y luego se quiere cambiar a 22 - 25, al ingresar 22 en edad desde, el slider
  // automaticamente cambia edad min a 20 y max a 22 siendo reflejado esto en los inputs
  search_edad_min: any;
  search_edad_max: any;

  searchOptions: IntSearchOptionSection[] = [];
  subsSearchOptions: Subscription = null;

  constructor(
    private store: Store<fromRoot.State>,
    private localidadesService: LocalidadesService,
    private utils: ZcUtilsService,
    private dialogRef: MatDialogRef<AdvancedSearchComponent>,
	private router: Router
    ) {



      this.localidades = this.localidadesService.getLocalidades();
    }

  ngOnInit() {

	  this.subsSearchOptions = this.store.pipe(select(fromRoot.getDashboardSearchOptions)).subscribe((options) => {
		  console.log(options);
		  this.searchOptions = options;
	  });
  }

  ngOnDestroy(): void {
    if (this.subsSearchOptions !== null) {
        this.subsSearchOptions.unsubscribe();
    }
  }

  advancedSearchClear(close: boolean) {
    this.searchParams = {
      'id_provincia': this.searchParams.id_provincia,
      'id_localidad': this.searchParams.id_localidad,
      'distancia': this.searchParams.distancia,
      'edad_min': this.searchParams.edad_min,
      'edad_max': this.searchParams.edad_max,
      'apodo': '',
      'estado_civil': '',
      'hijos': '',
      'religion': [],
      'busco': '',
      'altura': '',
      'estudios': '',
      'deseo_hijos': '',
      'fuma': '',
      'bebe': '',
      'signo': ''
    };
  }

  advancedSearchClose(){
		this.dialogRef.close();
  }

  doSearch() {
    // if (fail) this.searchParams = {};
    this.searchParams.distancia = '' + this.searchParams.distancia;
    this.store.dispatch(new DashboardInitSearch({type: IntDashboardGetListing.DASH_SEARCH, searchParams: this.searchParams}));
	this.dialogRef.close();
    //this.store.dispatch(new BodyScrollUnLock());
	if(this.router.url !== '/dashboard') {
	  this.router.navigateByUrl('/dashboard');
	}

  }

  updateCiudades() {
    this.searchParams.id_localidad = '';
    this.ciudades = this.localidadesService.getCiudad(parseInt(this.searchParams.id_provincia, 10));
  }

  getDistancia() {
    const c = this.localidadesService.getNombreCiudad(parseInt(this.searchParams.id_provincia, 10), parseInt(this.searchParams.id_localidad, 10));
    if (this.utils.isUndefinedOrNull(this.searchParams.distancia) || (this.searchParams.distancia === '') || (c === null)) {
        this.searchParams.distancia = 0;
    }

    if (c === null) {
        return '';
    } else {
        if ((this.searchParams.distancia === 0) || (this.searchParams.distancia === '0') || (this.searchParams.distancia === '')) {
            return 'Buscar sólo en ' + c.l;
        } else {
            return `Buscar hasta ${this.searchParams.distancia}km. de ${c.l}`;
        }
    }
  }

  getEdad() {
    let emin = parseInt(this.search_edad_min, 10);
    if (isNaN(emin) || emin === 0) {
        emin = 18;
    }

    let emax = parseInt(this.search_edad_max, 10);
    if (isNaN(emax)) {
        emax = 99;
    }

    if ((emin === 18 && emax === 99) || (emax === emin)) {
        return 'Todas las edades';
    } else {
        return `Desde ${emin} hasta ${emax} años`;
    }
  }

  sliderUpdate() {
    this.search_edad_min = this.searchParams.edad_min;
    this.search_edad_max = this.searchParams.edad_max;
  }
}
