import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../store/user/users.service';
import {IntPasswordChange, IntPasswordChangeReply} from '../../store/user/user.model';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import { MatDialogRef } from '@angular/material';
import {md5} from '../../../assets/js/md5';

@Component({
    selector: 'app-changepass',
    templateUrl: './changepass.component.html',
    styleUrls: ['./changepass.component.scss']
})
export class ChangepassComponent implements OnInit {

    data = {
        actual: '',
        nueva: '',
        conf: ''
    };

    error = {
        actual: '',
        nueva: '',
        conf: ''
    };
    loading = false;

    constructor(
        private store: Store<fromRoot.State>,
        private dialogRef: MatDialogRef<ChangepassComponent>,
        public toastrService: ToastrService,
        public userService: UsersService
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

    confirmar() {
        this.error = { actual: '', nueva: '', conf: '' };

        this.error.actual = this.data.actual === '' ? 'Completa todos los campos' : '';
        this.error.nueva = this.data.nueva === '' ? 'Completa todos los campos' : '';
        this.error.conf = this.data.conf === '' ? 'Completa todos los campos' : '';

        if ((this.data.actual === '') || (this.data.nueva === '') || (this.data.conf === '')) {
            return;
        }

        if (this.data.nueva !== this.data.conf) {
            this.error.conf = 'La contraseña no coincide'
            return;
        }

        const pass: IntPasswordChange = {
            old: md5(this.data.actual),
            new: md5(this.data.nueva),
        };

        this.loading = true;

        this.userService.changePassword(pass).subscribe(
            (r: IntPasswordChangeReply) => {
                this.loading = false;
                if (r.status !== 200) {
                    //this.error = r.error.msg || r.error.error || 'Error no especificado al cambiar la contraseña';
                    this.error.actual = r.msg || r.error || 'Error no especificado al cambiar la contraseña';
                    this.error.nueva = r.msg || r.error || 'Error no especificado al cambiar la contraseña';

                } else {
                    this.toastrService.info('Tu contraseña ha sido modificada',
						'Modificar contraseña', {progressBar: true, positionClass: 'toast-top-center'});
                    this.close();
                }
            },
            (r: any) => {
                this.loading = false;
                //this.error = r.error.msg || r.error.error || 'Error no especificado al cambiar la contraseña';
                this.error.actual = r.error.msg || 'Error no especificado al cambiar la contraseña';
            }
        );
    }
}
