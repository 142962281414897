import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {getCurrentUserState, State} from '../store';
import {Observable, of, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {LogInFromStoredToken} from '../store/user/user.actions';
import {UserState} from '../store/user/user.reducer';
import {SetSuscribiteMenu} from '../store/ui/ui.actions';

@Injectable()
export class LoggedUserGuard implements CanActivate {
    constructor(
        private store: Store<State>,
        public router: Router
    ) {
    }

    getFromStoreOrAPI(): Observable<any> {
        return this.store
        .select(getCurrentUserState).pipe(
            tap((userState: UserState) => {
                if ((userState.currentSession === null) || (userState.currentSession.status !== 200)) {
                    if (!userState.signupFromTokenLogin) {
                        // si no se ha disparado un intento previo de login por stored token
                        this.store.dispatch(new LogInFromStoredToken());
                    } else {
                        // generar error
                        throwError('');
                    }
                }
            }),
            filter((userState: UserState) => {
                return (userState.currentSession && userState.currentSession.status && (userState.currentSession.status === 200));
            }),
            take(1)
        );
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => {
            	let menuSuscribite: boolean = false;
            	if(route.url[0].path == 'suscribite'){
            		menuSuscribite = true;
				}
            	this.store.dispatch(new SetSuscribiteMenu(menuSuscribite));
            	return of(true);
            }),
            catchError(() => {
                this.router.navigate(['/']);
                return of(false);
            })
        );

    }
}
