export function MobbexErrorCodes(idCode): string {
		//
		const codes = {
			'400': 'Este precio es válido solo con Tarjeta de Crédito.',
			//'400': 'Declinada', Original
			'403': 'Fallida',
			'410': 'Denegada: Fondos Insuficientes',
			'411': 'Denegada: Autorización Requerida',
			'412': 'Denegada: Comercio Inválido',
			'413': 'Denegada: Tarjeta Inválida',
			'414': 'Denegada: Problema en la Red',
			'415': 'Denegada: Método de Pago Inválido',
			'416': 'Denegada: Operación Inválida',
			'417': 'Denegada: Tarjeta no vigente o vencida',
			'500': 'Error'
		}
		//
		return (codes[idCode]) ? codes[idCode] : codes['500'];
		//
}
