import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IntRecomendadosListing} from './recomendados.model';

@Injectable()
export class RecomendadosService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public listing(): Observable<IntRecomendadosListing> {
        return this.http
        .get<IntRecomendadosListing>(`${environment.appApi_base}/${environment.endpoints.userrecomendados}/`);

    }

}
