import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IntLoginPostData} from '../../store/user/user.model';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {
    LogInUser,
    UserForgot,
    UserForgotReset
} from '../../store/user/user.actions';
import {Observable, Subscription} from 'rxjs';
import {animate, style, transition, trigger} from '@angular/animations';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {UsersService} from '../../store/user/users.service';
import {MatCheckboxChange} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {LoginErrorContainerShow} from '../../store/ui/ui.actions';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('EnterLeave', [
            transition(':enter', [
                style({opacity: '0'}),
                animate('250ms ease', style({opacity: '1'}))
            ]),
            // transition(':leave', [
            //     animate('75ms ease', style({ opacity: '0' }))
            // ])
        ])
    ]
})
export class LoginComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('loginInput') loginInput: ElementRef;
    @ViewChild('forgotInput') forgotInput: ElementRef;

    formdata : FormGroup;
	formForgot: FormGroup;

    showForgotFlag = false;
    showForgotEmailError = false;
    hide = true;

	msgErrorLogin: string = '';

    loginData: IntLoginPostData = {
        email: null,
        password: null,
    };
    loginErrors: any = {
        email: 'El email es obligatorio',
        password: 'Ingresá tu contraseña',
    };
    subErrorLogin: Subscription = null;
	loginErrorApi: boolean = false;

    rememberLoginData = false;

    forgotData: IntLoginPostData = {
        email: null,
        password: null
    };

    loginResult$: Observable<any> = this.store.pipe(select(fromRoot.getUserLoginMessage));
    loginProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserLoginProgress));

    forgotResult$: Observable<string> = this.store.pipe(select(fromRoot.getUserForgotMessage));
    forgotProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserForgotProcess));
    forgotSuccess$: Observable<boolean> = this.store.pipe(select(fromRoot.getUserForgotSuccess));
	isLoginErrorContainer$: Observable<boolean> = this.store.pipe(select(fromRoot.isLoginErrorContainerShow));

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private userService: UsersService,
		private toastr: ToastrService,
    ) {
    }

    ngOnInit() {
        this.formdata = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', Validators.required)

         });
		this.formForgot = new FormGroup({
			forgotEmail: new FormControl('', Validators.required)
		});

        this.subErrorLogin = this.store.pipe(select(fromRoot.getUserLoginMessage)).subscribe( messages => {
			if(messages !== null){
				if(messages.loginError !== undefined){
					this.store.dispatch(new LoginErrorContainerShow(true));
					this.msgErrorLogin = messages.loginErrorMsg;
				}
			}
		});
    }

    login() {
		this.onCloseContainerError();
		this.loginErrorApi = false;
		const controls = this.formdata.controls;
		if (this.formdata.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loginData = {
			email: this.formdata.value.email,
			password: this.formdata.value.password
		};
		this.store.dispatch(new LogInUser({credentials: this.loginData}));
    }

    ngOnDestroy() {
    	if (this.subErrorLogin !== null) {
    		this.subErrorLogin.unsubscribe();
		}

	}

	showForgot() {
        this.store.dispatch(new UserForgotReset());
        this.showForgotFlag = true;
        this.showForgotEmailError = false;
        setTimeout(() => {
            if (this.forgotInput) {
                this.forgotInput.nativeElement.focus();
            }
        }, 250);
    }

    hideForgot() {
        this.store.dispatch(new UserForgotReset());
        this.showForgotFlag = false;
        this.showForgotEmailError = false;
        setTimeout(() => {
            if (this.loginInput) {
                this.loginInput.nativeElement.focus();
            }
        }, 250);
    }

    forgot() {
        if (this.utils.isUndefinedOrNull(this.formForgot.value.forgotEmail) || this.formForgot.value.forgotEmail.trim() === '') {
            this.showForgotEmailError = true;
        } else if (!this.utils.validateEmail(this.formForgot.value.forgotEmail)) {
            this.showForgotEmailError = true;
        } else {
            this.showForgotEmailError = false;
            this.store.dispatch(new UserForgot({forgotData: this.forgotData}));
        }
    }

    ngAfterViewInit() {
    }

	onCloseContainerError(){
		this.store.dispatch(new LoginErrorContainerShow(false));
	}

    setRemember(e: MatCheckboxChange) {
        this.rememberLoginData = e.checked;
    }
}
