import { Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[ngint]'
})
export class NgintDirective {

	constructor(public el: ElementRef) { }

	@HostListener('keydown', ['$event']) onKeyDown(event, callback) {
        var code = 0;

        if (event.which !== undefined) {
            code = event.which;
        } else if (event.keyCode !== undefined) {
            code = event.keyCode;
        }
        else
            return;

		if( ((code>=48) && (code<=57)) || ((code>=96) && (code<=105)) || (code==9) || (code==0) || (code==35) || (code==36) || (code==37) || (code==39) || (code==46) || (code==8) )
            return;
		else
			event.preventDefault();
	}
}
