import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TitleResolver} from './services/title.resolver';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {ProfileComponent} from './modules/profile/profile.component';
import {LoggedUserGuard} from './services/routes.guard';
import {WelcomeComponent} from './modules/welcome/welcome.component';
import {GustaronComponent} from './modules/gustaron/gustaron.component';
import {MensajesComponent} from './modules/mensajes/mensajes.component';
import {CuentaComponent} from './modules/cuenta/cuenta.component';
import {NomegustanComponent} from './modules/nomegustan/nomegustan.component';
import {VisitasComponent} from './modules/visitas/visitas.component';
import {RecoverComponent} from './modules/recover/recover.component';
import {SuscribiteComponent} from './modules/suscribite/suscribite.component';
import {Suscribite_mobbexComponent} from "./modules/suscribite_mobbex/suscribite_mobbex.component";
import {TerminosComponent} from './modules/terminos/terminos.component';
import {DeclaracionComponent} from './modules/declaracion/declaracion.component';
import {AutologinComponent} from './components/autologin/autologin.component';
import {RefundComponent} from './modules/cuenta/refund.component';
import {RecomendadosComponent} from './modules/recomendados/recomendados.component';
import {EmailblockedComponent} from './modules/emailblocked/emailblocked.component';
import {AbtestComponent} from "./modules/abtest/abtest.component";

export const appRoutes: Routes = [

    // public routes
    {
        path: '',
        component: WelcomeComponent,
        data: {title: 'Amor en Línea, Citas Web, Solos y solas, Buscar Pareja | Zonacitas'},
        resolve: {title: TitleResolver}
    },

    {
        path: 'reset_password/change/:token',   // mantener sincronizado con app.component.html:showAnyMenu()
        component: RecoverComponent,
        data: {title: 'Reestablecer contraseña | Zonacitas'},
        resolve: {title: TitleResolver},
    },
	{
		path: 'terminos',
		component: TerminosComponent,
		data: {title: 'Terminos y Condiciones | Zonacitas'},
		resolve: {title: TitleResolver},
	},
	{
		path: 'declaracion',
		component: DeclaracionComponent,
		data: {title: 'Declaracion de Privacidad | Zonacitas'},
		resolve: {title: TitleResolver},
	},
	{
		path: 'autologin/:token/:action',
		component: AutologinComponent,
		data: {title: 'Ingresando.... | Zonacitas'},
		resolve: {title: TitleResolver},
	},
	{
		path: 'autologin/:token/:action/:userid',
		component: AutologinComponent,
		data: {title: 'Ingresando.... | Zonacitas'},
		resolve: {title: TitleResolver},
	},
	{
		path: 'autologin/:token/:action/:userid/:db',
		component: AutologinComponent,
		data: {title: 'Ingresando.... | Zonacitas'},
		resolve: {title: TitleResolver},
	},
	{
		path: 'emailblocked/:email',
		component: EmailblockedComponent,
		data: {title: 'Validacion de Email | Zonacitas'},
		resolve: {title: TitleResolver},
	},

    // guarded routes, add canActivate: [LoggedUserGuard] to ALL routes form here
    {
        path: 'dashboard',
        component: DashboardComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'dashboard/search',
        component: DashboardComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'dashboard/perfil/:id',
        component: DashboardComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'gustaron',
        component: GustaronComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'nomegustan',
        component: NomegustanComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'mensajes',
        component: MensajesComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },
    {
        path: 'mensajes/:tab',
        component: MensajesComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'micuenta',
        component: CuentaComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

	{
		path: 'recomendados',
		component: RecomendadosComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},

    {
        path: 'refund',
        component: RefundComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'visitas',
        component: VisitasComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

	{
		path: 'paywall',
		component: AbtestComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},
	{
		path: 'paywall/:promo',
		component: AbtestComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},
	{
		path: 'suscribitemb',
		component: Suscribite_mobbexComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},
	{
		path: 'suscribitemb/:promo',
		component: Suscribite_mobbexComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},

    {
        path: 'suscribite',
        component: Suscribite_mobbexComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },
	{
		path: 'suscribite/:promo',
		component: Suscribite_mobbexComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},
	{
		path: 'suscribite/:promo/:idabono',
		component: Suscribite_mobbexComponent,
		data: {title: 'Zonacitas'},
		resolve: {title: TitleResolver},
		canActivate: [LoggedUserGuard]
	},
    {
        path: 'suscribite/:promo/:nick/:avatar',
        component: Suscribite_mobbexComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'visitas/:tab',
        component: VisitasComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'perfil',
        component: ProfileComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },

    {
        path: 'perfil/subirfoto',
        component: ProfileComponent,
        data: {title: 'Zonacitas'},
        resolve: {title: TitleResolver},
        canActivate: [LoggedUserGuard]
    },


    {
        path: '**',
        component: WelcomeComponent,
        data: {title: 'Página no encontrada'},
        resolve: {title: TitleResolver}
    }    // not found

];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})


export class AppRoutingModule {
}
