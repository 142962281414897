import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as fromRoot from './store/index';
import {SwUpdate} from '@angular/service-worker';
import {select, State, Store} from '@ngrx/store';
import {IntLoginData} from './store/user/user.model';
import {LogoutUser} from './store/user/user.actions';
import {UserState} from './store/user/user.reducer';
import {from, Observable, Subscription} from 'rxjs';
import {ZcUtilsService} from './services/zc-utils.service';
import {BodyScrollLock, BodyScrollUnLock, SetUploadPhoto} from './store/ui/ui.actions';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {DashboardInitSearch} from './store/dashboard/dashboard.actions';
import {IntDashboardGetListing} from './store/dashboard/dashboard.model';
import {NgxZendeskWebwidgetService} from 'ngx-zendesk-webwidget';
import {ZendeskConfig} from './services/zenddesk_config';

declare var FB: any;
declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

    bodyscrolllock: boolean = false;

    userState: UserState;
    userSession: IntLoginData;
    showMobileSide = false;
    bodyGenderClass = '';
    subs: Subscription = null;
    subsLock: Subscription = null;
    subsGender: Subscription = null;
	subsZendesk: Subscription = null;

    isStaticPage$: Observable<boolean> = this.store.pipe(select(fromRoot.isPageStaic));
    showSuscribiteMenu$: Observable<boolean> = this.store.pipe(select(fromRoot.showMenuSuscribite));

    constructor(
        private store: Store<fromRoot.State>,
        private utils: ZcUtilsService,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef,
        private swUpdate: SwUpdate,
        private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService
    ) {
		// Redirect para MOBILE
		let actualUrl = window.location.href;
		let mobileUrl = actualUrl.replace('https://argentina.zonacitas.com/','https://m.zonacitas.com/');
		if(navigator.userAgent.match(/Android/i)){
			window.location.href=mobileUrl;
		}
		else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){
			window.location.href=mobileUrl;
		}
		//
        this.subs = this.store.pipe(select(fromRoot.getCurrentUserState)).subscribe(userState => {
            this.userState = userState;
            this.userSession = userState.currentSession;
            this.cd.markForCheck();
        });
    }

    goHomePage() {
        if(this.router.url === '/dashboard'){
			this.store.dispatch(new DashboardInitSearch({type: IntDashboardGetListing.DASH_RECENT}));
		}else{
			this.router.navigateByUrl('/dashboard');
		}
    }

    goUploadPhoto() {
        this.store.dispatch(new SetUploadPhoto(true));
        this.router.navigateByUrl('/perfil');
    }

    ngAfterViewInit() {
        this.subsLock = this.store.pipe(select(fromRoot.getCurrentLockStatus)).subscribe(bodyLock => {
            if (bodyLock) {
                this.renderer.addClass(document.body, 'bodyscrolllock');
            } else {
                this.renderer.removeClass(document.body, 'bodyscrolllock');
            }
        });
        this.subsGender = this.store.pipe(select(fromRoot.getCurrentGenderClass)).subscribe(bodyGenderClass => {

			if (this.bodyGenderClass !== '') {
                this.renderer.removeClass(document.body, this.bodyGenderClass);
            }
            this.bodyGenderClass = bodyGenderClass;
            if (this.bodyGenderClass !== '') {
                this.renderer.addClass(document.body, this.bodyGenderClass);
            }
        });

        // $('body').UItoTop();
    }

    async ngOnInit() {

        // zendesk
        await this._NgxZendeskWebwidgetService.initZendesk(new ZendeskConfig());
        this._NgxZendeskWebwidgetService.zE.setLocale('es');
        this.router.events.subscribe(e => {
            const except = ['/suscribite', '/perfil', '/'];
            if (e instanceof NavigationEnd) {
                if (except.indexOf(e.url) === -1) {
					if(this.userSession && this.userSession.sessionInfo.me.billing.premium){
						this._NgxZendeskWebwidgetService.zE('webWidget', 'show');
					}else{
						this._NgxZendeskWebwidgetService.zE('webWidget', 'hide');
					}
                } else {
                    this._NgxZendeskWebwidgetService.zE('webWidget', 'hide');
                }
            }
        });
		this.subsZendesk = this.store.pipe(select(fromRoot.isZendeskShow)).subscribe( show =>{
			if(show) { this._NgxZendeskWebwidgetService.zE('webWidget', 'show'); } else { this._NgxZendeskWebwidgetService.zE('webWidget', 'hide'); }
		});


        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('Hay una nueva versión de Zonacitas, quieres actualizarla?')) {
                    window.location.reload();
                    return;
                }
            });
        }

        if ((typeof window.navigator.onLine !== 'undefined') && (window.navigator.onLine === false)) {
            window.location.href = '/offline.html';
            return;
        }
    }

    ngOnDestroy(): void {
		if(this.subsZendesk !== null){
			this.subsZendesk.unsubscribe();
		}

        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
        if (this.subsLock !== null) {
            this.subsLock.unsubscribe();
        }
        if (this.subsGender !== null) {
            this.subsGender.unsubscribe();
        }
    }

    resetOvers() {
        this.showMobileSide = false;
        this.store.dispatch(new BodyScrollUnLock());
    }

    showMobileSidebar() {
        this.showMobileSide = true;
        this.store.dispatch(new BodyScrollLock());
    }

    logout() {
        this.resetOvers();
        this.store.dispatch(new LogoutUser());
    }

    showAnyMenu() {
        return ((this.userState !== null) && this.userState.attemptLogin);
    }

    isLoggedIn() {
        return this.userSession && (this.userSession.status === 200);
    }

    sectionNavbarExists() {
        return (document.getElementById('section-navbar') != null);
    }
}
