import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/index';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule, DefaultRouterStateSerializer} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {HttpInterceptor} from './services/http.interceptor';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {AppRoutingModule} from './app-routing.module';
import {UsersEffects} from './store/user/user.effect';
import {UsersService} from './store/user/users.service';
import {UiEffects} from './store/ui/ui.effects';
import {JwtModule} from '@auth0/angular-jwt';
import {ToastrModule} from 'ngx-toastr';

import {LoginComponent} from './modules/login/login.component';
import {ProfileComponent} from './modules/profile/profile.component';
import {UserProfileComponent} from './components/userprofile/userprofile.component';
import {LoggedUserGuard} from './services/routes.guard';
import {SignupComponent} from './modules/signup/signup.component';
import {WelcomeComponent} from './modules/welcome/welcome.component';
import {GustaronComponent} from './modules/gustaron/gustaron.component';
import {MensajesComponent} from './modules/mensajes/mensajes.component';
import {CuentaComponent} from './modules/cuenta/cuenta.component';
import {DashboardEffects} from './store/dashboard/dashboard.effect';
import {DashboardService} from './store/dashboard/dashboard.service';
import {GustaronEffects} from './store/gustaron/gustaron.effect';
import {GustaronService} from './store/gustaron/gustaron.service';
import {MensajesEffects} from './store/mensajes/mensajes.effect';
import {MensajesService} from './store/mensajes/mensajes.service';
import {NomegustanComponent} from './modules/nomegustan/nomegustan.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {VisitasComponent} from './modules/visitas/visitas.component';
import {LoadingComponent} from './components/loading/loading.component';
import {LoadingSmComponent} from './components/loading/loading-sm.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {VisitasEffects} from './store/visitas/visitas.effect';
import {VisitasService} from './store/visitas/visitas.service';
import {NoGustaronEffects} from './store/nogustaron/nogustaron.effect';
import {NoGustaronService} from './store/nogustaron/nogustaron.service';
import {
    MatSelectModule, MatCheckboxModule, MatRadioModule, MatInputModule,
    MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE, MatIconModule, MatButtonModule, MatSlideToggleModule, MAT_DIALOG_DATA
} from '@angular/material';
import {TextMaskModule} from 'angular2-text-mask';
import {CropperComponent} from './components/cropper/cropper.component';
import {GalleryComponent} from './components/gallery/gallery.component';
import {ImageComponent} from './components/gallery/image.component';
import {RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha';

// cropper
import {LyThemeModule, LY_THEME} from '@alyle/ui';
import {LyResizingCroppingImageModule} from '@alyle/ui/resizing-cropping-images';
import {MinimaLight} from '@alyle/ui/themes/minima';

// Import only the used modules in order to get a smaller bundle
import {
    NgbModalModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgbNavModule
} from '@ng-bootstrap/ng-bootstrap';

import {BtnescribileComponent} from './components/btnescribile/btnescribile.component';
import {_DialogsServiceModalContent} from './services/dialogs.service';
import {NgintDirective} from './directives/ngint.directive';
import {ClickstoppropagationDirective} from './directives/clickstoppropagate';
import {Ng5SliderModule} from 'ng5-slider';
import {ChangepassComponent} from './modules/cuenta/changepass.component';
import {BajaComponent} from './modules/cuenta/baja.component';
import {UsercardComponent} from './modules/dashboard/usercard.component';
import {SelectfieldComponent} from './modules/profile/selectfield.component';
import {MessagingComponent} from './components/messaging/messaging.component';
import {MessagingEffects} from './store/messaging/messaging.effect';
import {SafehtmlPipe} from './directives/safehtml.pipe';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {RecoverComponent} from './modules/recover/recover.component';
import {ProfilegalleryComponent} from './modules/profile/profilegallery.component';
import {ProfileEffects} from './store/profile/profile.effect';
import {ReporteComponent} from './components/reporte/reporte.component';
import {UiService} from './store/ui/ui.service';
import {BannermktComponent} from './components/bannermkt/bannermkt.component';
import {SuscribiteComponent} from './modules/suscribite/suscribite.component';
import {Suscribite_mobbexComponent} from "./modules/suscribite_mobbex/suscribite_mobbex.component";
import {MatchComponent} from './components/match/match.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ModalesPostRegistroComponent} from './components/modales-post-registro/modales-post-registro.component';
import {AdvancedSearchComponent} from './components/advanced-search/advanced-search.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {EditProfileComponent} from './modules/profile/editprofile.component';
import {TerminosComponent} from './modules/terminos/terminos.component';
import {DeclaracionComponent} from './modules/declaracion/declaracion.component';
import {LoadingCircleComponent} from './components/loading/loading-circle.component';

// Zendesk
import {NgxZendeskWebwidgetModule} from 'ngx-zendesk-webwidget';
import {TicketComponent} from './modules/suscribite/ticket.component';
import {JuegoLikesComponent} from './components/juego-likes/juego-likes.component';
import {AutologinComponent} from './components/autologin/autologin.component';
import {ZendeskConfig} from './services/zenddesk_config';
import {RefundComponent} from './modules/cuenta/refund.component';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {OnlyNumbersDirective} from './directives/onlyNumbers';
import { OnlyAlphaDirective } from './directives/onlyAlpha';
import { RecomendadosComponent } from './modules/recomendados/recomendados.component';
import {RecomendadosEffects} from './store/recomendados/recomendados.effect';
import {RecomendadosService} from './store/recomendados/recomendados.service';
import { EmailblockedComponent } from './modules/emailblocked/emailblocked.component';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';

import {
	SocialLoginModule,
	AuthServiceConfig,
	GoogleLoginProvider,
	FacebookLoginProvider,
	LinkedinLoginProvider
} from 'ng-social-login-module';
import {AbtestComponent} from "./modules/abtest/abtest.component";
import {BajadebautComponent} from "./modules/cuenta/bajadebaut.component";


const CONFIG = new AuthServiceConfig([
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider(environment.facebookAppID)
	},
	/*{
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider('Google-OAuth-Client-Id')
	},
	{
		id: LinkedinLoginProvider.PROVIDER_ID,
		provider: new LinkedinLoginProvider('LINKEDIN_CLIENT_ID')
	}*/
], true);

export function provideConfig() {
	return CONFIG;
}

export function tokenGetter() {
    if (localStorage.getItem(environment.recordarLoginKey) === '1') {
        return localStorage.getItem(environment.accessTokenKey);
    } else {
        return sessionStorage.getItem(environment.accessTokenKey);
    }
}


@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        LoginComponent,
        ProfileComponent,
        UserProfileComponent,
        SignupComponent,
        WelcomeComponent,
        GustaronComponent,
        MensajesComponent,
        CuentaComponent,
        NomegustanComponent,
        SidebarComponent,
        VisitasComponent,
        LoadingComponent,
        LoadingSmComponent,
        CheckboxComponent,
        BtnescribileComponent,
        NgintDirective,
        ClickstoppropagationDirective,
		OnlyNumbersDirective,
		OnlyAlphaDirective,
        _DialogsServiceModalContent,
        ChangepassComponent,
        BajaComponent,
        TicketComponent,
        UsercardComponent,
        SelectfieldComponent,
        MessagingComponent,
        SafehtmlPipe,
        RecoverComponent,
        CropperComponent,
        GalleryComponent,
        ImageComponent,
        ProfilegalleryComponent,
        EditProfileComponent,
        ReporteComponent,
        BannermktComponent,
        SuscribiteComponent,
		Suscribite_mobbexComponent,
		AbtestComponent,
        MatchComponent,
        ModalesPostRegistroComponent,
        AdvancedSearchComponent,
        TerminosComponent,
        DeclaracionComponent,
        LoadingCircleComponent,
        JuegoLikesComponent,
        AutologinComponent,
        RefundComponent,
        RecomendadosComponent,
        EmailblockedComponent,
        EmptyMessageComponent,
		BajadebautComponent
    ],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		AppRoutingModule,
		ToastrModule.forRoot(),
		HammerModule,
		MatSelectModule, MatCheckboxModule, MatRadioModule,
		Ng5SliderModule,
		TextMaskModule,
		MatInputModule,
		MatSlideToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule,
		ReactiveFormsModule,
		NgScrollbarModule,
		AngularSvgIconModule.forRoot(),
		RecaptchaModule,  //this is the recaptcha main module
		RecaptchaFormsModule, //this is the module for form incase form validation

		NgbModalModule, NgbTooltipModule, NgbDropdownModule, NgbTypeaheadModule, NgbNavModule,

		// EmojiModule,
		PickerModule,

		// cropper
		LyThemeModule.setTheme('minima-light'),
		LyResizingCroppingImageModule,

		ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
		StoreModule.forRoot(reducers, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			}
		}),
		EffectsModule.forRoot([UsersEffects, UiEffects, DashboardEffects, GustaronEffects,
			MensajesEffects, VisitasEffects, NoGustaronEffects, MessagingEffects, ProfileEffects, RecomendadosEffects]),
		StoreRouterConnectingModule.forRoot({serializer: DefaultRouterStateSerializer}),
		StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
				whitelistedDomains: environment.whitelistedDomains,
				blacklistedRoutes: environment.blacklistedRoutes

			}
		}),
		MatButtonModule,
		NgxZendeskWebwidgetModule.forRoot(ZendeskConfig), EmojiModule,
		SocialLoginModule
	],
    providers: [
        UsersService,
        DashboardService,
        GustaronService,
        NoGustaronService,
        MensajesService,
        VisitasService,
        UiService,
        LoggedUserGuard,
		RecomendadosService,
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
        {provide: LY_THEME, useClass: MinimaLight, multi: true}, // cropper
        {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
		{
			provide: AuthServiceConfig,
			useFactory: provideConfig
		},
		{provide: Window, useValue: window}
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        _DialogsServiceModalContent,
        ChangepassComponent,
        BajaComponent,
        TicketComponent,
        EditProfileComponent,
        ModalesPostRegistroComponent,
        AdvancedSearchComponent,
        ReporteComponent,
        UserProfileComponent,
        MessagingComponent,
        JuegoLikesComponent
    ]
})
export class AppModule {
}
