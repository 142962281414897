import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {ZcUtilsService} from '../../services/zc-utils.service';
import {
	IntProfileReply,
	IntProfileReplyDatosInputsF,
	IntProfileReplyDatosInputsMultiple,
	IntProfileReplyDatosInputsSingle,
	IntProfileReplyDatosInputsTags
} from '../../store/profile/profile.model';
import {
	ProfileClose,
	ProfileDoDislike,
	ProfileDoLike,
	ProfileDoUndoLike,
	ProfileMoreUsers,
	ProfileOpen
} from '../../store/profile/profile.actions';
import {GalleryComponent, IntGalleryPicture} from '../gallery/gallery.component';
import * as PropMessages from '../../store/messaging/messaging.actions';
import {MessagingOpenChat} from '../../store/messaging/messaging.actions';
import {GaService} from '../../services/ga.service';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ReporteComponent} from '../reporte/reporte.component';
import {Actions, ofType} from '@ngrx/effects';
import {ZendeskShow} from '../../store/ui/ui.actions';
import {actionsModalProfile} from '../../directives/animations';

@Component({
    selector: 'app-user-profile',
    templateUrl: './userprofile.component.html',
    styleUrls: ['./userprofile.component.scss'],
	animations: [actionsModalProfile],
    changeDetection: ChangeDetectionStrategy.Default
})
export class UserProfileComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChildren('picturescontainer', {read: ElementRef}) picturescontainer: QueryList<ElementRef>;
    @ViewChild('galleryComponent') galleryComponent: GalleryComponent;

	animationStateLiked;
	animationStateDisLiked;

    loadingImg = true;

    inProgress$: Observable<boolean> = this.store.pipe(select(fromRoot.getProfileProcess));
    inProcessAction$: Observable<boolean> = this.store.pipe(select(fromRoot.getProfileProcessAction));
    isLoadingChat$: Observable<boolean> = this.store.pipe(select(fromRoot.getMessagingProcess));
    profile: IntProfileReply = null;
	resultMoreProfiles: any = null;
	userPremiun: boolean = false;

    currentUserID: string = null;
    profileSubs: Subscription = null;
    selfSubs: Subscription = null;
    queryListSubs: Subscription = null;
    dashResult: Subscription = null;
	currentSession: Subscription = null;

    // flag para saber si esta haciendo pan y no cerrar la ventana si se hace mouseup sobre backdrop
    isPanning = false;

    isGalleryOpen = false;
    galleryPictures: IntGalleryPicture[] = [];

    expandMasSobreMi = false;
    expandAveriguaBusqueda = false;

    // flag que se setea a true cuando se termina de hacer un pan sobre la imagen, si es true, openGallery setea a falso y retorna
    justPannedFlag = false;

    galleryStartIndex = 0;
    picturesCurrentIndex = 0;
    picturesSwipeRatio = 50;
    picturesCurrentXPosition = 0;
    picturesContainerWidth = 0;

    @HostListener('document:keydown.escape', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.close();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.utils.isUndefinedOrNull(this.picturescontainer) && !this.utils.isUndefinedOrNull(this.picturescontainer.first)) {
            setTimeout(() => {
                const container = this.picturescontainer.first.nativeElement;
                this.picturesContainerWidth = container.getBoundingClientRect().width;
                this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1);
            }, 100);
        }
    }

	isLoadingMoreUsers$: Observable<boolean> = this.store.pipe(select(fromRoot.getMoreUsersInProcess));
	resultsMoreUsers$: Observable<any> = this.store.pipe(select(fromRoot.getMoreUsers));

    constructor(
        private store: Store<fromRoot.State>,
        private cd: ChangeDetectorRef,
        private utils: ZcUtilsService,
        private ga: GaService,
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<UserProfileComponent>,
        private readonly actions$: Actions,
    ) {

		this.animationStateLiked = 'hidden';

        this.selfSubs = this.store.pipe(select(fromRoot.getCurrentUserState)).subscribe(userState => {
            this.currentUserID = userState.currentSession === null ? null : userState.currentSession.sessionInfo.me.id;
			this.userPremiun = userState.currentSession === null ? null : userState.currentSession.sessionInfo.me.billing.premium;
            this.cd.markForCheck();
        });

        this.profileSubs = this.store.pipe(select(fromRoot.getProfile)).subscribe((val) => {

            if ((this.profile !== null) && (val === null)) {
                this.close();
                return;
            }

            // inicializar flags de secciones expandidas
            this.expandAveriguaBusqueda = false;
            this.expandMasSobreMi = false;
            this.isGalleryOpen = false;
            this.picturesCurrentIndex = 0;
            this.picturesCurrentXPosition = 0;

            this.profile = val;

			// load more users
			if(this.profile !== null){
				this.store.dispatch(new ProfileMoreUsers());
			}

            if (!this.utils.isUndefinedOrNull(val)) {
                // obtener fotos para la galería
                if (!this.utils.isUndefinedOrNull(this.profile.data.gcpImages) && !this.utils.isUndefinedOrNull(this.profile.data.gcpImages.approved)) {
                    this.galleryPictures = this.profile.data.gcpImages.approved.reduce((prev, curr) => [...prev, {
                        image: this.utils.getGCPFullImageURL(curr),
                        thumb: this.utils.getGCPImageURL(curr)
                    }], []);

                    if (!this.utils.isUndefinedOrNull(this.picturescontainer) && !this.utils.isUndefinedOrNull(this.picturescontainer.first)) {
                        setTimeout(() => {
                            const container = this.picturescontainer.first.nativeElement;
                            this.picturesContainerWidth = container.getBoundingClientRect().width;
                            this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1);
                        }, 100);
                    }

                } else {
                    this.galleryPictures = [];
                }
            }

            this.moveToPicture(0);

            setTimeout(() => {
                this.cd.markForCheck();
            }, 50);
        });
    }

    private readonly messageSubs = new Subscription();

    ngOnInit() {

		// hide zendesk
		this.store.dispatch(new ZendeskShow(false));

        this.dialogRef.backdropClick().subscribe(() => {
            this.close();
        });
        this.messageSubs.add(
            this.actions$
            .pipe(ofType(PropMessages.MessagingActionTypes.MessagingFail))
            .subscribe(() => {
                this.close();
            })
        );
    }

    ngAfterViewInit() {
        this.queryListSubs = this.picturescontainer.changes.subscribe(
            (next: QueryList<ElementRef>) => {
                if (!this.utils.isUndefinedOrNull(next) && !this.utils.isUndefinedOrNull(next.first)) {
                    setTimeout(() => {
                        // puede haber cambiado
                        if (!this.utils.isUndefinedOrNull(next) && !this.utils.isUndefinedOrNull(next.first)) {
                            const container = next.first.nativeElement;
                            this.picturesContainerWidth = container.getBoundingClientRect().width;
                            this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1);

                            this.moveToPicture(this.picturesCurrentIndex);
                            this.cd.markForCheck();
                        }
                    }, 100);
                }
            });
	}

    ngOnDestroy(): void {

		if(this.userPremiun){
			this.store.dispatch(new ZendeskShow(true));
		}

        if (this.profileSubs !== null) {
            this.profileSubs.unsubscribe();
        }
        if (this.selfSubs !== null) {
            this.selfSubs.unsubscribe();
        }
        if (this.queryListSubs !== null) {
            this.queryListSubs.unsubscribe();
        }
        if (this.dashResult !== null) {
        	this.dashResult.unsubscribe();
		}
    }

    close() {
        if (this.isPanning) {
            // remover el flag para evitar que quede sin poder cerrarse por alguna conjunción de eventos
            this.isPanning = false;
            return;
        }
        this.store.dispatch(new ProfileClose());
        this.dialogRef.close();
    }

    openMoreProfile(iduser){
		//
		this.store.dispatch(new ProfileClose());
		this.dialogRef.close();
		//
		this.store.dispatch(new ProfileOpen({userId: parseInt(iduser, 10), type: 'sent'}));
		this.ga.triggerGenericEvent('evento', 'vista_perfil', 'masperfiles');
		//
	}

    picturesPan(e) {
        if (this.utils.isUndefinedOrNull(this.profile)) {
            return;
        }

        this.isPanning = true;
        const deltaVw = (e.deltaX * 100 / this.picturesContainerWidth);

        this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1) + deltaVw;
    }

    picturesPanEnd(e) {

        // luego de 100ms desactivar flag para evitar que quede activa si el pan terminó fuera del área del perfil
        setTimeout(() => {
            this.isPanning = false;
        }, 100);

        if (this.utils.isUndefinedOrNull(this.profile) || this.utils.isUndefinedOrNull(this.profile.data.gcpImages.approved)) {
            return;
        }

        this.justPannedFlag = true;

        if (e.deltaX < 0) {
            this.moveToPicture(this.picturesCurrentIndex + 1);
        } else {
            this.moveToPicture(this.picturesCurrentIndex - 1);
        }

        // luego de 100ms desactivar flag para evitar que quede activa si el pan terminó fuera del área de la img y el click no se dispoaró
        setTimeout(() => {
            this.justPannedFlag = false;
        }, 100);

        return;

        // if ((Math.abs(e.deltaX) > this.picturesSwipeRatio)) {
        //     if (e.deltaX > 0) {
        //         if (this.picturesCurrentIndex > 0) {
        //             this.picturesCurrentIndex--;
        //         }
        //     } else if (this.picturesCurrentIndex < this.profile.data.gcpImages.approved.length - 1) {
        //         this.picturesCurrentIndex++;
        //     }

        //     this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1);
        // }
    }

    moveToPicture(index: number) {
        if (this.utils.isUndefinedOrNull(this.profile) || this.utils.isUndefinedOrNull(this.profile.data.gcpImages.approved)) {
            return;
        }

        if (index < 0) {
            index = 0;
        }
        if (index > (this.profile.data.gcpImages.approved.length - 1)) {
            index = this.profile.data.gcpImages.approved.length - 1;
        }

        this.picturesCurrentIndex = index;
        this.picturesCurrentXPosition = this.picturesCurrentIndex * this.picturesContainerWidth * (-1);
    }

    openGallery(index: number) {
        if (this.justPannedFlag) {
            this.justPannedFlag = false;
        } else {
            this.galleryStartIndex = index;
            this.isGalleryOpen = true;
        }
    }

    closeGallery() {
        this.isGalleryOpen = false;
    }

    reportar() {
        const userId = this.profile.data.id;
        const userNick = this.profile.data.ficha.apodo_aprobado;
        let reportDialog = this.dialog.open(ReporteComponent, {
            data: {userId: userId, userNick: userNick},
            width: '600px',
            height: '650px',
        });
        reportDialog.afterClosed().subscribe((wasReported) => {
            // If report was executed and not closed, close active profile view
            if (wasReported) {
                this.dialogRef.close();
            }
        });
    }

    dejoDeGustar() {
        this.store.dispatch(new ProfileDoUndoLike({userId: this.profile.data.id}));
    }

    doSendMessage() {
        this.store.dispatch(new MessagingOpenChat({
            userId: parseInt(this.profile.data.id, 10),
            retreiveChatHistory: false,
            userName: this.profile.data.ficha.apodo_aprobado,
            userAvatar: this.utils.getUserImageName(this.profile.data.ficha.gender, this.profile.data.gcpImages.profile),
            gender: this.profile.data.ficha.gender
        }));
		//
		setTimeout(() => {
			this.store.dispatch(new ProfileClose());
			this.dialogRef.close();
		},600);
		//
    }

    doDislike() {
		this.animationStateDisLiked = 'visible';
        this.store.dispatch(new ProfileDoDislike({userId: this.profile.data.id}));
        this.ga.triggerGenericEvent('evento', 'no_me_gusta', 'vista_perfil');
    }

    doLike() {
        this.animationStateLiked = 'visible';
		this.store.dispatch(new ProfileDoLike({userId: this.profile.data.id}));
        this.ga.triggerGenericEvent('evento', 'me_gusta', 'vista_perfil');
    }

    renderDataSingle(data: IntProfileReplyDatosInputsSingle) {
        if (!this.utils.isUndefinedOrNull(data) && !this.utils.isUndefinedOrNull(data.valor_seleccionado)) {
            return data.opciones[data.valor_seleccionado] ? data.opciones[data.valor_seleccionado] : '';
        } else {
            return '';
        }
    }

    renderDataMultiple(data: IntProfileReplyDatosInputsMultiple | IntProfileReplyDatosInputsF) {
        if (!this.utils.isUndefinedOrNull(data) && !this.utils.isUndefinedOrNull(data.valor_seleccionado)) {
            const v = [];

            return data.valor_seleccionado.map(val => data.opciones[val] ? data.opciones[val] : '').join(', ');
        } else {
            return '';
        }
    }

    multipleOptionHasValue(o: IntProfileReplyDatosInputsMultiple | IntProfileReplyDatosInputsF | IntProfileReplyDatosInputsTags) {

        if (this.utils.isUndefinedOrNull(o)) {
            return false;
        } else {
            return !this.utils.isUndefinedOrNull(o.valor_seleccionado) && (o.valor_seleccionado.length > 0);
        }
    }

    singleOptionHasValue(o: IntProfileReplyDatosInputsSingle) {

        if (this.utils.isUndefinedOrNull(o)) {
            return false;
        } else {
            return !this.utils.isUndefinedOrNull(o.valor_seleccionado);
        }
    }

    hasSelectionPersonales() {
        return this.singleOptionHasValue(this.profile.data.personal.datos_personales.inputs.religion) ||
            this.singleOptionHasValue(this.profile.data.personal.datos_personales.inputs.hijos) ||
            this.singleOptionHasValue(this.profile.data.personal.datos_personales.inputs.estado_civil) ||
            this.singleOptionHasValue(this.profile.data.personal.datos_personales.inputs.finalidad);
    }

    hasSelectionApariencia() {
        return this.singleOptionHasValue(this.profile.data.personal.apariencia.inputs.ojos) ||
            this.singleOptionHasValue(this.profile.data.personal.apariencia.inputs.peso) ||
            this.singleOptionHasValue(this.profile.data.personal.apariencia.inputs.estatura) ||
            this.singleOptionHasValue(this.profile.data.personal.apariencia.inputs.cabello_color) ||
            this.singleOptionHasValue(this.profile.data.personal.apariencia.inputs.silueta);
    }

    hasSelectionProfFam() {
        return this.singleOptionHasValue(this.profile.data.personal.profesion_familia.inputs.deseo_hijos) ||
            this.singleOptionHasValue(this.profile.data.personal.profesion_familia.inputs.importancia_matrimonio) ||
            this.singleOptionHasValue(this.profile.data.personal.profesion_familia.inputs.profesion) ||
            this.singleOptionHasValue(this.profile.data.personal.profesion_familia.inputs.actividad) ||
            this.singleOptionHasValue(this.profile.data.personal.profesion_familia.inputs.estudios);
    }

    hasSelectionEstiloVida() {
        return this.singleOptionHasValue(this.profile.data.personal.estilo_vida.inputs.bebes) ||
            this.singleOptionHasValue(this.profile.data.personal.estilo_vida.inputs.fumas);
    }

    hasSelectionIdiomas() {
        return this.multipleOptionHasValue(this.profile.data.personal.idiomas.inputs.idioma);
    }

    hasSelectionPelisMusicaLibros() {
        return this.multipleOptionHasValue(this.profile.data.personal.actividades_vacaciones.inputs.tags_libros) ||
            this.multipleOptionHasValue(this.profile.data.personal.actividades_vacaciones.inputs.tags_musica) ||
            this.multipleOptionHasValue(this.profile.data.personal.actividades_vacaciones.inputs.tags_peliculas);
    }

    hasSelectionActividades() {
        return this.multipleOptionHasValue(this.profile.data.personal.actividades_vacaciones.inputs.actividades_tiempo_libre);
    }

    hasSelectionVacaciones() {
        return this.multipleOptionHasValue(this.profile.data.personal.actividades_vacaciones.inputs.vacaciones);
    }

    hasSelectionBuscaPersonales() {
        return this.singleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_desde) ||
            this.singleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_hasta) ||
            this.singleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_lugar) ||
            this.multipleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_hijos) ||
            this.multipleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_religion) ||
            this.multipleOptionHasValue(this.profile.data.pareja.datos_personales.inputs.bus_estado);
    }

    hasSelectionBuscaApariencia() {
        return this.multipleOptionHasValue(this.profile.data.pareja.apariencia.inputs.bus_cabello_color) ||
            this.multipleOptionHasValue(this.profile.data.pareja.apariencia.inputs.bus_silueta);
    }

    hasSelectionBuscaProfFam() {
        return this.multipleOptionHasValue(this.profile.data.pareja.profesion_familia.inputs.bus_profesion) ||
            this.multipleOptionHasValue(this.profile.data.pareja.profesion_familia.inputs.bus_actividad) ||
            this.multipleOptionHasValue(this.profile.data.pareja.profesion_familia.inputs.bus_estudios) ||
            this.singleOptionHasValue(this.profile.data.pareja.profesion_familia.inputs.bus_deseo_hijos) ||
            this.singleOptionHasValue(this.profile.data.pareja.profesion_familia.inputs.bus_importancia_matrimonio);
    }

    hasSelectionBuscaIdiomas() {
        return this.multipleOptionHasValue(this.profile.data.pareja.idiomas.inputs.bus_idioma);
    }

    hasSelectionnBuscaEstiloVida() {
        return this.singleOptionHasValue(this.profile.data.pareja.estilo_vida.inputs.bus_bebes) ||
            this.singleOptionHasValue(this.profile.data.pareja.estilo_vida.inputs.bus_fumas);
    }

    getDefault(event, gender: any) {
        this.loadingImg = false;
        event.target.src = this.utils.getUserImageURL(gender, null);
    }

}
