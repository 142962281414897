import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../store';
import {Router} from '@angular/router';
import {LogInFromStoredToken} from '../../store/user/user.actions';
import {Subscription} from 'rxjs';
import {ZendeskShow} from '../../store/ui/ui.actions';

declare var $: any;

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // reposition the image
        this.updateSlider();
    }

    attemptedToAutologin = false;
    subs: Subscription = null;

    constructor(
        private router: Router,
        private store: Store<fromRoot.State>
    ) {
		this.store.dispatch(new ZendeskShow(false));
        this.subs = this.store.pipe(select(fromRoot.getCurrentSession)).subscribe(user => {
            if (user === null) {
                if (!this.attemptedToAutologin) {
                    this.attemptedToAutologin = true;
                    this.store.dispatch(new LogInFromStoredToken());
                }
            } else if (user && (user.status === 200)) {
                this.router.navigate(['/dashboard']);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subs !== null) {
            this.subs.unsubscribe();
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        $('#revolution-slider').revolution({
            delay: 9000,
            startwidth: $('body').width(),
            startheight: 500,
            hideThumbs: 0,
            shadow: 0,
            stopAtSlide: -1,
            stopAfterLoops: -1,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            hideSliderAtLimit: 0,
            fullWidth: 'on',
            forceFullWidth: 'on',
            fullScreen: 'off',
            touchenabled: 'on',
            onHoverStop: 'on',
            navigation: {
                arrows:{
                    enable: false,
                },
                bullets: {
                    enable: true,
                    style: 'hesperiden'
                }
            },
            // navigationType: 'bullets',
            // navigationBullets: 'solo',
            // navigationStyle: 'bullets',
            // soloArrowLeftHalign: 'left',
            // soloArrowLeftValign: 'center',
            // soloArrowLeftHOffset: 0,
            // soloArrowLeftVOffset: 0,
            // soloArrowRightHalign: 'right',
            // soloArrowRightValign: 'center',
            // soloArrowRightHOffset: 0,
            // soloArrowRightVOffset: 0,
        });
        this.updateSlider();
    }

    updateSlider() {
        $('#carousel-container').caroufredsel({
            responsive: true,
            circular: true,
            infinite: true,
            items: {
                visible: {
                    min: 1,
                    max: 3
                }
            },
			pagination: {
                container: "#foo2_pag",
                items: 2
            },
            scroll: 1,
            auto: {
                play: false
            }
        });
    }
}
