export enum IntVisitasGetListing {
    Visitas_SENT = 'sent',
    Visitas_RECEIVED = 'received',
}

export interface IntVisitasListingResult {
    age: string;
    city: string;
    gcpImage: string;
    genderId: string;
    image: string;
    province: string;
    timestamp: string;
    userId: string;
    username: string;
    estado: string;
	isNotice: boolean;
}

export interface IntVisitasListingResults {
    'status'?: number;
    'error'?: string;
    'more'?: boolean;
    'data'?: IntVisitasListingResult[];
}
