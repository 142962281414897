import {Action} from '@ngrx/store';
import {IntGustaronGetListing, IntGustaronListingResult, IntGustaronListingResults} from './gustaron.model';

export enum GustaronActionTypes {
    GustaronInitSearch     = '[Gustaron API] Init search',
    GustaronDoSearch       = '[Gustaron API] Do search',
    GustaronInitScroll     = '[Gustaron API] Init Scroll',
    GustaronDoScroll       = '[Gustaron API] Do scroll',
    GustaronCancel         = '[Gustaron API] Cancel like',
    GustaronCanceled       = '[Gustaron API] Canceled like',
    GustaronToggleByID     = '[Gustaron API] Toggle by ID',
    GustaronFail           = '[Gustaron API] Fail',
    GustaronCleanup        = '[Gustaron API] Cleanup',
}

export class GustaronInitSearch implements Action {
    readonly type = GustaronActionTypes.GustaronInitSearch;

    constructor(public payload: { type: IntGustaronGetListing }) {
    }
}
export class GustaronDoSearch implements Action {
    readonly type = GustaronActionTypes.GustaronDoSearch;

    constructor(public payload: { type: IntGustaronGetListing, results: IntGustaronListingResults }) {
    }
}
export class GustaronInitScroll implements Action {
    readonly type = GustaronActionTypes.GustaronInitScroll;

    constructor(public payload: { type: IntGustaronGetListing }) {
    }
}
export class GustaronDoScroll implements Action {
    readonly type = GustaronActionTypes.GustaronDoScroll;

    constructor(public payload: { type: IntGustaronGetListing, results: IntGustaronListingResults }) {
    }
}
export class GustaronFail implements Action {
    readonly type = GustaronActionTypes.GustaronFail;
    constructor(public payload: { results: IntGustaronListingResults }) {
    }
}

export class GustaronCancel implements Action {
    readonly type = GustaronActionTypes.GustaronCancel;

    constructor(public payload: { user: IntGustaronListingResult }) {
    }
}

export class GustaronCanceled implements Action {
    readonly type = GustaronActionTypes.GustaronCanceled;

    constructor(public payload: { user: IntGustaronListingResult }) {
    }
}

export class GustaronToggleByID implements Action {
    readonly type = GustaronActionTypes.GustaronToggleByID;

    constructor(public payload: { userId: string, liked: boolean }) {
    }
}

export class GustaronCleanup implements Action {
    readonly type = GustaronActionTypes.GustaronCleanup;
}


export type GustaronActions =
    GustaronInitSearch
    | GustaronDoSearch
    | GustaronInitScroll
    | GustaronDoScroll
    | GustaronCancel
    | GustaronCanceled
    | GustaronToggleByID
    | GustaronCleanup
    | GustaronFail;
